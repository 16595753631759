import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { RootState } from "redux/reducers";
import { useAuth } from "contexts/AuthContext";

import CatalogFilterList from "./CatalogFilterList";

import { Category, SubCategory } from "types/catalog";
import { generateCategoryLists, handleMarqueeHeading } from "utils/catalog.utils";
import { PTMeta } from "types/pt";

interface PTCategoryFilterProps {
  menu: any;
  hidden: boolean;
  sectionName: string;
}

export const PTCategoryFilter = ({
  menu,
  hidden,
  sectionName,
}: PTCategoryFilterProps) => {
  const { currentUser } = useAuth();
  const dispatch = useDispatch();
  const { subscriptions, pt } = useSelector((state: RootState) => state);
  const [categoryList, setCategoryList] = useState<any[]>(menu);
  const [subCategoryList, setSubCategoryList] = useState<any[]>([]);
  const [ptSubs, setPTSubs] = useState<any[]>([]);
  const [ptSubIds, setPTSubIds] = useState<any[]>([]);
  const [ptFavIds, setPTFavIds] = useState<number[]>([]);

  window.addEventListener("resize", function () {
    handleMarqueeHeading();
    const vw = Math.max(
      document.documentElement.clientWidth || 0,
      window.innerWidth || 0
    );

    if (vw < 1000) {
      document
        .getElementById("CatalogCategoryFilter")
        ?.removeAttribute("style");
      document.getElementById("CatalogFilterLists")?.removeAttribute("style");
    }
  });

  useEffect(() => {
    if (currentUser.usergroupData) {
      // set user's favorites
      if (currentUser.ptFavorites) setPTFavIds(currentUser.ptFavorites);
    }
  }, [currentUser.usergroupData, currentUser.ptFavorites]);

  useEffect(() => {
    if (subscriptions.pt && subscriptions.pt.length > 0) {
      setPTSubs(subscriptions.pt);

      const ids = subscriptions.pt.map((sub) => sub.report_id);
      setPTSubIds(ids);
    }
  }, [subscriptions, dispatch]);

  const category = pt.filters.category;

  useEffect(() => {
    handleMarqueeHeading();
  }, [category]);

  // Subscriptions: Update SubCategoryList based on category state
  useEffect(() => {
    if (pt.filters.subscribed) {
      const handleFilter = (report) => {
        if (ptSubIds.includes(report.pt_id)) return report;
      };

      // Get report items using favIds
      const filteredReports: PTMeta[] = pt.categories.flatMap(
        (category: Category) => {
          return category.sub_categories.flatMap((sub: SubCategory) => {
            return sub.collections.filter(handleFilter);
          });
        }
      );

      generateCategoryLists(
        filteredReports,
        menu,
        pt.filters,
        setCategoryList,
        setSubCategoryList
      );
    } else if (pt.filters.filter === "Favorites" && !pt.filters.subscribed) {
      const handleFilter = (report) => {
        let display = {
          favorites: true,
        };

        if (pt.filters.filter === "Favorites" && ptFavIds) {
          // search user favorites for report
          display.favorites = ptFavIds.includes(report.pt_id);
        }

        if (!Object.values(display).includes(false)) {
          return report;
        }
      };

      // Get report items using favIds
      const filteredReports: PTMeta[] = pt.categories.flatMap(
        (category: Category) => {
          return category.sub_categories.flatMap((sub: SubCategory) => {
            return sub.collections.filter(handleFilter);
          });
        }
      );

      generateCategoryLists(
        filteredReports,
        menu,
        pt.filters,
        setCategoryList,
        setSubCategoryList
      );
    } else {
      setCategoryList(menu);

      let subcategories = menu.find((item) => item.text === category)
        ?.subCategories;
      if (subcategories) {
        setSubCategoryList(subcategories);
      } else {
        setSubCategoryList([]);
      }
    }
  }, [
    category,
    ptSubs,
    ptSubIds,
    ptFavIds,
    subscriptions,
    pt.filters,
    pt.categories,
    menu,
  ]);

  return (
    <Container id="CatalogCategoryFilter" hidden={hidden}>
      <Heading>
        <HeadingLeft>
          <h2 id="heading1">{category !== "" ? category : sectionName}</h2>
          <h2 id="heading2">{category !== "" ? category : sectionName}</h2>
        </HeadingLeft>
      </Heading>
      <Lists id="CatalogFilterLists">
        <CatalogFilterList categoryLevel={"category"} list={categoryList} />
        <CatalogFilterList
          categoryLevel={"subCategory"}
          list={subCategoryList}
        />
      </Lists>
    </Container>
  );
};

const Container = styled.div`
  border: 3px solid ${(props) => props.theme.colors.grey};
  padding: 2rem;
  grid-area: cat;
  max-height: inherit;
`;

const Heading = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 2rem;
`;

const HeadingLeft = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  justify-content: space-between;
  width: 100%;

  overflow: hidden;
  position: relative;

  h2 {
    margin: 0;
    font-size: 2.6rem;
  }

  @keyframes marquee1 {
    0% {
      transform: translateX(0%);
    }
    50% {
      transform: translateX(-150%);
    }
    100% {
      transform: translateX(-150%);
    }
  }

  @keyframes marquee2 {
    0% {
      transform: translateX(50%);
    }
    50% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }

  #heading1 {
    display: inline-block;
    animation: 7.5s linear infinite;
  }

  #heading2 {
    display: inline-block;
    animation: 7.5s linear infinite;
  }
`;

const Lists = styled.div`
  @media screen and (max-width: 1000px) {
    height: 85%;
  }

  display: flex;
  align-content: space-between;
  column-gap: 2rem;
`;

export default PTCategoryFilter;
