import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import axios, { AxiosResponse } from "axios";

import { setNotification } from "redux/actions/notifications";
import { NotificationVariant } from "redux/reducers/notifications";

import { Usergroup } from "types/firebase";
import { ReportMeta } from "types/reports";
import { MedSKUMeta } from "types/medsku";
import { PTMeta } from "types/pt";
import { SurveyMeta } from "types/survey";

import { useAuth } from "contexts/AuthContext";

import StandardButton from "../../../../../Common/buttons/StandardButton";
import Collapse from "../Collapse";

import NewFullCatalogList from "./NewFullCatalogList";
import ReportSummary from "../summary/ReportSummary";
import MedSKUSummary from "../summary/MedSKUSummary";
import PTSummary from "../summary/PTSummary";
import SurveySummary from "../summary/SurveySummary";
import CustomReportSummary from "../summary/CustomReportSummary";

type ReportListProps = { usergroup: Usergroup | undefined; synced: boolean };

// handle when usergroup is undefined?
const ReportList: React.FC<ReportListProps> = ({ usergroup }) => {
  const dispatch = useDispatch();
  const { isAdmin, isSales } = useAuth();

  const [update, setUpdate] = useState<number>(0);
  const [allExpanded, setAllExpanded] = useState<boolean>(false);

  let reportCurrent = useRef<ReportMeta[]>([]);
  const [reportToAdd, setReportToAdd] = useState<ReportMeta[]>([]);
  const [reportToRemove, setReportToRemove] = useState<number[]>([]);
  const [reportLoading, setReportLoading] = useState<boolean>(true);

  let customReportCurrent = useRef<ReportMeta[]>([]);
  // const [customReportToAdd, setCustomReportToAdd] = useState<ReportMeta[]>([]);
  // const [customReportToRemove, setCustomReportToRemove] = useState<number[]>([]);
  const [customReportLoading, setCustomReportLoading] = useState<boolean>(true);

  // let reportSampleCurrent = useRef<ReportMeta[]>([]);
  // const [reportSampleToAdd, setReportSampleToAdd] = useState<ReportMeta[]>([]);
  // const [reportSampleToRemove, setReportSampleToRemove] = useState<number[]>([]);
  // const [reportSampleLoading, setReportSampleLoading] = useState<boolean>(true);

  let medskuCurrent = useRef<MedSKUMeta[]>([]);
  const [medskuToAdd, setMedSKUToAdd] = useState<MedSKUMeta[]>([]);
  const [medskuToRemove, setMedSKUToRemove] = useState<number[]>([]);
  const [medskuLoading, setMedSKULoading] = useState<boolean>(true);

  // let medskuSampleCurrent = useRef<MedSKUMeta[]>([]);
  // const [medskuSampleToAdd, setMedSKUSampleToAdd] = useState<MedSKUMeta[]>([]);
  // const [medskuSampleToRemove, setMedSKUSampleToRemove] = useState<number[]>([]);

  let ptCurrent = useRef<PTMeta[]>([]);
  const [ptToAdd, setPTToAdd] = useState<PTMeta[]>([]);
  const [ptToRemove, setPTToRemove] = useState<number[]>([]);
  const [ptLoading, setPTLoading] = useState<boolean>(true);

  let surveyCurrent = useRef<SurveyMeta[]>([]);
  const [surveyToAdd, setSurveyToAdd] = useState<SurveyMeta[]>([]);
  const [surveyToRemove, setSurveyToRemove] = useState<number[]>([]);
  const [surveyLoading, setSurveyLoading] = useState<boolean>(true);

  // Returns function
  function remove(productType, current, toAdd, setToAdd, toRemove, setToRemove) {
    return function(id: number) {
      const currentIndex = current.findIndex(item => item[`${productType}_id`] === id);
      if (currentIndex !== -1) {
        const toRemoveIndex = toRemove.indexOf(id);
        // Remove current subscription
        // Add to toRemove state if it's not already there
        if (toRemoveIndex === -1) {
          const newToRemove = [...toRemove];
          newToRemove.push(id);
          setToRemove(newToRemove);
        } else {
          // Keep current subscription
          // Remove from toRemove state if it's there
          const newToRemove: number[] = [...toRemove];
          newToRemove.splice(toRemoveIndex, 1);
          setToRemove(newToRemove);
        }
      }

      // Remove from toAdd state if it's found there
      // This removes the subscription right away instead of marking to delete
      // since it was not a part of their existing subscriptions
      const toAddIndex = toAdd.findIndex(item => item[`${productType}_id`] === id);
      if (toAddIndex !== -1) {
        const newToAdd: any[] = [...toAdd];
        newToAdd.splice(toAddIndex, 1);
        setToAdd(newToAdd);
      }
    }
  }

  function add(productType, current, toAdd, setToAdd) {
    return function(product) {
      // Add to toAdd state if it's not already there
      const currentIndex = current.findIndex(item => item[`${productType}_id`] === product[`${productType}_id`]);
      const toAddIndex = toAdd.findIndex(item => item[`${productType}_id`] === product[`${productType}_id`]);

      if (currentIndex === -1 && toAddIndex === -1) {
        dispatch(
          setNotification({
            message:
              `Added subscription to: "${product[`${productType}_name`]}"`,
            variant: NotificationVariant.primary,
          }, true)
        );

        const newToAdd: any[] = [...toAdd];
        newToAdd.push(product);
        setToAdd(newToAdd);
      } else {
        dispatch(
          setNotification({
            message:
              `"${product[`${productType}_name`]}" has already been added to company's subscriptions`,
            variant: NotificationVariant.danger,
          }, true)
        );
      }
    }
  }

  async function save() {
    if (usergroup) {
      try {
        const reportSubs = prepareFinalSubcriptions("report", reportCurrent.current, reportToAdd, reportToRemove);
        const reportFullSubs = reportSubs.filter((sub) => sub.sample === undefined).map((sub) => sub.report_id);
        await axios.post(`/subscriptions/company/${usergroup.id}`, reportFullSubs);

        const reportSampleSubs = reportSubs
          .filter((sub) => sub.sample === true)
          .map((sub) => { return { report_id: sub.report_id, market_name: sub.market_name } });
        await axios.post(`/subscriptions/sample/company/${usergroup.id}`, reportSampleSubs);

        const medskuSubs = prepareFinalSubcriptions("medsku", medskuCurrent.current, medskuToAdd, medskuToRemove);
        const medskuFullSubs = medskuSubs.filter((sub) => sub.demo === undefined).map((sub) => sub.medsku_id);
        await axios.post(`/medsku/subscriptions/company/${usergroup.id}`, medskuFullSubs);

        const medskuSampleSubs = medskuSubs.filter((sub) => sub.demo === true).map((sub) => sub.medsku_id);
        await axios.post(`/medsku/subscriptions/demo/${usergroup.id}`, medskuSampleSubs.filter(onlyUnique));

        const ptSubs = prepareFinalSubcriptions("pt", ptCurrent.current, ptToAdd, ptToRemove);
        const ptFullSubs = ptSubs.filter((sub) => sub.demo === undefined).map((sub) => sub.pt_id);
        await axios.post(`/pt/subscriptions/company/${usergroup.id}`, ptFullSubs);

        const ptSampleSubs = ptSubs.filter((sub) => sub.demo === true).map((sub) => sub.pt_id);
        await axios.post(`/pt/subscriptions/demo/${usergroup.id}`, ptSampleSubs.filter(onlyUnique));

        const surveySubs = prepareFinalSubcriptions("survey", surveyCurrent.current, surveyToAdd, surveyToRemove);
        await axios.post(`/survey/subscriptions/company/${usergroup.id}`, surveySubs.map((sub) => sub.survey_id));

        dispatch(
          setNotification({
            message:
              `Successfully updated subscriptions!`,
            variant: NotificationVariant.success,
          }, true)
        );

        setUpdate(update + 1);
      } catch (error) {
        console.error(error);

        dispatch(
          setNotification({
            message:
              `Something went wrong in updating subscriptions`,
            variant: NotificationVariant.danger,
          }, true)
        );
      }
    }

    function onlyUnique(value, index, array) {
      return array.indexOf(value) === index;
    }
  }

  function prepareFinalSubcriptions(productType, current, toAdd, toRemove) {
    // final = current - toRemove + toAdd
    const subsAfterRemove = current.filter((sub) => !toRemove.includes(+sub[`${productType}_id`]));

    return subsAfterRemove.concat(toAdd);
  }

  useEffect(() => {
    if (usergroup) {
      setAllExpanded(false);

      const fetchSubscriptions = async () => {
        setReportLoading(true);

        const { data } = await axios.get(
          `/subscriptions/company/${usergroup.id}`
        );

        if (data) {
          reportCurrent.current = data.active;
          setReportToAdd([]);
          setReportToRemove([]);

          setReportLoading(false);
        }
      };

      const fetchCustomReports = async () => {
        setCustomReportLoading(true);

        if (
          usergroup.headquarter &&
          usergroup.subsidiaryCompanies !== undefined
        ) {
          let promises: Promise<AxiosResponse>[] = [];
          for (const company of usergroup.subsidiaryCompanies) {
            promises.push(axios.get(`/custom-reports/${company}`));
          }
          let data = [];
          Promise.all(promises).then((responses) => {
            for (const response of responses) {
              data = data.concat(response.data);
            }

            if (data) {
              customReportCurrent.current = data;

              setCustomReportLoading(false);
            }
          });
        } else {
          const { data } = await axios.get(`/custom-reports/${usergroup.id}`);

          if (data) {
            customReportCurrent.current = data;

            setCustomReportLoading(false);
          }
        }
      };

      const fetchMedSKU = async () => {
        setMedSKULoading(true);

        const { data } = await axios.get(
          `/medsku/subscriptions/company/${usergroup.id}`
        );

        if (data) {
          medskuCurrent.current = data.map((sub) => {
            if (sub.medsku_demo_id !== undefined) {
              return {
                ...sub,
                demo: true
              }
            }

            return sub;
          });

          setMedSKUToAdd([]);
          setMedSKUToRemove([]);

          setMedSKULoading(false);
        }
      };

      const fetchPT = async () => {
        setPTLoading(true);

        const { data } = await axios.get(
          `/pt/subscriptions/company/${usergroup.id}`
        );

        if (data) {
          ptCurrent.current = data.map((sub) => {
            if (sub.pt_demo_id !== undefined) {
              return {
                ...sub,
                demo: true
              }
            }

            return sub;
          });

          setPTToAdd([]);
          setPTToRemove([]);

          setPTLoading(false);
        }
      };

      const fetchSurvey = async () => {
        setSurveyLoading(true);

        const { data } = await axios.get(
          `/survey/subscriptions/company/${usergroup.id}`
        );

        if (data) {
          surveyCurrent.current = data;
          setSurveyToAdd([]);
          setSurveyToRemove([]);

          setSurveyLoading(false);
        }
      };

      fetchSubscriptions();
      fetchCustomReports();
      fetchMedSKU();
      fetchPT();
      fetchSurvey();
    }
  }, [usergroup, update])

  return (
    <Container>
      <Heading>
        <h2>Subscriptions Summary</h2>
        <StandardButton size="xs" type="button" onClick={() => setAllExpanded(!allExpanded)}>{allExpanded ? "Hide All" : "See All"}</StandardButton>
      </Heading>

      <div>
        {reportLoading ?
          <Collapse heading="Loading report subscriptions..." forceExpanded={allExpanded}><></></Collapse>
          :
          <Collapse heading={`Reports (${reportCurrent.current.length - reportToRemove.length + reportToAdd.length})`} forceExpanded={allExpanded}>
            <ReportSummary subs={reportCurrent.current} remove={remove("report", reportCurrent.current, reportToAdd, setReportToAdd, reportToRemove, setReportToRemove)} />
            <ReportSummary subs={reportToAdd} remove={remove("report", reportCurrent.current, reportToAdd, setReportToAdd, reportToRemove, setReportToRemove)} />
          </Collapse>
        }

        {customReportLoading ?
          <Collapse heading="Loading MedCore subscriptions..." forceExpanded={allExpanded}><></></Collapse>
          :
          <Collapse heading={`MedCores (${customReportCurrent.current.length})`} forceExpanded={allExpanded}>
            <CustomReportSummary subs={customReportCurrent.current} usergroup={usergroup} />
          </Collapse>
        }

        {medskuLoading ?
          <Collapse heading="Loading MedSKU subscriptions..." forceExpanded={allExpanded}><></></Collapse>
          :
          <Collapse heading={`MedSKU (${medskuCurrent.current.length - medskuToRemove.length + medskuToAdd.length})`} forceExpanded={allExpanded}>
            <MedSKUSummary subs={medskuCurrent.current} remove={remove("medsku", medskuCurrent.current, medskuToAdd, setMedSKUToAdd, medskuToRemove, setMedSKUToRemove)} />
            <MedSKUSummary subs={medskuToAdd} remove={remove("medsku", medskuCurrent.current, medskuToAdd, setMedSKUToAdd, medskuToRemove, setMedSKUToRemove)} />
          </Collapse>
        }

        {ptLoading ?
          <Collapse heading="Loading Procedure Tracker subscriptions..." forceExpanded={allExpanded}><></></Collapse>
          :
          <Collapse heading={`Procedure Tracker (${ptCurrent.current.length - ptToRemove.length + ptToAdd.length})`} forceExpanded={allExpanded}>
            <PTSummary subs={ptCurrent.current} remove={remove("pt", ptCurrent.current, ptToAdd, setPTToAdd, ptToRemove, setPTToRemove)} />
            <PTSummary subs={ptToAdd} remove={remove("pt", ptCurrent.current, ptToAdd, setPTToAdd, ptToRemove, setPTToRemove)} />
          </Collapse>
        }

        {surveyLoading ?
          <Collapse heading="Loading survey subscriptions..." forceExpanded={allExpanded}><></></Collapse>
          :
          <Collapse heading={`Survey (${surveyCurrent.current.length - surveyToRemove.length + surveyToAdd.length})`} forceExpanded={allExpanded}>
            <SurveySummary subs={surveyCurrent.current} remove={remove("survey", surveyCurrent.current, surveyToAdd, setSurveyToAdd, surveyToRemove, setSurveyToRemove)} />
            <SurveySummary subs={surveyToAdd} remove={remove("survey", surveyCurrent.current, surveyToAdd, setSurveyToAdd, surveyToRemove, setSurveyToRemove)} />
          </Collapse>
        }

      </div>

      {isAdmin() ?
        <>
          <h2>Add Subscriptions</h2>
          <div>
            {reportLoading ?
              null :
              <Collapse heading="Reports" forceExpanded={false}>
                <NewFullCatalogList productType={"report"} add={add("report", reportCurrent.current, reportToAdd, setReportToAdd)} />
              </Collapse>
            }

            {medskuLoading ?
              null :
              <Collapse heading="MedSKU" forceExpanded={false}>
                <NewFullCatalogList productType={"medsku"} add={add("medsku", medskuCurrent.current, medskuToAdd, setMedSKUToAdd)} />
              </Collapse>
            }

            {ptLoading ?
              null :
              <Collapse heading="Procedure Tracker" forceExpanded={false}>
                <NewFullCatalogList productType={"pt"} add={add("pt", ptCurrent.current, ptToAdd, setPTToAdd)} />
              </Collapse>
            }

            {surveyLoading ?
              null :
              <Collapse heading="Survey" forceExpanded={false}>
                <NewFullCatalogList productType={"survey"} add={add("survey", surveyCurrent.current, surveyToAdd, setSurveyToAdd)} />
              </Collapse>
            }
          </div>
        </>
        : <></>}

      <UpdateButton type="button" onClick={() => save()} disabled={isSales()}>Update Subscriptions</UpdateButton>
    </Container>
  );
};

const Container = styled.form`
  color: ${(props) => props.theme.colors.idataDarkGrey};
  padding-left: 2rem;
`;

const Heading = styled.div`
  display: flex;
  align-items: center;

  button {
    margin: auto 1rem;
  }
`;

const UpdateButton = styled(StandardButton)`
  width: 25rem;
  margin: 2rem auto;
`;

export default ReportList;
