import React, { useContext, useState, useEffect, useCallback } from "react";
import * as Sentry from "@sentry/browser";
import { useDispatch } from "react-redux";
import { useIdleTimer } from "react-idle-timer";
import axios from "axios";

import {
  clearSubscriptions,
} from "redux/actions/subscriptions";
import { setNotification } from "redux/actions/notifications";
import { setErrors } from "redux/actions/errors";
import { resetCustomReportData } from "redux/actions/custom-report";
import { NotificationVariant } from "redux/reducers/notifications";

import { checkTokenExpiry, setAuthToken } from "utils/token.utils";

import {
  auth,
  createUserProfileObject,
  getUsergroupData,
  getUsergroupFromUser,
  createNewUser,
  setLastActive,
  deleteLastActive,
  getLastActive,
  getDevices,
  addDevice,
  addDeviceCorporate,
} from "../firebase";
import { User } from "types/firebase";
import { fetchCatalogData } from "redux/actions/catalog";

const maxIdleTime = 3600000 * 4; // 1 hour * 4 in milliseconds

const AuthContext = React.createContext({});

export const useAuth: any = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const dispatch = useDispatch();
  const [_currentUser, setCurrentUser] = useState<any>();
  const [loading, setLoading] = useState(true);

  const logout = useCallback(
    async (reason?) => {
      dispatch(clearSubscriptions());
      dispatch(resetCustomReportData());

      await deleteLastActive(auth?.currentUser?.uid);

      return auth
        .signOut()
        .then(() => {
          dispatch(
            setNotification(
              {
                message: reason,
                variant: NotificationVariant.danger,
              },
              false
            )
          );
        })
        .catch((error) => console.error(error));
    },
    [dispatch]
  );

  const handleOnIdle = (event) => {
    if (auth.currentUser) setLastActive(auth.currentUser.uid);
  };

  const handleOnActive = useCallback(async () => {
    let lastActive = undefined;

    if (auth.currentUser)
      lastActive = await getLastActive(auth.currentUser.uid);

    // If specific amount of time since lastActive, automatically log out
    if (lastActive && Date.now() > lastActive + maxIdleTime) {
      logout("You have been inactive for too long. Please log in again.");
      return;
    } else {
      // Reset lastActive
      if (auth.currentUser) {
        setLastActive(auth.currentUser.uid);
      }
    }
  }, [logout]);

  const timeout = 1000;
  useIdleTimer({
    timeout,
    crossTab: true,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
  });

  useEffect(() => {
    // Add observer to watch for user sign in and out
    const authObserver = auth.onAuthStateChanged(async (user) => {
      const idToken = await auth.currentUser
        ?.getIdToken()
        .catch((error) => console.error(error));

      setAuthToken(idToken);
      checkTokenExpiry(idToken, auth);

      if (user) {
        setLoading(true);
        handleOnActive();

        if (user?.providerData[0]?.providerId === "saml.zimmer-biomet") {
          const newUser = {
            uid: user.uid,
            email: user.email ? user.email : "email",
            company: "Zimmer Biomet SSO",
            name: user.email ? user.email : "name",
            phone: "",
            title: "",
            country: "",
            category: "",
            lead: false,
            password: "",
            confirmPassword: "", 
            role: "",
            favorites: [],
            customReports: {
              created: [],
              shared: []
            }, 
            enabled: true
          };

          await createNewUser(newUser);
        }

        const userRef = await createUserProfileObject(user);

        const onNext = async (snapShot) => {
          const data = snapShot.data();

          let usergroupData;

          // populate usergroup data if ref exists
          if (data && data.usergroupRef) {
            try {
              const usergroup = await data.usergroupRef.get();
              usergroupData = await getUsergroupData(usergroup);

              if (usergroupData.headquarter) {
                // Update Headquarter company subscriptions with subsidiary company subscriptions
                await axios.post("/subscriptions/syncHQ", {
                  headquarterId: usergroupData.id,
                  subsidiaryCompanies: usergroupData.subsidiaryCompanies,
                });
              }
            } catch (error) {
              // console.log(error);
            }
          }

          setCurrentUser({
            id: snapShot.id,
            ...data,
            usergroupData,
          });
          setLoading(false);
        };

        const onError = async (error) => {
          return error;
        };

        userRef && userRef.onSnapshot(onNext, onError);
      } else {
        // When there is no user (ie. user has signed out)
        setCurrentUser(null);
        setLoading(false);
      }
    });

    // Return observer method to remove subscription and clean up
    return authObserver;
  }, [dispatch, handleOnActive]);

  const register = async (newUser: User) => {
    if (newUser.password) {
      // Create new user account
      // Automatically signs in when account has successfully been created
      return auth
        .createUserWithEmailAndPassword(newUser.email, newUser.password)
        .then((response) => {
          if (response.user !== null) {
            newUser.uid = response.user.uid;

            // create user profile and link to usergroup
            return createNewUser(newUser);
          }
        })
        .catch((error) => {
          return error;
        });
    }
  };

  const adminCreateUser = async (newUser: User) => {
    axios
      .post("/user/create", {
        email: newUser.email,
        password: newUser.password,
        confirmPassword: newUser.confirmPassword,
      })
      .then((response) => {
        const uid = response.data;
        newUser.uid = uid;
        // create user profile and link to usergroup
        return createNewUser(newUser);
      })
      .catch((error) => {
        throw error.response.data;
      });
  };

  // https://stackoverflow.com/a/49224652
  function getCookie(name) {
    let cookie = {};
    document.cookie.split(";").forEach(function (el) {
      let [k, v] = el.split("=");
      cookie[k.trim()] = v;
    });
    return cookie[name];
  }

  function isRegistered(cookie, devices) {
    return devices.find((device) => device.cookie === +cookie);
  }

  const login = async (email: string, password: string) => {
    auth
      .signInWithEmailAndPassword(email, password)
      .then(async function (userCredential) {
        const user = userCredential.user;
        const deviceLimit = 2;

        const usergroupData = await getUsergroupFromUser(user?.uid);
        const license = usergroupData?.license;

        const usergroupDevicesLimit = usergroupData?.deviceLimit;
        const usergroupUid = usergroupData?.uid;

        if (user) {
          // single, site
          if (license === "single" || license === "site") {
            const devices = await Promise.all(
              await getDevices("users", user?.uid)
            );
            const cookie = getCookie("device");
            Sentry.addBreadcrumb({
              message: `cookie: ${cookie}`,
              level: "debug",
            });

            if (
              !isRegistered(cookie, devices) &&
              email !== "support@idataresearch.net" &&
              email !== "marketing@idataresearch.net"
            ) {
              Sentry.addBreadcrumb({
                message: `devices.length: ${devices.length}, deviceLimit: ${deviceLimit}`,
                level: "debug",
              });

              if (devices.length < deviceLimit) {
                Sentry.addBreadcrumb({
                  message: "devices.length < deviceLimit",
                  level: "debug",
                });

                addDevice(devices, user?.uid);
              } else {
                Sentry.captureMessage(`${email} has exceeded device limit`);

                logout(
                  `You may not log in on more than ${deviceLimit} devices due to your licensing limitation. If you have any questions, please contact support@idataresearch.net.`
                );
              }
            }
          }

          // corporate or enterprise
          if (license === "corporate" || license === "enterprise") {
            const devices = await Promise.all(
              await getDevices("usergroups", usergroupUid)
            );
            const cookie = await getCookie("device");

            if (
              !isRegistered(cookie, devices) &&
              email !== "support@idataresearch.net" &&
              email !== "marketing@idataresearch.net"
            ) {
              if (devices.length < usergroupDevicesLimit) {
                addDeviceCorporate(devices, user.uid, usergroupUid);
              } else {
                logout(
                  `Your current license does not allow your company to log in to more than ${usergroupDevicesLimit} devices. If you have any questions, please contact support@idataresearch.net.`
                );
              }
            }
          }

          // User successfully signed in and is not enrolled with a second factor.
          const idToken = await auth.currentUser?.getIdToken();
          setAuthToken(idToken);
          dispatch(fetchCatalogData());
          setLastActive(userCredential?.user?.uid);

          // Sentry.captureMessage(`${email} has successfully logged in`);
        }
      })
      .catch(function (error) {
        dispatch(setErrors({ message: error.message }));
      });
  };

  const resetPassword = (email: string) => {
    return auth.sendPasswordResetEmail(email);
  };

  const updatePassword = async (password: string) => {
    try {
      if (auth.currentUser) {
        await auth.currentUser.updatePassword(password);
      }
    } catch (error) {
      throw error;
    }
  };

  /* auth.currentUser has the most up to date information
   _currentUser is a state so it will cause a re-render when necessary
   re-rendering will help with redirecting the user to the home page
   when they have reached the number of devices they can be logged in with */
  let currentUser;

  if (auth.currentUser) {
    currentUser = _currentUser;
  } else {
    currentUser = null;
  }

  const isAdmin = () => {
    if (currentUser.role === "admin") return true;
    return false;
  };

  const isSales = () => {
    if (currentUser.role === "sales") return true;
    return false;
  };

  const value = {
    currentUser,
    isAdmin,
    isSales,
    register,
    adminCreateUser,
    login,
    logout,
    resetPassword,
    updatePassword,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
