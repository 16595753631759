import React, { useEffect } from "react";
import styled from "styled-components";

import { MenuItem } from "types";
import { useAuth } from "contexts/AuthContext";
import { navbar } from "data/menus/navbar";

import MainMenuItem from "./MainMenuItem";

const MainMenu: React.FC = () => {
  const { currentUser } = useAuth();

  // Add Google Translate API CDN here instead of index.html so that
  // it runs when the Google Translate div element is rendered
  // https://stackoverflow.com/a/34425083 - Adding script tag to React/JSX
  // https://www.coderepublics.com/howto/how-to-google-translate.php -
  // Add Google Translate to site
  useEffect(() => {
    if (process.env.NODE_ENV !== "development") {
      const script = document.createElement("script");
      script.src =
        "https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
      script.async = true;
      document.body.appendChild(script);
  
      return () => {
        document.body.removeChild(script);
      };
    }
  }, []);

  function removeExtraNodes(className) {
    const nodes = document.querySelectorAll(className);
    if (nodes.length > 1) nodes[1].remove();
  }

  removeExtraNodes(".goog-te-combo");
  removeExtraNodes(".goog-te-gadget");

  const handleAccess = (menu: MenuItem) => {
    if (menu.access.length > 0) {
      return currentUser && menu.access.includes(currentUser.role);
    } else if (currentUser && menu.key === "signin") {
      return false;
    }

    return menu;
  };

  return (
    <Container>
      {navbar
        .filter(({ menu }) => handleAccess(menu))
        .map(({ menu }) => (
          <MainMenuItem
            key={menu.key}
            menu={menu}
            isDropdown={menu.childMenu && menu.childMenu.length > 0}
          />
        ))}
    </Container>
  );
};

const Container = styled.ul`
  display: flex;
  flex-wrap: nowrap;
  margin: 0;
  @media screen and (max-width: 1290px) {
    display: none;
  }
`;

export default MainMenu;
