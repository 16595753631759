import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";

import { useAuth } from "contexts/AuthContext";
import { generatePageTitle } from "utils/general.utils";
import { generateReportUrl } from "utils/report.utils";

// function reportWindowSize() {
//   const vw = Math.max(
//     document.documentElement.clientWidth || 0,
//     window.innerWidth || 0
//   );
//   const vh = Math.max(
//     document.documentElement.clientHeight || 0,
//     window.innerHeight || 0
//   );

//   // Update iframe width and height based on the window size
//   document
//     .getElementById("Dashboard")
//     ?.setAttribute("style", `width: ${vw - 17}px; height: ${vh}px;`);
// }

// window.addEventListener("resize", reportWindowSize);

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const Dashboard: React.FC = () => {
  const location = useLocation();
  const query = useQuery();
  const navigate = useNavigate();

  const { id } = useParams<{ id: string }>();
  const { currentUser } = useAuth();
  const usergroupId = currentUser.usergroupData.id;
  const [path, setPath] = useState<string>("");

  const idToken = axios.defaults.headers.common["Authorization"].split(
    "Bearer "
  )[1];

  // reportWindowSize();

  let domain = "";

  if (process.env.NODE_ENV !== "production") {
    // REACT_APP_PORT useful with multiple Git worktrees
    domain = `http://localhost:501${process.env.REACT_APP_PORT ?
      process.env.REACT_APP_PORT.substring(3) : '0'}`;
  }

  useEffect(() => {
    // MedCore global report dashboard
    // Check to see if the first part of the path is a numerical ID or not
    // MedCore version of path does not have numerical ID
    if (Number.isNaN(+location.pathname.split("/")[3])) {
      axios.get(`/custom-reports/info/${id}`).then((response) => {
        setPath(
          `/market?report=${response.data.report_id}&customReport=${response.data.c_report_id}&location=all&idToken=${idToken}&usergroupId=${usergroupId}`
        );

        if (response.data.c_report_name) {
          document.title = generatePageTitle(
            `${response.data.c_report_name} - Dashboard`
          );
        } else {
          document.title = generatePageTitle(`Dashboard`);
        }
      });
    } else if (location.pathname.split("/")[1] === "medsku") {
      setPath(
        `/medsku/marketOverview?report=${id}&idToken=${idToken}&usergroupId=${usergroupId}&demo=${query.get("demo")}`
      );

      axios.get(`/medsku/info/${id}`).then((response) => {
        const name = response.data.medsku_name;
        if (name) {
          document.title = generatePageTitle(
            `${name} - MedSKU Dashboard`
          );
          navigate(`./${generateReportUrl(name)}`, { replace: true });
        } else {
          document.title = generatePageTitle(`MedSKU Dashboard`);
        }
      });
    } else if (location.pathname.split("/")[1] === "pt") {
      setPath(`/pt?report=${id}&idToken=${idToken}&usergroupId=${usergroupId}&demo=${query.get("demo")}`);

      axios.get(`/pt/info/${id}`).then((response) => {
        const name = response.data.pt_name;
        if (name) {
          document.title = generatePageTitle(
            `${name} - Procedure Tracker Dashboard`
          );
          navigate(`./${generateReportUrl(name)}`, { replace: true });
        } else {
          document.title = generatePageTitle(`Procedure Tracker Dashboard`);
        }
      });
    } else if (location.pathname.split("/")[1] === "survey") {
      setPath(`/survey?report=${id}&idToken=${idToken}&usergroupId=${usergroupId}`);

      axios.get(`/survey/info/${id}`).then((response) => {
        const name = response.data.survey_name;
        if (name) {
          document.title = generatePageTitle(
            `${name} - Procedure Tracker Dashboard`
          );
          navigate(`./${generateReportUrl(name)}`, { replace: true });
        } else {
          document.title = generatePageTitle(`Procedure Tracker Dashboard`);
        }
      });
    } else {
      // global report dashboard
      const sample = query.get("sample");
      const market = query.get("market");

      if (!sample) {
        // full
        setPath(
          `/market?report=${id}&location=all&idToken=${idToken}&usergroupId=${usergroupId}`
        );
      } else {
        // sample
        setPath(
          `/market?report=${id}&location=all&idToken=${idToken}&usergroupId=${usergroupId}&sample=${sample}&market=${market}`
        );
      }

      axios.get(`/reports/report-info/${id}`).then((response) => {
        const name = response.data.report_name;
        if (name) {
          document.title = generatePageTitle(
            `${name} - Dashboard`
          );
          navigate(`./${generateReportUrl(name)}`, { replace: true });
        } else {
          document.title = generatePageTitle(`Dashboard`);
        }
      });

    }

    // Set favicon
    const favicons = document.getElementsByClassName("favicon");
    for (let index = 0; index < favicons.length; index++) {
      const element = favicons[index];
      element.setAttribute("href", "/bar-chart.svg");
    }
  }, [id, location.pathname, idToken, usergroupId]);

  return (
    <Iframe
      id="Dashboard"
      title="Dashboard"
      frameBorder="0"
      scrolling="yes"
      src={domain + path}
      name="Dashboard"
    ></Iframe>
  );
};

const Iframe = styled.iframe`
  position:fixed;
  top:0;
  left:0;
  bottom:0;
  right:0;
  width:100%;
  height:100%;
  border:none;
  margin:0;
  padding:0;
  overflow:hidden;
  z-index:999999;
`;

export default Dashboard;
