import React, { useEffect } from "react";
import axios from "axios";
import styled from "styled-components";

import { generatePageTitle } from "utils/general.utils";

function reportWindowSize() {
  const vw = Math.max(
    document.documentElement.clientWidth || 0,
    window.innerWidth || 0
  );
  const vh = Math.max(
    document.documentElement.clientHeight || 0,
    window.innerHeight || 0
  );

  // Update iframe width and height based on the window size
  document
    .getElementById("PT")
    ?.setAttribute("style", `width: ${vw - 150}px; height: ${vh}px;`);
}

window.addEventListener("resize", reportWindowSize);

const ImportPTDashboard: React.FC = () => {
  const idToken = axios.defaults.headers.common["Authorization"].split(
    "Bearer "
  )[1];

  useEffect(() => {
    document.title = generatePageTitle("Import Procedure Tracker Dashboard");
  });

  reportWindowSize();

  if (process.env.NODE_ENV === "production") {
    return (
      <Iframe
        id="PT"
        title="PT"
        frameBorder="0"
        scrolling="yes"
        src={`/pt/importdata?idToken=${idToken}`}
      ></Iframe>
    );
  } else {
    return (
      <Iframe
        id="PT"
        title="PT"
        frameBorder="0"
        scrolling="yes"
        src={`http://localhost:5010/pt/importdata?idToken=${idToken}`}
      ></Iframe>
    );
  }
};

const Iframe = styled.iframe`
  height: 100vh;
  width: 92vw;
`;

export default ImportPTDashboard;
