import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { CONTACT_US } from "data/email";
import { countries, categories } from "data/menus/formOptions";

import { clearErrors } from "redux/actions/errors";
import { sendContactUsMessage } from "redux/actions/admin";
import { RootState } from "redux/reducers";

import FormInput from "../../../Common/forms/FormInput";
import SelectInput from "../../../Common/forms/SelectInput";
import TextAreaInput from "../../../Common/forms/TextAreaInput";
import StandardButton from "../../../Common/buttons/StandardButton";

const ContactForm: React.FC = () => {
  const dispatch = useDispatch();
  const errors = useSelector((state: RootState) => state.errors);
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [title, setTitle] = useState<string>("");
  const [company, setCompany] = useState<string>("");
  const [country, setCountry] = useState<string>("");
  const [category, setCategory] = useState<string>("");
  const [message, setMessage] = useState<string>("");

  useEffect(() => {
    return () => {
      dispatch(clearErrors());
    };
  }, [dispatch]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    dispatch(clearErrors());

    const userMessage = {
      name: `${name}`,
      email,
      phone,
      title,
      company,
      country,
      category,
      message,
      subject: CONTACT_US,
      other: "",
    };

    dispatch(sendContactUsMessage(userMessage));
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Info>
        Please contact us at +1 (604) 266-6933 or fill in the following form and
        someone will be in touch with you as soon as possible.
      </Info>
      <FormInput
        placeholder="Name *"
        name="name"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setName(e.target.value)
        }
        value={name}
        error={errors.name}
      />
      <FormInput
        type="email"
        placeholder="Email *"
        name="email"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setEmail(e.target.value)
        }
        value={email}
        error={errors.email}
      />
      <FormInput
        type="tel"
        placeholder="Phone *"
        name="phone"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setPhone(e.target.value)
        }
        value={phone}
        error={errors.phone}
      />
      <FormInput
        placeholder="Professional Title"
        name="title"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setTitle(e.target.value)
        }
        value={title}
      />
      <FormInput
        placeholder="Company *"
        name="company"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setCompany(e.target.value)
        }
        value={company}
        error={errors.company}
      />
      <SelectInput
        name="country"
        options={countries}
        value={country}
        onChange={(e) => setCountry(e.target.value)}
      />
      <SelectInput
        name="category"
        options={categories}
        value={category}
        onChange={(e) => setCategory(e.target.value)}
      />
      <TextAreaInput
        name="message"
        placeholder="Your message... *"
        onChange={(e) => setMessage(e.target.value)}
        value={message}
        error={errors.message}
      />
      <StandardButton type="submit">Submit</StandardButton>
    </Form>
  );
};

const Form = styled.form`
  background-color: #fff;
  border: 1px solid ${(props) => props.theme.colors.textGrey};
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
  padding: 5.5rem;
  @media screen and (max-width: ${(props) => props.theme.bp.sm}px) {
    padding: 2.5rem;
  }
`;

const Info = styled.p`
  grid-column: 1 / -1;
  margin-top: -3rem;
  padding: 2rem 0;
`;

export default ContactForm;
