import { Dispatch } from "redux";
import axios from "axios";

import { getAllUsergroups } from "../../firebase";
import { setErrors } from "./errors";
import { setNotification } from "./notifications";
import { NotificationVariant } from "../reducers/notifications";
import { Usergroup } from "types/firebase";
import { UserInvite } from "types/admin";

export const GET_USERGROUPS = "GET_USERGROUPS";
export const GET_AUTH_REQUESTS = "GET_AUTH_REQUESTS";
export const TOGGLE_RECOMMENDING = "TOGGLE_RECOMMENDING";
export const UPDATE_REPORT_ID_REC_FOR = "UPDATE_REPORT_ID_REC_FOR";

// Gets collection of all usergroups
export const getUsergroups = (
  setLoading?: (loading: boolean) => void
) => async (dispatch: Dispatch) => {
  try {
    const usergroups = await getAllUsergroups();

    dispatch({
      type: GET_USERGROUPS,
      payload: usergroups,
    });

    if (setLoading) {
        setLoading(false);
      }
  } catch (error) {
    dispatch(setErrors(error));
  }
};

// Gets all pending user requests
export const getAuthRequests: any = () => async (dispatch: Dispatch) => {
  try {
    const authRequests = await axios.get(
      "/usergroups/team-management/auth-requests"
    );
    dispatch({
      type: GET_AUTH_REQUESTS,
      payload: authRequests.data,
    });
  } catch (error) {
    dispatch(setErrors(error));
  }
};

// Creates user pending object in database
export const createUserAuthorizationRequest = (
  user: UserInvite,
  usergroup: Usergroup
) => async (dispatch: Dispatch) => {
  // check new user doesn't already exist
  const exists =
    usergroup.users && usergroup.users.find((u) => u.email === user.email);
  if (exists) {
    dispatch(setErrors({ email: "User already exists" }));
    return;
  }

  try {
    const { data } = await axios.post(
      "/usergroups/team-management/auth-requests",
      user
    );

    dispatch(
      setNotification({
        message: data.success,
        variant: NotificationVariant.primary,
      })
    );
  } catch (error) {
    if (axios.isAxiosError(error)) {
      dispatch(setErrors(error));

      if (
        error?.response?.data === "Too many requests, please try again later."
      ) {
        dispatch(
          setNotification({
            message:
              "Too many Team Member invitations have been sent from your IP address, please try again later.",
            variant: NotificationVariant.danger,
          })
        );
      }
    }
  }
};

// approval from admin panel in app
export const approveAuthRequest = (user: UserInvite) => async (
  dispatch: Dispatch
) => {
  try {
    await axios.post("/usergroups/team-management/register-request", user);
    dispatch(getAuthRequests());
  } catch (error) {
    dispatch(setErrors(error));
  }
};

// approval from email
export const approveAuthRequestByEmail = async (token: any) => {
  const body = { token };
  const { data } = await axios.post(
    "/usergroups/team-management/register-request-email",
    body
  );
  return data;
};

// validate token for new users filling out registration form
export const validateTempUser = async (token: any, navigate: any) => {
  const body = { token };

  try {
    const { data } = await axios.post(
      "/usergroups/team-management/verify-register-token",
      body
    );
    return data;
  } catch (error) {
    return navigate("/");
  }
};

export const toggleRecommending: any = () => (dispatch: Dispatch) => {
  dispatch({
    type: TOGGLE_RECOMMENDING,
  });
};

export const updateReportIdRecFor = (reportId: number) => (
  dispatch: Dispatch
) => {
  dispatch({
    type: UPDATE_REPORT_ID_REC_FOR,
    payload: reportId,
  });
};

// Send contact form message
export const sendContactUsMessage = (
  message: object,
  sendToggleModal?: Function
) => async (dispatch: Dispatch) => {
  try {
    const { data } = await axios.post("/contact/contact-us", message);
    dispatch(
      setNotification({
        message: data,
        variant: NotificationVariant.success,
      })
    );

    if (sendToggleModal) {
      sendToggleModal(false);
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      dispatch(setErrors(error));

      if (
        error?.response?.data === "Too many requests, please try again later."
      ) {
        dispatch(
          setNotification({
            message:
              "Too many requests have been sent from your IP address, please try again later.",
            variant: NotificationVariant.danger,
          })
        );
      }
    }
  }
};

// get backlog of subscriptions from ido v1
export const syncSubscriptions = async (user: object) => {
  try {
    await axios.post("/subscriptions/sync", user);
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error(error.message);
    }
  }
};
