export const createNewUserObject = (user) => {
  const name = user.name ? user.name : `${user.firstName} ${user.lastName}`;
  const role = user.lead ? "team leader" : "standard";

  const userObj: any = {
    name,
    role,
    enabled: true,
    company: user.company,
    phone: user.phone,
    title: user.title,
    country: user.country,
    category: user.category,
    favorites: [],
  };

  if (user.usergroupRef) {
    userObj.usergroupRef = user.usergroupRef;
  }

  return userObj;
};
