import React, { useEffect, useState } from "react";
import styled from "styled-components";

import arrowDownIcon from "assets/icons/arrow-down-dark.svg";
import arrowUpIcon from "assets/icons/chevrons-right-dark.svg";

const Collapse = ({ heading, children, forceExpanded }) => {
  const [expanded, setExpanded] = useState<boolean>(false);

  useEffect(() => {
    setExpanded(forceExpanded);
  }, [forceExpanded])

  return (
    <div>
      <Heading onClick={() => setExpanded(!expanded)} >
        <div>{heading}</div>
        <img src={expanded ? arrowDownIcon : arrowUpIcon} alt="arrow" />
      </Heading>
      <Children $display={expanded}>
        {children}
      </Children>
    </div>
  );

};

const Heading = styled.div`
  display: flex;
  align-items: center;
  
  margin-bottom: 1rem;

  font-weight: bold;

  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }
  transition: all 0.2s;
`;

const Children = styled.div<{ $display?: boolean; }>`
  display: ${props => props.$display ? "block" : "none"};
`;

export default Collapse;
