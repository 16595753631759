import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import launchIcon from "assets/icons/launch-white.svg";
import { getUsergroups } from "redux/actions/admin";

import StandardButton from "../../../../Common/buttons/StandardButton";
import NewUserForm from "./NewUserForm";
import NewUsergroupForm from "./NewUsergroupForm";

import { generatePageTitle } from "utils/general.utils";

const ManageClients: React.FC = () => {
  const dispatch = useDispatch();
  let dashboardUrl: string | undefined;

  if (process.env.NODE_ENV === "production") {
    dashboardUrl = process.env.REACT_APP_PROD_FIREBASE_DASHBOARD_URL;
  } else {
    dashboardUrl = process.env.REACT_APP_FIREBASE_DASHBOARD_URL;
  }

  useEffect(() => {
    document.title = generatePageTitle("Manage Clients");
  });

  useEffect(() => {
    dispatch(getUsergroups());
  }, [dispatch]);

  return (
    <Container>
      <PageTitle>Manage Clients</PageTitle>
      <hr />
      <FormsContainer>
        <NewUsergroupForm />
        <NewUserForm />
      </FormsContainer>
      <hr />
      <a href={dashboardUrl} target="_blank" rel="noopener noreferrer">
        <CustomButton type="button" mt="5rem">
          Open Firebase Dashboard
          <img src={launchIcon} alt="launch icon" />
        </CustomButton>
      </a>
    </Container>
  );
};

const Container = styled.div`
  max-width: 100rem;
  margin: auto;
  text-align: center;
`;

const PageTitle = styled.h2`
  color: ${(props) => props.theme.colors.idataDarkGrey};
  margin-bottom: 3rem;
`;

const CustomButton = styled(StandardButton)`
  max-width: 35rem;
  margin: 5rem auto;
`;

const FormsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 5rem;

  @media screen and (max-width: ${(props) => props.theme.bp.lg}px) {
    flex-direction: column;
  }
`;

export default ManageClients;
