import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

import generatePaginationGroupings from "utils/generatePaginationGroupings";
import { generateReportUrl } from "utils/report.utils";
import leftArrow from "assets/icons/chevron_left-black-24dp.svg";
import rightArrow from "assets/icons/chevron_right-black-24dp.svg";
import { ReportMeta } from "types/reports";

type TableProps = { collection: ReportMeta[] };
type ButtonProps = { readonly disabled: boolean };

const PaginatedTable: React.FC<TableProps> = ({ collection }) => {
  const [selectedGroup, setSelectedGroup] = useState<number>(0);
  const groupings = generatePaginationGroupings(collection, 10);

  const handleFilter = (user: any, i: number) => {
    if (collection.length > 10 && groupings[selectedGroup]) {
      const firstIndex = groupings[selectedGroup][0];
      const lastIndex = groupings[selectedGroup][1];

      return i > firstIndex && i <= lastIndex;
    }

    return true;
  };

  return (
    <Table>
      <thead>
        <TableRow>
          <TableHead>
            <div className="th-data">Report Name</div>
          </TableHead>
          <TableHead>
            <div className="th-data">Year</div>
          </TableHead>
        </TableRow>
      </thead>
      <tbody>
        {collection.filter(handleFilter).map((item) => {
          const isSub = item.hasOwnProperty("subscription_id");

          if (item.report_name) {
            const url = generateReportUrl(item.report_name);

            return (
              <TableRow key={item.archive_id + " " + item.report_id}>
                <TableData>
                  {isSub ? (
                    <Link
                      to={`/reports/${item.report_id}/${url}/1`}
                      target="_blank"
                    >
                      {item.report_name}
                    </Link>
                  ) : (
                    item.report_name
                  )}
                </TableData>
                <TableData>{item.report_year}</TableData>
              </TableRow>
            );
          } else {
            return null;
          }
        })}
      </tbody>
      <tfoot>
        <tr>
          <FooterData>Total: {collection.length}</FooterData>
          <FooterData>
            {collection.length > 10 ? (
              <>
                <PaginationControl
                  onClick={() => setSelectedGroup(selectedGroup - 1)}
                  disabled={groupings[selectedGroup][0] <= 0}
                >
                  <img src={leftArrow} alt="left arrow icon" />
                </PaginationControl>
                <PaginationControl
                  onClick={() => setSelectedGroup(selectedGroup + 1)}
                  disabled={groupings[selectedGroup][1] >= collection.length}
                >
                  <img src={rightArrow} alt="right arrow icon" />
                </PaginationControl>
              </>
            ) : (
              <div>&nbsp;</div>
            )}
          </FooterData>
        </tr>
      </tfoot>
    </Table>
  );
};

const Table = styled.table`
  border-collapse: collapse;
  border-style: hidden;
  border-radius: 4px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.3);
  color: ${(props) => props.theme.colors.idataDarkGrey};
  margin-bottom: 5rem;
  overflow: hidden;
  text-align: left;
  width: 100%;
`;

const TableData = styled.td`
  background-color: #fff;
  padding: 1.5rem 2rem;
`;

const TableRow = styled.tr`
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;

const TableHead = styled.th`
  background-color: #fff;
  padding: 1.5rem 2rem;
`;

const FooterData = styled.td`
  background-color: #fff;
  padding: 1.5rem 2rem;
  padding-top: 5rem;
  min-width: 12rem;
`;

const disabledStyles = () => css`
  opacity: 0.3;
  pointer-events: none;
`;

const PaginationControl = styled.button<ButtonProps>`
  ${(props) => (props.disabled ? disabledStyles() : null)};
`;

export default PaginatedTable;
