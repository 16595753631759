import React from "react";
import styled from "styled-components";

type InputProps = {
  placeholder: string;
  name: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  type?: string;
  error?: string;
  disabled?: boolean;
};

const FormInput: React.FC<InputProps> = ({
  type = "text",
  placeholder,
  name,
  value,
  onChange,
  error = "",
  disabled = false,
}) => (
  <div>
    <Label htmlFor={name}>{placeholder}</Label>
    <Input
      type={type}
      placeholder={placeholder}
      name={name}
      onChange={onChange}
      value={value}
      disabled={disabled}
    />
    <Error>{error}</Error>
  </div>
);

const Label = styled.label`
  display: none;
`;

const Input = styled.input`
  background-color: ${(props) => props.theme.colors.lighterGrey};
  border: 2px solid ${(props) => props.theme.colors.lighterGrey};
  font-size: 2rem;
  padding: 1rem 2.5rem;
  height: 5rem;
  width: 100%;

  &:active,
  &:focus {
    outline: none;
  }
`;

const Error = styled.p`
  color: ${(props) => props.theme.colors.red};
  font-size: 1.4rem;
  font-weight: 700rem;
`;

export default FormInput;
