import React from "react";
import styled from "styled-components";

const PageHeader: React.FC = (props) => (
  <Container>
    <PageTitle>{props.children}</PageTitle>
  </Container>
);

const Container = styled.div`
  border-bottom: 2px solid ${(props) => props.theme.colors.darkGrey};
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 3rem;
  padding: 1rem 0;
  grid-column: 1 / -1;
`;

const PageTitle = styled.h1`
  color: ${(props) => props.theme.colors.idataDarkGrey};
  font-size: 4rem;
  font-weight: 400;
`;

export default PageHeader;
