import React from "react";
import styled from "styled-components";

type TextAreaProps = {
  name: string;
  placeholder: string;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  value: string;
  error?: string;
};

const TextAreaInput: React.FC<TextAreaProps> = ({
  name,
  placeholder,
  onChange,
  value,
  error = "",
}) => (
  <>
    <TextArea
      name={name}
      placeholder={placeholder}
      onChange={onChange}
      value={value}
    />
    <Error>{error}</Error>
  </>
);

const TextArea = styled.textarea`
  background-color: ${(props) => props.theme.colors.lighterGrey};
  border: 2px solid ${(props) => props.theme.colors.lighterGrey};
  font-size: 2rem;
  grid-column: 1 / -1;
  padding: 1rem 2rem;
  height: 15rem;
  width: 100%;

  &:active,
  &:focus {
    outline: none;
  }
`;

const Error = styled.p`
  color: ${(props) => props.theme.colors.red};
  font-size: 1.4rem;
  font-weight: 700rem;
`;

export default TextAreaInput;
