import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import { getUsergroupUsers } from "../../../../../firebase";
import { useAuth } from "contexts/AuthContext";
import closeIcon from "assets/icons/close-black.svg";
import { User } from "types/firebase";
import { CustomReport } from "types/custom";
import { shareCustomReport } from "redux/actions/custom-report";

import StandardButton from "../../../../Common/buttons/StandardButton";
import Tip from "components/Common/misc/Tip";

import { SHARE as shareTip } from "data/tips";

Modal.setAppElement("#root");

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
  overlay: {
    zIndex: 1000,
  },
};

type ModalProps = {
  toggleShareModal: boolean;
  setToggleShareModal: (toggleShareModal: boolean) => void;
  customReportData: CustomReport;
};

const ShareModal: React.FC<ModalProps> = ({
  toggleShareModal,
  setToggleShareModal,
  customReportData,
}) => {
  const { currentUser } = useAuth();
  const dispatch = useDispatch();
  const [emailChecklist, setEmailChecklist] = useState<string[]>([]);
  const [idChecklist, setIdChecklist] = useState<string[]>([]);
  const [users, setUsers] = useState<any>([]);

  useEffect(() => {
    if (customReportData.shared) {
      setEmailChecklist(customReportData.shared.split(","));
    }
  }, [customReportData.shared]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    dispatch(
      shareCustomReport(
        customReportData.c_report_id,
        emailChecklist,
        handleClose
      )
    );
  };

  const handleClose = () => {
    setToggleShareModal(!toggleShareModal);
  };

  const handleChecklist = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { email, id } = JSON.parse(e.target.value);
    const exists = emailChecklist.includes(email);

    if (!exists) {
      setEmailChecklist([...emailChecklist, email]);
      setIdChecklist([...idChecklist, id]);
    } else {
      setEmailChecklist([...emailChecklist.filter((e: string) => e !== email)]);
      setIdChecklist([...idChecklist.filter((i: string) => i !== id)]);
    }
  };

  const handleFilter = (teamMember: User) => {
    return teamMember.enabled && teamMember.email !== currentUser.email;
  };

  const checkForAnyMembers = (): boolean => {
    const teamMembers = users.filter((user) => currentUser.id !== user.id);

    return teamMembers.length > 0;
  };

  const checkForEnabledMembers = (): boolean => {
    const teamMembers = users.filter(
      (user) => currentUser.id !== user.id && user.enabled
    );

    return teamMembers.length > 0;
  };

  useEffect(() => {
    if (toggleShareModal) {
      getUsergroupUsers(currentUser.usergroupData.users).then((result) =>
        setUsers(result)
      );
    }
  }, [toggleShareModal, currentUser.usergroupData.users]);

  if (currentUser) {
    return (
      <Modal
        isOpen={toggleShareModal}
        onRequestClose={handleClose}
        shouldCloseOnOverlayClick={toggleShareModal}
        style={customStyles}
        contentLabel="Custom Bookmarks"
      >
        <StyledCheckboxContainer>
          <StyledCheckboxHeader>
            <div>
              <h2>Share Custom Report</h2>
              <SummaryText>
                Share this Custom Report with team members
              </SummaryText>
            </div>
            <Tip text={shareTip} bottom={-75} right={-90} width={206} />
            <Control onClick={handleClose}>
              <img src={closeIcon} alt={"close modal icon"} />
            </Control>
          </StyledCheckboxHeader>
          <StyledCheckboxForm onSubmit={handleSubmit}>
            {users.filter(handleFilter).map((user: User) => (
              <StyledSelection key={user.email}>
                <StyledCheckbox
                  type="checkbox"
                  name={user.email.toString()}
                  value={JSON.stringify({ email: user.email, id: user.id })}
                  onChange={handleChecklist}
                  checked={emailChecklist.includes(user.email)}
                />
                <StyledLabel htmlFor={user.email}>
                  {user.name} / {user.email}
                </StyledLabel>
              </StyledSelection>
            ))}
            <NoTeamMembers hidden={checkForAnyMembers()}>
              There are no other members in your team
            </NoTeamMembers>
            <NoTeamMembers hidden={checkForEnabledMembers()}>
              Your team members need to be <strong>enabled</strong> to share
              this Custom Report
            </NoTeamMembers>
            <CustomButton
              disabled={!checkForEnabledMembers()}
              type="submit"
              size="md"
            >
              <span>Share</span>
            </CustomButton>
          </StyledCheckboxForm>
        </StyledCheckboxContainer>
      </Modal>
    );
  } else {
    return <></>;
  }
};

const StyledCheckboxContainer = styled.div`
  color: ${(props) => props.theme.colors.darkerGrey};
  padding: 0 2rem;
  width: 40em;
  max-height: 40em;
`;

const StyledCheckboxHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  width: 100%;
`;

const StyledCheckboxForm = styled.form`
  padding: 2rem 0;
`;

const StyledSelection = styled.div`
  border-radius: 4px;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;

  &:hover {
    background-color: ${(props) => props.theme.colors.lightBlue};
  }
`;

const StyledLabel = styled.label`
  font-size: ${(props) => props.theme.fontSizes.medium};
  margin-left: 1rem;

  &:active {
    transition: ${(props) => props.theme.transform.transition};
  }
`;

const StyledCheckbox = styled.input`
  height: 2em;
  width: 2em;
`;

const NoTeamMembers = styled.p`
  text-align: center;
`;

const CustomButton = styled(StandardButton)`
  margin: 5rem auto 2rem auto;
`;

const Control = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    opacity: 0.5;
  }
`;

const SummaryText = styled.p`
  color: ${(props) => props.theme.colors.lightGrey};
  font-size: 1.6rem;
  font-style: italic;
`;

export default ShareModal;
