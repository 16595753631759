import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import Modal from "react-modal";

import { setCurrentChapter } from "redux/actions/report";
import { modalStyles } from "styles/modals";
import { RootState } from "redux/reducers";

import BuyReport from "components/Common/forms/BuyReport";
import StandardButton from "components/Common/buttons/StandardButton";
import TableOfContents from "./TableOfContents";

import { generatePageTitle } from "utils/general.utils";

Modal.setAppElement("#root");

export type UnsubscribedProps = {
  report_name: string;
  report_year: string;
  report_id: number;
};

const Unsubscribed: React.FC<UnsubscribedProps> = () => {
  const dispatch = useDispatch();
  const { chapter = "1" } = useParams<{ chapter: string }>();
  const { unsubscribed } = useSelector((state: RootState) => state.report);
  const [toggleModal, setToggleModal] = useState(false);

  useEffect(() => {
    document.title = generatePageTitle(`${unsubscribed.content.report_name}`);
  });

  useEffect(() => {
    dispatch(setCurrentChapter(parseInt(chapter)));
  }, [chapter, dispatch]);

  return (
    <Container id="reportContent">
      <CTAContainer>
        <p>You Are Not Yet Subscribed to This Report</p>
        <StandardButton
          type="button"
          size="md"
          onClick={() => setToggleModal(true)}
        >
          <NewIcon>!</NewIcon>Buy Now
        </StandardButton>
        <Modal
          onRequestClose={() => setToggleModal(false)}
          isOpen={toggleModal}
          contentLabel="Buy Report"
          style={modalStyles}
        >
          <BuyReport
            setToggleModal={setToggleModal}
            report_name={unsubscribed.content.report_name}
            report_year={unsubscribed.content.report_year}
            report_id={unsubscribed.content.report_id}
          />
        </Modal>
      </CTAContainer>
      <TableOfContents url={unsubscribed.url} />
    </Container>
  );
};

const Container = styled.div`
  background-color: #fff;
  font-size: 1.8rem;
  padding: 2rem 2.5rem;
  min-height: 100rem;

  h3 {
    color: ${(props) => props.theme.colors.idataBlue};
    font-size: 3.5rem;
    margin-top: 5rem;
    text-align: center;
  }
`;

const CTAContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const NewIcon = styled.span`
  background-color: ${(props) => props.theme.colors.idataBlue};
  border: 1px solid #fff;
  border-radius: 50%;
  display: inline-block;
  color: #fff;
  font-size: 2.5rem;
  line-height: 1;
  margin-right: 1.5rem;
  height: 2.5rem;
  width: 2.5rem;
  transition: all 0.2s;
`;

export default Unsubscribed;
