import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { RootState } from "redux/reducers";

import PaginatedTable from "../../../Common/tables/PaginatedTable";
import { generatePageTitle } from "utils/general.utils";

const MyOrders = () => {
  const subscriptions = useSelector((state: RootState) => state.subscriptions);

  useEffect(() => {
    document.title = generatePageTitle("My Orders");
  }, []);

  return (
    <Container>
      <SubSection>
        <SubHeading>Currently Subscribed Reports</SubHeading>
        {subscriptions.active.length > 0 ? (
          <PaginatedTable collection={subscriptions.active} />
        ) : (
          <p>No subscriptions to display</p>
        )}
      </SubSection>
      <SubSection>
        <SubHeading>Order History</SubHeading>
        {subscriptions.expired.length > 0 ? (
          <PaginatedTable collection={subscriptions.expired} />
        ) : (
          <p>No order history to show</p>
        )}
      </SubSection>
    </Container>
  );
};

const Container = styled.div`
  margin: auto;
  max-width: 90rem;
  width: 100%;
`;

const SubSection = styled.div`
  margin-bottom: 5rem;
`;

const SubHeading = styled.h2`
  color: ${(props) => props.theme.colors.idataDarkGrey};
  font-size: 3.5rem;
  font-weight: 700;
  margin: 2rem 0;
`;

export default MyOrders;
