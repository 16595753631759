import React from "react";
import { useDispatch } from "react-redux";
import styled, { css } from "styled-components";

import { ReactComponent as RightArrow } from "assets/icons/arrow_right-solid.svg";
import { resetFilters, setFilters } from "redux/actions/catalog";
import { CatalogMenuItem } from "types/catalog";

type CatalogFilterListItemProps = {
  item: CatalogMenuItem;
  categoryLevel: string;
  highlight: boolean;
};

export const CatalogFilterListItem: React.FC<CatalogFilterListItemProps> = ({
  item,
  categoryLevel,
  highlight,
}) => {
  const dispatch = useDispatch();

  const handleClick = (e, item) => {
    if (categoryLevel === "category") {
      dispatch(resetFilters());
    }

    dispatch(setFilters("region", "All"));

    dispatch(setFilters(categoryLevel, item.text));
  };

  let fillColor: string = "#e6e7e8";

  return (
    <Item highlight={highlight} onClick={(e) => handleClick(e, item)}>
      {item.text} <RightArrow fill={fillColor} />
    </Item>
  );
};

const highlightStyles = () => {
  return css`
    background-color: ${(props) => props.theme.colors.idataBlue};
    color: white;
  `;
};

const Item = styled.div<{ highlight: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  padding-left: 1.5rem;
  ${(props) => (props.highlight ? null : highlightStyles())}

  & svg {
    min-height: 36px;
    min-width: 36px;
  }

  &:hover {
    background-color: ${(props) => props.theme.colors.idataBlue};
    color: #fff;
    cursor: pointer;
  }
`;

export default CatalogFilterListItem;
