import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import Modal from "react-modal";
import { useAuth } from "contexts/AuthContext";

import { ChapterMeta } from "types/reports";
import { CustomReport, Checklist } from "types/custom";
import { RootState } from "redux/reducers";
import {
  addCustomReport,
  updateCustomReportSingle,
} from "redux/actions/custom-report";
import { createChecklist } from "utils/custom.utils";
import { setErrors, clearErrors } from "redux/actions/errors";
import closeIcon from "assets/icons/close-black.svg";

import StandardButton from "../../../../Common/buttons/StandardButton";
import FormInput from "../../../../Common/forms/FormInput";
import Tip from "components/Common/misc/Tip";

import { BOOKMARK as bookmarkTip } from "data/tips";

Modal.setAppElement("#root");

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "2rem",
  },
  overlay: {
    zIndex: 1000,
  },
};

type ModalProps = {
  toggleModal: boolean;
  setToggleModal: (toggleModal: boolean) => void;
  selectedCustomReport: CustomReport | undefined;
  setSelectedCustomReport: (
    selectedCustomReport: CustomReport | undefined
  ) => void;
};

const BOOKMARK_LIMIT: number = 15;

const BookmarkModal: React.FC<ModalProps> = ({
  toggleModal,
  setToggleModal,
  selectedCustomReport,
  setSelectedCustomReport,
}) => {
  const { currentUser } = useAuth();
  const dispatch = useDispatch();
  const { report, errors } = useSelector((state: RootState) => state);
  const [title, setTitle] = useState<string>("");
  const [checklist, setChecklist] = useState<Checklist[]>([]);
  const [limitReached, setLimitReached] = useState<boolean>(false);

  const checkLimitReached = useCallback(
    (limit: number) => {
      // Notify user that limit Custom Bookmarks limit has been reached
      // Prevent user from adding more Custom Bookmarks
      // If new Custom Report being created, only check checklist.length
      // If adding to existing Custom Report, add amount of chapters already in report
      // to checklist.length
      if (
        (!selectedCustomReport && checklist.length >= limit) ||
        (selectedCustomReport &&
          selectedCustomReport?.collections.length + checklist.length >= limit)
      ) {
        dispatch(
          setErrors({
            customReportName: "Custom Bookmarks limit reached",
          })
        );

        setLimitReached(true);
      } else {
        setLimitReached(false);
      }
    },
    [checklist.length, dispatch, selectedCustomReport]
  );

  useEffect(() => {
    if (selectedCustomReport) {
      const collection = createChecklist(
        selectedCustomReport.collections,
        report
      );

      if (collection && collection.length > 0) {
        setChecklist(collection);
      }

      setTitle(selectedCustomReport.c_report_name);
      // setToggleModal(!toggleModal);
    }

    checkLimitReached(BOOKMARK_LIMIT);

    return () => {
      dispatch(clearErrors());
    };
  }, [
    checkLimitReached,
    dispatch,
    report,
    selectedCustomReport,
    setToggleModal,
    toggleModal,
  ]);

  const handleTitle = (e) => {
    const length = parseInt(e.target.value.length);

    if (length <= 50) {
      setTitle(e.target.value);
    } else {
      dispatch(
        setErrors({
          customReportName: "Title must not exceed 50 characters",
        })
      );
    }
  };

  const handleChecklist = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = JSON.parse((e.target as HTMLInputElement).value);
    const exists = checklist.some(
      (c) => c.chapter_number === value.chapter_number
    );

    if (!exists) {
      // Subtract by 1 because want to warn before they add the LIMIT + 1 chapter
      checkLimitReached(BOOKMARK_LIMIT - 1);

      if (!limitReached) {
        // Add to checklist
        setChecklist([...checklist, value]);
      }
    } else {
      // Remove from checklist
      setChecklist(
        checklist.filter((c) => c.chapter_number !== value.chapter_number)
      );

      setLimitReached(false);
      dispatch(clearErrors());
    }
  };

  const handleChecked = (num: number) => {
    const chapters = checklist.map((c) => c.chapter_number);
    return chapters.includes(num);
  };

  const handleClose = () => {
    setTitle("");
    setChecklist([]);
    setToggleModal(!toggleModal);
    setSelectedCustomReport(undefined);
    dispatch(clearErrors());
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    dispatch(clearErrors());

    // determine whether report is new or an edit
    if (!selectedCustomReport) {
      const newCustomReport = {
        c_report_name: title,
        report_id: report.reportMeta.report_id,
        checklist,
      };

      dispatch(
        addCustomReport(
          currentUser.email,
          newCustomReport,
          handleClose,
          currentUser.usergroupData.id
        )
      );
    } else {
      const { c_report_id } = selectedCustomReport;

      const update = {
        c_report_id,
        c_report_name: title,
        report_id: report.reportMeta.report_id,
        checklist,
      };

      dispatch(
        updateCustomReportSingle(
          update,
          handleClose,
          currentUser.usergroupData.id
        )
      );
    }
  };

  return (
    <Modal
      isOpen={toggleModal}
      onRequestClose={handleClose}
      shouldCloseOnOverlayClick={toggleModal}
      style={customStyles}
      contentLabel="Custom Bookmarks"
    >
      <StyledCheckboxContainer>
        <StyledCheckboxHeader>
          <div>
            <h2>
              {selectedCustomReport ? "Add/Edit" : "New"} Custom Bookmarks
            </h2>
            <SummaryText>
              Include or remove chapters from this report
            </SummaryText>
          </div>
          <Tip text={bookmarkTip} bottom={-75} right={-110} width={260} />
          <Control onClick={handleClose}>
            <img src={closeIcon} alt={"close modal icon"} />
          </Control>
        </StyledCheckboxHeader>
        <StyledCheckboxForm onSubmit={handleSubmit}>
          <FormInput
            placeholder="Enter a title"
            name="customReportName"
            value={title}
            onChange={handleTitle}
            error={errors.customReportName}
          />
          <BookmarkList>
            {report.chapterMeta.map((chapter: ChapterMeta) => (
              <StyledSelection key={chapter.id}>
                <StyledCheckbox
                  type="checkbox"
                  name={chapter.chapter_number.toString()}
                  value={JSON.stringify({
                    chapter_number: chapter.chapter_number,
                    chapter_title: chapter.chapter_title,
                  })}
                  onChange={handleChecklist}
                  checked={handleChecked(chapter.chapter_number)}
                  disabled={
                    limitReached && !handleChecked(chapter.chapter_number)
                  }
                />
                <StyledLabel htmlFor={chapter.chapter_number.toString()}>
                  {chapter.chapter_title}
                </StyledLabel>
              </StyledSelection>
            ))}
          </BookmarkList>
          <Error>{errors.checklist}</Error>
          <CustomButton type="submit" size="md">
            Save
          </CustomButton>
        </StyledCheckboxForm>
      </StyledCheckboxContainer>
    </Modal>
  );
};

const StyledCheckboxContainer = styled.div`
  color: ${(props) => props.theme.colors.idataDarkGrey};
  padding: 0 2rem;
  width: 40em;
  max-height: 40em;
`;

const BookmarkList = styled.div`
  height: 40vh;
  overflow: auto;
`;

const StyledCheckboxHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 2rem;
`;

const StyledCheckboxForm = styled.form`
  /*   padding: 2rem 0; */
`;

const StyledSelection = styled.div`
  border-radius: 4px;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;

  &:hover {
    background-color: ${(props) => props.theme.colors.lightBlue};
  }
`;

const StyledLabel = styled.label`
  font-size: ${(props) => props.theme.fontSizes.medium};
  margin-left: 1rem;

  &:active {
    transition: ${(props) => props.theme.transform.transition};
  }
`;

const StyledCheckbox = styled.input`
  height: 2em;
  width: 2em;
`;

const CustomButton = styled(StandardButton)`
  /* margin: 5rem auto 2rem auto; */
  margin: 0 auto;
  margin-top: 2rem;
`;

const Error = styled.p`
  color: ${(props) => props.theme.colors.red};
  font-size: 1.4rem;
  font-weight: 700rem;
`;

const Control = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    opacity: 0.5;
  }
`;

const SummaryText = styled.p`
  color: ${(props) => props.theme.colors.lightGrey};
  font-size: 1.6rem;
  font-style: italic;
`;

export default BookmarkModal;
