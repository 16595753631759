import axios from "axios";
import { Dispatch } from "redux";

import { setErrors } from "./errors";
import { ReportSearchType } from "types/reports";
import { setNotification } from "./notifications";
import { NotificationVariant } from "redux/reducers/notifications";
import {
  NewCustomReport,
  CustomReportData,
  CustomReportUpdateMultiple,
} from "types/custom";
import { addHighlights, removeHighlights } from "utils/search.utils";

import { getUsergroupName } from "../../firebase";

export const FETCH_CUSTOM_REPORT_DATA = "FETCH_CUSTOM_REPORT_DATA";
export const FETCH_CUSTOM_REPORT_METADATA = "FETCH_CUSTOM_REPORT_METADATA";
export const GET_USER_CUSTOM_REPORTS = "GET_USER_CUSTOM_REPORTS";
export const ADD_CUSTOM_REPORT = "ADD_CUSTOM_REPORT";
export const UPDATE_CUSTOM_REPORT_CONTENTS = "UPDATE_CUSTOM_REPORT_CONTENTS";
export const SET_CUSTOM_REPORT_SEARCH = "SET_CUSTOM_REPORT_SEARCH";
export const SET_CUSTOM_SCROLL_POSITIONS = "SET_CUSTOM_SCROLL_POSITIONS";
export const SET_CUSTOM_CURRENT_CHAPTER = "SET_CUSTOM_CURRENT_CHAPTER";
export const RESET_CUSTOM_REPORT_SEARCH = "RESET_CUSTOM_REPORT_SEARCH";
export const RESET_CUSTOM_REPORT_DATA = "RESET_CUSTOM_REPORT_DATA";
export const SET_CUSTOM_SEARCH_POSITION = "SET_CUSTOM_SEARCH_POSITION";

export const fetchCustomReportData: any = (
  customReportId: number,
  company: string,
  setLoading?: any,
  subsidiaryCompanies?: string[]
) => async (dispatch: Dispatch) => {
  const subsidiaryCompanyNames: string[] = [];

  if (subsidiaryCompanies) {
    for (const company of subsidiaryCompanies) {
      const companyName: string = await getUsergroupName(company);
      subsidiaryCompanyNames.push(companyName);
    }
  }

  try {
    const { data } = await axios.get(`/custom-report/${customReportId}`, {
      params: { company, subsidiaryCompanies },
    });
    dispatch({
      type: FETCH_CUSTOM_REPORT_DATA,
      payload: data,
    });

    if (setLoading) {
      setLoading(false);
    }
  } catch (error) {
    setErrors(error);
  }
};

export const fetchCustomReportMetadata: any = (
  companies?: string[],
  setLoading?: (loading: boolean) => void
) => async (dispatch: Dispatch) => {
  try {
    const { data } = await axios.get("/custom-reports/metadata", {
      params: { companies },
    });
    dispatch({
      type: FETCH_CUSTOM_REPORT_METADATA,
      payload: data,
    });

    if (setLoading) {
      setLoading(false);
    }
  } catch (error) {
    setErrors(error);
  }
};

// add new custom report
export const addCustomReport = (
  email: string,
  data: NewCustomReport,
  handleClose: () => void,
  companyId
) => async (dispatch: Dispatch) => {
  try {
    const metadata = await axios.get("/custom-reports/metadata", {
      params: { companies: [companyId] },
    });
    const userCustomReports = metadata.data.filter(
      (customReport) => customReport.user_email === email
    );

    if (
      userCustomReports.length < 10 ||
      email === "support@idataresearch.net" ||
      email === "marketing@idataresearch.net"
    ) {
      // create new custom report
      await axios.post("/custom-reports", data);

      // show notification
      dispatch(
        setNotification({
          message: `Custom report '${data.c_report_name}' created`,
          variant: NotificationVariant.primary,
        })
      );

      // refetch metadata
      dispatch(fetchCustomReportMetadata([companyId]));

      // close modal
      handleClose();
    } else {
      dispatch(
        setNotification({
          message: `You may only create up to 10 Custom Reports. Delete another Custom
          Report if you want to create a new Custom Report.`,
          variant: NotificationVariant.danger,
        })
      );

      dispatch(fetchCustomReportMetadata([companyId]));

      handleClose();
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      dispatch(setErrors(error));

      if (
        error?.response?.data === "Too many requests, please try again later."
      ) {
        handleClose();
        dispatch(
          setNotification({
            message: `Too many Custom Reports have been created from your IP address, please try again later.`,
            variant: NotificationVariant.danger,
          })
        );
      }
    }
  }
};

// edit custom report
export const updateCustomReportSingle = (
  update: NewCustomReport,
  handleClose: () => void,
  companyId
) => async (dispatch: Dispatch) => {
  try {
    const success = await axios.put(
      `/custom-report/single/${update.c_report_id}`,
      update
    );

    // show notification
    dispatch(
      setNotification({
        message: success.data,
        variant: NotificationVariant.primary,
      })
    );

    dispatch(fetchCustomReportMetadata([companyId]));

    handleClose();
  } catch (error) {
    dispatch(setErrors(error));
  }
};

export const updateCustomReportMultiple = (
  update: CustomReportUpdateMultiple,
  handleClose: () => void,
  companyId,
  pathname: string,
  navigate?: any
) => async (dispatch: Dispatch) => {
  try {
    const success = await axios.put(
      `/custom-report/multiple/${update.c_report_id}`,
      update
    );

    // Go back to first chapter
    if (navigate) {
      navigate(pathname.substring(0, pathname.lastIndexOf("/")) + "/1");
    }

    // show notification
    dispatch(
      setNotification({
        message: success.data,
        variant: NotificationVariant.primary,
      })
    );

    dispatch(fetchCustomReportData(update.c_report_id));
    dispatch(fetchCustomReportMetadata([companyId]));

    handleClose();
  } catch (error) {
    dispatch(setErrors(error));
  }
};

// delete custom report
export const removeCustomReport = (
  id: number,
  companyId,
  navigate?: any
) => async (dispatch: Dispatch) => {
  try {
    const { data } = await axios.delete(`/custom-report/${id}`);

    dispatch(
      setNotification({
        message: data.message,
        variant: NotificationVariant.primary,
      })
    );

    // cleanup, refetch and redirect
    dispatch(resetCustomReportData());
    dispatch(fetchCustomReportMetadata([companyId]));

    if (navigate) {
      navigate("/");
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
};

export const removeCustomChapter = (
  customReportId: number,
  reportId: number,
  chapterNum: number,
  companyId
) => async (dispatch: Dispatch) => {
  try {
    await axios.delete(
      `/custom-report/${customReportId}/${reportId}/${chapterNum}`
    );
    dispatch(fetchCustomReportMetadata([companyId]));
  } catch (error) {
    dispatch(setErrors(error));
  }
};

export const searchCustomReport = (
  data: any,
  query: string,
  prev: string
) => async (dispatch: Dispatch) => {
  // scan each chapter for string
  const { highlighted, chapters } = await addHighlights(
    data,
    query,
    prev,
    true
  );

  if (chapters.length > 0) {
    dispatch(updateCustomReportContents(highlighted));
    dispatch(setCustomReportSearch(query, chapters));
  } else {
    dispatch(
      setErrors({ response: { data: { search: "No matches found " } } })
    );
  }
};

export const updateCustomReportContents: any = (updatedContent) => (
  dispatch: Dispatch
) => {
  dispatch({
    type: UPDATE_CUSTOM_REPORT_CONTENTS,
    payload: updatedContent,
  });
};

export const setCustomReportSearch: any = (
  query: string,
  chapters: ReportSearchType[]
) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_CUSTOM_REPORT_SEARCH,
    payload: {
      query,
      chapters,
    },
  });
};

export const setCustomScrollPositions = (scrollPositions: number[]) => (
  dispatch: Dispatch
) => {
  dispatch({
    type: SET_CUSTOM_SCROLL_POSITIONS,
    payload: scrollPositions,
  });
};

export const setCustomCurrentChapter = (chapter: number) => (
  dispatch: Dispatch
) => {
  dispatch({
    type: SET_CUSTOM_CURRENT_CHAPTER,
    payload: chapter,
  });
};

export const resetCustomReportSearch: any = (
  customReport: CustomReportData
) => async (dispatch: Dispatch) => {
  const updatedContent = await removeHighlights(customReport.contentData);
  dispatch(updateCustomReportContents(updatedContent));
  dispatch({
    type: RESET_CUSTOM_REPORT_SEARCH,
  });
};

export const shareCustomReport = (
  customReportId: number,
  users: string[],
  handleClose: () => void
) => async (dispatch: Dispatch) => {
  try {
    // update custom report in db with user email
    const success = await axios.patch(
      `/custom-report/${customReportId}/shared`,
      { users }
    );

    // show notification
    dispatch(
      setNotification({
        message: success.data,
        variant: NotificationVariant.primary,
      })
    );

    // refetch data and close modal
    dispatch(fetchCustomReportData(customReportId));
    handleClose();
  } catch (error) {
    dispatch(setErrors(error));
  }
};

export const shareCustomReportCompany = (
  customReportId: number,
  companies: string[],
  handleClose: () => void
) => async (dispatch: Dispatch) => {
  try {
    // update custom report in db with company IDs
    const success = await axios.patch(
      `/custom-report/${customReportId}/shared_company`,
      { companies }
    );

    // show notification
    dispatch(
      setNotification({
        message: success.data,
        variant: NotificationVariant.primary,
      })
    );

    // refetch data and close modal
    dispatch(fetchCustomReportData(customReportId));
    handleClose();
  } catch (error) {
    dispatch(setErrors(error));
  }
};

export const resetCustomReportData: any = () => (dispatch: Dispatch) => {
  dispatch({
    type: RESET_CUSTOM_REPORT_DATA,
  });
};

export const setCustomSearchPosition = (value: number) => async (
  dispatch: Dispatch
) => {
  dispatch({
    type: SET_CUSTOM_SEARCH_POSITION,
    payload: value,
  });
};
