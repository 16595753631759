import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";

import Modal from "react-modal";

import { RootState } from "redux/reducers";
import { fetchCatalogData } from "redux/actions/catalog";

import { modalStyles } from "styles/modals";
import ReportListItem from "./ReportListItem";
import StandardButton from "components/Common/buttons/StandardButton";
import Spinner from "components/Common/misc/Spinner";

import EditReportModal from "./ChildComponents/EditReportModal";

import { generatePageTitle } from "utils/general.utils";

Modal.setAppElement("#root");

const ManageReports = () => {
  const dispatch = useDispatch();
  const catalog = useSelector((state: RootState) => state.catalog);
  const categories = catalog?.categories?.filter(
    (cat) => cat.sub_categories.length > 0
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [catalogChanged, setCatalogChanged] = useState<boolean>(false);
  const [toggleModal, setToggleModal] = useState(false);
  const [reportToEdit, setReportToEdit] = useState({
    id: 0,
    title: "",
    year: "",
    region: "",
    description: "",
    icon: "",
  });

  useEffect(() => {
    document.title = generatePageTitle("Manage Reports");
  }, []);

  useEffect(() => {
    dispatch(fetchCatalogData(setLoading, true));
  }, [dispatch, setLoading]);

  const handleSort = (a, b) => {
    return a.report_name
      .toLowerCase()
      .localeCompare(b.report_name.toLowerCase());
  };

  if (loading) {
    return (
      <FetchCatalog>
        <p>
          Fetching catalog and calculating number of subscribers per report...
        </p>
        <Spinner fullscreen={false} />
      </FetchCatalog>
    );
  } else {
    return (
      <div>
        <PageTitle>Manage Reports</PageTitle>
        <RefreshCatalog>
          {loading ? <Spinner fullscreen={false} /> : " "}
          <StandardButton
            type="button"
            size="md"
            color={loading ? "red" : "green"}
            onClick={() => {
              setLoading(true);
              setCatalogChanged(false);
              dispatch(fetchCatalogData(setLoading, true));
            }}
          >
            {loading ? "Refreshing Catalog" : "Refresh Catalog"}
          </StandardButton>
        </RefreshCatalog>
        <div>
          <p>
            <em>Quick Links</em>
          </p>
          <QuickMenu>
            {categories?.map((cat) => (
              <li key={cat.category_id}>
                <a href={`#${cat.category_id}`}>{cat.category_name}</a> |
              </li>
            ))}
          </QuickMenu>
        </div>
        <Modal
          isOpen={toggleModal}
          contentLabel="Edit Report"
          style={modalStyles}
        >
          <EditReportModal
            setToggleModal={setToggleModal}
            reportToEdit={reportToEdit}
          />
        </Modal>
        <ul>
          {categories?.map((cat) => (
            <li key={cat.category_id}>
              <CatTitle id={cat.category_id}>{cat.category_name}</CatTitle>
              <ul>
                {cat.sub_categories
                  .filter((sub) => sub.collections.length > 0)
                  .map((sub) => (
                    <SubCatMenu key={sub.sub_category_id}>
                      <SubCatTitle>{sub.sub_category_name}</SubCatTitle>
                      <table>
                        <thead>
                          <TableRow>
                            <th>Title</th>
                            <th>Year</th>
                            <th>Region</th>
                            <th>Subscribers</th>
                            <th>Show?</th>
                            <th>Delete</th>
                          </TableRow>
                        </thead>
                        <tbody>
                          {sub.collections.sort(handleSort).map((report) => (
                            <ReportListItem
                              key={report.report_id}
                              report={report}
                              setCatalogChanged={() => setCatalogChanged(true)}
                              setToggleModal={setToggleModal}
                              setReportToEdit={setReportToEdit}
                            />
                          ))}
                        </tbody>
                      </table>
                    </SubCatMenu>
                  ))}
              </ul>
            </li>
          ))}
        </ul>
      </div>
    );
  }
};

const RefreshCatalog = styled.div`
  display: flex;
  align-items: center;
  float: right;
  position: sticky;
  top: 1rem;
  z-index: 200;

  button {
    margin-left: 3rem;
  }
`;

const PageTitle = styled.h2`
  color: ${(props) => props.theme.colors.idataDarkGrey};
  margin-bottom: 3rem;
`;

const CatTitle = styled.p`
  background-color: ${(props) => props.theme.colors.coolBlue};
  color: #fff;
  font-size: 2.2rem;
  text-transform: uppercase;
  padding: 1rem;
`;

const SubCatMenu = styled.li`
  padding-left: 2rem;
`;

const SubCatTitle = styled.p`
  font-size: 2rem;
  border-bottom: 1px solid ${(props) => props.theme.colors.idataDarkGrey};
  width: 50rem;
`;

const TableRow = styled.tr`
  cursor: pointer;

  &:hover td {
    background-color: ${(props) => props.theme.colors.grey};
  }

  td,
  th {
    padding: 1rem;
  }
`;

const QuickMenu = styled.ul`
  display: flex;
  flex-wrap: wrap;
  max-width: 50rem;
  margin-bottom: 2rem;

  li {
    margin-right: 1rem;
  }
`;

const FetchCatalog = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  p {
    margin-bottom: 10rem;
  }
`;

export default ManageReports;
