import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";
import styled, { css } from "styled-components";
import { useSelector } from "react-redux";

import { RootState } from "redux/reducers";
import ReportContent from "components/Pages/report/ChildComponents/Content/ReportContent";

import settingsIcon from "assets/icons/settings-black.svg";
import checkIcon from "assets/icons/check-black.svg";

type Toggle = { toggle?: boolean };
type ItemLinkType = { disabled?: boolean };

const CustomReportMain = () => {
  const { contentData } = useSelector((state: RootState) => state.customReport);
  const [tablesOnly, setTablesOnly] = useState<boolean>(false);
  const [toggle, setToggle] = useState<boolean>(false);

  return (
    <Container id="CustomReportMain">
      <ReportSettings>
        <SettingsButton onClick={() => setToggle(!toggle)}>
          <img src={settingsIcon} alt="gear icon" />
        </SettingsButton>
        <Dropdown toggle={toggle}>
          <DropdownItem>
            <ItemLink onClick={() => setTablesOnly(!tablesOnly)}>
              View Tables Only{" "}
              {tablesOnly && <CheckIcon src={checkIcon} alt="check icon" />}
            </ItemLink>
          </DropdownItem>
        </Dropdown>
      </ReportSettings>
      <Routes>
        <Route
          path=":name/:chapter"
          element={<ReportContent 
            contentData={contentData}
            reportType="custom"
            tablesOnly={tablesOnly} />}
        />
      </Routes>
    </Container>
  );
};

const Container = styled.div`
  margin: 1rem 0;
  padding: 3rem 0;
  background-color: #fff;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.3);
  padding: 2rem 2.5rem;
  min-height: 100rem;
`;

const disabledLinkStyles = () => {
  return css`
    opacity: 0.3;
    pointer-events: none;
  `;
};

const ItemLink = styled.button<ItemLinkType>`
  color: ${(props) => props.theme.colors.idataDarkGrey};
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  height: 2.6rem;

  &:hover {
    opacity: 0.9;
  }

  ${(props) => (props.disabled ? disabledLinkStyles : "")};
`;

const ReportSettings = styled.div`
  float: right;
  position: relative;
`;

const SettingsButton = styled.button`
  &:hover {
    opacity: 0.8;
  }
`;

const Dropdown = styled.ul<Toggle>`
  background: #fff;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.3);
  display: ${(props) => (props.toggle ? "block" : "none")};
  font-size: 2rem;
  padding: 2.5rem 3rem;
  position: absolute;
  top: 3rem;
  right: -4rem;
  text-align: left;
  min-width: 35rem;
`;

const DropdownItem = styled.li`
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

const CheckIcon = styled.img`
  vertical-align: bottom;
`;

export default CustomReportMain;
