export const modalStyles = {
  overlay: {
    zIndex: 1999,
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

export const ChartModalStyles = {
  overlay: {
    zIndex: 1999,
  },
};

export const dashboardModalStyles = {
  overlay: {
    zIndex: 1999,
  },
  content: {
    height: "100vh",
    width: "100vw",
    inset: 0,
    padding: 0,
  },
};
