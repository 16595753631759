import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { useAuth } from "contexts/AuthContext";

import { Usergroup } from "types/firebase";

import {
  updateUsergroupLicense,
  resetDevices,
  updateSubsidiaryCompanies,
  findHeadquarterCompany,
  deleteUsergroup,
} from "../../../../../../firebase";

import { setErrors, clearErrors } from "redux/actions/errors";
import { setNotification } from "redux/actions/notifications";
import { NotificationVariant } from "redux/reducers/notifications";
import { RootState } from "redux/reducers";
import { getUsergroups } from "redux/actions/admin";

import StandardButton from "components/Common/buttons/StandardButton";

type T = { usergroup: Usergroup | undefined };

const ClientList: React.FC<T> = ({ usergroup }) => {
  const dispatch = useDispatch();
  const { isSales } = useAuth();

  const admin = useSelector((state: RootState) => state.admin);
  const errors = useSelector((state: RootState) => state.errors);

  const [company, setCompany] = useState<string>("");
  const [expiry, setExpiry] = useState<any>(0);
  const [license, setLicense] = useState<string>("unassigned");
  const [licenses, setLicenses] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [deviceLimit, setDeviceLimit] = useState<number>(100);
  const [deviceCount, setDeviceCount] = useState<number>(0);

  const [headquarter, setHeadquarter] = useState<boolean>(false);
  const [headquarterCompany, setHeadquarterCompany] = useState<string>("");
  const [subsidiaryCompanies, setSubsidiaryCompanies] = useState<any>([]);

  useEffect(() => {
    if (usergroup) {
      setCompany(usergroup.company);
      setExpiry(usergroup.expiry);
      setLicense(usergroup.license || "unassigned");
      setLicenses(usergroup.licenses);
      setDeviceLimit(usergroup.deviceLimit || 100);
      setDeviceCount(usergroup?.devices?.length || 0);

      setHeadquarter(usergroup.headquarter || false);

      if (usergroup.id) {
        findHeadquarterCompany(usergroup.id).then((res) => {
          if (res) {
            setHeadquarterCompany(res)
          } else {
            setHeadquarterCompany("");
          }
        }
        );
      }

      if (usergroup.subsidiaryCompanies) {
        setSubsidiaryCompanies(usergroup.subsidiaryCompanies);
      }
    }
  }, [usergroup]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (usergroup) {
      dispatch(clearErrors());

      let newExpiry = new Date(expiry);
      newExpiry.setDate(newExpiry.getDate() + 1);

      let update = {};

      if (license === "corporate" || license === "enterprise") {
        update = {
          company,
          expiry: newExpiry,
          license,
          licenses,
          deviceLimit,
          headquarter,
        };
      } else {
        update = {
          company,
          expiry: newExpiry,
          license,
          licenses,
          headquarter: false,
        };
      }

      updateUsergroup(usergroup, update);
    }
  };

  async function updateUsergroup(usergroup, update) {
    setLoading(true);
    try {
      // update Firebase documents
      await updateUsergroupLicense(usergroup.id, update);

      if (headquarter) {
        await updateSubsidiaryCompanies(usergroup.id, subsidiaryCompanies);
      } else {
        await updateSubsidiaryCompanies(usergroup.id, []);
      }

      dispatch(
        setNotification({
          message: "Updated",
          variant: NotificationVariant.primary,
        })
      );
    } catch {
      dispatch(setErrors({ general: "Something went wrong" }));
    }

    dispatch(getUsergroups());
    setLoading(false);
  }

  const handleSelect = async (e) => {
    if (e.target.value) {
      setLicense(e.target.value);
    }
  };

  const handleReset = () => {
    if (usergroup) {
      resetDevices("usergroups", usergroup.id);
      setDeviceCount(0);
    }
  };

  const handleDelete = () => {
    if (usergroup) {
      if (
        window.confirm(
          `Are you sure you want to delete company: ${usergroup.company}?`
        )
      ) {
        deleteUsergroup(usergroup.id);
        window.location.reload();
      }
    }
  };

  const handleHeadquarters = async (e) => {
    var options = e.target.options;
    var value: string[] = [];
    for (var i = 0, l = options.length; i < l; i++) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    setSubsidiaryCompanies(value);
  };

  if (usergroup !== undefined) {
    return (
      <Form onSubmit={handleSubmit}>

        <Columns>
          <Metadata>
            <div>
              <label htmlFor="company-name"><b>Company name:</b></label>
              <Input
                id="company-name"
                name="company-name"
                type="text"
                value={company}
                onChange={(e) => setCompany(e.target.value)}
              />
            </div>
            <div hidden={isSales()}><b>Company ID:</b> {usergroup.id}</div>
            <div><b>Description:</b> {usergroup.description}</div>

            <div>
              <label htmlFor="expiry"><b>Expiry date:</b></label>
              <Expiry
                id="expiry"
                name="expiry"
                type="date"
                value={expiry}
                disabled={isSales()}
                onChange={(e) => setExpiry(e.target.value)}
                $expiry={expiry}
              />
            </div>

            <div>
              <label htmlFor="license-select"><b>License:</b></label>
              <Select name="licenses" id="license-select" onChange={handleSelect}
                value={license} disabled={isSales()}>
                <option value="unassigned">Unassigned</option>
                <option value="single">Single</option>
                <option value="site">Site</option>
                <option value="corporate">Corporate</option>
                <option value="enterprise">Enterprise</option>
              </Select>
            </div>

            <div><b>Number of users:</b> {usergroup.users?.length ?? 0}</div>
            <div>
              <label htmlFor="number-of-licenses"><b>Number of users Team Leader may invite:</b></label>
              <Input
                id="number-of-licenses"
                name="number-of-licenses"
                type="number"
                value={licenses}
                min="0"
                disabled={isSales() || license === "single"}
                onChange={(e) => setLicenses(parseInt(e.target.value))}
              />
            </div>

            <div>
              <b>Device Limit: </b>

              <span hidden={license !== "unassigned"}>No limit</span>

              <span hidden={license === "unassigned" || license === "corporate" || license === "enterprise"}>2 devices per user</span>

              <Input
                type="number"
                value={deviceLimit}
                hidden={license !== "corporate" && license !== "enterprise"}
                disabled={isSales()}
                onChange={(e) => setDeviceLimit(parseInt(e.target.value))}
              />
            </div>

          </Metadata>

          <Metadata>
            <div>
              <label htmlFor="checkbox" id="headquarter"><b>Headquarter:</b></label>
              <Input
                id="headquarter"
                type="checkbox"
                checked={headquarter}
                disabled={(license !== "corporate" && license !== "enterprise") || headquarterCompany !== "" || isSales()}
                onChange={() => setHeadquarter(!headquarter)}
              />
            </div>

            <div hidden={headquarter}><b>Headquarter Company: </b>{headquarterCompany ? headquarterCompany : "None"}</div>

            <div hidden={!headquarter || (license !== "corporate" && license !== "enterprise")}>
              <div>
                <label htmlFor="checkbox" id="subsidiary-companies-select"><b>Subsidiary companies:</b></label>
              </div>

              <select
                onChange={(e) => handleHeadquarters(e)}
                name="subsidiary-companies-select"
                id="subsidiary-companies-select"
                disabled={isSales()}
                multiple
              >
                {admin.usergroups.map((u: Usergroup) => {
                  if (usergroup.company !== u.company) {
                    // Add selected attribute if company is found in subsidiaryCompanies
                    if (
                      subsidiaryCompanies.find(
                        (element: string) => element === u.id
                      )
                    ) {
                      return (
                        <option selected key={u.id} value={u.id}>
                          {u.company}
                        </option>
                      );
                    } else {
                      return (
                        <option key={u.id} value={u.id}>
                          {u.company}
                        </option>
                      );
                    }
                  }
                  return null;
                })}
              </select>

              <div>
                <em hidden={isSales()}>
                  Hold down the Ctrl (Windows) or Command (Mac) button to select
                  multiple options.
                </em>
              </div>
            </div>

          </Metadata>
        </Columns>

        <Error>{errors.general}</Error>

        <Buttons $hidden={isSales()}>
          <StandardButton type="submit" size="xs" disabled={loading}>
            Update
          </StandardButton>

          <StandardButton type="button" size="xs" disabled={loading} color="red" onClick={handleDelete}>
            Delete
          </StandardButton>
        </Buttons>

        <div hidden={license !== "corporate" && license !== "enterprise"}>
          <LoggedInDevices>
            <div><b>Entire company's logged in devices:</b> {deviceCount}</div>
            <StandardButton type="button" onClick={() => handleReset()} size="xs"
              disabled={loading || isSales()}>
              Reset
            </StandardButton>
          </LoggedInDevices>
        </div>

      </Form>
    );
  } else {
    return <></>;
  }

};

const Form = styled.form`
  padding: 2rem;
  margin: 2rem 0;
  background-color: ${(props) => props.theme.colors.grey};
`;

const Columns = styled.div`
  display: flex;

  & > div {
    flex: 1 1 50%;
  }
`;

const Metadata = styled.div`
  & > div {
    margin-bottom: 1.5rem;
  }
`;

const Input = styled.input`
  margin-left: 1rem;
  padding: 0.75rem;
`;

const Expiry = styled(Input) <{ $expiry: string }>`
  font-size: 2rem;
  font-weight: bold;

  background-color: ${props => new Date(props.$expiry) > new Date() ? props.theme.colors.green : props.theme.colors.red};
`;

const Select = styled.select`
  margin-left: 1rem;
`;

const Error = styled.div`
  color: ${(props) => props.theme.colors.red};
  font-size: 1.4rem;
  font-weight: 700rem;
`;

const Buttons = styled.div<{ $hidden: boolean }>`
  display: ${props => props.$hidden ? "none" : "flex"};
  justify-content: space-between;
`;

const LoggedInDevices = styled.div`
  background-color: ${(props) => props.theme.colors.lighterGrey};

  display: flex;
  padding: 1rem;
  margin-top: 1rem;
  align-items: center;

  div {
    margin-right: 2rem;
  }
`;

export default ClientList;
