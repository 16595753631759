import axios from "axios";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import { fetchCatalogData } from "redux/actions/catalog";
import { setNotification } from "redux/actions/notifications";
import { NotificationVariant } from "redux/reducers/notifications";

import StandardButton from "components/Common/buttons/StandardButton";
import ReportThumbnail from "components/Common/misc/ReportThumbnail";

// import IconLegend from "assets/images/CategoryThumbnails/report-thumbnails-legend.png";
import REPORT_THUMBNAIL_NAMES from "data/report-thumbnails";

type ModalProps = {
  setToggleModal: Function;
  reportToEdit: {
    id: number;
    title: string;
    year: string;
    region: string;
    description: string;
    icon: string;
  };
};

const EditReportModal: React.FC<ModalProps> = ({
  setToggleModal,
  reportToEdit,
}) => {
  const dispatch = useDispatch();
  const [title, setTitle] = useState<string>(reportToEdit.title);
  const [year, setYear] = useState<number>(+reportToEdit.year);
  const [region, setRegion] = useState<string>(reportToEdit.region);
  const [icon, setIcon] = useState<string>(reportToEdit.icon);
  const [description, setDescription] = useState<string>(
    reportToEdit.description
  );

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const update = await axios.put(`/reports/${reportToEdit.id}`, {
      title: title === "" ? reportToEdit.title : title,
      year: year === 0 ? reportToEdit.year : year,
      region: region === "" ? "Global" : region,
      icon: icon === "" ? "Bandage" : icon,
      description: description === "" ? reportToEdit.description : description,
    });

    if (update) {
      dispatch(
        setNotification({
          message: "Report Edit Successful!",
          variant: NotificationVariant.success,
        })
      );
      dispatch(fetchCatalogData(() => { }, true));
      setToggleModal(false);
    }
  };

  return (
    <>
      <div>Edit Report Details</div>
      <Container>
        <Form onSubmit={handleSubmit}>
          <label>
            Title
            <input
              type="text"
              defaultValue={reportToEdit.title}
              name="title"
              onChange={(e) => setTitle(e.target.value)}
              required
            />
          </label>
          <label>
            Year
            <input
              type="number"
              defaultValue={reportToEdit.year}
              name="year"
              onChange={(e) => setYear(+e.target.value)}
              required
            />
          </label>
          <label>
            Region
            <select
              onChange={(e) => setRegion(e.target.value)}
              defaultValue={reportToEdit.region}
            >
              <option value="Global">Global</option>
              <option value="North America">North America</option>
              <option value="Europe">Europe</option>
              <option value="Asia Pacific">Asia Pacific</option>
              <option value="Latin America">Latin America</option>
            </select>
          </label>
          <label>
            Icon
            <select
              onChange={(e) => setIcon(e.target.value)}
              defaultValue={reportToEdit.icon}
            >
              {/* <option value="Arthroscope">Arthroscope</option>
              <option value="Bandage">Bandage</option>
              <option value="Brain">Brain</option>
              <option value="Cell">Cell</option>
              <option value="Cosmo">Cosmo</option>
              <option value="Dental">Dental</option>
              <option value="Diabetes">Diabetes</option>
              <option value="ENT">ENT</option>
              <option value="Ear">Ear</option>
              <option value="Eye">Eye</option>
              <option value="Heart">Heart</option>
              <option value="Kidneys">Kidneys</option>
              <option value="Knee">Knee</option>
              <option value="MRI">MRI</option>
              <option value="OxygenTank">OxygenTank</option>
              <option value="PatientMonitoring">PatientMonitoring</option>
              <option value="Pharma">Pharma</option>
              <option value="Spine">Spine</option>
              <option value="Stomach">Stomach</option>
              <option value="Vascular">Vascular</option>
              <option value="Video">Video</option>
              <option value="WomensHealth">WomensHealth</option> */}
              {
                REPORT_THUMBNAIL_NAMES.map((thumbnail, index) => (
                  <option key={index} value={thumbnail}>{thumbnail}</option>
                ))
              }
            </select>
          </label>
          <label>Description</label>
          <textarea
            rows={10}
            cols={5}
            onChange={(e) => setDescription(e.target.value)}
            defaultValue={reportToEdit.description}
          ></textarea>

          <StandardButton type="submit">Save Changes</StandardButton>
          <StandardButton
            type="button"
            color={"red"}
            onClick={() => setToggleModal(false)}
          >
            Cancel
          </StandardButton>
        </Form>
        <IconLegendDiv>
          {/* <div>Icon Legend</div> */}
          {
            REPORT_THUMBNAIL_NAMES.map((thumbnail, index) => (
              <ThumnailContainer key={index}>
                <ReportThumbnail icon={thumbnail} />
                <p>{thumbnail}</p>
              </ThumnailContainer>
            ))
          }
        </IconLegendDiv>
      </Container>
    </>
  );
};

const Container = styled.form`
  display: flex;
  flex-direction: row;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 75rem;
  padding: 1rem;

  input,
  select {
    width: 100%;
    border: solid 1px black;
    border-radius: 0;
  }

  label,
  button {
    margin: 1rem 0;
  }
`;

const IconLegendDiv = styled.div`
  padding: 1rem;
  display: grid;
  grid-template-columns: repeat(7, minmax(0, 1fr));
  grid-gap: 1rem;
  row-gap: 2rem !important; /* Adjust this value to set the desired row gap */
`;

const ThumnailContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-weight: 500;
  font-size: 1.75rem;
  /* overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; */
`;
export default EditReportModal;
