import axios from "axios";

export const setAuthToken = (token) => {
  if (token) {
    // Global axios defaults
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common["Authorization"];
  }
};

export const checkTokenExpiry = (token, auth) => {
  const currentTime = Date.now() / 1000;
  if (token && token.exp < currentTime) {
    return auth.signOut();
  }
};
