import { AnyAction } from "redux";

const initialErrorState = {};

const errorReducer = (state = initialErrorState, action: AnyAction) => {
  switch (action.type) {
    case "SET_ERRORS":
      return action.payload;
    case "CLEAR_ERRORS":
      return initialErrorState;
    default:
      return state;
  }
};

export default errorReducer;
