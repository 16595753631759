import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { generateReportUrl } from "utils/report.utils";
import { RootState } from "redux/reducers/index";
import { toggleRecommending } from "redux/actions/admin";
import { Category, SubCategory } from "types/catalog";

import StandardButton from "components/Common/buttons/StandardButton";

const RecommendBar: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { recommending, report_id_rec_for } = useSelector(
    (state: RootState) => state.admin
  );
  const { catalog } = useSelector((state: RootState) => state);

  const filteredReports = catalog?.categories?.flatMap((category: Category) => {
    return category.sub_categories.flatMap((sub: SubCategory) => {
      return sub.collections;
    });
  });

  if (recommending && report_id_rec_for !== -1) {
    const index = filteredReports
      .map((e) => e.report_id)
      .indexOf(report_id_rec_for);
    const report = filteredReports[index];
    const url: string = generateReportUrl(report.report_name);

    const handleClick = () => {
      navigate(`/reports/${report.report_id}/${url}/1`);
      dispatch(toggleRecommending());
    };

    return (
      <Container hidden={recommending ? false : true}>
        <div>Recommending reports for: </div>
        <ReportName>
          {report.report_name} - {report.region} - {report.report_year}
        </ReportName>
        <div>
          <StandardButton
            type="button"
            size="md"
            color="green"
            outline={true}
            onClick={handleClick}
          >
            Stop Recommending
          </StandardButton>
        </div>
      </Container>
    );
  } else {
    return <div></div>;
  }
};

const Container = styled.div`
  position: sticky;
  top: 0;
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  background-color: ${(props) => props.theme.colors.lighterGrey};
  text-align: center;
  color: #000;
  width: 23%;
  padding: 1rem;
  border-bottom-left-radius: 10px;
  margin-left: auto;
  margin-right: 0;

  & div {
    padding: 0.5rem;
  }
`;

const ReportName = styled.h2`
  font-size: 2.5rem;
`;

export default RecommendBar;
