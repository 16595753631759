import axios, { AxiosRequestConfig } from "axios";
import { Dispatch } from "redux";

import { setErrors } from "./errors";
import { fetchCatalogData } from "./catalog";
import { ReportSearchType, ReportData } from "types/reports";
import { setNotification } from "./notifications";
import { NotificationVariant } from "redux/reducers/notifications";
import { addHighlights, removeHighlights } from "utils/search.utils";

export const FETCH_REPORT_DATA = "FETCH_REPORT_DATA";
export const FETCH_UNSUBSCRIBED_DATA = "FETCH_UNSUBSCRIBED_DATA";
export const UPDATE_REPORT_CONTENTS = "UPDATE_REPORT_CONTENTS";
export const GET_REPORTS_BY_TITLE = "GET_REPORTS_BY_TITLE";
export const SET_REPORT_SEARCH = "SET_REPORT_SEARCH";
export const SET_SCROLL_POSITIONS = "SET_SCROLL_POSITIONS";
export const SET_CURRENT_CHAPTER = "SET_CURRENT_CHAPTER";
export const RESET_REPORT_SEARCH = "RESET_REPORT_SEARCH";
export const FETCH_RECOMMENDATIONS = "FETCH_RECOMMENDATIONS";
export const TOGGLE_REPORT_LOADING = "TOGGLE_REPORT_LOADING";
export const SET_SEARCH_POSITION = "SET_SEARCH_POSITION";

export const fetchReportData = (
  reportId: string,
  companyId: string,
  setLoading: (loading: boolean) => void
) => async (dispatch: Dispatch) => {
  try {
    const { data } = await axios.get(
      `/reports/subscribed/${reportId}/${companyId}`
    );

    axios.get(`/reports/global-dashboard-status/${reportId}`).then((res) => {
      data.reportMeta.global_dashboard = res.data[0].global_dashboard;

      dispatch({
        type: FETCH_REPORT_DATA,
        payload: data,
      });

      setLoading(false);
    });
  } catch (error) {
    dispatch(setErrors(error));
  }
};

export const uploadReport = (report: any, callback?: (error?: any) => void) => async (dispatch: Dispatch) => {
  try {
    const res = await axios.post("/reports/upload-report", report);
    dispatch(
      setNotification({
        message: res.data,
        variant: NotificationVariant.success,
      })
    );
    // refetch catalog/reports
    dispatch(fetchCatalogData());
    if (callback) callback();
  } catch (error) {
    dispatch(setErrors(error));
    if (callback) callback(error);
  }
};

export const uploadReportNoWriteup = (report: any, callback?: (error?: any) => void) => async (
  dispatch: Dispatch
) => {
  try {
    const res = await axios.post("/reports/upload-report-no-writeup", report);
    dispatch(
      setNotification(
        {
          message: `Report with no writeup upload complete! Report ID is ${res.data}.`,
          variant: NotificationVariant.success,
        },
        false
      )
    );
    // refetch catalog/reports
    dispatch(fetchCatalogData());
    if (callback) callback();
  } catch (error) {
    dispatch(setErrors(error));
    if (callback) callback(error);
  }
};

export const downloadReport = (
  reportId: string,
  companyId: string,
  setLoading: (loading: boolean) => void
) => async (dispatch: Dispatch) => {
  const options: AxiosRequestConfig = {
    responseType: "blob",
    headers: { "Content-Type": "application/pdf" },
  };
  try {
    const res = await axios.get(
      `/reports/download-report/${reportId}/${companyId}`,
      options
    );
    const fileName = res.headers["content-disposition"].replace(
      /(attachment;\sfilename=)|(")/g,
      ""
    );

    const url = window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement("a");

    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    setLoading(false);
  } catch (error) {
    dispatch(setErrors(error));
  }
};

export const downloadReportToc = (
  reportId: string,
  setLoading: (loading: boolean) => void
) => async (dispatch: Dispatch) => {
  const options: AxiosRequestConfig = {
    responseType: "blob",
    headers: { "Content-Type": "application/pdf" },
  };

  axios
    .get(`/reports/unsubscribed/${reportId}`)
    .then((response) => {
      return response.data;
    })
    .then((content) => {
      axios
        .get(`/reports/download-toc/${reportId}`, options)
        .then((response) => {
          const data = {
            content,
            url: window.URL.createObjectURL(new Blob([response.data])),
          };

          dispatch({
            type: FETCH_UNSUBSCRIBED_DATA,
            payload: data,
          });

          setLoading(false);
        })
        .catch(() => {
          const data = {
            content,
            url: null,
          };

          dispatch({
            type: FETCH_UNSUBSCRIBED_DATA,
            payload: data,
          });

          setLoading(false);
        });
    });
};

export const deleteReport = (reportId: number) => async (
  dispatch: Dispatch
) => {
  try {
    await axios.delete(`/reports/${reportId}`);
    dispatch(
      setNotification({
        message: "Report deleted successfully!",
        variant: NotificationVariant.success,
      })
    );
  } catch (error) {
    dispatch(setErrors(error));
  }
};

export const searchReport = (data: any, query: string, prev: string) => async (
  dispatch: Dispatch
) => {
  // scan each chapter for string
  const { highlighted, chapters } = await addHighlights(data, query, prev);

  if (chapters.length > 0) {
    dispatch(updateReportContents(highlighted));
    dispatch(setReportSearch(query, chapters));
  } else {
    dispatch(
      setErrors({ response: { data: { search: "No matches found " } } })
    );
  }
};

export const updateReportContents: any = (updatedContent) => (
  dispatch: Dispatch
) => {
  dispatch({
    type: UPDATE_REPORT_CONTENTS,
    payload: updatedContent,
  });
};

export const setReportSearch: any = (
  query: string,
  chapters: ReportSearchType[]
) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_REPORT_SEARCH,
    payload: {
      query,
      chapters,
    },
  });
};

export const setScrollPositions = (scrollPositions: number[]) => (
  dispatch: Dispatch
) => {
  dispatch({
    type: SET_SCROLL_POSITIONS,
    payload: scrollPositions,
  });
};

export const setCurrentChapter = (chapter: number) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_CURRENT_CHAPTER,
    payload: chapter,
  });
};

export const resetReportSearch: any = (report: ReportData) => async (
  dispatch: Dispatch
) => {
  const updatedContent = await removeHighlights(report.reportContentData);
  dispatch(updateReportContents(updatedContent));
  dispatch({
    type: RESET_REPORT_SEARCH,
  });
};

export const fetchRecommendations: any = (reportId: number) => async (
  dispatch: Dispatch
) => {
  try {
    const { data } = await axios.get(`/reports/recommendations/${reportId}`);
    dispatch({
      type: FETCH_RECOMMENDATIONS,
      payload: data,
    });
  } catch (error) {
    dispatch(setErrors(error));
  }
};

export const addRecommendation = (
  recReportId: number,
  reportId: number
) => async (dispatch: Dispatch) => {
  await axios.post("/reports/recommendations", { recReportId, reportId });
  dispatch(fetchRecommendations(reportId));
};

export const removeRecommendation = (recId: number, reportId: number) => async (
  dispatch: Dispatch
) => {
  await axios.delete(`/reports/recommendations/${recId}`);
  dispatch(fetchRecommendations(reportId));
};

export const setSearchPosition = (value: number) => async (
  dispatch: Dispatch
) => {
  dispatch({
    type: SET_SEARCH_POSITION,
    payload: value,
  });
};
