import React from "react";
import styled from "styled-components";
import { FormOption } from "types/index";

type SelectProps = {
  name: string;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  value: string;
  options: FormOption[];
};

const SelectInput: React.FC<SelectProps> = ({
  name,
  onChange,
  value,
  options,
}) => (
  <Select name={name} onChange={onChange} value={value}>
    {options.map((item: FormOption) => (
      <option key={item.id} value={item.value}>
        {item.text ? item.text : item.value}
      </option>
    ))}
  </Select>
);

const Select = styled.select`
  background-color: ${(props) => props.theme.colors.lighterGrey};
  border: 0;
  border-radius: 5rem;
  font-size: 2rem;
  padding: 1rem 2.5rem;
  height: 5rem;
  width: 100%;

  &:active,
  &:focus {
    outline: none;
  }
`;

export default SelectInput;
