const REPORT_THUMBNAIL_NAMES = [
  "Arthroscope",
  "Bandage",
  "Bed",
  "Brain",
  "Cell",
  "Computer",
  "Cosmo",
  "Dental",
  "Diabetes",
  "Ear",
  "ENT",
  "Eye",
  "Foot",
  "Heart",
  "iDataPharma",
  "Kidneys",
  "Knee",
  "MRI",
  "OxygenTank",
  "PatientMonitoring",
  "Pharma",
  "Screw",
  "Skull",
  "Spine",
  "Stomach",
  "Vascular",
  "Video",
  "WomensHealth",
];

export default REPORT_THUMBNAIL_NAMES;