import React from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import { checklistItemExists, removeChecklistItem } from "utils/custom.utils";
import { setErrors, clearErrors } from "redux/actions/errors";
import { CustomChapterMeta, ChecklistMultiple } from "types/custom";

type ChapterProps = {
  chapters: CustomChapterMeta[];
  checklist: ChecklistMultiple[];
  setChecklist: (checklist: ChecklistMultiple[]) => void;
};

const EditModalChapters: any = ({
  chapters,
  checklist,
  setChecklist,
}: ChapterProps) => {
  const dispatch = useDispatch();

  const handleChecklist = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value: ChecklistMultiple = JSON.parse(
      (e.target as HTMLInputElement).value
    );
    const exists = checklistItemExists(checklist, value);

    if (!exists) {
      setChecklist([...checklist, value]);
    } else {
      const newChecklist = removeChecklistItem(checklist, value);

      if (!newChecklist) {
        dispatch(
          setErrors({
            chapterNumbers:
              "Custom reports must have at least 1 chapter from each report",
          })
        );
      } else {
        dispatch(clearErrors());

        setChecklist(newChecklist);
      }
    }
  };

  const handleOrder = (
    e: React.ChangeEvent<HTMLInputElement>,
    reportId: number,
    chapterNumber: number
  ) => {
    const value = parseInt(e.target.value);
    const updated = checklist.map((i) => {
      if (i.report_id === reportId && i.chapter_number === chapterNumber) {
        i.custom_order = value;
      }
      return i;
    });

    setChecklist(updated);
  };

  return chapters.map((chapter) => {
    const exists = checklistItemExists(checklist, chapter);

    return (
      <StyledSelection key={chapter.id}>
        <StyledLeft>
          <StyledCheckbox
            type="checkbox"
            name={chapter.chapter_number.toString()}
            value={JSON.stringify({
              collection_id: chapter.collection_id,
              report_id: chapter.report_id,
              chapter_number: chapter.chapter_number,
              custom_order: chapter.custom_order,
            })}
            onChange={handleChecklist}
            checked={exists ? true : false}
          />
          <StyledLabel htmlFor={chapter.chapter_number.toString()}>
            {chapter.chapter_title} <br />
            <StyledSpan>
              {chapter.report_name
                ? `${chapter.report_name} - ${chapter.report_year}`
                : "Fetching report information..."}
            </StyledSpan>
          </StyledLabel>
        </StyledLeft>
        <div>
          <OrderSelect
            type="number"
            min="1"
            max="20"
            value={exists && exists.custom_order}
            onChange={(e) =>
              handleOrder(e, chapter.report_id, chapter.chapter_number)
            }
          />
        </div>
      </StyledSelection>
    );
  });
};

const StyledSelection = styled.div`
  border-radius: 4px;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover {
    background-color: ${(props) => props.theme.colors.lightBlue};
  }
`;

const StyledLeft = styled.div`
  display: flex;
  align-items: center;
`;

const StyledLabel = styled.label`
  display: block;
  font-size: ${(props) => props.theme.fontSizes.medium};
  margin-left: 1rem;

  &:active {
    transition: ${(props) => props.theme.transform.transition};
  }
`;

const StyledSpan = styled.span`
  font-size: ${(props) => props.theme.fontSizes.small};
`;

const StyledCheckbox = styled.input`
  height: 2em;
  width: 2em;
`;

const OrderSelect = styled.input`
  font-size: 1.8rem;
  padding: 1rem;
`;

export default EditModalChapters;
