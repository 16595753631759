import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import axios from "axios";
import { FirebaseError } from "@firebase/util";
import * as Sentry from "@sentry/browser";

import { formatExpiryDate } from "utils/expiry.utils";
import { createNewUserObject } from "utils/firebase.utils";
import { checkTokenExpiry, setAuthToken } from "utils/token.utils";
import { User, Usergroup } from "types/firebase";

let config = {};

if (process.env.NODE_ENV === "production") {
  config = {
    apiKey: process.env.REACT_APP_PROD_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_PROD_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROD_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_PROD_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_PROD_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_PROD_FIREBASE_APP_ID,
  };
} else {
  config = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
  };
}

const app = firebase.initializeApp(config);

const db = firebase.firestore(app);

const getIdToken = async () => {
  const idToken = await auth.currentUser?.getIdToken();

  setAuthToken(idToken);
  checkTokenExpiry(idToken, auth);
};

// create a user object that can be stored in redux
export const createUserProfileObject = async (userAuth: any) => {
  if (!userAuth) return;

  getIdToken();

  // Attempt to get user document
  const userRef = firestore.doc(`users/${userAuth.uid}`);
  const snapShot = await userRef.get();

  // If user document does not exist, create one
  if (!snapShot.exists) {
    const { email } = userAuth;
    const createdAt = new Date();

    try {
      await userRef.set({
        email,
        createdAt,
      });
    } catch (error) {
      if (error instanceof FirebaseError) {
        console.error("Error creating user: ", error.message);
      }
    }
  }

  // Return user document data
  return userRef;
};

// Get when user was last active
export function getLastActive(uid) {
  if (uid) {
    const userRef = db.collection("users").doc(uid);

    return userRef
      .get()
      .then((userDoc) => {
        if (userDoc.exists) {
          const data = userDoc.data();
          if (data) {
            return data.lastActive;
          }
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      })
      .catch((error) => {
        console.log("Error getting document:", error);
      });
  }

  return null;
}

// Update last active time
export async function setLastActive(uid) {
  if (uid) {
    const userRef = db.collection("users");
    const lastActive = await getLastActive(uid);

    if (lastActive === undefined || lastActive < Date.now() - 10000) {
      userRef
        .doc(uid)
        .update({
          lastActive: Date.now(),
        })
        // .then(() => console.log("setLastActive"))
        .catch((error) => console.error(error));
    }
  }
}

export function deleteLastActive(uid) {
  if (uid) {
    const userRef = db.collection("users");

    return (
      userRef
        .doc(uid)
        .set(
          {
            lastActive: firebase.firestore.FieldValue.delete(),
          },
          { merge: true }
        )
        // .then(() => console.log("deleteLastActive"))
        .catch((error) => console.error(error))
    );
  }
}

export const getDevices = async (collectionName, uid) => {
  let devices: any = [];

  if (uid) {
    const ref = await db.collection(collectionName).doc(uid).get();

    if (ref.exists) {
      const data = ref.data();

      if (data && data?.devices) {
        devices = await data.devices.map(async (device) => {
          const deviceRef = await device.get();
          return deviceRef.data();
        });
      }
    }
  }

  return devices;
};

export const addDevice = async (devices, uid) => {
  Sentry.addBreadcrumb({
    message: `addDevice called for user ${uid}`,
    level: "debug",
  });

  const randomValue = Math.trunc(Math.random() * 1000000000000);
  document.cookie = `device=${randomValue};max-age=2147483647`;

  db.collection("devices")
    .add({
      cookie: randomValue,
      os: "Windows",
      user: db.doc("users/" + uid),
    })
    .then((docRef) => {
      console.log("Document written with ID: ", docRef.id);

      function updateDevices() {
        userRef
          .update({
            devices: devicesToSet,
          })
          .then(() => {
            console.log("Document successfully updated!");
          })
          .catch((error) => {
            // The document probably doesn't exist.
            console.error("Error updating document: ", error);
          });
      }

      let devicesToSet = [db.doc("devices/" + docRef.id)];

      const userRef = db.collection("users").doc(uid);

      if (devices.length === 1) {
        userRef
          .get()
          .then((doc) => {
            if (doc.exists) {
              console.log("Document data:", doc.data());

              devicesToSet.push(doc?.data()?.devices[0]);
              updateDevices();
            } else {
              // doc.data() will be undefined in this case
              console.log("No such document!");
            }
          })
          .catch((error) => {
            console.log("Error getting document:", error);
          });
      } else {
        updateDevices();
      }
    })
    .catch((error) => {
      console.error("Error adding document: ", error);
    });
};

export const addDeviceCorporate = async (devices, userUid, usergroupUid) => {
  const randomValue = Math.trunc(Math.random() * 1000000000000);
  document.cookie = `device=${randomValue};max-age=2147483647`;

  // Add document to devices collection
  db.collection("devices")
    .add({
      cookie: randomValue,
      user: db.doc("users/" + userUid),
      usergroup: db.doc("usergroups/" + usergroupUid),
    })
    .then((docRef) => {
      console.log("Document written with ID: ", docRef.id);

      function updateDevices() {
        usergroupRef
          .update({
            devices: devicesToSet,
          })
          .then(() => {
            console.log(devicesToSet);
            console.log("Document successfully updated!");
          })
          .catch((error) => {
            // The document probably doesn't exist.
            console.error("Error updating document: ", error);
          });
      }

      // Update devices array in usergroup document
      let devicesToSet = [db.doc("devices/" + docRef.id)];

      const usergroupRef = db.collection("usergroups").doc(usergroupUid);

      if (devices.length >= 1) {
        usergroupRef
          .get()
          .then((doc) => {
            if (doc.exists) {
              console.log("Document data:", doc.data());

              devicesToSet.push(...doc?.data()?.devices);
              updateDevices();
            } else {
              // doc.data() will be undefined in this case
              console.log("No such document!");
            }
          })
          .catch((error) => {
            console.log("Error getting document:", error);
          });
      } else {
        updateDevices();
      }
    })
    .catch((error) => {
      console.error("Error adding document: ", error);
    });
};

export const resetDevices = async (collectionName, uid) => {
  if (uid) {
    const userDoc = await db.collection(collectionName).doc(uid).get();

    if (userDoc.exists) {
      const userData = userDoc.data();

      if (userData && userData?.devices) {
        userData.devices.forEach((device) => {
          device.get().then((res) => {
            // Delete device document
            db.collection("devices")
              .doc(res.id)
              .delete()
              .then(() => {
                console.log("Document successfully deleted!");
              })
              .catch((error) => {
                console.error("Error removing document: ", error);
              });
          });
        });
      }
    }

    // Clear devices array
    const userRef = db.collection(collectionName).doc(uid);

    userRef
      .update({
        devices: [],
      })
      .then(() => {
        console.log("Document successfully updated!");
      })
      .catch((error) => {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
      });
  }
};

// USERGROUPS
export const getUsergroupData = async (data: any) => {
  let usergroupData = data;
  const id = usergroupData.id;

  usergroupData = usergroupData.data();
  usergroupData.id = id;
  usergroupData.expiry = formatExpiryDate(usergroupData.expiry.seconds);

  // map through and retrieve user data
  // const users = usergroupData.users.map(async (user: any) => {
  //   let userData = await user.get();
  //   const userId = userData.id;

  //   userData = await userData.data();
  //   if (userData) {
  //     userData.id = userId;

  //     // Get user record by email
  //     const userRecord = await axios.get(`/user/${userData.email}`);
  //     const emailVerified: boolean = userRecord.data.emailVerified;
  //     userData.emailVerified = emailVerified;

  //     delete userData.usergroupRef;

  //     return userData;
  //   }
  // });

  // usergroupData.users = await Promise.all(users);

  return usergroupData;
};

export const getAllUsergroups = async () => {
  getIdToken();

  const usergroups: any = [];
  const usergroupsRef = await firestore.collection("usergroups").get();

  for (let doc of usergroupsRef.docs) {
    const data = await getUsergroupData(doc);
    if (data.company !== "admin") {
      usergroups.push(data);
    }
  }

  return usergroups;
};

export const getUsergroupFromUser = async (uid) => {
  if (uid) {
    const userRef = await db.collection("users").doc(uid).get();

    if (userRef.exists) {
      const userData = userRef.data();

      if (userData && userData?.usergroupRef) {
        const ref = await userData.usergroupRef.get();
        const usergroupData = ref.data();

        // const devicePromises = await usergroupData.devices.map(async device => {
        //   const deviceRef = await device.get();
        //   return await deviceRef.data();
        // });

        // const devices = await Promise.all(await devicePromises);

        return {
          license: usergroupData.license,
          devices: [],
          deviceLimit: usergroupData.deviceLimit,
          uid: ref.id,
        };
      }
    }
  }
};

export const createUsergroup = async (newUsergroup: Usergroup) => {
  getIdToken();
  try {
    // make sure usergroup doesn't already exist
    const usergroups = await firestore.collection("usergroups").get();

    for (let usergroup of usergroups.docs) {
      const data = usergroup.data();

      if (data.company === newUsergroup.company) {
        return false;
      }
    }

    await firestore.collection("usergroups").add(newUsergroup);
    return true;
  } catch (error) {
    if (error instanceof FirebaseError) {
      console.error(error.message);
    }
  }
};

export const updateUsergroupLicense = async (id: string, update: any) => {
  getIdToken();
  const usergroupRef = firestore.doc(`usergroups/${id}`);
  return await usergroupRef.update(update);
};

// Headquarter
// Update array of Firebase usergroup IDs
export const updateSubsidiaryCompanies = async (
  hqUsergroupId,
  usergroupIds
) => {
  getIdToken();
  const usergroupRef = firestore.doc(`usergroups/${hqUsergroupId}`);

  const update = { subsidiaryCompanies: usergroupIds };
  return await usergroupRef.update(update);
};

export const getUsergroupName = async (id: string) => {
  const ref = await db.collection("usergroups").doc(id).get();

  if (ref.exists) {
    const data = ref.data();
    if (data) return data.company;
  }
};

export const findHeadquarterCompany = async (subsidiaryCompanyId: string) => {
  const usergroupsRef = db.collection("usergroups");
  const snapshot = await usergroupsRef
    .where("subsidiaryCompanies", "array-contains", subsidiaryCompanyId)
    .get();

  if (snapshot.empty) {
    console.log("No matching documents.");
    return;
  }

  let result;
  snapshot.forEach((doc) => {
    const data = doc.data();
    result = data.company;
  });

  return result;
};

// USERS
export const createNewUser = async (newUser: User) => {
  // get usergroup ref
  const usergroups = await firestore.collection("usergroups").get();
  let usergroupRef;

  // Add new user's company info to new user object
  for (let usergroup of usergroups.docs) {
    const id = usergroup.id;
    const data = usergroup.data();

    if (data.company === newUser.company) {
      usergroupRef = firestore.doc(`usergroups/${id}`);
      newUser.usergroupRef = usergroupRef;
      break;
    }
  }

  await createUserProfileObject({
    uid: newUser.uid,
    email: newUser.email,
  });

  // Get users collection
  const users = await firestore.collection("users").get();

  for (let user of users.docs) {
    const userId = user.id;
    const userData = user.data();
    if (userData.email === newUser.email) {
      const userRef = firestore.doc(`users/${userId}`);
      const userObj = createNewUserObject(newUser);

      // Update user document created in AuthContext useEffect
      await userRef.update(userObj);

      if (usergroupRef) {
        await usergroupRef.update({
          users: firebase.firestore.FieldValue.arrayUnion(userRef),
        });
      }
      break;
    }
  }

  return true;
};

export const updateUserDoc = async (data: object, user: any) => {
  getIdToken();
  const userRef = firestore.doc(`users/${user.id}`);
  userRef.update(data);
};

export const getUserDoc = async (uid): Promise<any> => {
  var docRef = db.collection("users").doc(uid);

  return docRef
    .get()
    .then((doc) => {
      if (doc.exists) {
        // console.log("Document data:", doc.data());
        const data = doc.data();
        const result = { ...data, id: uid };

        return result;
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
      }
    })
    .catch((error) => {
      console.log("Error getting document:", error);
    });
};

export const deleteUser = async (
  usergroupId: string | undefined,
  userId: string | undefined
) => {
  if (usergroupId !== undefined || userId !== undefined) {
    const userDocRef = db.collection("users").doc(userId);

    // Update users array in usergroup document
    const usergroupRef = db.collection("usergroups").doc(usergroupId);

    usergroupRef.update({
      users: firebase.firestore.FieldValue.arrayRemove(userDocRef),
    });

    // Remove documents from devices collection associated with this user
    db.collection("devices")
      .where("user", "==", userDocRef)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          // Delete from devices array
          usergroupRef.update({
            devices: firebase.firestore.FieldValue.arrayRemove(doc.ref),
          });

          // Delete device documents
          db.collection("devices")
            .doc(doc.id)
            .delete()
            .then(() => {
              console.log(
                `Document ${doc.id} from devices collection successfully deleted!`
              );
            })
            .catch((error) => {
              console.error("Error removing document: ", error);
            });
        });
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });

    // Remove user document from users collection
    db.collection("users")
      .doc(userId)
      .delete()
      .then(() => {
        console.log("Document successfully deleted!");
      })
      .catch((error) => {
        console.error("Error removing document: ", error);
      });

    // axios request to backend
    axios
      .post(`/user/delete/${userId}`)
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
  }
};

export const deleteUsergroup = (usergroupId: string | undefined) => {
  const usergroupRef = db.collection("usergroups").doc(usergroupId);

  // Get and delete all users associated with usergroup
  db.collection("users")
    .where("usergroupRef", "==", usergroupRef)
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        // Delete user documents
        db.collection("users")
          .doc(doc.id)
          .delete()
          .then(() => {
            console.log(
              `Document ${doc.id} from users collection successfully deleted!`
            );
          })
          .catch((error) => {
            console.error("Error removing document: ", error);
          });

        // axios request to backend
        axios
          .post(`/user/delete/${doc.id}`)
          .then((res) => console.log(res))
          .catch((err) => console.log(err));
      });
    })
    .catch((error) => {
      console.log("Error getting documents: ", error);
    });

  // Get and delete all devices associated with usergroup
  db.collection("devices")
    .where("usergroupRef", "==", usergroupRef)
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        // Delete from devices array
        usergroupRef.update({
          devices: firebase.firestore.FieldValue.arrayRemove(doc.ref),
        });

        // Delete device documents
        db.collection("devices")
          .doc(doc.id)
          .delete()
          .then(() => {
            console.log(
              `Document ${doc.id} from devices collection successfully deleted!`
            );
          })
          .catch((error) => {
            console.error("Error removing document: ", error);
          });
      });
    })
    .catch((error) => {
      console.log("Error getting documents: ", error);
    });

  db.collection("usergroups")
    .doc(usergroupId)
    .delete()
    .then(() => {
      console.log(
        `Document ${usergroupId} from devices collection successfully deleted!`
      );
    })
    .catch((error) => {
      console.error("Error removing document: ", error);
    });
};

// documentSnapshots = currentUser.usergroupData.users
export const getUsergroupUsers = async (documentSnapshots) => {
  return Promise.all(
    documentSnapshots.map((snapshot) => getUserDoc(snapshot.id))
  );
};

// FAVORITES
export const addOrRemoveUserFavorite = async (
  user: User,
  value: number,
  action: string,
  medCore: boolean
) => {
  getIdToken();
  const userRef = firestore.doc(`users/${user.id}`);
  const add = firebase.firestore.FieldValue.arrayUnion(value);
  const remove = firebase.firestore.FieldValue.arrayRemove(value);
  const favorites = action === "add" ? add : remove;

  if (medCore) {
    userRef.update({ medCoreFavorites: favorites });
  } else {
    userRef.update({ favorites });
  }
};

export const addOrRemoveUserMedSKUFavorite = async (
  user: User,
  value: number,
  action: string
) => {
  getIdToken();

  const userRef = firestore.doc(`users/${user.id}`);
  const add = firebase.firestore.FieldValue.arrayUnion(value);
  const remove = firebase.firestore.FieldValue.arrayRemove(value);
  const medskuFavorites = action === "add" ? add : remove;

  userRef.update({ medskuFavorites });
};

export const addOrRemoveUserPTFavorite = async (
  user: User,
  value: number,
  action: string
) => {
  getIdToken();

  const userRef = firestore.doc(`users/${user.id}`);
  const add = firebase.firestore.FieldValue.arrayUnion(value);
  const remove = firebase.firestore.FieldValue.arrayRemove(value);
  const ptFavorites = action === "add" ? add : remove;

  userRef.update({ ptFavorites });
};

export const addOrRemoveUserSurveyFavorite = async (
  user: User,
  value: number,
  action: string
) => {
  getIdToken();

  const userRef = firestore.doc(`users/${user.id}`);
  const add = firebase.firestore.FieldValue.arrayUnion(value);
  const remove = firebase.firestore.FieldValue.arrayRemove(value);
  const surveyFavorites = action === "add" ? add : remove;

  userRef.update({ surveyFavorites });
};

export const setAutoScroll = async (user: User, value: boolean) => {
  getIdToken();

  const userRef = firestore.doc(`users/${user.id}`);

  userRef.update({ autoScroll: value });
};

export const getAutoScroll = async (user: User) => {
  getIdToken();

  const userRef = firestore.doc(`users/${user.id}`);
  const doc = await userRef.get();

  if (doc.exists) {
    const data = doc.data();
    if (data) {
      if (data.autoScroll == undefined) {
        return false;
      } else {
        return data.autoScroll;
      }
    }
  }
}

export const auth = app.auth();
export const firestore = app.firestore();
export default app;
