import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import { useAuth } from "contexts/AuthContext";
import { sendContactUsMessage } from "redux/actions/admin";
import { clearErrors } from "redux/actions/errors";

import closeIcon from "assets/icons/close-black.svg";
import StandardButton from "components/Common/buttons/StandardButton";
import { RENEWAL } from "data/email";

type RequestRenewalProps = {
  setToggleModal: Function;
  expiry: string;
};

export const RequestRenewal: React.FC<RequestRenewalProps> = ({
  setToggleModal,
  expiry,
}) => {
  const dispatch = useDispatch();
  const { currentUser } = useAuth();

  useEffect(() => {
    return () => {
      dispatch(clearErrors());
    };
  }, [dispatch]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    dispatch(clearErrors());

    const userMessage = {
      name: currentUser.name,
      email: currentUser.email,
      phone: currentUser.phone,
      title: currentUser.title,
      company: currentUser.company,
      category: "",
      message: "---------------",
      subject: RENEWAL,
      other: expiry,
    };

    dispatch(sendContactUsMessage(userMessage, setToggleModal));
  };

  return (
    <Container onClick={handleSubmit}>
      <Header>
        <div>Requesting to Renew Subscription</div>
        <button
          onClick={() => {
            setToggleModal(false);
          }}
        >
          <img src={closeIcon} alt="close icon" />
        </button>
      </Header>
      <ButtonContainer>
        <StandardButton type="submit" size="md">
          Send request
        </StandardButton>
      </ButtonContainer>
    </Container>
  );
};

const Container = styled.form`
  padding: 2rem;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 1.5em;
  margin-bottom: 1em;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export default RequestRenewal;
