export default function generatePaginationGroupings(
  results: React.ReactNode[],
  limit: number
) {
  const groups = Math.ceil(results.length / limit);
  const groupIndexes: number[][] = [];
  let first = 0;
  let last = limit;

  for (let i = 1; i <= groups; i++) {
    groupIndexes.push([first, last]);
    first += limit;
    last += limit;
  }

  return groupIndexes;
}
