import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { useAuth } from "contexts/AuthContext";
import { daysLeft } from "utils/expiry.utils";

// Notifies user when their account is about to expire and when
// their account is not associated with a Team Leader
const ExpiryNotification: React.FC = () => {
  const { currentUser } = useAuth();
  const expiry = currentUser.usergroupData && currentUser.usergroupData.expiry;
  const daysLeftToExpiry = daysLeft(expiry);

  let daysLeftStr: string = "";

  if (daysLeftToExpiry < 1) {
    daysLeftStr = "Your subscription has expired!";
  } else if (daysLeftToExpiry === 1) {
    daysLeftStr = "Subscription expiring in 1 day!";
  } else {
    daysLeftStr = `Subscription expiring in ${daysLeftToExpiry} days`;
  }
  daysLeftStr += " - ";

  return (
    <div>
      <Container hidden={daysLeftToExpiry <= 60 ? false : true}>
        <Message>
          {daysLeftStr}
          <RenewLink to="/dashboard/account-info">
            Request Renewal Now
          </RenewLink>
        </Message>
      </Container>
      {/* <Container hidden={(Object.keys(usergroup.teamLeader).length === 0 && usergroup.teamLeader.constructor === Object) ? false : true}>
        <Message>
          Your account is not associated with a Team Leader! Please <RenewLink to="/contact">contact support.</RenewLink>
        </Message>
      </Container> */}
    </div>
  );
};

const Container = styled.div`
  background-color: ${(props) => props.theme.colors.yellow};
  color: #000;
  padding: 1rem 7.5rem;
  text-align: right;
  width: 100%;
  z-index: 100;
  @media screen and (max-width: ${(props) => props.theme.bp.xs}px) {
    padding: 1rem 0.5rem;
    text-align: center;
  }
`;

const Message = styled.p`
  margin: 0;
`;

const RenewLink = styled(Link)`
  color: #000;
  text-decoration: underline;
  &:hover {
    opacity: 0.9;
  }
`;

export default ExpiryNotification;
