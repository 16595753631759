import React from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import { setSearchFilters } from "redux/actions/search";

type SortProps = {
  results: number;
  categories: string[];
};

const SortOptions: React.FC<SortProps> = ({ results, categories }) => {
  const dispatch = useDispatch();

  return (
    <Container>
      <SearchSummary>
        {results} result(s) found in {categories.length} categories
      </SearchSummary>
      <SortControls>
        <Sort>
          <Label htmlFor="sort">Sort by:</Label>
          <SortSelect
            name="sort"
            onChange={(e) =>
              dispatch(setSearchFilters("sortBy", e.target.value))
            }
          >
            <option value="new">Newest First</option>
            <option value="chapters">Chapters</option>
            <option value="old">Oldest First</option>
            <option value="category">Category</option>
          </SortSelect>
        </Sort>
        <Sort>
          <Label htmlFor="category">Filter by Category:</Label>
          <SortSelect
            name="category"
            onChange={(e) =>
              dispatch(setSearchFilters("category", e.target.value))
            }
          >
            <option value="all">All Categories</option>
            {categories
              .filter((category) => category.trim().length > 0)
              .map((category, i) => (
                <option key={i} value={category}>
                  {category}
                </option>
              ))}
          </SortSelect>
        </Sort>
      </SortControls>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: ${(props) => props.theme.bp.sm}px) {
    flex-direction: column;
  }
`;

const Label = styled.label`
  // display: none;
`;

const SortControls = styled.div`
  display: flex;
`;

const Sort = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.8rem;
  margin: 0;

  &:not(:last-child) {
    margin-right: 2rem;
  }

  @media screen and (max-width: ${(props) => props.theme.bp.lg}px) {
    font-size: 1.5rem;
  }
`;

const SortSelect = styled.select`
  border: 1px solid ${(props) => props.theme.colors.grey};
  border-radius: 4px;
  font-size: 1.8rem;
  margin-left: 1rem;

  &:hover {
    cursor: pointer;
  }

  &:active,
  &:focus {
    outline: none;
  }

  @media screen and (max-width: ${(props) => props.theme.bp.lg}px) {
    font-size: 1.5rem;
  }
`;

const SearchSummary = styled.p`
  font-size: 1.8rem;
  margin: 0;
`;

export default SortOptions;
