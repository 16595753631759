import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import styled from "styled-components";

import { RootState } from "redux/reducers/index";
import { resetNotification } from "redux/actions/notifications";

import closeIcon from "assets/icons/close-black.svg";

const Notification: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
      if (location.pathname !== "/") dispatch(resetNotification());
  }, [dispatch, location]);

  const notifications = useSelector((state: RootState) => state.notifications);

  return (
    <CustomContainer
      customColor={notifications.variant}
      message={notifications.message}
    >
      <Message>{notifications.message}</Message>
      <Control onClick={() => dispatch(resetNotification())}>
        <img src={closeIcon} alt={"close icon"} />
      </Control>
    </CustomContainer>
  );
};

const CustomContainer = styled.div<{ customColor: string; message: string }>`
  display: flex;
  display: ${(props) => {
    const test = props.message;

    if (test === "") {
      return "none";
    } else {
      return "flex";
    }
  }};

  justify-content: center;
  align-items: center;
  position: sticky;
  top: 0;
  left: 0;
  background-color: ${(props) => props.customColor};
  text-align: center;
  color: #000;
  padding: 1rem;
  width: 100%;
  z-index: 100;

  @media screen and (max-width: ${(props) => props.theme.bp.xs}px) {
    padding: 1rem 0.5rem;
  }
`;

const Control = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    opacity: 0.5;
  }
`;

const Message = styled.p`
  margin: 0;
  flex-grow: 1;
`;

export default Notification;
