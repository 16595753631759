import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Modal from "react-modal";

import { useAuth } from "contexts/AuthContext";
import { daysLeft } from "utils/expiry.utils";
import defaultProfilePicture from "assets/images/Default-Profile-Picture.png";

import { modalStyles } from "styles/modals";
import RequestRenewal from "components/Common/forms/RequestRewewal";
import StandardButton from "../../../../Common/buttons/StandardButton";

import { generatePageTitle } from "utils/general.utils";

Modal.setAppElement("#root");

const AccountInformation: React.FC = () => {
  const { currentUser } = useAuth();
  const [toggleModal, setToggleModal] = useState(false);
  const expiry = currentUser.usergroupData && currentUser.usergroupData.expiry;

  useEffect(() => {
    document.title = generatePageTitle("Account Information");
  }, []);

  return (
    <div>
      <ProfileSection>
        <ProfileSectionTitle>Profile</ProfileSectionTitle>
        <ProfileContent>
          <ProfileInformation>
            <SpecialItem>
              <strong>Name:</strong> {currentUser.name}
            </SpecialItem>
            <Item>
              <strong>Title:</strong> {currentUser.title}
            </Item>
            <Item>
              <strong>Phone Number:</strong> {currentUser.phone}
            </Item>
            <Item>
              <strong>Contact Email:</strong> {currentUser.email}
            </Item>
          </ProfileInformation>
          <PortraitBox>
            <Portrait src={defaultProfilePicture} alt="profile avatar" />
          </PortraitBox>
          <ButtonContainer>
            <Link to="/dashboard/edit-account-info">
              <StandardButton type="button" size="md">
                Edit Profile
              </StandardButton>
            </Link>
            <Link
              to="/dashboard/change-password"
              hidden={currentUser.email === "marketing@idataresearch.net"}
            >
              <StandardButton type="button" size="md">
                Change Password
              </StandardButton>
            </Link>
          </ButtonContainer>
        </ProfileContent>
      </ProfileSection>
      <AccountInfoSection>
        <ProfileSectionTitle>Account Information</ProfileSectionTitle>
        <AccountInfoContent>
          <AccountInfoMain>
            <ProfileInformation>
              <SpecialItem>
                <strong>Account Name:</strong> {currentUser.company}
              </SpecialItem>
              <Item>
                <strong>Subscription Expiry:</strong> {expiry}
              </Item>
            </ProfileInformation>
            <Buttons>
              <div hidden={daysLeft(expiry) <= 60 ? false : true}>
                <ExpiryNotification>
                  <NewIcon>!</NewIcon> Expires in {daysLeft(expiry)} Days
                </ExpiryNotification>
              </div>
              <StandardButton
                type="button"
                size="md"
                onClick={() => setToggleModal(true)}
              >
                Request Renewal
              </StandardButton>
              <Modal
                onRequestClose={() => setToggleModal(false)}
                isOpen={toggleModal}
                contentLabel="Request Renewal"
                style={modalStyles}
              >
                <RequestRenewal
                  expiry={expiry}
                  setToggleModal={setToggleModal}
                />
              </Modal>
            </Buttons>
          </AccountInfoMain>
          {/* <Divider /> */}
          {/* <p>Auto upgrades included with subscription: No</p> */}
        </AccountInfoContent>
      </AccountInfoSection>
    </div>
  );
};

const ProfileSection = styled.div`
  font-size: 1.8rem;
  margin-bottom: 5rem;
`;

const ProfileSectionTitle = styled.h2`
  color: ${(props) => props.theme.colors.idataDarkGrey};
  font-size: 3.5rem;
  font-weight: 700;
  margin: 2rem 0;
`;

const ProfileContent = styled.div`
  background-color: #fff;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 2.5rem;
  @media screen and (max-width: ${(props) => props.theme.bp.sm}px) {
    flex-direction: column;
    align-items: center;
  }
`;

const ButtonContainer = styled.div`
  button {
    margin-bottom: 1rem;
  }
`;

const AccountInfoContent = styled.div`
  background-color: #fff;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.3);
  padding: 2.5rem;
`;

const ProfileInformation = styled.ul`
  margin: 0;
  max-width: 45%;
  @media screen and (max-width: ${(props) => props.theme.bp.xl}px) {
    margin-bottom: 2rem;
  }
  @media screen and (max-width: ${(props) => props.theme.bp.sm}px) {
    max-width: 100%;
  }
`;

const Item = styled.li`
  &:not(:last-child) {
    margin-bottom: 1.5rem;
  }
`;

const SpecialItem = styled.li`
  color: ${(props) => props.theme.colors.idataBlue};
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  @media screen and (max-width: ${(props) => props.theme.bp.lg}px) {
    font-size: 2rem;
  }
`;

const PortraitBox = styled.div`
  border: 2px solid rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem 5rem;
  width: 30rem;
  @media screen and (max-width: ${(props) => props.theme.bp.xl}px) {
    max-width: 50%;
  }
  @media screen and (max-width: ${(props) => props.theme.bp.sm}px) {
    margin-bottom: 2rem;
  }
`;

const Portrait = styled.img`
  max-height: 15rem;
  max-width: 15rem;
  width: 100%;
`;

const AccountInfoSection = styled.div`
  font-size: 1.8rem;
  margin-bottom: 5rem;
`;

const AccountInfoMain = styled.div`
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: ${(props) => props.theme.bp.sm}px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const Buttons = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  @media screen and (max-width: ${(props) => props.theme.bp.sm}px) {
    justify-content: center;
  }
`;

const ExpiryNotification = styled.button`
  background-color: ${(props) => {
    const daysLeft: number = props?.children?.[2];

    if (daysLeft <= 10) {
      return "red";
    } else {
      return props.theme.colors.yellow;
    }
  }};

  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  font-size: 2.2rem;
  padding: 1rem 2rem;
  pointer-events: none;
  margin-right: 2rem;
  height: 5rem;
  width: 28rem;
  @media screen and (max-width: ${(props) => props.theme.bp.xl}px) {
    margin-bottom: 2rem;
    margin-right: 0;
  }
`;

const NewIcon = styled.span`
  /* background-color: ${(props) => props.theme.colors.yellow}; */
  border: 1px solid #000;
  border-radius: 50%;
  display: inline-block;
  color: #000;
  font-size: 2.5rem;
  line-height: 1;
  margin-right: 1.5rem;
  height: 2.5rem;
  width: 2.5rem;
`;

// const Divider = styled.hr`
//   margin: 3rem 0;
// `;

export default AccountInformation;
