import { useLocation } from "react-router-dom";
import { ReportSearchData } from "types/reports";

export const addHighlights = async (
  reportContent,
  query: string,
  previousQuery: string,
  isCustom: boolean = false
) => {
  // RegExp Constructor Function
  // g: looks for all matches
  // i: case-insensitive
  const regex = new RegExp(`(${query})`, "gi");
  const chapters: ReportSearchData[] = [];
  let original = reportContent;

  // clear previous query highlights
  if (previousQuery) {
    original = await removeHighlights(reportContent);
  }

  // iterate over each chapter, replacing keywords with special
  // html element
  const highlighted = original.map((content) => {
    // highlight items if query exists in chapter
    if (content.chapter_content.match(regex)) {
      let selectedChapter = content.chapter_content;
      const matches = selectedChapter.match(regex);
      const chapterNumber = isCustom
        ? content.custom_order
        : content.chapter_number;

      selectedChapter = selectedChapter.replace(
        regex,
        `<span class="report-highlight">$1</span>`
      );

      chapters.push({
        chapter: chapterNumber,
        matches: matches.length,
      });

      content.chapter_content = selectedChapter;
    }

    return content;
  });

  return {
    highlighted,
    chapters,
  };
};

export const removeHighlights = async (reportContent) => {
  const regex = /<span class="report-highlight">(.*?)<\/span>/gi;
  const original = await reportContent.map((content) => {
    content.chapter_content = content.chapter_content.replace(regex, "$1");
    return content;
  });

  return original;
};

export const getScrollPositions = () => {
  window.scroll(0, 0);

  const highlighted: NodeListOf<HTMLElement> = document.querySelectorAll(
    ".report-highlight"
  );

  const scrollPositions: number[] = [];

  for (let i = 0; i < highlighted.length; i++) {
    var viewportOffset = highlighted[i].getBoundingClientRect();
    const pos = viewportOffset.top + window.scrollY;
    scrollPositions.push(pos);
  }

  return scrollPositions;
};

export const combineSearchData = (data) => {
  const combined = {};

  data.forEach(({ attrs }) => {
    if (combined[attrs.report_id]) {
      combined[attrs.report_id].chapters.push(attrs.chapter_number);
    } else {
      combined[attrs.report_id] = {
        ...attrs,
        chapters: [attrs.chapter_number],
      };
    }
  });

  return [...Object.values(combined)];
};

export const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};
