export const reportFilters = [
  { id: 1, text: "Subscriptions", url: "/catalog" },
  { id: 2, text: "Favorites", url: "/catalog" },
  { id: 3, text: "Custom Reports", url: "/catalog" },
  { id: 4, text: "All Reports", url: "/catalog" },
];

export const catalogMenu = [
  {
    categoryId: 1,
    text: "Anesthesiology",
    url: "/catalog/anesthesiology",
    subCategories: [
      {
        subCategoryId: 1,
        text: "Anesthesiology, Respiratory, and Sleep Management Devices",
        url:
          "/catalog/anesthesiology/anesthesiology-respiratory-sleep-management-devices",
      },
    ],
  },
  {
    categoryId: 2,
    text: "Audiology / Hearing Devices",
    url: "/catalog/audiology-hearing-devices",
    subCategories: [
      {
        subCategoryId: 137,
        text: "Hearing Devices",
        url: "/catalog/audiology-hearing-devices/hearing-devices",
      },
    ],
  },
  {
    categoryId: 3,
    text: "Cardiovascular",
    url: "/catalog/cardiovascular",
    subCategories: [
      {
        subCategoryId: 6,
        text: "Cardiac Rhythm Management",
        url: "/catalog/cardiovascular/cardiac-rhythm-management",
      },
      {
        subCategoryId: 7,
        text: "Cardiac Surgery",
        url: "/catalog/cardiovascular/cardiac-surgery",
      },
      // {
      //   subCategoryId: 136,
      //   text: "Custom Projects and Consulting",
      //   url: "/catalog/cardiovascular/custom-projects-consulting",
      // },
      {
        subCategoryId: 9,
        text: "Interventional Cardiology",
        url: "/catalog/cardiovascular/interventional-cardiology",
      },
      {
        subCategoryId: 10,
        text: "Peripheral Vascular",
        url: "/catalog/cardiovascular/peripheral-vascular",
      },
      {
        subCategoryId: 11,
        text: "Vascular Access",
        url: "/catalog/cardiovascular/vascular-access",
      },
    ],
  },
  // {
  //   categoryId: 23,
  //   text: "Consulting Projects",
  //   url: "/catalog/consulting-projects",
  //   subCategories: [
  //     {
  //       subCategoryId: 124,
  //       text: "Body Composition Analyzers",
  //       url: "/catalog/consulting-projects/body-composition-analyzers",
  //     },
  //     {
  //       subCategoryId: 135,
  //       text: "Chronic Venuous Insufficiency",
  //       url: "/catalog/consulting-projects/chronic-venuous-insufficiency",
  //     },
  //     {
  //       subCategoryId: 128,
  //       text: "Surveys",
  //       url: "/catalog/consulting-projects/surveys",
  //     },
  //   ],
  // },
  {
    categoryId: 5,
    text: "Cosmetic Surgery",
    url: "/catalog/cosmetic-surgery",
    subCategories: [
      {
        subCategoryId: 13,
        text: "Cosmetic Surgery, Facial Aesthetics, and Medical Laser Devices",
        url:
          "/catalog/cosmetic-surgery/cosmetic-surgery-facial-aesthetics-medical-laser-devices",
      },
    ],
  },
  {
    categoryId: 6,
    text: "Dental",
    url: "/catalog/dental",
    subCategories: [
      {
        subCategoryId: 15,
        text: "Dental Bone Graft Substitutes",
        url: "/catalog/dental/dental-bone-graft-substitutes",
      },
      {
        subCategoryId: 17,
        text: "Dental Hygiene",
        url: "/catalog/dental/dental-hygiene",
      },
      {
        subCategoryId: 18,
        text: "Dental Imaging",
        url: "/catalog/dental/dental-imaging",
      },
      {
        subCategoryId: 19,
        text: "Dental Implants",
        url: "/catalog/dental/dental-implants",
      },
      {
        subCategoryId: 132,
        text: "Dental Lasers",
        url: "/catalog/dental/dental-lasers",
      },
      {
        subCategoryId: 80,
        text: "Dental Materials",
        url: "/catalog/dental/dental-materials",
      },
      {
        subCategoryId: 20,
        text: "Dental Operatory Equipment",
        url: "/catalog/dental/dental-operatory-equipment",
      },
      {
        subCategoryId: 21,
        text: "Dental Overdentures",
        url: "/catalog/dental/dental-overdentures",
      },
      {
        subCategoryId: 22,
        text: "Dental Prosthetics and CAD/CAM",
        url: "/catalog/dental/dental-prosthetics-cad-cam",
      },
      {
        subCategoryId: 81,
        text: "Dentures",
        url: "/catalog/dental/dentures",
      },
      {
        subCategoryId: 143,
        text: "Dental Orthodontics",
        url: "/catalog/dental/dental-orthodontics",
      },
      {
        subCategoryId: 146,
        text: "Dental Endodontics",
        url: "/catalog/dental/dental-endodontics",
      },
    ],
  },
  {
    categoryId: 7,
    text: "Diabetes",
    url: "/catalog/diabetes",
    subCategories: [
      {
        subCategoryId: 23,
        text: "Diabetes Diagnosis, Treatment, and Drug Delivery",
        url: "/catalog/diabetes/diabetes-diagnosis-treatment-drug-delivery",
      },
    ],
  },
  {
    categoryId: 8,
    text: "Diagnostics",
    url: "/catalog/diagnostics",
    subCategories: [
      // {
      //   subCategoryId: 36,
      //   text: "Audiology/Hearing Devices",
      //   url: "/catalog/diagnostics/audiology-hearing-devices",
      // },
      {
        subCategoryId: 24,
        text: "Digital Radiography",
        url: "/catalog/diagnostics/digital-radiography",
      },
      {
        subCategoryId: 83,
        text: "Image Guided Surgery",
        url: "/catalog/diagnostics/image-guided-surgery",
      },
      {
        subCategoryId: 82,
        text: "Immuno Assay Devices",
        url: "/catalog/diagnostics/immuno-assay-devices",
      },
      {
        subCategoryId: 84,
        text: "In Vitro Diagnostics",
        url: "/catalog/diagnostics/in-vitro-diagnostics",
      },
      {
        subCategoryId: 25,
        text: "Magnetic Resonance, Computed Tomography, and Nuclear Medicine",
        url:
          "/catalog/diagnostics/magnetic-resonance-computed-tomography-nuclear-medicine",
      },
      {
        subCategoryId: 26,
        text: "Medical Imaging",
        url: "/catalog/diagnostics/medical-imaging",
      },
      {
        subCategoryId: 140,
        text: "Oncology",
        url: "/catalog/diagnostics/oncology",
      },
      {
        subCategoryId: 58,
        text: "Patient Monitoring",
        url: "/catalog/diagnostics/patient-monitoring",
      },
      {
        subCategoryId: 88,
        text: "Retinal Screening Devices",
        url: "/catalog/diagnostics/retinal-screening-devices",
      },
      {
        subCategoryId: 28,
        text: "Ultrasound Imaging",
        url: "/catalog/diagnostics/ultrasound-imaging",
      },
    ],
  },
  {
    categoryId: 30,
    text: "Dialysis",
    url: "/catalog/dialysis",
    subCategories: [
      {
        subCategoryId: 78,
        text: "Dialysis",
        url: "/catalog/dialysis/dialysis",
      },
    ],
  },
  {
    categoryId: 9,
    text: "Emergency Room",
    url: "/catalog/emergency-room",
    subCategories: [
      {
        subCategoryId: 131,
        text: "Emergency Room Equipment",
        url: "/catalog/emergency-room/emergency-room-equipment",
      },
    ],
  },
  {
    categoryId: 10,
    text: "Endoscopy",
    url: "/catalog/endoscopy",
    subCategories: [
      {
        subCategoryId: 114,
        text: "Bioresorbable Screws",
        url: "/catalog/endoscopy/bioresorbable-screws",
      },
      {
        subCategoryId: 134,
        text: "Breast Tissue Repair",
        url: "/catalog/endoscopy/breast-tissue-repair",
      },
      {
        subCategoryId: 30,
        text: "Endoscopic Devices",
        url: "/catalog/endoscopy/endoscopic-devices",
      },
      {
        subCategoryId: 31,
        text: "ENT and Bronchoscopic Devices",
        url: "/catalog/endoscopy/ent-bronchoscopic-devices",
      },
      {
        subCategoryId: 32,
        text: "Gastrointestinal Devices",
        url: "/catalog/endoscopy/gastrointestinal-devices",
      },
      {
        subCategoryId: 131,
        text: "Laparoscopic Morcellators",
        url: "/catalog/endoscopy/laparoscopic-morcellators",
      },
      {
        subCategoryId: 33,
        text: "Laparoscopy",
        url: "/catalog/endoscopy/laparoscopy",
      },
      {
        subCategoryId: 138,
        text: "Robotics and Navigation",
        url: "/catalog/endoscopy/robotics-navigation",
      },
      // {
      //   subCategoryId: 138,
      //   text: "Surgical Navigation Systems and Robotics",
      //   url: "/catalog/endoscopy/surgical-navigation-systems-robotics",
      // },
      {
        subCategoryId: 144,
        text: "Sterilization",
        url: "/catalog/endoscopy/sterilization",
      },
    ],
  },
  {
    categoryId: 31,
    text: "Infusion Therapy",
    url: "/catalog/infusion-therapy",
    subCategories: [
      {
        subCategoryId: 8,
        text: "Infusion Therapy",
        url: "/catalog/infusion-therapy/infusion-therapy",
      },
    ],
  },
  {
    categoryId: 13,
    text: "Neurology",
    url: "/catalog/neurology",
    subCategories: [
      {
        subCategoryId: 41,
        text: "Neurological Devices",
        url: "/catalog/neurology/neurological-devices",
      },
    ],
  },
  {
    categoryId: 14,
    text: "Ophthalmics",
    url: "/catalog/ophthalmics",
    subCategories: [
      {
        subCategoryId: 44,
        text: "Diagnostic Ophthalmic Devices",
        url: "/catalog/ophthalmics/diagnostic-ophthalmic-devices",
      },
      {
        subCategoryId: 45,
        text: "Interventional Ophthalmic Devices",
        url: "/catalog/ophthalmics/interventional-ophthalmic-devices",
      },
      {
        subCategoryId: 86,
        text: "Ophthalmics (combined)",
        url: "/catalog/ophthalmics/ophthalmics-combined",
      },
    ],
  },
  {
    categoryId: 15,
    text: "Orthopedics",
    url: "/catalog/orthopedics",
    subCategories: [
      {
        subCategoryId: 76,
        text: "Arthroscopy",
        url: "/catalog/orthopedics/arthroscopy",
      },
      {
        subCategoryId: 47,
        text: "Orthopedic Biomaterials",
        url: "/catalog/orthopedics/orthopedic-biomaterials",
      },
      {
        subCategoryId: 48,
        text: "Orthopedic Bracing",
        url: "/catalog/orthopedics/orthopedic-bracing",
      },
      {
        subCategoryId: 139,
        text: "Orthopedic Craniomaxillofacial and Thoracic",
        url: "/catalog/orthopedics/orthopedic-craniomaxillofacial-thoracic",
      },
      {
        subCategoryId: 73,
        text: "Orthopedic Large Joint",
        url: "/catalog/orthopedics/orthopedic-large-joint",
      },
      {
        subCategoryId: 74,
        text: "Orthopedic Small Bone and Joint",
        url: "/catalog/orthopedics/orthopedic-small-bone-joint",
      },
      {
        subCategoryId: 51,
        text: "Orthopedic Soft Tissue Repair & Sports Medicine",
        url:
          "/catalog/orthopedics/orthopedic-soft-tissue-repair-sports-medicine",
      },
      {
        subCategoryId: 50,
        text: "Orthopedic Trauma",
        url: "/catalog/orthopedics/orthopedic-trauma",
      },
    ],
  },
  {
    categoryId: 36,
    text: "Pharmaceuticals",
    url: "/catalog/pharmaceuticals",
    subCategories: [
      {
        subCategoryId: 76,
        text: "Immunology",
        url: "/catalog/pharmaceuticals/immunology",
      },
    ],
  },
  {
    categoryId: 33,
    text: "Spine",
    url: "/catalog/spine",
    subCategories: [
      {
        subCategoryId: 52,
        text: "Spinal Implants",
        url: "/catalog/spine/spinal-implants",
      },
      {
        subCategoryId: 53,
        text: "Spinal MIS",
        url: "/catalog/spine/spinal-mis",
      },
    ],
  },
  {
    categoryId: 37,
    text: "Tissue Repair",
    url: "/catalog/tissue-repair",
    subCategories: [
      {
        subCategoryId: 142,
        text: "Soft Tissue Reinforcement",
        url: "/catalog/tissue-repair/soft-tissue-reinforcement",
      },
    ],
  },
  {
    categoryId: 20,
    text: "Urology",
    url: "/catalog/urology",
    subCategories: [
      {
        subCategoryId: 65,
        text: "Urological Devices",
        url: "/catalog/urology/urological-devices",
      },
    ],
  },
  {
    categoryId: 34,
    text: "Video, High-Tech, and IGS",
    url: "/catalog/video-high-tech-igs",
    subCategories: [
      {
        subCategoryId: 35,
        text: "Video, High-Tech, and IGS",
        url: "/catalog/video-high-tech-igs/video-high-tech-igs",
      },
    ],
  },
  {
    categoryId: 32,
    text: "Women`s Health Devices",
    url: "/catalog/womens-health-devices",
    subCategories: [
      {
        subCategoryId: 90,
        text: "Female and Neonatal Devices",
        url: "/catalog/womens-health-devices/female-neonatal-devices",
      },
      {
        subCategoryId: 91,
        text: "Gynecological Devices",
        url: "/catalog/womens-health-devices/gynelogical-devices",
      },
    ],
  },
  {
    categoryId: 22,
    text: "Wound Management",
    url: "/catalog/wound-management",
    subCategories: [
      {
        subCategoryId: 70,
        text: "Therapeutic Support Surfaces",
        url: "/catalog/wound-management/therapeutic-support-devices",
      },
      {
        subCategoryId: 71,
        text: "Wound & Tissue Management",
        url: "/catalog/wound-management/wound-tissue-management",
      },
    ],
  },
];

export const medskuFilters = [
  { id: 1, text: "Subscriptions", url: "/medsku" },
  { id: 2, text: "Favorites", url: "/medsku" },
  { id: 4, text: "All MedSKU", url: "/medsku" },
];

export const ptFilters = [
  { id: 1, text: "Subscriptions", url: "/pt" },
  { id: 2, text: "Favorites", url: "/pt" },
  { id: 4, text: "All Procedure Tracker", url: "/pt" },
];

export const surveyFilters = [
  { id: 1, text: "Subscriptions", url: "/survey" },
  { id: 2, text: "Favorites", url: "/survey" },
  { id: 4, text: "All Surveys", url: "/survey" },
];

export const medskuMenu = [
  {
    categoryId: 1,
    text: "Anesthesiology",
    url: "/catalog/anesthesiology",
    subCategories: [
      {
        subCategoryId: 1,
        text: "Anesthesiology, Respiratory, and Sleep Management Devices",
        url:
          "/catalog/anesthesiology/anesthesiology-respiratory-sleep-management-devices",
      },
    ],
  },
  {
    categoryId: 2,
    text: "Audiology / Hearing Devices",
    url: "/catalog/audiology-hearing-devices",
    subCategories: [
      {
        subCategoryId: 137,
        text: "Hearing Devices",
        url: "/catalog/audiology-hearing-devices/hearing-devices",
      },
    ],
  },
  {
    categoryId: 3,
    text: "Cardiovascular",
    url: "/catalog/cardiovascular",
    subCategories: [
      {
        subCategoryId: 6,
        text: "Cardiac Rhythm Management",
        url: "/catalog/cardiovascular/cardiac-rhythm-management",
      },
      {
        subCategoryId: 7,
        text: "Cardiac Surgery",
        url: "/catalog/cardiovascular/cardiac-surgery",
      },
      {
        subCategoryId: 136,
        text: "Custom Projects and Consulting",
        url: "/catalog/cardiovascular/custom-projects-consulting",
      },
      {
        subCategoryId: 9,
        text: "Interventional Cardiology",
        url: "/catalog/cardiovascular/interventional-cardiology",
      },
      {
        subCategoryId: 10,
        text: "Peripheral Vascular",
        url: "/catalog/cardiovascular/peripheral-vascular",
      },
      {
        subCategoryId: 11,
        text: "Vascular Access",
        url: "/catalog/cardiovascular/vascular-access",
      },
    ],
  },
  // {
  //   categoryId: 23,
  //   text: "Consulting Projects",
  //   url: "/catalog/consulting-projects",
  //   subCategories: [
  //     {
  //       subCategoryId: 124,
  //       text: "Body Composition Analyzers",
  //       url: "/catalog/consulting-projects/body-composition-analyzers",
  //     },
  //     {
  //       subCategoryId: 135,
  //       text: "Chronic Venuous Insufficiency",
  //       url: "/catalog/consulting-projects/chronic-venuous-insufficiency",
  //     },
  //     {
  //       subCategoryId: 128,
  //       text: "Surveys",
  //       url: "/catalog/consulting-projects/surveys",
  //     },
  //   ],
  // },
  {
    categoryId: 5,
    text: "Cosmetic Surgery",
    url: "/catalog/cosmetic-surgery",
    subCategories: [
      {
        subCategoryId: 13,
        text: "Cosmetic Surgery, Facial Aesthetics, and Medical Laser Devices",
        url:
          "/catalog/cosmetic-surgery/cosmetic-surgery-facial-aesthetics-medical-laser-devices",
      },
    ],
  },
  {
    categoryId: 6,
    text: "Dental",
    url: "/catalog/dental",
    subCategories: [
      {
        subCategoryId: 15,
        text: "Dental Bone Graft Substitutes",
        url: "/catalog/dental/dental-bone-graft-substitutes",
      },
      {
        subCategoryId: 17,
        text: "Dental Hygiene",
        url: "/catalog/dental/dental-hygiene",
      },
      {
        subCategoryId: 18,
        text: "Dental Imaging",
        url: "/catalog/dental/dental-imaging",
      },
      {
        subCategoryId: 19,
        text: "Dental Implants",
        url: "/catalog/dental/dental-implants",
      },
      {
        subCategoryId: 132,
        text: "Dental Lasers",
        url: "/catalog/dental/dental-lasers",
      },
      {
        subCategoryId: 80,
        text: "Dental Materials",
        url: "/catalog/dental/dental-materials",
      },
      {
        subCategoryId: 20,
        text: "Dental Operatory Equipment",
        url: "/catalog/dental/dental-operatory-equipment",
      },
      {
        subCategoryId: 21,
        text: "Dental Overdentures",
        url: "/catalog/dental/dental-overdentures",
      },
      {
        subCategoryId: 22,
        text: "Dental Prosthetics and CAD/CAM",
        url: "/catalog/dental/dental-prosthetics-cad-cam",
      },
      {
        subCategoryId: 81,
        text: "Dentures",
        url: "/catalog/dental/dentures",
      },
      {
        subCategoryId: 143,
        text: "Dental Orthodontics",
        url: "/catalog/dental/dental-orthodontics",
      },
      {
        subCategoryId: 146,
        text: "Dental Endodontics",
        url: "/catalog/dental/dental-endodontics",
      },
    ],
  },
  {
    categoryId: 7,
    text: "Diabetes",
    url: "/catalog/diabetes",
    subCategories: [
      {
        subCategoryId: 23,
        text: "Diabetes Diagnosis, Treatment, and Drug Delivery",
        url: "/catalog/diabetes/diabetes-diagnosis-treatment-drug-delivery",
      },
    ],
  },
  {
    categoryId: 8,
    text: "Diagnostics",
    url: "/catalog/diagnostics",
    subCategories: [
      // {
      //   subCategoryId: 36,
      //   text: "Audiology/Hearing Devices",
      //   url: "/catalog/diagnostics/audiology-hearing-devices",
      // },
      {
        subCategoryId: 24,
        text: "Digital Radiography",
        url: "/catalog/diagnostics/digital-radiography",
      },
      {
        subCategoryId: 83,
        text: "Image Guided Surgery",
        url: "/catalog/diagnostics/image-guided-surgery",
      },
      {
        subCategoryId: 82,
        text: "Immuno Assay Devices",
        url: "/catalog/diagnostics/immuno-assay-devices",
      },
      {
        subCategoryId: 84,
        text: "In Vitro Diagnostics",
        url: "/catalog/diagnostics/in-vitro-diagnostics",
      },
      {
        subCategoryId: 25,
        text: "Magnetic Resonance, Computed Tomography, and Nuclear Medicine",
        url:
          "/catalog/diagnostics/magnetic-resonance-computed-tomography-nuclear-medicine",
      },
      {
        subCategoryId: 26,
        text: "Medical Imaging",
        url: "/catalog/diagnostics/medical-imaging",
      },
      {
        subCategoryId: 140,
        text: "Oncology",
        url: "/catalog/diagnostics/oncology",
      },
      {
        subCategoryId: 58,
        text: "Patient Monitoring",
        url: "/catalog/diagnostics/patient-monitoring",
      },
      {
        subCategoryId: 88,
        text: "Retinal Screening Devices",
        url: "/catalog/diagnostics/retinal-screening-devices",
      },
      {
        subCategoryId: 28,
        text: "Ultrasound Imaging",
        url: "/catalog/diagnostics/ultrasound-imaging",
      },
    ],
  },
  {
    categoryId: 30,
    text: "Dialysis",
    url: "/catalog/dialysis",
    subCategories: [
      {
        subCategoryId: 78,
        text: "Dialysis",
        url: "/catalog/dialysis/dialysis",
      },
    ],
  },
  {
    categoryId: 9,
    text: "Emergency Room",
    url: "/catalog/emergency-room",
    subCategories: [
      {
        subCategoryId: 131,
        text: "Emergency Room Equipment",
        url: "/catalog/emergency-room/emergency-room-equipment",
      },
    ],
  },
  {
    categoryId: 10,
    text: "Endoscopy",
    url: "/catalog/endoscopy",
    subCategories: [
      {
        subCategoryId: 114,
        text: "Bioresorbable Screws",
        url: "/catalog/endoscopy/bioresorbable-screws",
      },
      {
        subCategoryId: 134,
        text: "Breast Tissue Repair",
        url: "/catalog/endoscopy/breast-tissue-repair",
      },
      {
        subCategoryId: 30,
        text: "Endoscopic Devices",
        url: "/catalog/endoscopy/endoscopic-devices",
      },
      {
        subCategoryId: 31,
        text: "ENT and Bronchoscopic Devices",
        url: "/catalog/endoscopy/ent-bronchoscopic-devices",
      },
      {
        subCategoryId: 32,
        text: "Gastrointestinal Devices",
        url: "/catalog/endoscopy/gastrointestinal-devices",
      },
      {
        subCategoryId: 131,
        text: "Laparoscopic Morcellators",
        url: "/catalog/endoscopy/laparoscopic-morcellators",
      },
      {
        subCategoryId: 33,
        text: "Laparoscopy",
        url: "/catalog/endoscopy/laparoscopy",
      },
      {
        subCategoryId: 121,
        text: "Robotics and Navigation",
        url: "/catalog/endoscopy/robotics-navigation",
      },
      {
        subCategoryId: 138,
        text: "Surgical Navigation Systems and Robotics",
        url: "/catalog/endoscopy/surgical-navigation-systems-robotics",
      },
      {
        subCategoryId: 144,
        text: "Sterilization",
        url: "/catalog/endoscopy/sterilization",
      },
    ],
  },
  {
    categoryId: 31,
    text: "Infusion Therapy",
    url: "/catalog/infusion-therapy",
    subCategories: [
      {
        subCategoryId: 8,
        text: "Infusion Therapy",
        url: "/catalog/infusion-therapy/infusion-therapy",
      },
    ],
  },
  {
    categoryId: 13,
    text: "Neurology",
    url: "/catalog/neurology",
    subCategories: [
      {
        subCategoryId: 41,
        text: "Neurological Devices",
        url: "/catalog/neurology/neurological-devices",
      },
    ],
  },
  {
    categoryId: 14,
    text: "Ophthalmics",
    url: "/catalog/ophthalmics",
    subCategories: [
      {
        subCategoryId: 44,
        text: "Diagnostic Ophthalmic Devices",
        url: "/catalog/ophthalmics/diagnostic-ophthalmic-devices",
      },
      {
        subCategoryId: 45,
        text: "Interventional Ophthalmic Devices",
        url: "/catalog/ophthalmics/interventional-ophthalmic-devices",
      },
      {
        subCategoryId: 86,
        text: "Ophthalmics (combined)",
        url: "/catalog/ophthalmics/ophthalmics-combined",
      },
    ],
  },
  {
    categoryId: 15,
    text: "Orthopedics",
    url: "/catalog/orthopedics",
    subCategories: [
      {
        subCategoryId: 76,
        text: "Arthroscopy",
        url: "/catalog/orthopedics/arthroscopy",
      },
      {
        subCategoryId: 47,
        text: "Orthopedic Biomaterials",
        url: "/catalog/orthopedics/orthopedic-biomaterials",
      },
      {
        subCategoryId: 48,
        text: "Orthopedic Bracing",
        url: "/catalog/orthopedics/orthopedic-bracing",
      },
      {
        subCategoryId: 139,
        text: "Orthopedic Craniomaxillofacial and Thoracic",
        url: "/catalog/orthopedics/orthopedic-craniomaxillofacial-thoracic",
      },
      {
        subCategoryId: 73,
        text: "Orthopedic Large Joint",
        url: "/catalog/orthopedics/orthopedic-large-joint",
      },
      {
        subCategoryId: 74,
        text: "Orthopedic Small Bone and Joint",
        url: "/catalog/orthopedics/orthopedic-small-bone-joint",
      },
      {
        subCategoryId: 51,
        text: "Orthopedic Soft Tissue Repair & Sports Medicine",
        url:
          "/catalog/orthopedics/orthopedic-soft-tissue-repair-sports-medicine",
      },
      {
        subCategoryId: 50,
        text: "Orthopedic Trauma",
        url: "/catalog/orthopedics/orthopedic-trauma",
      },
    ],
  },
  {
    categoryId: 36,
    text: "Pharmaceuticals",
    url: "/catalog/pharmaceuticals",
    subCategories: [
      {
        subCategoryId: 76,
        text: "Immunology",
        url: "/catalog/pharmaceuticals/immunology",
      },
    ],
  },
  {
    categoryId: 33,
    text: "Spine",
    url: "/catalog/spine",
    subCategories: [
      {
        subCategoryId: 52,
        text: "Spinal Implants",
        url: "/catalog/spine/spinal-implants",
      },
      {
        subCategoryId: 53,
        text: "Spinal MIS",
        url: "/catalog/spine/spinal-mis",
      },
    ],
  },
  {
    categoryId: 37,
    text: "Tissue Repair",
    url: "/catalog/tissue-repair",
    subCategories: [
      {
        subCategoryId: 142,
        text: "Soft Tissue Reinforcement",
        url: "/catalog/tissue-repair/soft-tissue-reinforcement",
      },
    ],
  },
  {
    categoryId: 20,
    text: "Urology",
    url: "/catalog/urology",
    subCategories: [
      {
        subCategoryId: 65,
        text: "Urological Devices",
        url: "/catalog/urology/urological-devices",
      },
    ],
  },
  {
    categoryId: 34,
    text: "Video, High-Tech, and IGS",
    url: "/catalog/video-high-tech-igs",
    subCategories: [
      {
        subCategoryId: 35,
        text: "Video, High-Tech, and IGS",
        url: "/catalog/video-high-tech-igs/video-high-tech-igs",
      },
    ],
  },
  {
    categoryId: 32,
    text: "Women`s Health Devices",
    url: "/catalog/womens-health-devices",
    subCategories: [
      {
        subCategoryId: 90,
        text: "Female and Neonatal Devices",
        url: "/catalog/womens-health-devices/female-neonatal-devices",
      },
      {
        subCategoryId: 91,
        text: "Gynecological Devices",
        url: "/catalog/womens-health-devices/gynelogical-devices",
      },
    ],
  },
  {
    categoryId: 22,
    text: "Wound Management",
    url: "/catalog/wound-management",
    subCategories: [
      {
        subCategoryId: 70,
        text: "Therapeutic Support Surfaces",
        url: "/catalog/wound-management/therapeutic-support-devices",
      },
      {
        subCategoryId: 71,
        text: "Wound & Tissue Management",
        url: "/catalog/wound-management/wound-tissue-management",
      },
    ],
  },
];

export const ptMenu = [
  {
    categoryId: 1,
    text: "Anesthesiology",
    url: "/catalog/anesthesiology",
    subCategories: [
      {
        subCategoryId: 1,
        text: "Anesthesiology, Respiratory, and Sleep Management Devices",
        url:
          "/catalog/anesthesiology/anesthesiology-respiratory-sleep-management-devices",
      },
    ],
  },
  {
    categoryId: 2,
    text: "Audiology / Hearing Devices",
    url: "/catalog/audiology-hearing-devices",
    subCategories: [
      {
        subCategoryId: 137,
        text: "Hearing Devices",
        url: "/catalog/audiology-hearing-devices/hearing-devices",
      },
    ],
  },
  {
    categoryId: 3,
    text: "Cardiovascular",
    url: "/catalog/cardiovascular",
    subCategories: [
      {
        subCategoryId: 6,
        text: "Cardiac Rhythm Management",
        url: "/catalog/cardiovascular/cardiac-rhythm-management",
      },
      {
        subCategoryId: 7,
        text: "Cardiac Surgery",
        url: "/catalog/cardiovascular/cardiac-surgery",
      },
      {
        subCategoryId: 136,
        text: "Custom Projects and Consulting",
        url: "/catalog/cardiovascular/custom-projects-consulting",
      },
      {
        subCategoryId: 9,
        text: "Interventional Cardiology",
        url: "/catalog/cardiovascular/interventional-cardiology",
      },
      {
        subCategoryId: 10,
        text: "Peripheral Vascular",
        url: "/catalog/cardiovascular/peripheral-vascular",
      },
      {
        subCategoryId: 11,
        text: "Vascular Access",
        url: "/catalog/cardiovascular/vascular-access",
      },
    ],
  },
  // {
  //   categoryId: 23,
  //   text: "Consulting Projects",
  //   url: "/catalog/consulting-projects",
  //   subCategories: [
  //     {
  //       subCategoryId: 124,
  //       text: "Body Composition Analyzers",
  //       url: "/catalog/consulting-projects/body-composition-analyzers",
  //     },
  //     {
  //       subCategoryId: 135,
  //       text: "Chronic Venuous Insufficiency",
  //       url: "/catalog/consulting-projects/chronic-venuous-insufficiency",
  //     },
  //     {
  //       subCategoryId: 128,
  //       text: "Surveys",
  //       url: "/catalog/consulting-projects/surveys",
  //     },
  //   ],
  // },
  {
    categoryId: 5,
    text: "Cosmetic Surgery",
    url: "/catalog/cosmetic-surgery",
    subCategories: [
      {
        subCategoryId: 13,
        text: "Cosmetic Surgery, Facial Aesthetics, and Medical Laser Devices",
        url:
          "/catalog/cosmetic-surgery/cosmetic-surgery-facial-aesthetics-medical-laser-devices",
      },
    ],
  },
  {
    categoryId: 6,
    text: "Dental",
    url: "/catalog/dental",
    subCategories: [
      {
        subCategoryId: 15,
        text: "Dental Bone Graft Substitutes",
        url: "/catalog/dental/dental-bone-graft-substitutes",
      },
      {
        subCategoryId: 17,
        text: "Dental Hygiene",
        url: "/catalog/dental/dental-hygiene",
      },
      {
        subCategoryId: 18,
        text: "Dental Imaging",
        url: "/catalog/dental/dental-imaging",
      },
      {
        subCategoryId: 19,
        text: "Dental Implants",
        url: "/catalog/dental/dental-implants",
      },
      {
        subCategoryId: 132,
        text: "Dental Lasers",
        url: "/catalog/dental/dental-lasers",
      },
      {
        subCategoryId: 80,
        text: "Dental Materials",
        url: "/catalog/dental/dental-materials",
      },
      {
        subCategoryId: 20,
        text: "Dental Operatory Equipment",
        url: "/catalog/dental/dental-operatory-equipment",
      },
      {
        subCategoryId: 21,
        text: "Dental Overdentures",
        url: "/catalog/dental/dental-overdentures",
      },
      {
        subCategoryId: 22,
        text: "Dental Prosthetics and CAD/CAM",
        url: "/catalog/dental/dental-prosthetics-cad-cam",
      },
      {
        subCategoryId: 81,
        text: "Dentures",
        url: "/catalog/dental/dentures",
      },
      {
        subCategoryId: 143,
        text: "Dental Orthodontics",
        url: "/catalog/dental/dental-orthodontics",
      },
      {
        subCategoryId: 146,
        text: "Dental Endodontics",
        url: "/catalog/dental/dental-endodontics",
      },
    ],
  },
  {
    categoryId: 7,
    text: "Diabetes",
    url: "/catalog/diabetes",
    subCategories: [
      {
        subCategoryId: 23,
        text: "Diabetes Diagnosis, Treatment, and Drug Delivery",
        url: "/catalog/diabetes/diabetes-diagnosis-treatment-drug-delivery",
      },
    ],
  },
  {
    categoryId: 8,
    text: "Diagnostics",
    url: "/catalog/diagnostics",
    subCategories: [
      // {
      //   subCategoryId: 36,
      //   text: "Audiology/Hearing Devices",
      //   url: "/catalog/diagnostics/audiology-hearing-devices",
      // },
      {
        subCategoryId: 24,
        text: "Digital Radiography",
        url: "/catalog/diagnostics/digital-radiography",
      },
      {
        subCategoryId: 83,
        text: "Image Guided Surgery",
        url: "/catalog/diagnostics/image-guided-surgery",
      },
      {
        subCategoryId: 82,
        text: "Immuno Assay Devices",
        url: "/catalog/diagnostics/immuno-assay-devices",
      },
      {
        subCategoryId: 84,
        text: "In Vitro Diagnostics",
        url: "/catalog/diagnostics/in-vitro-diagnostics",
      },
      {
        subCategoryId: 25,
        text: "Magnetic Resonance, Computed Tomography, and Nuclear Medicine",
        url:
          "/catalog/diagnostics/magnetic-resonance-computed-tomography-nuclear-medicine",
      },
      {
        subCategoryId: 26,
        text: "Medical Imaging",
        url: "/catalog/diagnostics/medical-imaging",
      },
      {
        subCategoryId: 140,
        text: "Oncology",
        url: "/catalog/diagnostics/oncology",
      },
      {
        subCategoryId: 58,
        text: "Patient Monitoring",
        url: "/catalog/diagnostics/patient-monitoring",
      },
      {
        subCategoryId: 88,
        text: "Retinal Screening Devices",
        url: "/catalog/diagnostics/retinal-screening-devices",
      },
      {
        subCategoryId: 28,
        text: "Ultrasound Imaging",
        url: "/catalog/diagnostics/ultrasound-imaging",
      },
      {
        subCategoryId: 145,
        text: "Other",
        url: "/catalog/diagnostics/other",
      },
    ],
  },
  {
    categoryId: 30,
    text: "Dialysis",
    url: "/catalog/dialysis",
    subCategories: [
      {
        subCategoryId: 78,
        text: "Dialysis",
        url: "/catalog/dialysis/dialysis",
      },
    ],
  },
  {
    categoryId: 9,
    text: "Emergency Room",
    url: "/catalog/emergency-room",
    subCategories: [
      {
        subCategoryId: 131,
        text: "Emergency Room Equipment",
        url: "/catalog/emergency-room/emergency-room-equipment",
      },
    ],
  },
  {
    categoryId: 10,
    text: "Endoscopy",
    url: "/catalog/endoscopy",
    subCategories: [
      {
        subCategoryId: 114,
        text: "Bioresorbable Screws",
        url: "/catalog/endoscopy/bioresorbable-screws",
      },
      {
        subCategoryId: 134,
        text: "Breast Tissue Repair",
        url: "/catalog/endoscopy/breast-tissue-repair",
      },
      {
        subCategoryId: 30,
        text: "Endoscopic Devices",
        url: "/catalog/endoscopy/endoscopic-devices",
      },
      {
        subCategoryId: 31,
        text: "ENT and Bronchoscopic Devices",
        url: "/catalog/endoscopy/ent-bronchoscopic-devices",
      },
      {
        subCategoryId: 32,
        text: "Gastrointestinal Devices",
        url: "/catalog/endoscopy/gastrointestinal-devices",
      },
      {
        subCategoryId: 131,
        text: "Laparoscopic Morcellators",
        url: "/catalog/endoscopy/laparoscopic-morcellators",
      },
      {
        subCategoryId: 33,
        text: "Laparoscopy",
        url: "/catalog/endoscopy/laparoscopy",
      },
      {
        subCategoryId: 121,
        text: "Robotics and Navigation",
        url: "/catalog/endoscopy/robotics-navigation",
      },
      {
        subCategoryId: 138,
        text: "Surgical Navigation Systems and Robotics",
        url: "/catalog/endoscopy/surgical-navigation-systems-robotics",
      },
      {
        subCategoryId: 144,
        text: "Sterilization",
        url: "/catalog/endoscopy/sterilization",
      },
    ],
  },
  {
    categoryId: 31,
    text: "Infusion Therapy",
    url: "/catalog/infusion-therapy",
    subCategories: [
      {
        subCategoryId: 8,
        text: "Infusion Therapy",
        url: "/catalog/infusion-therapy/infusion-therapy",
      },
    ],
  },
  {
    categoryId: 13,
    text: "Neurology",
    url: "/catalog/neurology",
    subCategories: [
      {
        subCategoryId: 41,
        text: "Neurological Devices",
        url: "/catalog/neurology/neurological-devices",
      },
    ],
  },
  {
    categoryId: 14,
    text: "Ophthalmics",
    url: "/catalog/ophthalmics",
    subCategories: [
      {
        subCategoryId: 44,
        text: "Diagnostic Ophthalmic Devices",
        url: "/catalog/ophthalmics/diagnostic-ophthalmic-devices",
      },
      {
        subCategoryId: 45,
        text: "Interventional Ophthalmic Devices",
        url: "/catalog/ophthalmics/interventional-ophthalmic-devices",
      },
      {
        subCategoryId: 86,
        text: "Ophthalmics (combined)",
        url: "/catalog/ophthalmics/ophthalmics-combined",
      },
    ],
  },
  {
    categoryId: 15,
    text: "Orthopedics",
    url: "/catalog/orthopedics",
    subCategories: [
      {
        subCategoryId: 76,
        text: "Arthroscopy",
        url: "/catalog/orthopedics/arthroscopy",
      },
      {
        subCategoryId: 47,
        text: "Orthopedic Biomaterials",
        url: "/catalog/orthopedics/orthopedic-biomaterials",
      },
      {
        subCategoryId: 48,
        text: "Orthopedic Bracing",
        url: "/catalog/orthopedics/orthopedic-bracing",
      },
      {
        subCategoryId: 139,
        text: "Orthopedic Craniomaxillofacial and Thoracic",
        url: "/catalog/orthopedics/orthopedic-craniomaxillofacial-thoracic",
      },
      {
        subCategoryId: 73,
        text: "Orthopedic Large Joint",
        url: "/catalog/orthopedics/orthopedic-large-joint",
      },
      {
        subCategoryId: 74,
        text: "Orthopedic Small Bone and Joint",
        url: "/catalog/orthopedics/orthopedic-small-bone-joint",
      },
      {
        subCategoryId: 51,
        text: "Orthopedic Soft Tissue Repair & Sports Medicine",
        url:
          "/catalog/orthopedics/orthopedic-soft-tissue-repair-sports-medicine",
      },
      {
        subCategoryId: 50,
        text: "Orthopedic Trauma",
        url: "/catalog/orthopedics/orthopedic-trauma",
      },
    ],
  },
  {
    categoryId: 36,
    text: "Pharmaceuticals",
    url: "/catalog/pharmaceuticals",
    subCategories: [
      {
        subCategoryId: 76,
        text: "Immunology",
        url: "/catalog/pharmaceuticals/immunology",
      },
    ],
  },
  {
    categoryId: 33,
    text: "Spine",
    url: "/catalog/spine",
    subCategories: [
      {
        subCategoryId: 52,
        text: "Spinal Implants",
        url: "/catalog/spine/spinal-implants",
      },
      {
        subCategoryId: 53,
        text: "Spinal MIS",
        url: "/catalog/spine/spinal-mis",
      },
    ],
  },
  {
    categoryId: 37,
    text: "Tissue Repair",
    url: "/catalog/tissue-repair",
    subCategories: [
      {
        subCategoryId: 142,
        text: "Soft Tissue Reinforcement",
        url: "/catalog/tissue-repair/soft-tissue-reinforcement",
      },
    ],
  },
  {
    categoryId: 20,
    text: "Urology",
    url: "/catalog/urology",
    subCategories: [
      {
        subCategoryId: 65,
        text: "Urological Devices",
        url: "/catalog/urology/urological-devices",
      },
    ],
  },
  {
    categoryId: 34,
    text: "Video, High-Tech, and IGS",
    url: "/catalog/video-high-tech-igs",
    subCategories: [
      {
        subCategoryId: 35,
        text: "Video, High-Tech, and IGS",
        url: "/catalog/video-high-tech-igs/video-high-tech-igs",
      },
    ],
  },
  {
    categoryId: 32,
    text: "Women`s Health Devices",
    url: "/catalog/womens-health-devices",
    subCategories: [
      {
        subCategoryId: 90,
        text: "Female and Neonatal Devices",
        url: "/catalog/womens-health-devices/female-neonatal-devices",
      },
      {
        subCategoryId: 91,
        text: "Gynecological Devices",
        url: "/catalog/womens-health-devices/gynelogical-devices",
      },
    ],
  },
  {
    categoryId: 22,
    text: "Wound Management",
    url: "/catalog/wound-management",
    subCategories: [
      {
        subCategoryId: 70,
        text: "Therapeutic Support Surfaces",
        url: "/catalog/wound-management/therapeutic-support-devices",
      },
      {
        subCategoryId: 71,
        text: "Wound & Tissue Management",
        url: "/catalog/wound-management/wound-tissue-management",
      },
    ],
  },
];

export const surveyMenu = [
  {
    categoryId: 1,
    text: "Anesthesiology",
    url: "/catalog/anesthesiology",
    subCategories: [
      {
        subCategoryId: 1,
        text: "Anesthesiology, Respiratory, and Sleep Management Devices",
        url:
          "/catalog/anesthesiology/anesthesiology-respiratory-sleep-management-devices",
      },
    ],
  },
  {
    categoryId: 2,
    text: "Audiology / Hearing Devices",
    url: "/catalog/audiology-hearing-devices",
    subCategories: [
      {
        subCategoryId: 137,
        text: "Hearing Devices",
        url: "/catalog/audiology-hearing-devices/hearing-devices",
      },
    ],
  },
  {
    categoryId: 3,
    text: "Cardiovascular",
    url: "/catalog/cardiovascular",
    subCategories: [
      {
        subCategoryId: 6,
        text: "Cardiac Rhythm Management",
        url: "/catalog/cardiovascular/cardiac-rhythm-management",
      },
      {
        subCategoryId: 7,
        text: "Cardiac Surgery",
        url: "/catalog/cardiovascular/cardiac-surgery",
      },
      {
        subCategoryId: 136,
        text: "Custom Projects and Consulting",
        url: "/catalog/cardiovascular/custom-projects-consulting",
      },
      {
        subCategoryId: 9,
        text: "Interventional Cardiology",
        url: "/catalog/cardiovascular/interventional-cardiology",
      },
      {
        subCategoryId: 10,
        text: "Peripheral Vascular",
        url: "/catalog/cardiovascular/peripheral-vascular",
      },
      {
        subCategoryId: 11,
        text: "Vascular Access",
        url: "/catalog/cardiovascular/vascular-access",
      },
    ],
  },
  // {
  //   categoryId: 23,
  //   text: "Consulting Projects",
  //   url: "/catalog/consulting-projects",
  //   subCategories: [
  //     {
  //       subCategoryId: 124,
  //       text: "Body Composition Analyzers",
  //       url: "/catalog/consulting-projects/body-composition-analyzers",
  //     },
  //     {
  //       subCategoryId: 135,
  //       text: "Chronic Venuous Insufficiency",
  //       url: "/catalog/consulting-projects/chronic-venuous-insufficiency",
  //     },
  //     {
  //       subCategoryId: 128,
  //       text: "Surveys",
  //       url: "/catalog/consulting-projects/surveys",
  //     },
  //   ],
  // },
  {
    categoryId: 5,
    text: "Cosmetic Surgery",
    url: "/catalog/cosmetic-surgery",
    subCategories: [
      {
        subCategoryId: 13,
        text: "Cosmetic Surgery, Facial Aesthetics, and Medical Laser Devices",
        url:
          "/catalog/cosmetic-surgery/cosmetic-surgery-facial-aesthetics-medical-laser-devices",
      },
    ],
  },
  {
    categoryId: 6,
    text: "Dental",
    url: "/catalog/dental",
    subCategories: [
      {
        subCategoryId: 15,
        text: "Dental Bone Graft Substitutes",
        url: "/catalog/dental/dental-bone-graft-substitutes",
      },
      {
        subCategoryId: 17,
        text: "Dental Hygiene",
        url: "/catalog/dental/dental-hygiene",
      },
      {
        subCategoryId: 18,
        text: "Dental Imaging",
        url: "/catalog/dental/dental-imaging",
      },
      {
        subCategoryId: 19,
        text: "Dental Implants",
        url: "/catalog/dental/dental-implants",
      },
      {
        subCategoryId: 132,
        text: "Dental Lasers",
        url: "/catalog/dental/dental-lasers",
      },
      {
        subCategoryId: 80,
        text: "Dental Materials",
        url: "/catalog/dental/dental-materials",
      },
      {
        subCategoryId: 20,
        text: "Dental Operatory Equipment",
        url: "/catalog/dental/dental-operatory-equipment",
      },
      {
        subCategoryId: 21,
        text: "Dental Overdentures",
        url: "/catalog/dental/dental-overdentures",
      },
      {
        subCategoryId: 22,
        text: "Dental Prosthetics and CAD/CAM",
        url: "/catalog/dental/dental-prosthetics-cad-cam",
      },
      {
        subCategoryId: 81,
        text: "Dentures",
        url: "/catalog/dental/dentures",
      },
      {
        subCategoryId: 143,
        text: "Dental Orthodontics",
        url: "/catalog/dental/dental-orthodontics",
      },
      {
        subCategoryId: 146,
        text: "Dental Endodontics",
        url: "/catalog/dental/dental-endodontics",
      },
    ],
  },
  {
    categoryId: 7,
    text: "Diabetes",
    url: "/catalog/diabetes",
    subCategories: [
      {
        subCategoryId: 23,
        text: "Diabetes Diagnosis, Treatment, and Drug Delivery",
        url: "/catalog/diabetes/diabetes-diagnosis-treatment-drug-delivery",
      },
    ],
  },
  {
    categoryId: 8,
    text: "Diagnostics",
    url: "/catalog/diagnostics",
    subCategories: [
      // {
      //   subCategoryId: 36,
      //   text: "Audiology/Hearing Devices",
      //   url: "/catalog/diagnostics/audiology-hearing-devices",
      // },
      {
        subCategoryId: 24,
        text: "Digital Radiography",
        url: "/catalog/diagnostics/digital-radiography",
      },
      {
        subCategoryId: 83,
        text: "Image Guided Surgery",
        url: "/catalog/diagnostics/image-guided-surgery",
      },
      {
        subCategoryId: 82,
        text: "Immuno Assay Devices",
        url: "/catalog/diagnostics/immuno-assay-devices",
      },
      {
        subCategoryId: 84,
        text: "In Vitro Diagnostics",
        url: "/catalog/diagnostics/in-vitro-diagnostics",
      },
      {
        subCategoryId: 25,
        text: "Magnetic Resonance, Computed Tomography, and Nuclear Medicine",
        url:
          "/catalog/diagnostics/magnetic-resonance-computed-tomography-nuclear-medicine",
      },
      {
        subCategoryId: 26,
        text: "Medical Imaging",
        url: "/catalog/diagnostics/medical-imaging",
      },
      {
        subCategoryId: 140,
        text: "Oncology",
        url: "/catalog/diagnostics/oncology",
      },
      {
        subCategoryId: 58,
        text: "Patient Monitoring",
        url: "/catalog/diagnostics/patient-monitoring",
      },
      {
        subCategoryId: 88,
        text: "Retinal Screening Devices",
        url: "/catalog/diagnostics/retinal-screening-devices",
      },
      {
        subCategoryId: 28,
        text: "Ultrasound Imaging",
        url: "/catalog/diagnostics/ultrasound-imaging",
      },
    ],
  },
  {
    categoryId: 30,
    text: "Dialysis",
    url: "/catalog/dialysis",
    subCategories: [
      {
        subCategoryId: 78,
        text: "Dialysis",
        url: "/catalog/dialysis/dialysis",
      },
    ],
  },
  {
    categoryId: 9,
    text: "Emergency Room",
    url: "/catalog/emergency-room",
    subCategories: [
      {
        subCategoryId: 131,
        text: "Emergency Room Equipment",
        url: "/catalog/emergency-room/emergency-room-equipment",
      },
    ],
  },
  {
    categoryId: 10,
    text: "Endoscopy",
    url: "/catalog/endoscopy",
    subCategories: [
      {
        subCategoryId: 114,
        text: "Bioresorbable Screws",
        url: "/catalog/endoscopy/bioresorbable-screws",
      },
      {
        subCategoryId: 134,
        text: "Breast Tissue Repair",
        url: "/catalog/endoscopy/breast-tissue-repair",
      },
      {
        subCategoryId: 30,
        text: "Endoscopic Devices",
        url: "/catalog/endoscopy/endoscopic-devices",
      },
      {
        subCategoryId: 31,
        text: "ENT and Bronchoscopic Devices",
        url: "/catalog/endoscopy/ent-bronchoscopic-devices",
      },
      {
        subCategoryId: 32,
        text: "Gastrointestinal Devices",
        url: "/catalog/endoscopy/gastrointestinal-devices",
      },
      {
        subCategoryId: 131,
        text: "Laparoscopic Morcellators",
        url: "/catalog/endoscopy/laparoscopic-morcellators",
      },
      {
        subCategoryId: 33,
        text: "Laparoscopy",
        url: "/catalog/endoscopy/laparoscopy",
      },
      {
        subCategoryId: 121,
        text: "Robotics and Navigation",
        url: "/catalog/endoscopy/robotics-navigation",
      },
      {
        subCategoryId: 138,
        text: "Surgical Navigation Systems and Robotics",
        url: "/catalog/endoscopy/surgical-navigation-systems-robotics",
      },
      {
        subCategoryId: 144,
        text: "Sterilization",
        url: "/catalog/endoscopy/sterilization",
      },
    ],
  },
  {
    categoryId: 31,
    text: "Infusion Therapy",
    url: "/catalog/infusion-therapy",
    subCategories: [
      {
        subCategoryId: 8,
        text: "Infusion Therapy",
        url: "/catalog/infusion-therapy/infusion-therapy",
      },
    ],
  },
  {
    categoryId: 13,
    text: "Neurology",
    url: "/catalog/neurology",
    subCategories: [
      {
        subCategoryId: 41,
        text: "Neurological Devices",
        url: "/catalog/neurology/neurological-devices",
      },
    ],
  },
  {
    categoryId: 14,
    text: "Ophthalmics",
    url: "/catalog/ophthalmics",
    subCategories: [
      {
        subCategoryId: 44,
        text: "Diagnostic Ophthalmic Devices",
        url: "/catalog/ophthalmics/diagnostic-ophthalmic-devices",
      },
      {
        subCategoryId: 45,
        text: "Interventional Ophthalmic Devices",
        url: "/catalog/ophthalmics/interventional-ophthalmic-devices",
      },
      {
        subCategoryId: 86,
        text: "Ophthalmics (combined)",
        url: "/catalog/ophthalmics/ophthalmics-combined",
      },
    ],
  },
  {
    categoryId: 15,
    text: "Orthopedics",
    url: "/catalog/orthopedics",
    subCategories: [
      {
        subCategoryId: 76,
        text: "Arthroscopy",
        url: "/catalog/orthopedics/arthroscopy",
      },
      {
        subCategoryId: 47,
        text: "Orthopedic Biomaterials",
        url: "/catalog/orthopedics/orthopedic-biomaterials",
      },
      {
        subCategoryId: 48,
        text: "Orthopedic Bracing",
        url: "/catalog/orthopedics/orthopedic-bracing",
      },
      {
        subCategoryId: 139,
        text: "Orthopedic Craniomaxillofacial and Thoracic",
        url: "/catalog/orthopedics/orthopedic-craniomaxillofacial-thoracic",
      },
      {
        subCategoryId: 73,
        text: "Orthopedic Large Joint",
        url: "/catalog/orthopedics/orthopedic-large-joint",
      },
      {
        subCategoryId: 74,
        text: "Orthopedic Small Bone and Joint",
        url: "/catalog/orthopedics/orthopedic-small-bone-joint",
      },
      {
        subCategoryId: 51,
        text: "Orthopedic Soft Tissue Repair & Sports Medicine",
        url:
          "/catalog/orthopedics/orthopedic-soft-tissue-repair-sports-medicine",
      },
      {
        subCategoryId: 50,
        text: "Orthopedic Trauma",
        url: "/catalog/orthopedics/orthopedic-trauma",
      },
    ],
  },
  {
    categoryId: 36,
    text: "Pharmaceuticals",
    url: "/catalog/pharmaceuticals",
    subCategories: [
      {
        subCategoryId: 76,
        text: "Immunology",
        url: "/catalog/pharmaceuticals/immunology",
      },
    ],
  },
  {
    categoryId: 33,
    text: "Spine",
    url: "/catalog/spine",
    subCategories: [
      {
        subCategoryId: 52,
        text: "Spinal Implants",
        url: "/catalog/spine/spinal-implants",
      },
      {
        subCategoryId: 53,
        text: "Spinal MIS",
        url: "/catalog/spine/spinal-mis",
      },
    ],
  },
  {
    categoryId: 37,
    text: "Tissue Repair",
    url: "/catalog/tissue-repair",
    subCategories: [
      {
        subCategoryId: 142,
        text: "Soft Tissue Reinforcement",
        url: "/catalog/tissue-repair/soft-tissue-reinforcement",
      },
    ],
  },
  {
    categoryId: 20,
    text: "Urology",
    url: "/catalog/urology",
    subCategories: [
      {
        subCategoryId: 65,
        text: "Urological Devices",
        url: "/catalog/urology/urological-devices",
      },
    ],
  },
  {
    categoryId: 34,
    text: "Video, High-Tech, and IGS",
    url: "/catalog/video-high-tech-igs",
    subCategories: [
      {
        subCategoryId: 35,
        text: "Video, High-Tech, and IGS",
        url: "/catalog/video-high-tech-igs/video-high-tech-igs",
      },
    ],
  },
  {
    categoryId: 32,
    text: "Women`s Health Devices",
    url: "/catalog/womens-health-devices",
    subCategories: [
      {
        subCategoryId: 90,
        text: "Female and Neonatal Devices",
        url: "/catalog/womens-health-devices/female-neonatal-devices",
      },
      {
        subCategoryId: 91,
        text: "Gynecological Devices",
        url: "/catalog/womens-health-devices/gynelogical-devices",
      },
    ],
  },
  {
    categoryId: 22,
    text: "Wound Management",
    url: "/catalog/wound-management",
    subCategories: [
      {
        subCategoryId: 70,
        text: "Therapeutic Support Surfaces",
        url: "/catalog/wound-management/therapeutic-support-devices",
      },
      {
        subCategoryId: 71,
        text: "Wound & Tissue Management",
        url: "/catalog/wound-management/wound-tissue-management",
      },
    ],
  },
];
