import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { useAuth } from "contexts/AuthContext";

import StandardButton from "../buttons/StandardButton";
import { RootState } from "redux/reducers/index";
import { setInactive } from "redux/actions/inactive";

const Inactive: React.FC = () => {
  const dispatch = useDispatch();
  const { logout } = useAuth();
  const { inactive } = useSelector((state: RootState) => state.inactive);

  const handleClick = () => {
    dispatch(setInactive(false));
    return logout();
  };

  if (inactive) {
    return (
      <Container>
        <div>
          <h1>Session Expired</h1>
          <p>
            You have been inactive for too long. Please return to the homepage
            and log in again.
          </p>
        </div>
        <div>
          <StandardButton
            type="button"
            size="md"
            color="green"
            onClick={handleClick}
          >
            Return to homepage
          </StandardButton>
        </div>
      </Container>
    );
  } else {
    return <></>;
  }
};

const Container = styled.article`
  position: absolute;
  background-color: white;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 3;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export default Inactive;
