import { createStore, applyMiddleware } from "redux";
import { persistStore } from "redux-persist";
// import logger from "redux-logger";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import rootReducer from "../reducers";
import {
  createStateSyncMiddleware,
  initMessageListener,
} from "redux-state-sync";

const stateSyncConfig = {
  whitelist: ["SET_NOTIFICATION"],
};

const middlewares = [thunk, createStateSyncMiddleware(stateSyncConfig)];

export const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(...middlewares))
);

export type AppStore = ReturnType<typeof createStore>

initMessageListener(store);

export const persistor = persistStore(store);
