import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import { approveAuthRequestByEmail } from "redux/actions/admin";

import Spinner from "components/Common/misc/Spinner";

import Header from "components/Header/Header";
import Footer from "components/Footer/Footer";

const RegistrationRequest = () => {
  const { token } = useParams<{ token: string }>();
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    approveAuthRequestByEmail(token)
      .then((res) => setMessage(res))
      .finally(() => setLoading(false));
  }, [token]);

  return loading ? (
    <>
      <Header />
      <Spinner />
      <Footer />
    </>
  ) : (
    <>
      <Header />
      <Container>
        <h3>{message}</h3>
      </Container>
      <Footer />
    </>
  );
};

const Container = styled.div`
  max-width: ${(props) => props.theme.bp.xxl}px;
  margin: auto;
  padding: 5rem;
`;

export default RegistrationRequest;
