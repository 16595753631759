import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { useAuth } from "contexts/AuthContext";

import { generateReportUrl } from "utils/report.utils";
import StandardButton from "components/Common/buttons/StandardButton";

type ItemProps = {
  subs: any;
  remove: Function;
};

const ReportSummary: React.FC<ItemProps> = ({ subs, remove }) => {
  if (subs.length > 0) {
    return subs.map((sub) =>
      <Item key={`SubscriptionsItem_${sub.report_id}`} sub={sub} remove={remove} />
    );
  } else {
    return null;
  }
};

const Item = ({ sub, remove }) => {
  const { isSales } = useAuth();

  const [keep, setKeep] = useState<boolean>(false);

  const url = generateReportUrl(sub.report_name);

  function handleClick() {
    setKeep(!keep);
    remove(sub.report_id);
  }

  if (isSales()) {
    return (
      <SubscriptionsItem $keep={keep}>
        <div>
          <Link
            to={`/reports/${sub.report_id}/${url}/1`}
            target="_blank"
          >
            {sub.report_name} ({sub.report_year})
          </Link>
          <Small>Access assigned on: {sub.date_added ? new Date(sub.date_added).toString() : "Just now"}</Small>
          <Small>{sub.sample ? `Sample with access to market: ${sub.market_name}` : ""}</Small>
        </div>

      </SubscriptionsItem>
    );
  } else {
    return (
      <SubscriptionsItem $keep={keep}>
        <div>
          <Link
            to={`/reports/${sub.report_id}/${url}/1`}
            target="_blank"
          >
            {sub.report_name} ({sub.report_year})
          </Link>
          <Small>Access assigned on: {sub.date_added ? new Date(sub.date_added).toString() : "Just now"}</Small>
          <Small>{sub.sample ? `Sample with access to market: ${sub.market_name}` : ""}</Small>
        </div>

        <StandardButton onClick={handleClick} size="xs" type={"button"}>
          {keep ? "Keep" : "Remove"}
        </StandardButton>
      </SubscriptionsItem>
    );

  }
}

const SubscriptionsItem = styled.div<{ $keep?: boolean; }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  padding: 1rem;

  div {
    width: 100%;
  }

  opacity: ${props => props.$keep ? 0.5 : 1};
  transition: 0.2s;
`;

const Small = styled.div`
  font-size: 1.5rem;
`;

export default ReportSummary;
