import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { RootState } from "redux/reducers";

import StandardButton from "../../../../Common/buttons/StandardButton";

type DashboardSettingsProps = {
  setToggleModal: (toggleModal: boolean) => void;
};

const DashboardSettings: React.FC<DashboardSettingsProps> = ({
  setToggleModal,
}) => {
  const { reportData } = useSelector((state: RootState) => state.customReport);
  const [globalDashboard, setGlobalDashboard] = useState(false);
  const [availableMarkets, setAvailableMarkets] = useState<any>([]);
  const [markets, setMarkets] = useState<any>([]);

  // Get and set markets
  useEffect(() => {
    axios
      .get(`/global-dashboards/markets/${reportData.report_id}`)
      .then((res) => setAvailableMarkets(res.data));

    axios
      .get(`/custom-reports/subscribed-markets/${reportData.c_report_id}`)
      .then((res) => {
        if (res.data.length > 0) {
          setMarkets(res.data);
          setGlobalDashboard(true);
        }
      });
  }, [reportData.report_id, reportData.c_report_id]);

  const handleMarkets = (e) => {
    var options = e.target.options;
    var value: string[] = [];
    for (var i = 0, l = options.length; i < l; i++) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    setMarkets(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let market_names = markets;

    if (!globalDashboard) {
      market_names = [];
    }

    axios
      .put(`/custom-reports/global-dashboard/${reportData.c_report_id}`, {
        report_id: reportData.report_id,
        market_names,
      })
      .then(() => {
        setToggleModal(false);
      });
  };

  return (
    <form onSubmit={handleSubmit}>
      <h2>Dashboard Settings</h2>
      <div>
        <input
          type="checkbox"
          id="globalDashboard"
          checked={globalDashboard}
          onChange={() => setGlobalDashboard(!globalDashboard)}
        />
        <label htmlFor="globalDashboard">Global dashboard</label>
      </div>

      <p>Choose which markets will be available to this MedCore</p>
      <div hidden={!globalDashboard}>
        <select multiple onChange={(e) => handleMarkets(e)}>
          {availableMarkets.map((item) => {
            if (markets.find((element: string) => element === item)) {
              return (
                <option selected key={item} value={item}>
                  {item ? item : item}
                </option>
              );
            } else {
              return (
                <option key={item} value={item}>
                  {item ? item : item}
                </option>
              );
            }
          })}
        </select>
        <p>
          <em>
            Hold down the Ctrl (windows) or Command (Mac) button to select
            multiple options.
          </em>
        </p>
      </div>
      <SaveButton type="submit" size="sm" mt="3rem">
        Save
      </SaveButton>
    </form>
  );
};

const SaveButton = styled(StandardButton)`
  margin: 5rem auto 2rem auto;
`;

export default DashboardSettings;
