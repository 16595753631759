import React, { useState, useEffect } from "react";
import styled from "styled-components";

import { useAuth } from "contexts/AuthContext";
import { addOrRemoveUserFavorite } from "../../../firebase";
import starIcon from "assets/icons/star-icon.svg";
import starIconOutline from "assets/icons/star-icon-outline.svg";

type FavButtonProps = {
  reportId: number;
  c_report_id: number | undefined;
  medCore: boolean;
};

const FavoriteButton: React.FC<FavButtonProps> = ({
  reportId,
  c_report_id,
  medCore,
}) => {
  const { currentUser } = useAuth();
  const [isFavorite, setFavorite] = useState<boolean>();

  useEffect(() => {
    if (medCore) {
      if (currentUser.medCoreFavorites) {
        const favorite = currentUser.medCoreFavorites.includes(c_report_id);
        setFavorite(favorite);
      }
    } else {
      if (currentUser.favorites) {
        const favorite = currentUser.favorites.includes(reportId);
        setFavorite(favorite);
      }
    }
  }, [c_report_id, currentUser, medCore, reportId]);

  const handleFavorite = async () => {
    if (isFavorite) {
      try {
        await addOrRemoveUserFavorite(
          currentUser,
          c_report_id ? c_report_id : reportId,
          "remove",
          medCore
        );
        setFavorite(false);
      } catch (error) {
        throw new Error("Failed to update favorites");
      }
    } else {
      try {
        await addOrRemoveUserFavorite(
          currentUser,
          c_report_id ? c_report_id : reportId,
          "add",
          medCore
        );
        setFavorite(true);
      } catch (error) {
        throw new Error("Failed to add favorite");
      }
    }
  };

  return (
    <Container onClick={handleFavorite}>
      <img src={isFavorite ? starIcon : starIconOutline} alt="favorite" />
      <TextSpan>Save to Favorites</TextSpan>
    </Container>
  );
};

const Container = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  transition: all 0.2s;
  position: relative;

  &:hover {
    opacity: 0.8;
  }

  &:hover span {
    visibility: visible;
  }
`;

const TextSpan = styled.span`
  visibility: hidden;
  background-color: ${(props) => props.theme.colors.idataBlue};
  color: white;
  padding: 10px;
  border-radius: 5px;
  width: 140px;

  position: absolute;
  z-index: 1;
  bottom: -40px;
  right: -50px;

  @media screen and (max-width: 700px) {
    right: -10px;
  }
`;

export default FavoriteButton;
