import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import firebase from "firebase/app";
import styled from "styled-components";
import { FirebaseError } from "@firebase/util";

import { RootState } from "redux/reducers";
import { setErrors, clearErrors } from "redux/actions/errors";

import StandardButton from "components/Common/buttons/StandardButton";

const VerifyCode: React.FC<{ error: any }> = ({ error }) => {
  const dispatch = useDispatch();
  const errors = useSelector((state: RootState) => state.errors);

  const [verificationId, setVerificationId] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [recaptchaVerifier, setRecaptchaVerifier] = useState<any>();
  const [sendVerificationCode, setSendVerificationCode] = useState<boolean>(
    false
  );
  const [codeVerified, setCodeVerified] = useState<boolean>(false);

  useEffect(() => {
    const localRecaptcha = new firebase.auth.RecaptchaVerifier(
      "sign-in-recaptcha",
      {
        size: "invisible",
        callback: function (response) {
          console.log("It works!");
        },
        "expired-callback": function (response) {
          console.log("expired-callback");
        },
      }
    );
    setRecaptchaVerifier(localRecaptcha);
    setSendVerificationCode(true);

    localRecaptcha.render();
  }, []);

  useEffect(() => {
    if (recaptchaVerifier && sendVerificationCode) {
      // The user is a multi-factor user. Second factor challenge is required.
      const resolver = error.resolver;

      var phoneInfoOptions = {
        multiFactorHint: resolver.hints[0],
        session: resolver.session,
      };

      var phoneAuthProvider = new firebase.auth.PhoneAuthProvider();

      phoneAuthProvider
        .verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier)
        .then((verificationId) => {
          setVerificationId(verificationId);

          const input = document.getElementById("code-input");
          input?.focus();
        })
        .catch((error) => {
          switch (error.code) {
            case "auth/captcha-check-failed":
              // Thrown if the reCAPTCHA response token was invalid, expired, or if this method was called from a non-whitelisted domain.
              dispatch(
                setErrors({
                  message:
                    "There is an issue with reCAPTCHA. Refresh the page and please log in again.",
                })
              );
              break;
            case "auth/invalid-phone-number":
              // Thrown if the phone number has an invalid format.
              dispatch(
                setErrors({
                  message:
                    "Phone number format is invalid. Ensure that the entered phone number follows the requirements above.",
                })
              );
              break;
            case "auth/missing-phone-number":
              // Thrown if the phone number is missing.
              dispatch(
                setErrors({
                  message:
                    "Phone number is missing. There may be an issue with the code.",
                })
              );
              break;
            case "auth/quota-exceeded":
              // Thrown if the SMS quota for the Firebase project has been exceeded.
              dispatch(
                setErrors({
                  message:
                    "iData Online cannot send anymore SMSes at this time.",
                })
              );
              break;
            case "auth/user-disabled":
              // Thrown if the user corresponding to the given phone number has been disabled.
              dispatch(
                setErrors({
                  message:
                    "The user associated with this phone number has been disabled. Please use a different phone number or contact Support.",
                })
              );
              break;
            case "auth/maximum-second-factor-count-exceeded":
              // Thrown if The maximum allowed number of second factors on a user has been exceeded.
              dispatch(
                setErrors({
                  message:
                    "The user has exceeded the maximum allowed number of second factors.",
                })
              );
              break;
            case "auth/second-factor-already-in-use":
              // Thrown if the second factor is already enrolled on this account.
              dispatch(
                setErrors({
                  message:
                    "Multi-factor has already been enrolled on this account.",
                })
              );
              break;
            case "auth/unsupported-first-factor":
              // Thrown if the first factor being used to sign in is not supported.
              dispatch(
                setErrors({
                  message:
                    "First factor being used to sign in is not supported.",
                })
              );
              break;
            case "auth/unverified-email":
              // Thrown if the email of the account is not verified.
              dispatch(
                setErrors({
                  message: `Please verify your account via the link found in the email that has been sent to`,
                })
              );
              break;
            default:
              dispatch(
                setErrors({
                  message:
                    "Something went wrong. You may have tried too many times. Please try again later or contact Support.",
                })
              );
              break;
          }
        });

      setSendVerificationCode(false);
    }
  }, [dispatch, error.resolver, recaptchaVerifier, sendVerificationCode]);

  function resend() {
    dispatch(clearErrors());

    setSendVerificationCode(true);
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      // verificationId will be needed for sign-in completion.
      // Ask user for the SMS verification code.
      var cred = firebase.auth.PhoneAuthProvider.credential(
        verificationId,
        verificationCode
      );

      var multiFactorAssertion = firebase.auth.PhoneMultiFactorGenerator.assertion(
        cred
      );

      // Complete sign-in.
      const result = await error.resolver.resolveSignIn(multiFactorAssertion);

      if (result) setCodeVerified(true);
    } catch (error) {
      if (error instanceof FirebaseError) {
        switch (error.code) {
          case "auth/missing-verification-code":
            // Thrown if the verification code is missing.
            dispatch(
              setErrors({ message: "Please enter a verification code." })
            );
            break;
          case "auth/invalid-verification-code":
            // Thrown if the verification code is not valid.
            dispatch(
              setErrors({
                message: `The verification code is invalid. Re-enter the verification code you received or resend a new verification code, and try again.`,
              })
            );
            break;
          case "auth/missing-verification-id":
            // Thrown if the verification ID is missing.
            dispatch(
              setErrors({
                message: "Verification ID is missing. Please try again.",
              })
            );
            break;
          case "auth/invalid-verification-id":
            // Thrown if the credential is a firebase.auth.PhoneAuthProvider.credential and the verification ID of the credential is not valid.
            dispatch(
              setErrors({
                message: "Verification ID is invalid. Please try again.",
              })
            );
            break;
          case "auth/code-expired":
            // Thrown if the verification code has expired.
            dispatch(
              setErrors({
                message:
                  "Verification code has expired. Resent a new verification code and try again.",
              })
            );
            break;
          case "auth/invalid-multi-factor-session":
            // Thrown if the request does not contain a valid proof of first factor successful sign-in.
            dispatch(
              setErrors({
                message:
                  "Valid proof of first factor successful sign-in is missing.",
              })
            );
            break;
          case "auth/missing-multi-factor-session":
            // Thrown if The request is missing proof of first factor successful sign-in.
            dispatch(
              setErrors({
                message: "Proof of first factor successful sign-in is missing.",
              })
            );
            break;
          default:
            dispatch(
              setErrors({
                message: "Something went wrong.",
              })
            );
            break;
        }
      }
    }
  };

  return (
    <Wrapper>
      <Container>
        <h2>Enter verification code</h2>
        <form onSubmit={handleSubmit} hidden={codeVerified}>
          <div>
            <p>
              Please enter the verification code you received on your phone.
            </p>
            <input
              id="code-input"
              type="number"
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
            />
            <ButtonsDiv>
              <StandardButton type="button" onClick={resend}>
                Resend
              </StandardButton>
              <StandardButton id="code-button" color={"green"} type="submit">
                Submit
              </StandardButton>
            </ButtonsDiv>
          </div>

          <Error>{errors.message}</Error>
        </form>

        <div hidden={!codeVerified}>
          Confirmed verification code! Signing in...
        </div>

        <div id="sign-in-recaptcha"></div>
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  scroll-margin-top: 6rem;
  border: 1px solid ${(props) => props.theme.colors.darkGrey};
  padding: 5.5rem;
`;

const Container = styled.div`
  width: 45rem;

  h2 {
    margin-top: 0;
  }

  input {
    display: block;
    width: 15rem;
    height: 4rem;
    margin: 3rem auto;
    // margin: 2rem 0;
    padding: 1rem 2rem;
  }

  ul {
    list-style-type: disc;
    padding-left: 4rem;
  }

  small {
    display: block;
    margin-top: 2rem;
  }

  @media screen and (max-width: ${(props) => props.theme.bp.lg}px) {
    margin: 10rem auto;
  }
`;

const ButtonsDiv = styled.div`
  display: flex;

  button {
    margin: 0 1rem;
  }
`;

const Error = styled.p`
  color: ${(props) => props.theme.colors.red};
  font-size: 1.4rem;
  font-weight: 700rem;
`;

export default VerifyCode;
