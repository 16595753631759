import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import adminReducer from "./admin";
import reportReducer from "./report";
import customReportReducer from "./custom-report";
import searchReducer from "./search";
import errorReducer from "./errors";
import catalogReducer from "./catalog";
import medskuReducer from "./medsku";
import ptReducer from "./pt";
import surveysReducer from "./survey";
import notificationReducer from "./notifications";
import subscriptionsReducer from "./subscriptions";
import inactiveReducer from "./inactive";

const persistConfig = {
  key: "root",
  storage,
  whitelist: [
    "admin",
    "catalog",
    "subscriptions",
    "report",
    "customReport",
    "search",
  ],
};

const rootReducer = combineReducers({
  admin: adminReducer,
  report: reportReducer,
  customReport: customReportReducer,
  search: searchReducer,
  catalog: catalogReducer,
  medsku: medskuReducer,
  pt: ptReducer,
  survey: surveysReducer,
  errors: errorReducer,
  notifications: notificationReducer,
  subscriptions: subscriptionsReducer,
  inactive: inactiveReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default persistReducer(persistConfig, rootReducer);
