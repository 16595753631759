import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { getUsergroups } from "redux/actions/admin";
import { useAuth } from "contexts/AuthContext";

import {
  getUserDoc,
} from "../../../firebase";

import DataTables from "datatables.net";

import { RootState } from "redux/reducers";
import Spinner from "components/Common/misc/Spinner";
import StandardButton from "components/Common/buttons/StandardButton";
import { NotificationVariant } from "redux/reducers/notifications";
import { setNotification } from "redux/actions/notifications";

const UserReport: React.FC = () => {
  const dispatch = useDispatch();
  const { isSales } = useAuth();
  const usergroupsToDownloadRef = useRef<string[]>([]);

  const admin = useSelector((state: RootState) => state.admin);

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<string[][]>([]);

  useEffect(() => {
    dispatch(getUsergroups(setLoading));
  }, [dispatch]);

  function getDataPromises(usergroups) {
    const promises: Promise<string[]>[] = [];

    for (let index = 0; index < usergroups.length; index++) {
      const usergroup = usergroups[index];

      if (usergroup) {
        for (const user of usergroup.users) {
          const promise = new Promise<string[]>((resolve, reject) => {
            let row: string[] = [];

            const id = user._.S_.path.segments[6]

            getUserDoc(id)
              .then(doc => {
                if (doc) {
                  row.push(usergroup.company);
                  row.push(usergroup.license);
                  row.push(usergroup.expiry);
                  row.push(new Date(
                    doc.createdAt.seconds * 1000
                  ).toLocaleDateString("en-ca"));
                  row.push(doc.enabled == true ? "yes" : "no");
                  row.push(doc.name);
                  row.push(doc.email);
                  row.push(doc.role);

                  if (doc.lastActive) {
                    row.push(new Date(doc.lastActive).toLocaleDateString() +
                      " " +
                      new Date(doc.lastActive).toLocaleTimeString());
                  } else {
                    row.push("Has not logged in yet");
                  }

                  row.push(id);

                  resolve(row);
                } else {
                  throw new Error(`No document found for user ${id}`);
                }
              })
              .catch(error => {
                row.push("");
                row.push("");
                row.push("");
                row.push("");
                row.push("");
                row.push("");
                row.push("");
                row.push("");
                row.push("");

                resolve(row);
              });
          });
          promises.push(promise);
        }
      }
    }

    return promises;
  };

  function initializeTable() {
    new DataTables('#example', {
      destroy: true,
      initComplete: function() {
        {/* 
  // @ts-ignore */}
        this.api()
          .columns()
          .every(function(index) {
            if (index === 0 || index === 1 || index === 4 || index === 7 || index === 8) {
              {/* 
  // @ts-ignore */}
              let column = this;

              // Create select element
              let select = document.createElement('select');
              select.multiple = true;
              select.add(new Option(''));
              column.footer().replaceChildren(select);

              // Apply listener for user change in value
              select.addEventListener('change', function() {
                const options = select.selectedOptions;
                const values = Array.from(options).map(function(element, index) {
                  return DataTables.util.escapeRegex(element.value);
                });

                usergroupsToDownloadRef.current = values;

                const selectedValues = values.join('|');
                column
                  .search(selectedValues.length > 0 ? '^(' + selectedValues + ')$' : '', true, false)
                  .draw();
              });

              // Add list of options
              column
                .data()
                .unique()
                .sort()
                .each(function(d, j) {
                  function decodeHtml(html) {
                    var txt = document.createElement("textarea");
                    txt.innerHTML = html;
                    return txt.value;
                  }

                  select.add(new Option(decodeHtml(d), decodeHtml(d)));
                });
            }
          });
      }
    });
  }

  function downloadUserReport() {
    // get usergroup object from admin.usergroups based on usergroupsToDownloadRef.current
    let usergroups;

    if (usergroupsToDownloadRef.current.length === 1 && usergroupsToDownloadRef.current[0] === "") {
      usergroups = admin.usergroups;
      dispatch(
        setNotification({
          message: "Downloading report containing all users",
          variant: NotificationVariant.primary,
        })
      );
    } else if (usergroupsToDownloadRef.current.length > 0) {
      usergroups = usergroupsToDownloadRef.current.map((usergroupName) => {
        if (usergroupName !== "") {
          return admin.usergroups.find((usergroup) => usergroup.company === usergroupName);
        } else {
          return null;
        }
      });
      dispatch(
        setNotification({
          message: "Downloading report containing users from selected companies only",
          variant: NotificationVariant.primary,
        })
      );
    } else {
      usergroups = admin.usergroups;
      dispatch(
        setNotification({
          message: "Downloading report containing all users",
          variant: NotificationVariant.primary,
        })
      );
    }

    Promise.all(getDataPromises(usergroups))
      .then((values) => {
        const report: string[][] = [];

        let headers = [
          "Company Name",
          "License",
          "Expiry",
          "User Added On",
          "Enabled",
          "Name",
          "Email",
          "Role",
          "Last Active",
          isSales() ? "" : "ID"
        ];

        report.push(headers);

        report.push(...values);

        let csvContent = "data:text/csv;charset=utf-8,";

        if (report.length > 1) {
          report.forEach(function(rowArray) {
            rowArray.pop();
            let row = rowArray.join(",");
            csvContent += row + "\r\n";
          });

          var hiddenElement = document.createElement('a');
          hiddenElement.href = encodeURI(csvContent);
          hiddenElement.target = '_blank';
          hiddenElement.download = `New iData Online Users - ${new Date().toLocaleString()}.csv`;
          hiddenElement.click();
        }

      });
  }

  useEffect(() => {
    Promise.all(getDataPromises(admin.usergroups))
      .then((values) => {
        setData(values);

        initializeTable();
      });

  }, [loading]);

  if (!loading) {
    return (
      <Wrapper>
        <Header>
          <h2>User Report</h2>
          <div>
            <ExportButton onClick={downloadUserReport} type="button">Download user report</ExportButton>
          </div>
        </Header>

        <Table id="example">
          <thead>
            <tr>
              <th>Company Name</th>
              <th>License</th>
              <th>Expiry</th>
              <th>User Added On</th>
              <th>Enabled</th>
              <th>Name</th>
              <th>Email</th>
              <th>Role</th>
              <th>Last Active</th>
              <th hidden={isSales()}>ID</th>
            </tr>
          </thead>
          <tbody>
            {data.map(user => {
              return (
                <tr key={user[9]}>
                  <td>{user[0]}</td>
                  <td>{user[1]}</td>
                  <td>{user[2]}</td>
                  <td>{user[3]}</td>
                  <td>{user[4]}</td>
                  <td>{user[5]}</td>
                  <td><a href={`mailto:${user[6]}`}>{user[6]}</a></td>
                  <td>{user[7]}</td>
                  <td>{user[8]}</td>
                  <td hidden={isSales()}>{user[9]}</td>
                </tr>
              );
            })}
          </tbody>
          <tfoot>
            <tr>
              <th>Company Name</th>
              <th>License</th>
              <th>Expiry</th>
              <th>User Added On</th>
              <th>Enabled</th>
              <th>Name</th>
              <th>Email</th>
              <th>Role</th>
              <th>Last Active</th>
              <th hidden={isSales()}>ID</th>
            </tr>
          </tfoot>
        </Table>
        <p>
          <em>
            Hold down the Ctrl (Windows) or Command (Mac) button to select multiple options.
          </em>
        </p>
        <p>
          <em>
            Click the first blank option to reset the column's filter
          </em>
        </p>
      </Wrapper>
    );
  } else {
    return <Spinner />
  }

};

const Wrapper = styled.div`
  overflow: auto;

  // from datatables.net example
  .dataTables_wrapper .dataTables_paginate .paginate_button {
      box-sizing: border-box;
      display: inline-block;
      min-width: 1.5em;
      padding: 0.5em 1em;
      margin-left: 2px;
      text-align: center;
      text-decoration: none !important;
      cursor: pointer;
      *cursor: hand;
      color: #333 !important;
      border: 1px solid transparent;
      border-radius: 2px;
  }

  .dataTables_wrapper .dataTables_paginate .paginate_button.current, .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
      color: #333 !important;
      border: 1px solid #979797;
      background-color: white;
      background: linear-gradient(to bottom, #fff 0%, #dcdcdc 100%);
  }
  
  .dataTables_wrapper .dataTables_paginate .paginate_button:hover {
      color: white !important;
      border: 1px solid #111;
      background-color: #585858;
      background: linear-gradient(to bottom, #585858 0%, #111 100%);
  }

  .dataTables_wrapper .dataTables_paginate .paginate_button.disabled, .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover, .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:active {
      cursor: default;
      color: #666 !important;
      border: 1px solid transparent;
      background: transparent;
      box-shadow: none;
  }

  input {
    border: solid 1px grey;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  div {
    display: flex;
  }
`;

const ExportButton = styled(StandardButton)`
  width: auto;
  margin: 0.5rem;
`;

const Table = styled.table`
  table-layout: auto;
  border-collapse: collapse;
  margin-top: 1rem;
  width: 100%;

  tbody td {
    text-align: center;
  }

  button {
    margin: 0 auto;
  }

  th, td {
    padding: 1rem;  
    border: 1px solid black;
  }

  tr:nth-child(odd) td{
    background-color: ${(props) => props.theme.colors.grey};
  }
`;

export default UserReport;
