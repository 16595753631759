import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { createUsergroup } from "../../../../../firebase";
import { setNotification } from "redux/actions/notifications";
import { getUsergroups } from "redux/actions/admin";
import { setErrors, clearErrors } from "redux/actions/errors";
import { NotificationVariant } from "redux/reducers/notifications";
import { RootState } from "redux/reducers";

import StandardButton from "components/Common/buttons/StandardButton";

const NewUsergroupForm = () => {
  const dispatch = useDispatch();
  const errors = useSelector((state: RootState) => state.errors);
  const [company, setCompany] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const handleReset = () => {
    setCompany("");
    setDescription("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(clearErrors());

    const newUsergroup = {
      company,
      description,
      licenses: 0,
      license: "unassigned",
      expiry: new Date(),
      createdAt: new Date(),
      users: [],
    };

    setLoading(true);
    const result = await createUsergroup(newUsergroup);

    if (!result) {
      dispatch(
        setErrors({
          company: "Error creating company or company already exists",
        })
      );
      setLoading(false);
      return;
    }

    dispatch(
      setNotification({
        message: "Company successfully added",
        variant: NotificationVariant.primary,
      })
    );
    dispatch(getUsergroups());
    handleReset();
    setLoading(false);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <FormTitle>Add New Company</FormTitle>
      <div>
        <Label htmlFor="company">Company</Label>
        <br />
        <Input
          type="text"
          placeholder="Company"
          name="company"
          onChange={(e) => setCompany(e.target.value)}
          value={company}
          required
        />
        <Error>{errors.company}</Error>
      </div>
      <div>
        <Label htmlFor="desc">Description</Label>
        <br />
        <Input
          type="text"
          placeholder="Description"
          name="desc"
          onChange={(e) => setDescription(e.target.value)}
          value={description}
        />
      </div>
      <StandardButton type="submit" size="sm" disabled={loading}>
        Add Company
      </StandardButton>
    </Form>
  );
};

const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  min-width: 50rem;

  button {
    margin: 5rem auto;
  }
`;

const FormTitle = styled.h3`
  border-bottom: 2px solid ${(props) => props.theme.colors.green};
  max-width: 25rem;
  margin: 0 auto;
  padding-bottom: 1rem;
`;

const Input = styled.input`
  background-color: transparent;
  border-bottom: 2px solid ${(props) => props.theme.colors.idataDarkGrey};
  border-radius: 0;
  font-size: 1.8rem;
  padding: 0.5rem;
  height: 4rem;
  width: 80%;
`;

const Label = styled.label`
  color: ${(props) => props.theme.colors.idataDarkGrey};
  display: none;
`;

const Error = styled.p`
  color: ${(props) => props.theme.colors.red};
  font-size: 1.4rem;
  font-weight: 700rem;
`;

export default NewUsergroupForm;
