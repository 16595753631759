import { AnyAction } from "redux";

const initialInactiveState = {
  inactive: false,
};

const inactiveReducer = (state = initialInactiveState, action: AnyAction) => {
  switch (action.type) {
    case "SET_INACTIVE":
      return { inactive: action.payload };
    default:
      return state;
  }
};

export default inactiveReducer;
