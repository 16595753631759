import { AnyAction } from "redux";

export enum NotificationVariant {
  primary = "#cce5ff", // blue
  success = "#d4edda", // green
  danger = "#f8d7da", // red
  warning = "#fff3cd", // yellow
}

const initialNotificationState = {
  message: "",
  variant: NotificationVariant.primary,
};

const notificationReducer = (
  state = initialNotificationState,
  action: AnyAction
) => {
  switch (action.type) {
    case "SET_NOTIFICATION":
      return action.payload;
    case "RESET_NOTIFICATION":
      return initialNotificationState;
    default:
      return state;
  }
};

export default notificationReducer;
