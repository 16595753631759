export const accountMenu = [
  {
    name: "Account Information",
    key: "my-account",
    path: "/dashboard/account-info",
    access: ["standard", "team leader"],
  },
  {
    name: "My Orders",
    key: "my-orders",
    path: "/dashboard/my-orders",
    access: ["standard", "team leader"],
  },
  {
    name: "Custom Reports",
    key: "custom-reports",
    path: "/dashboard/custom-reports",
    access: ["standard", "team leader", "sales"],
  },
  {
    name: "Team Management",
    key: "team-management",
    path: "/dashboard/team-management",
    access: ["team leader"],
  },
  {
    name: "Custom Research Request",
    key: "custom-research-request",
    path: "/dashboard/custom-research-request",
    access: ["standard", "team leader"],
  },
];
