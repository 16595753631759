import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled, { css } from "styled-components";

import chevronLeft from "assets/icons/chevron_left-blue.svg";
import chevronRight from "assets/icons/chevron_right-blue.svg";
import { RootState } from "redux/reducers";
import { setSearchPosition } from "redux/actions/report";

type ButtonProps = { readonly disabled: boolean };

const ReportNavigator: React.FC = () => {
  const dispatch = useDispatch();
  const { report } = useSelector((state: RootState) => state);
  const [position, setPosition] = useState<number>(report.search["position"]);
  const headerHeight = document.getElementById("ReportHeader")?.offsetHeight;

  let distFromTop = 100;
  if (headerHeight) distFromTop = headerHeight + 150;

  useEffect(() => {
    setPosition(report.search["position"]);
  }, [report.search]);

  useEffect(() => {
    setPosition(1);
    dispatch(setSearchPosition(1));
  }, [report.currentChapter, dispatch]);

  useEffect(() => {
    window.scrollTo(
      0,
      report.search.scrollPositions[position - 1] - distFromTop
    );
  }, [distFromTop, position, report.search.scrollPositions]);

  const prevPosition = () => {
    setPosition(position - 1);
    window.scrollTo(
      0,
      report.search.scrollPositions[position - 1] - distFromTop
    );
    dispatch(setSearchPosition(position - 1));
  };

  const nextPosition = () => {
    setPosition(position + 1);
    window.scrollTo(
      0,
      report.search.scrollPositions[position + 1] - distFromTop
    );
    dispatch(setSearchPosition(position + 1));
  };

  return (
    <Container>
      <NavigationButton
        disabled={position === 1}
        onClick={() => prevPosition()}
      >
        <img src={chevronLeft} alt="left arrow icon" />
      </NavigationButton>
      <NavInfo>
        <p>
          {position} of {report.search.scrollPositions.length}
        </p>
      </NavInfo>
      <NavigationButton
        disabled={position + 1 > report.search.scrollPositions.length}
        onClick={() => nextPosition()}
      >
        <img src={chevronRight} alt="right arrow icon" />
      </NavigationButton>
    </Container>
  );
};

const Container = styled.div`
  background-color: ${(props) => props.theme.colors.lighterGrey};
  border: 1px solid ${(props) => props.theme.colors.idataBlue};
  border-radius: 10rem;
  color: ${(props) => props.theme.colors.idataBlue};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const NavInfo = styled.div`
  font-size: 2.2rem;
  text-align: center;

  p {
    margin: 0;
  }
`;

const disabledStyles = css`
  opacity: 0.4;
  pointer-events: none;
`;

const NavigationButton = styled.button<ButtonProps>`
  background: transparent;
  border: none;
  padding: 0;
  pointer-events: auto;

  &:hover {
    opacity: 0.8;
  }

  ${(props) => (props.disabled ? disabledStyles : null)}
`;

export default ReportNavigator;
