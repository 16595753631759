import axios from "axios";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { useAuth } from "contexts/AuthContext";

import { RootState } from "redux/reducers";
import { getUsergroups } from "redux/actions/admin";
import { fetchMedSKUCatalogData } from "redux/actions/medsku";
import { fetchPTCatalogData } from "redux/actions/pt";
import { fetchSurveyCatalogData } from "redux/actions/survey";

import { Usergroup } from "types/firebase";

import ClientList from "./clients/ClientList";
import UserList from "./UserList";
import ReportList from "./reports/ReportList";

import { generatePageTitle } from "utils/general.utils";

const ClientAccess: React.FC = () => {
  const dispatch = useDispatch();
  const { isSales } = useAuth();

  const { usergroups } = useSelector((state: RootState) => state.admin);

  const [currentUsergroup, setCurrentUsergroup] = useState<Usergroup>();
  const [synced, setSynced] = useState<boolean>(true);
  const [showUsers, setShowUsers] = useState<boolean>(false);

  useEffect(() => {
    document.title = generatePageTitle("Client Access");

    usergroups.sort((a: Usergroup, b: Usergroup) => a.company.localeCompare(b.company));
  }, []);

  useEffect(() => {
    dispatch(getUsergroups());
    dispatch(fetchMedSKUCatalogData());
    dispatch(fetchPTCatalogData());
    dispatch(fetchSurveyCatalogData());
  }, [dispatch]);

  const handleSelect = async (e) => {
    if (e.target.value) {
      const selectedUsergroup = usergroups.find((u: Usergroup) => u.id === e.target.value);
      setCurrentUsergroup(selectedUsergroup);

      if (selectedUsergroup.headquarter === true) {
        syncSubscriptions(selectedUsergroup);
      } else {
        setSynced(true);
      }
    } else {
      setCurrentUsergroup(undefined);
    }
  };

  const handleTab = (e) => {
    if (e.target.textContent === "Subscriptions") {
      setShowUsers(false);
    } else if (e.target.textContent === "Users") {
      setShowUsers(true);
    }

    const lists = document.getElementById("lists");
    lists?.scrollIntoView(true);
  }

  function syncSubscriptions(selectedUsergroup: Usergroup): void {
    setSynced(false);

    // Update Headquarter company subscriptions with subsidiary company subscriptions
    axios
      .post("/subscriptions/syncHQ", {
        headquarterId: selectedUsergroup.id,
        subsidiaryCompanies: selectedUsergroup.subsidiaryCompanies,
      })
      .then(() => {
        setSynced(true);
      });
  }

  return (
    <>
      <div>
        <PageTitle>Client Access</PageTitle>

        <Container>
          <div>
            <label htmlFor="company-select">Choose a company:</label>

            <CompanySelect id="company-select" name="companies" onChange={handleSelect} autoFocus>
              <option value={""}>Please choose an option</option>

              {usergroups
                .sort((a: Usergroup, b: Usergroup) => a.company.localeCompare(b.company))
                .map((u: Usergroup) => (
                  <option key={u.id} value={u.id}>
                    {u.company}
                  </option>
                ))}

            </CompanySelect>
          </div>
        </Container>

        <ClientList usergroup={currentUsergroup} />

        <div id="lists" hidden={!currentUsergroup}>
          <Tabs>
            <Tab $active={!showUsers} onClick={handleTab}>Subscriptions</Tab>
            <Tab $active={showUsers} onClick={handleTab}>Users</Tab>
          </Tabs>

          <div hidden={showUsers}>
            <ReportList usergroup={currentUsergroup} synced={synced} />
          </div>

          <div hidden={!showUsers}>
            <UserList usergroup={currentUsergroup} />
          </div>
        </div>

      </div>
    </>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

const PageTitle = styled.h2`
`;

const CompanySelect = styled.select`
  margin-left: 1rem;
`;

const Tabs = styled.div`
  display: flex;
  justify-content: space-between;

  div {
    padding: 2rem;
    flex: 1 1 100%;
  }
`;

const Tab = styled.button<{ $active: boolean }>`
  background: ${props => props.$active ? props.theme.colors.idataBlue : props.theme.colors.lighterGrey};
  color: ${props => props.$active ? "white" : "black"};

  width: 100%;
  border-radius: 0;
  height: 3rem;
  border: solid 1px black;

  &:hover {
    opacity: 0.5;
  }
`;

export default ClientAccess;
