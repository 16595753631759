import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { FirebaseError } from "@firebase/util";

import { useAuth } from "contexts/AuthContext";
import { RootState } from "redux/reducers/index";
import { setErrors, clearErrors } from "redux/actions/errors";

import { setNotification } from "redux/actions/notifications";
import { NotificationVariant } from "redux/reducers/notifications";

import StandardButton from "../../../../Common/buttons/StandardButton";

import { generatePageTitle } from "utils/general.utils";

const ChangePassword: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { updatePassword } = useAuth();
  const { errors } = useSelector((state: RootState) => state);
  const [newPassword, setNewPassword] = useState<string>("");
  const [confirmNewPassword, setConfirmNewPassword] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    document.title = generatePageTitle("Change Password");
    return () => {
      dispatch(clearErrors());
    };
  }, [dispatch]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    dispatch(clearErrors());

    if (newPassword !== confirmNewPassword) {
      return dispatch(
        setErrors({ confirmNewPassword: "Passwords do not match " })
      );
    }

    try {
      setLoading(true);
      await updatePassword(newPassword);
      navigate("/dashboard/account-info");
      dispatch(
        setNotification({
          message: "Password successfully changed!",
          variant: NotificationVariant.success,
        })
      );
    } catch (error) {
      if (error instanceof FirebaseError) {
        if (error.code === "auth/requires-recent-login") {
          dispatch(
            setErrors({
              message:
                "Password is valid, but changing your password requires recent authentication. Please log in again and try again.",
            })
          );
        } else if (error.code === "auth/weak-password") {
          dispatch(setErrors({ message: error.message }));
        }
      }
    }

    setLoading(false);
  };

  return (
    <EditForm onSubmit={handleSubmit}>
      <h2>
        <strong>Change Password</strong>
      </h2>
      <div>
        <Label htmlFor="newPassword">New Password</Label>
        <Input
          type="password"
          name="newPassword"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
        />
        <Error>{errors.newPassword}</Error>
      </div>
      <div>
        <Label htmlFor="confirmNewPassword">Confirm New Password</Label>
        <Input
          type="password"
          name="confirmNewPassword"
          value={confirmNewPassword}
          onChange={(e) => setConfirmNewPassword(e.target.value)}
        />
        <Error>{errors.confirmNewPassword}</Error>
      </div>
      <Error>{errors.message}</Error>
      <Buttons>
        <StandardButton type="submit" size="sm">
          Save
        </StandardButton>
        <Link to="/dashboard/account-info">
          <StandardButton
            type="button"
            size="sm"
            color="red"
            disabled={loading}
          >
            Cancel
          </StandardButton>
        </Link>
      </Buttons>
    </EditForm>
  );
};

const EditForm = styled.form`
  background-color: #fff;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.3);
  padding: 2rem;
  margin: 10rem auto;
  width: 100%;
`;

const Label = styled.label`
  text-align: right;
  width: 10rem;
`;

const Input = styled.input`
  border-bottom: 2px solid ${(props) => props.theme.colors.lighterGrey};
  border-radius: 0;
  font-size: 2rem;
  padding: 0.5rem;
  margin-bottom: 1rem;
  margin-left: 1rem;
  height: 4rem;
  width: 50rem;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 5rem;

  button {
    margin-right: 2rem;
  }
`;

const Error = styled.p`
  color: ${(props) => props.theme.colors.red};
  font-size: 1.4rem;
  font-weight: 700rem;
`;

export default ChangePassword;
