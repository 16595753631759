import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import firebase from "firebase/app";
import styled from "styled-components";
import { FirebaseError } from "@firebase/util";

import { setErrors, clearErrors } from "redux/actions/errors";
import { RootState } from "redux/reducers";
import { setNotification } from "redux/actions/notifications";
import { NotificationVariant } from "redux/reducers/notifications";

import StandardButton from "components/Common/buttons/StandardButton";

const EnrollMFA: React.FC<{ user: any }> = ({ user }) => {
  const dispatch = useDispatch();
  const errors = useSelector((state: RootState) => state.errors);

  const [recaptchaVerifier, setRecaptchaVerifier] = useState<any>();
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [verificationCode, setVerificationCode] = useState<string>("");
  const [verificationId, setVerificationId] = useState<string>("");
  const [phoneVerified, setPhoneVerified] = useState<boolean>(false);
  const [codeVerified, setCodeVerified] = useState<boolean>(false);

  useEffect(() => {
    if (!recaptchaVerifier) {
      const _recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
        "enroll-mfa-recaptcha",
        {
          size: "invisible",
          callback: function (response) {
            console.log("It works!");
          },
          "expired-callback": function (response) {
            console.log("expired-callback");
          },
        }
      );

      setRecaptchaVerifier(_recaptchaVerifier);
      _recaptchaVerifier.render();
    }
  }, [recaptchaVerifier]);

  async function verifyPhoneNumber() {
    dispatch(clearErrors());

    try {
      const multiFactorSession = await user.multiFactor.getSession();

      var phoneInfoOptions = {
        phoneNumber: phoneNumber,
        session: multiFactorSession,
      };

      if (phoneNumber !== "+16042666933") {
        var phoneAuthProvider = new firebase.auth.PhoneAuthProvider();

        const _verificationId = await phoneAuthProvider.verifyPhoneNumber(
          phoneInfoOptions,
          recaptchaVerifier
        );

        if (_verificationId) {
          setVerificationId(_verificationId);
          setPhoneVerified(true);

          const input = document.getElementById("code-input");
          input?.focus();
        }
      } else {
        dispatch(
          setErrors({ message: "Please use a different phone number." })
        );
      }
    } catch (error) {
      if (error instanceof FirebaseError) {
        switch (error.code) {
          case "auth/argument-error":
            dispatch(setErrors({ message: "Please enter a phone number." }));
            break;
          case "auth/user-token-expired":
            // Thrown if the token of the user to be updated is expired.
            dispatch(
              setErrors({
                message:
                  "Token has expired. Refresh the page and please log in again.",
              })
            );
            break;
          case "auth/captcha-check-failed":
            // Thrown if the reCAPTCHA response token was invalid, expired, or if this method was called from a non-whitelisted domain.
            dispatch(
              setErrors({
                message:
                  "There is an issue with reCAPTCHA. Refresh the page and please log in again.",
              })
            );
            break;
          case "auth/invalid-phone-number":
            // Thrown if the phone number has an invalid format.
            dispatch(
              setErrors({
                message:
                  "Phone number format is invalid. Ensure that the entered phone number follows the requirements above.",
              })
            );
            break;
          case "auth/missing-phone-number":
            // Thrown if the phone number is missing.
            dispatch(
              setErrors({
                message:
                  "Phone number is missing. There may be an issue with the code.",
              })
            );
            break;
          case "auth/quota-exceeded":
            // Thrown if the SMS quota for the Firebase project has been exceeded.
            dispatch(
              setErrors({
                message: "iData Online cannot send anymore SMSes at this time.",
              })
            );
            break;
          case "auth/user-disabled":
            // Thrown if the user corresponding to the given phone number has been disabled.
            dispatch(
              setErrors({
                message:
                  "The user associated with this phone number has been disabled. Please use a different phone number or contact Support.",
              })
            );
            break;
          case "auth/maximum-second-factor-count-exceeded":
            // Thrown if The maximum allowed number of second factors on a user has been exceeded.
            dispatch(
              setErrors({
                message:
                  "The user has exceeded the maximum allowed number of second factors.",
              })
            );
            break;
          case "auth/second-factor-already-in-use":
            // Thrown if the second factor is already enrolled on this account.
            dispatch(
              setErrors({
                message:
                  "Multi-factor has already been enrolled on this account.",
              })
            );
            break;
          case "auth/unsupported-first-factor":
            // Thrown if the first factor being used to sign in is not supported.
            dispatch(
              setErrors({
                message: "First factor being used to sign in is not supported.",
              })
            );
            break;
          case "auth/unverified-email":
            // Thrown if the email of the account is not verified.
            dispatch(
              setErrors({
                message: `Please verify your account via the link found in the email that has been sent to ${user.email}.`,
              })
            );
            break;
          case "auth/requires-recent-login":
            dispatch(setErrors({ message: error.message }));

            dispatch(
              setNotification({
                message: `Redirecting to Sign In...`,
                variant: NotificationVariant.danger,
              })
            );

            setTimeout(() => {
              window.location.reload();
            }, 1500);
            break;
          default:
            dispatch(
              setErrors({
                message: "Something went wrong.",
              })
            );
            break;
        }
      }
    }
  }

  async function verifyCode() {
    dispatch(clearErrors());

    try {
      // Ask user for the verification code.
      var cred = firebase.auth.PhoneAuthProvider.credential(
        verificationId,
        verificationCode
      );

      var multiFactorAssertion = firebase.auth.PhoneMultiFactorGenerator.assertion(
        cred
      );

      // Complete enrollment.
      await user.multiFactor
        .enroll(multiFactorAssertion, "My personal phone number")
        .then(() => {
          setCodeVerified(true);
          setTimeout(() => {
            window.location.reload();
          }, 5000);
        });
    } catch (error) {
      if (error instanceof FirebaseError) {
        switch (error.code) {
          case "auth/missing-verification-code":
            // Thrown if the verification code is missing.
            dispatch(
              setErrors({ message: "Please enter a verification code." })
            );
            break;
          case "auth/invalid-verification-code":
            // Thrown if the verification code is not valid.
            dispatch(
              setErrors({
                message: `The verification code is invalid. Re-enter the verification code you received or resend a new verification code, and try again.`,
              })
            );
            break;
          case "auth/missing-verification-id":
            // Thrown if the verification ID is missing.
            dispatch(
              setErrors({
                message: "Verification ID is missing. Please try again.",
              })
            );
            break;
          case "auth/invalid-verification-id":
            // Thrown if the credential is a firebase.auth.PhoneAuthProvider.credential and the verification ID of the credential is not valid.
            dispatch(
              setErrors({
                message: "Verification ID is invalid. Please try again.",
              })
            );
            break;
          case "auth/code-expired":
            // Thrown if the verification code has expired.
            dispatch(
              setErrors({
                message:
                  "Verification code has expired. Resent a new verification code and try again.",
              })
            );
            break;
          case "auth/maximum-second-factor-count-exceeded":
            // Thrown if The maximum allowed number of second factors on a user has been exceeded.
            dispatch(
              setErrors({
                message:
                  "The user has exceeded the maximum allowed number of second factors.",
              })
            );
            break;
          case "auth/second-factor-already-in-use":
            // Thrown if the second factor is already enrolled on this account.
            dispatch(
              setErrors({
                message:
                  "Multi-factor has already been enrolled on this account.",
              })
            );
            break;
          case "auth/unsupported-first-factor":
            // Thrown if the first factor being used to sign in is not supported.
            dispatch(
              setErrors({
                message: "First factor being used to sign in is not supported.",
              })
            );
            break;
          case "auth/unverified-email":
            // Thrown if the email of the account is not verified.
            dispatch(
              setErrors({
                message: `Please verify your account via the link found in the email that has been sent to ${user.email}.`,
              })
            );
            break;
          case "auth/requires-recent-login":
            // Thrown if the user's last sign-in time does not meet the security threshold. Use firebase.User.reauthenticateWithCredential to resolve.
            dispatch(setErrors({ message: error.message }));

            dispatch(
              setNotification({
                message: `Redirecting to Sign In...`,
                variant: NotificationVariant.danger,
              })
            );

            setTimeout(() => {
              window.location.reload();
            }, 5000);
            break;
          default:
            dispatch(
              setErrors({
                message: "Something went wrong.",
              })
            );
            break;
        }
      }
    }
  }

  function submitPhone(e: React.FormEvent) {
    e.preventDefault();

    verifyPhoneNumber();
  }

  function submitCode(e: React.FormEvent) {
    e.preventDefault();

    verifyCode();
  }

  function resend() {
    setPhoneVerified(false);
  }

  return (
    <Wrapper>
      <Container>
        <h2>Enroll in required multi-factor authentication</h2>
        <form onSubmit={submitPhone} hidden={phoneVerified}>
          <p>Please enter a phone number that can receive SMS.*</p>
          <p>Phone number must follow international format, so it must:</p>
          <ul>
            <li>start with + sign and country code</li>
            <li>not contain any hyphens or parentheses</li>
          </ul>
          <p>Example: +16042666933</p>
          <input
            autoFocus
            type="text"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
          />
          <StandardButton color={"green"} type="submit">
            Next
          </StandardButton>

          <Error>{errors.message}</Error>

          <small>*Standard text message rates apply.</small>
        </form>

        <form onSubmit={submitCode} hidden={!phoneVerified}>
          <div hidden={codeVerified}>
            <p>
              Please enter the 6 digit verification code you have received on
              your phone.
            </p>
            <input
              id="code-input"
              type="text"
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
            />
            <ButtonsDiv>
              <StandardButton type="button" onClick={resend}>
                Resend
              </StandardButton>
              <StandardButton color={"green"} type="submit">
                Next
              </StandardButton>
            </ButtonsDiv>

            <Error>{errors.message}</Error>
          </div>

          <div hidden={!codeVerified}>
            Multi-factor authentication setup complete! Signing in...
          </div>
        </form>

        <div id="enroll-mfa-recaptcha"></div>
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  scroll-margin-top: 6rem;
  border: 1px solid ${(props) => props.theme.colors.darkGrey};
  padding: 5.5rem;
`;

const Container = styled.div`
  width: 45rem;

  h2 {
    margin-top: 0;
  }

  input {
    display: block;
    width: 15rem;
    height: 4rem;
    margin: 3rem auto;
    // margin: 2rem 0;
    padding: 1rem 2rem;
  }

  ul {
    list-style-type: disc;
    padding-left: 4rem;
  }

  small {
    display: block;
    margin-top: 2rem;
  }

  @media screen and (max-width: ${(props) => props.theme.bp.lg}px) {
    margin: 10rem auto;
  }
`;

const ButtonsDiv = styled.div`
  display: flex;

  button {
    margin: 0 1rem;
  }
`;

const Error = styled.p`
  color: ${(props) => props.theme.colors.red};
  font-size: 1.4rem;
  font-weight: 700rem;
`;

export default EnrollMFA;
