import React, { useEffect } from "react";
import axios from "axios";
import styled from "styled-components";

import { generatePageTitle } from "utils/general.utils";

function resizeIframe() {
  const element = document.getElementById("adminContainer");

  if (element) {
    let computedStyle = getComputedStyle(element);

    let elementHeight = element.clientHeight;  // height with padding
    let elementWidth = element.clientWidth;   // width with padding

    elementHeight -= parseFloat(computedStyle.paddingTop) + parseFloat(computedStyle.paddingBottom);
    elementWidth -= parseFloat(computedStyle.paddingLeft) + parseFloat(computedStyle.paddingRight);

    // Update iframe width and height based on the window size
    document
      .getElementById("Survey")
      ?.setAttribute("width", `${elementWidth}px`);

    document
      .getElementById("Survey")
      ?.setAttribute("height", `${elementHeight}px`);
  }
}

window.addEventListener("resize", resizeIframe);

const ImportSurveyDashboard: React.FC = () => {
  const idToken = axios.defaults.headers.common["Authorization"].split(
    "Bearer "
  )[1];

  useEffect(() => {
    document.title = generatePageTitle("Import Survey Dashboard");

    resizeIframe();
  });

  if (process.env.NODE_ENV === "production") {
    return (
      <Iframe
        id="Survey"
        title="Survey"
        src={`/survey/importData?idToken=${idToken}`}
      ></Iframe>
    );
  } else {
    return (
      <Iframe
        id="Survey"
        title="Survey"
        src={`http://localhost:5010/survey/importData?idToken=${idToken}`}
      ></Iframe>
    );
  }
};

const Iframe = styled.iframe`
  height: 95vh;
`;

export default ImportSurveyDashboard;
