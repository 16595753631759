import axios from "axios";
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { useAuth } from "contexts/AuthContext";
import closeIcon from "assets/icons/close-black.svg";
import { clearErrors } from "redux/actions/errors";
import { updateCustomReportMultiple } from "redux/actions/custom-report";

import { CustomChecklistItem } from "types/custom";
import { RootState } from "redux/reducers";
import { combineCustomChapters } from "utils/custom.utils";

import StandardButton from "../../../../Common/buttons/StandardButton";
import FormInput from "../../../../Common/forms/FormInput";
import EditModalChapters from "./EditModalChapters";
import Tip from "components/Common/misc/Tip";

import { BOOKMARK as bookmarkTip } from "data/tips";

Modal.setAppElement("#root");

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "2rem",
  },
  overlay: {
    zIndex: 1000,
  },
};

type ModalProps = {
  toggleEditModal: boolean;
  setToggleEditModal: (toggleEditModal: boolean) => void;
};

const EditModal: React.FC<ModalProps> = ({
  toggleEditModal,
  setToggleEditModal,
}) => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { customReport, errors } = useSelector((state: RootState) => state);

  const { collections, chapterNumbers } = combineCustomChapters(
    customReport.chapterData
  );

  useEffect(() => {
    const getReportNameYear = async (reportId: number) => {
      return axios
        .get(`/reports/report-info/${reportId}`)
        .then((response) => response.data);
    };

    const setReportNameYear = async () => {
      for (const chapter of collections[0].chapters) {
        const { report_name, report_year } = await getReportNameYear(
          chapter.report_id
        );
        chapter.report_name = report_name;
        chapter.report_year = report_year;
      }
    };

    setReportNameYear();
  }, [collections, customReport]);

  const [title, setTitle] = useState<string>(
    customReport.reportData.c_report_name
  );
  const [checklist, setChecklist] = useState<CustomChecklistItem[]>(
    chapterNumbers
  );

  const handleClose = () => {
    setToggleEditModal(!toggleEditModal);
    dispatch(clearErrors());
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const { c_report_id } = customReport.reportData;

    const update = {
      c_report_id,
      c_report_name: title,
      checklist,
    };

    const pathname = location.pathname;

    dispatch(
      updateCustomReportMultiple(
        update,
        handleClose,
        currentUser.usergroupData.id,
        pathname,
        navigate
      )
    );
  };

  return (
    <Modal
      isOpen={toggleEditModal}
      onRequestClose={handleClose}
      shouldCloseOnOverlayClick={toggleEditModal}
      style={customStyles}
      contentLabel="Custom Bookmarks"
    >
      <StyledCheckboxContainer>
        <StyledCheckboxHeader>
          <div>
            <h2>Edit Custom Report</h2>
            <SummaryText>Remove or change order of chapters</SummaryText>
          </div>
          <Tip text={bookmarkTip} bottom={-75} right={-110} width={260} />
          <Control onClick={handleClose}>
            <img src={closeIcon} alt={"close modal icon"} />
          </Control>
        </StyledCheckboxHeader>
        <StyledCheckboxForm onSubmit={handleSubmit}>
          <FormInput
            placeholder="Enter a title"
            name="customReportName"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            error={errors.customReportName}
          />
          <BookmarkList>
            {collections.map((collection, i) => (
              <div key={i}>
                <EditModalChapters
                  chapters={collection.chapters}
                  checklist={checklist}
                  setChecklist={setChecklist}
                />
              </div>
            ))}
          </BookmarkList>
          <Error>{errors.chapterNumbers}</Error>
          <CustomButton type="submit" size="md">
            Save
          </CustomButton>
        </StyledCheckboxForm>
      </StyledCheckboxContainer>
    </Modal>
  );
};

const StyledCheckboxContainer = styled.div`
  color: ${(props) => props.theme.colors.darkerGrey};
  padding: 0 2rem;
  width: 40em;
  max-height: 40em;
`;

const BookmarkList = styled.div`
  height: 43vh;
  overflow: auto;
`;

const StyledCheckboxHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 2rem;
`;

const StyledCheckboxForm = styled.form`
  /* padding: 2rem 0; */
`;

const CustomButton = styled(StandardButton)`
  /*   margin: 5rem auto 2rem auto; */
  margin: 0 auto;
  margin-top: 2rem;
`;

const Error = styled.p`
  color: ${(props) => props.theme.colors.red};
  font-size: 1.4rem;
  font-weight: 700rem;
`;

const Control = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    opacity: 0.5;
  }
`;

const SummaryText = styled.p`
  color: ${(props) => props.theme.colors.lightGrey};
  font-size: 1.6rem;
  font-style: italic;
`;

export default EditModal;
