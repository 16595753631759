import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";

import addIcon from "assets/icons/add-black-sm.svg";
import arrowRightIcon from "assets/icons/arrow_right-solid.png";
import closeIcon from "assets/icons/close-black.svg";
import { CustomReport } from "types/custom";
import { generateReportUrl } from "utils/report.utils";
import { removeCustomChapter } from "redux/actions/custom-report";

type BookmarkProps = {
  item: CustomReport;
  currentUser: any;
  setSelectedCustomReport: (item: CustomReport) => void;
  setToggleModal: (toggleModal: boolean) => void;
};

type ToggleProps = { readonly toggle: boolean };

const CustomBookmark: React.FC<BookmarkProps> = ({
  item,
  currentUser,
  setSelectedCustomReport,
  setToggleModal,
}) => {
  const dispatch = useDispatch();
  const url = generateReportUrl(item.c_report_name);
  const [toggle, setToggle] = useState<boolean>(false);

  const handleDelete = (col) => {
    // delete just the selected chapter
    dispatch(
      removeCustomChapter(
        item.c_report_id,
        col.report_id,
        col.chapter_number,
        currentUser.usergroupData.id
      )
    );
  };

  return (
    <div>
      <CustomBookmarkMenuItem>
        <ToggleContainer toggle={toggle} onClick={() => setToggle(!toggle)}>
          <img src={arrowRightIcon} alt="right icon" />
        </ToggleContainer>
        <CustomControls toggle={toggle}>
          <Link
            to={`/custom-reports/${item.c_report_id}/${url}/1`}
            target="_blank"
          >
            {item.c_report_name}
          </Link>
          {currentUser.email === item.user_email && (
            <Controls>
              <Control
                onClick={() => {
                  setSelectedCustomReport(item);
                  setToggleModal(true);
                }}
              >
                <img src={addIcon} alt="add icon" />
                Add
              </Control>
            </Controls>
          )}
        </CustomControls>
      </CustomBookmarkMenuItem>
      <ChapterList toggle={toggle}>
        {item.collections.length > 0 ? (
          item.collections.map((col) => (
            <ChapterListItem key={col.collection_id} toggle={toggle}>
              <span>{col.chapter_title}</span>
              {currentUser.email === item.user_email && (
                <DeleteIcon onClick={() => handleDelete(col)}>
                  <img src={closeIcon} alt="delete" />
                </DeleteIcon>
              )}
            </ChapterListItem>
          ))
        ) : (
          <ChapterListItem toggle={toggle}>
            <em>No chapters added</em>
          </ChapterListItem>
        )}
      </ChapterList>
    </div>
  );
};

const CustomBookmarkMenuItem = styled.li`
  font-size: 2.2rem;
  display: flex;
  align-items: center;
  max-height: 6rem;
  margin-bottom: 0.5rem;

  &:hover span {
    color: ${(props) => props.theme.colors.idataDarkBlue};
  }
`;

const CustomControls = styled.div<ToggleProps>`
  border-bottom: ${(props) =>
    props.toggle ? `1px solid ${props.theme.colors.idataDarkGrey}` : "none"};
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const Controls = styled.div`
  display: flex;
  align-items: center;
`;

const ToggleContainer = styled.div<ToggleProps>`
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: all 0.2s;
  transform: ${(props) => (props.toggle ? "rotate(90deg)" : "rotate(0deg)")};
  margin-right: 1.3rem;
  padding-left: 7px;
`;

const Control = styled.button`
  background: none;
  border: none;
  color: ${(props) => props.theme.colors.idataDarkGrey};
  cursor: pointer;
  display: flex;
  align-items: flex-end;
  font-size: 1.6rem;
  padding: 0;
  transition: all 0.2s;

  &:hover {
    opacity: 0.5;
  }

  &:not(:last-child) {
    margin-right: 0.5rem;
  }
`;

const ChapterList = styled.ul<ToggleProps>`
  padding-left: 3rem;
  margin-top: 1.5rem;
  margin-bottom: ${(props) => (props.toggle ? "2rem" : "0")};
`;

const ChapterListItem = styled.li<ToggleProps>`
  display: ${(props) => (props.toggle ? "flex" : "none")};
  align-items: center;
  justify-content: space-between;
`;

const DeleteIcon = styled.span`
  margin-left: 1rem;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    opacity: 0.8;
  }
`;

export default CustomBookmark;
