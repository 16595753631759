import React from "react";
import styled from "styled-components";

import searchIcon from "assets/icons/Search-Icon-White.svg";

type InputProps = {
  icon: string;
};

const MobileSearchBar: React.FC = () => (
  <Input type="text" placeholder="Search All Reports" icon={searchIcon} />
);

const Input = styled.input<InputProps>`
  align-self: center;
  background-color: ${(props) => props.theme.colors.lightBlue};
  background-image: url(${(props) => props.icon});
  background-position: right 2rem top 50%;
  color: #fff;
  display: none;
  font-size: 2rem;
  padding: 2rem;
  margin: 2rem auto;
  height: 4rem;
  // max-width: 45rem;
  width: 100%;
  &::placeholder {
    color: #fff;
  }
  @media screen and (max-width: 1290px) {
    display: block;
  }
`;

export default MobileSearchBar;
