import { Dispatch } from "redux";
import { Notification } from "types";

export const SET_NOTIFICATION = "SET_NOTIFICATION";
export const RESET_NOTIFICATION = "RESET_NOTIFICATION";

export const setNotification: any = (
  notification: Notification,
  timeout: boolean = true
) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_NOTIFICATION,
    payload: notification,
  });
  if (timeout) setTimeout(() => dispatch(resetNotification()), 10000);
};

export const resetNotification: any = () => (dispatch: Dispatch) => {
  dispatch({
    type: RESET_NOTIFICATION,
  });
};
