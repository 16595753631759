export const formatExpiryDate = (seconds: number) => {
  const expiry = new Date(seconds * 1000);

  let day = "" + expiry.getDate();
  let month = "" + (expiry.getMonth() + 1);
  let year = expiry.getFullYear();

  if (day.length < 2) day = "0" + day;
  if (month.length < 2) month = "0" + month;

  return [year, month, day].join("-");
};

export const daysLeft = (expiry: string) => {
  const date1 = Number(new Date(expiry));
  const date2 = Number(new Date());
  const diffTime = Math.abs(date1 - date2);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  if (date2 > date1) {
    return -diffDays;
  }

  return diffDays;
};
