import React, { ReactNode } from "react";
import { ThemeProvider } from "styled-components";

type ThemeProps = {
  children: ReactNode;
};

export const Theme: React.FC<ThemeProps> = ({ children }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);

const theme = {
  colors: {
    lightBlue: "#b7bdc7",
    idataBlue: "#1D83B7",
    idataDarkBlue: "#376187",
    coolBlue: "#17a1e6",
    yellow: "#EFB957",
    green: "#5DC097",
    red: "#D05F41",
    lightRed: "#DE6645",
    orange: "#fe811b",
    lighterGrey: "#f6f8f9",
    grey: "#e6e7e8",
    darkGrey: "#acacac",
    idataDarkGrey: "#4d4d4f",
    tableHeading: "#1f84b8",
    tableSource: "#91d4e7",
    tableSubheading: "#538ed5",
  },
  fonts: {
    roboto: "Roboto Condensed sans-serif",
  },
  fontSizes: {
    xxs: "0.75rem",
    xs: "1rem",
    small: "1.5rem",
    medium: "2rem",
    large: "3rem",
  },
  bp: {
    xxs: 370,
    xs: 500,
    sm: 700,
    md: 900,
    lg: 1100,
    xl: 1400,
    ronslaptop: 1440,
    xxl: 1720,
  },
  shadow: {
    normal: "0 0.5em 0.5em rgba(0, 0, 0, 0.3)",
    less: "0 0.25em 0.25rem rgba(0, 0, 0, 0.3)",
  },
  transform: {
    transition: "transition 250ms ease-in-out",
  },
};
