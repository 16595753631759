import { AnyAction } from "redux";

const initialAdminState = {
  usergroups: [],
  authRequests: [],
  recommending: false,
  report_id_rec_for: -1,
};

const adminReducer = (state = initialAdminState, action: AnyAction) => {
  switch (action.type) {
    case "GET_USERGROUPS":
      return {
        ...state,
        usergroups: action.payload,
      };
    case "GET_AUTH_REQUESTS":
      return {
        ...state,
        authRequests: action.payload,
      };
    case "TOGGLE_RECOMMENDING":
      return {
        ...state,
        recommending: !state.recommending,
      };
    case "UPDATE_REPORT_ID_REC_FOR":
      return {
        ...state,
        report_id_rec_for: action.payload,
      };
    default:
      return state;
  }
};

export default adminReducer;
