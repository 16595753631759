export const countries = [
  { id: 0, value: "", text: "--- Select a country ---" },
  { id: 1, value: "Afghanistan" },
  { id: 2, value: "Algeria" },
  { id: 3, value: "Argentina" },
  { id: 4, value: "Armenia" },
  { id: 5, value: "Aruba" },
  { id: 6, value: "Australia" },
  { id: 7, value: "Austria" },
  { id: 8, value: "Azerbaijan" },
  { id: 9, value: "Bahamas" },
  { id: 10, value: "Bangladesh" },
  { id: 11, value: "Barbados" },
  { id: 12, value: "Belgium" },
  { id: 13, value: "Brazil" },
  { id: 14, value: "Bulgaria" },
  { id: 15, value: "Cambodia" },
  { id: 16, value: "Cameroon" },
  { id: 17, value: "Canada" },
  { id: 18, value: "Cape Verde" },
  { id: 19, value: "Chile" },
  { id: 20, value: "China" },
  { id: 21, value: "Colombia" },
  { id: 22, value: "Comoros" },
  { id: 23, value: "Costa Rica" },
  { id: 24, value: "Croatia" },
  { id: 25, value: "Cuba" },
  { id: 26, value: "Czech Republic" },
  { id: 27, value: "Denmark" },
  { id: 28, value: "Dominica" },
  { id: 29, value: "Ecuador" },
  { id: 30, value: "Egypt" },
  { id: 31, value: "El Salvador" },
  { id: 32, value: "Estonia" },
  { id: 33, value: "Fiji" },
  { id: 34, value: "Finland" },
  { id: 35, value: "France" },
  { id: 36, value: "French Guiana" },
  { id: 37, value: "French Polynesia" },
  { id: 38, value: "Gambia" },
  { id: 39, value: "Georgia" },
  { id: 40, value: "Germany" },
  { id: 41, value: "Ghana" },
  { id: 42, value: "Greece" },
  { id: 43, value: "Greenland" },
  { id: 44, value: "Grenada" },
  { id: 45, value: "Guadeloupe" },
  { id: 46, value: "Guatemala" },
  { id: 47, value: "Guinea" },
  { id: 48, value: "Guinea-Bissau" },
  { id: 49, value: "Guyana" },
  { id: 50, value: "Haiti" },
  { id: 51, value: "Honduras" },
  { id: 52, value: "Hungary" },
  { id: 53, value: "Iceland" },
  { id: 54, value: "India" },
  { id: 55, value: "Indonesia" },
  { id: 56, value: "Iraq" },
  { id: 57, value: "Iran" },
  { id: 58, value: "Ireland" },
  { id: 59, value: "Islands" },
  { id: 60, value: "Israel" },
  { id: 61, value: "Italy" },
  { id: 62, value: "Jamaica" },
  { id: 63, value: "Japan" },
  { id: 64, value: "Jordan" },
  { id: 65, value: "Kazakhstan" },
  { id: 66, value: "Kyrgyzstan" },
  { id: 67, value: "Lebanon" },
  { id: 68, value: "Liberia" },
  { id: 69, value: "Lithuania" },
  { id: 70, value: "Luxembourg" },
  { id: 71, value: "Malaysia" },
  { id: 72, value: "Malta" },
  { id: 73, value: "Mauritania" },
  { id: 74, value: "Mexico" },
  { id: 75, value: "Monaco" },
  { id: 76, value: "Mongolia" },
  { id: 77, value: "Montenegro" },
  { id: 78, value: "Montserrat" },
  { id: 79, value: "Myanmar" },
  { id: 80, value: "Netherlands" },
  { id: 81, value: "New Zealand" },
  { id: 82, value: "Nicaragua" },
  { id: 83, value: "Niger" },
  { id: 84, value: "Nigeria" },
  { id: 85, value: "Norway" },
  { id: 86, value: "Oman" },
  { id: 87, value: "Pakistan" },
  { id: 88, value: "Palestine" },
  { id: 89, value: "Panama" },
  { id: 90, value: "Paraguay" },
  { id: 91, value: "Peru" },
  { id: 92, value: "Philippines" },
  { id: 93, value: "Poland" },
  { id: 94, value: "Portugal" },
  { id: 95, value: "Qatar" },
  { id: 96, value: "Romania" },
  { id: 97, value: "Russian Federation" },
  { id: 98, value: "Saudia Arabia" },
  { id: 99, value: "Senegal" },
  { id: 100, value: "Serbia" },
  { id: 101, value: "Singapore" },
  { id: 102, value: "Slovakia" },
  { id: 103, value: "Slovenia" },
  { id: 104, value: "South Africa" },
  { id: 105, value: "South Korea" },
  { id: 106, value: "South Sudan" },
  { id: 107, value: "Spain" },
  { id: 108, value: "Suriname" },
  { id: 109, value: "Swaziland" },
  { id: 110, value: "Sweden" },
  { id: 111, value: "Switzerland" },
  { id: 112, value: "Syria" },
  { id: 113, value: "Taiwan" },
  { id: 114, value: "Tajikistan" },
  { id: 115, value: "Thailand" },
  { id: 116, value: "Turkey" },
  { id: 117, value: "Turkmenistan" },
  { id: 118, value: "Ukraine" },
  { id: 119, value: "United Arab Emirates" },
  { id: 120, value: "United Kingdom" },
  { id: 121, value: "United States" },
  { id: 122, value: "Uruguay" },
  { id: 123, value: "Uzbekistan" },
  { id: 124, value: "Vietnam" },
  { id: 125, value: "Yemen" },
  { id: 126, value: "Zimbabwe" },
];

export const categories = [
  { id: 0, value: "", text: "--- Select a category ---" },
  { id: 1, value: "Anesthesiology" },
  { id: 2, value: "Anesthesiology - Respiratory" },
  { id: 3, value: "Anesthesiology - Sleep Therapy" },
  { id: 4, value: "Audiology/Hearing Devices" },
  { id: 5, value: "Cardiology" },
  { id: 6, value: "Cardiology - Cardiac Rhythm Management" },
  { id: 7, value: "Cardiology - Cardiac Surgery" },
  { id: 8, value: "Cardiology - Infusion Therapy" },
  { id: 9, value: "Cardiology - Interventional Cardiology" },
  { id: 10, value: "Cardiology - Peripheral Vascular" },
  { id: 11, value: "Cardiology - Vascular Access" },
  { id: 12, value: "Consulting/Investment" },
  { id: 13, value: "Cosmetics" },
  { id: 14, value: "Cosmetics - Cosmetic Surgery" },
  { id: 15, value: "Cosmetics - Injectables" },
  { id: 16, value: "Cosmetics - Lasers" },
  { id: 17, value: "Dental" },
  { id: 18, value: "Dental Biomaterials" },
  { id: 19, value: "Dental Hygiene" },
  { id: 20, value: "Dental Imaging" },
  { id: 21, value: "Dental Implants" },
  { id: 22, value: "Dental Imaging" },
  { id: 23, value: "Dental Lasers" },
  { id: 24, value: "Dental Materials" },
  { id: 25, value: "Dental Operatory Equipment" },
  { id: 26, value: "Dental Orthodontics" },
  { id: 27, value: "Dental Overdentures" },
  { id: 28, value: "Dental Prosthetics & CAD/CAM" },
  { id: 29, value: "Diabetes" },
  { id: 30, value: "Diagnostics" },
  { id: 31, value: "Diagnostics - Imaging" },
  { id: 32, value: "Diagnostics - In-Vitro" },
  { id: 33, value: "Dialysis" },
  { id: 34, value: "Emergency Room" },
  { id: 35, value: "Endoscopy" },
  { id: 36, value: "Endoscopy - ENT" },
  { id: 37, value: "Endoscopy - Gastro-Intestinal" },
  { id: 38, value: "Endoscopy - Laparoscopy" },
  { id: 39, value: "Endoscopy - Soft Tissue Repair" },
  { id: 40, value: "Equity Firm" },
  { id: 41, value: "Neurology" },
  { id: 42, value: "Oncology" },
  { id: 43, value: "Ophthalmics" },
  { id: 44, value: "Orthopedics" },
  { id: 45, value: "Orthopedics - Arthroscopy" },
  { id: 46, value: "Orthopedics - Biomaterials" },
  { id: 47, value: "Orthopedics - Bracing" },
  { id: 48, value: "Orthopedics - CMF" },
  { id: 49, value: "Orthopedics - Large Joints & Recon." },
  { id: 50, value: "Orthopedics - Orthopedics Soft Tissue" },
  { id: 51, value: "Orthopedics - Small Joints" },
  { id: 52, value: "Orthopedics - Spine" },
  { id: 53, value: "Orthopedics - Trauma" },
  { id: 54, value: "PACS" },
  { id: 55, value: "Pain Management" },
  { id: 56, value: "Patient Monitoring" },
  { id: 57, value: "Pharmaceuticals" },
  { id: 58, value: "Procedure Tracker" },
  { id: 59, value: "Reimbursement Tracker" },
  { id: 60, value: "Reprocessed Devices" },
  { id: 61, value: "Robotics and Navigation Devices" },
  { id: 62, value: "Surveys" },
  { id: 63, value: "Ultrasound" },
  { id: 64, value: "Unidentified" },
  { id: 65, value: "Urology" },
  { id: 66, value: "Video High Tech" },
  { id: 67, value: "Women's Health" },
  { id: 68, value: "Women's Health - FND" },
  { id: 69, value: "Women's Health - GYN" },
  { id: 70, value: "Wound Management" },
];
