import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { RootState } from "redux/reducers";
import { useAuth } from "contexts/AuthContext";

import CatalogFilterList from "./CatalogFilterList";

import { Category, SubCategory } from "types/catalog";
import { ReportMeta } from "types/reports";
import axios from "axios";
import { generateCategoryLists, handleMarqueeHeading } from "utils/catalog.utils";

interface CatalogCategoryFilterProps {
  menu: any;
  hidden: boolean;
  sectionName: string;
}

export const CatalogCategoryFilter = ({
  menu,
  hidden,
  sectionName,
}: CatalogCategoryFilterProps) => {
  const { currentUser } = useAuth();
  const dispatch = useDispatch();
  const { catalog, subscriptions, customReport } = useSelector(
    (state: RootState) => state
  );
  const [categoryList, setCategoryList] = useState<any[]>(menu);
  const [subCategoryList, setSubCategoryList] = useState<any[]>([]);
  const [reportSubs, setReportSubs] = useState<any[]>([]);
  const [favIds, setFavIds] = useState<number[]>([]);
  const [medCoreFavIds, setMedCoreFavIds] = useState<number[]>([]);
  const [medCores, setMedCores] = useState<any[]>([]);

  window.addEventListener("resize", function () {
    handleMarqueeHeading();
    const vw = Math.max(
      document.documentElement.clientWidth || 0,
      window.innerWidth || 0
    );

    if (vw < 1000) {
      document
        .getElementById("CatalogCategoryFilter")
        ?.removeAttribute("style");
      document.getElementById("CatalogFilterLists")?.removeAttribute("style");
    }
  });

  useEffect(() => {
    if (currentUser.usergroupData) {
      // set user's favorites
      if (currentUser.favorites) setFavIds(currentUser.favorites);
      if (currentUser.medCoreFavorites)
        setMedCoreFavIds(currentUser.medCoreFavorites);
    }
  }, [
    currentUser.usergroupData,
    currentUser.favorites,
    currentUser.medCoreFavorites,
    currentUser.medskuFavorites,
  ]);

  useEffect(() => {
    let result: any[] = [];
    let reportIds: any[] = [];

    customReport.metadata.forEach((el) => {
      if (
        el.collections.length > 0 &&
        el.user_email === "support@idataresearch.net"
      ) {
        reportIds.push(el.collections[0].report_id);
        result.push(el);
      }
    });

    var uniqueCustomReportIds = reportIds.filter(function (item, pos) {
      return reportIds.indexOf(item) === pos;
    });

    let requests: any[] = [];

    uniqueCustomReportIds.forEach((reportId) => {
      requests.push(axios.get(`/reports/report-info/${reportId}`));
    });

    axios.all(requests).then((res) => {
      for (let index = 0; index < res.length; index++) {
        const element = res[index];
        result[index].category_id = element.data.category_id;
        result[index].sub_category_id = element.data.sub_category_id;
      }

      setMedCores(result);
    });
  }, [customReport.metadata]);

  useEffect(() => {
    subscriptions.active.push(...medCores);

    if (subscriptions.active.length > 0) {
      setReportSubs(subscriptions.active);
    }
  }, [
    subscriptions,
    dispatch,
    medCores,
  ]);

  const filters = catalog.filters;
  const category = filters.category;

  useEffect(() => {
    handleMarqueeHeading();
  }, [category]);

  // Subscriptions: Update SubCategoryList based on category state
  useEffect(() => {
    if (catalog.filters.subscribed) {
      generateCategoryLists(
        reportSubs,
        menu,
        catalog.filters,
        setCategoryList,
        setSubCategoryList
      );
    } else if (
      catalog.filters.filter === "Favorites" &&
      !catalog.filters.subscribed
    ) {
      const handleFilter = (report: ReportMeta) => {
        let display = {
          favorites: true,
        };

        if (catalog.filters.filter === "Favorites") {
          if (report.c_report_id) {
            // search user favorites for report
            display.favorites = medCoreFavIds.includes(report.c_report_id);

            // Only show MedCores if the user is subscribed to them
            if (
              !subscriptions.active.find(
                (obj) => obj.c_report_id === report.c_report_id
              )
            ) {
              display.favorites = false;
            }
          } else {
            // search user favorites for report
            display.favorites = favIds.includes(report.report_id);
          }
        }

        if (!Object.values(display).includes(false)) {
          return report;
        }
      };

      // Get report items using favIds
      const filteredReports: ReportMeta[] = catalog.categories.flatMap(
        (category: Category) => {
          return category.sub_categories.flatMap((sub: SubCategory) => {
            return sub.collections.filter(handleFilter);
          });
        }
      );

      generateCategoryLists(
        filteredReports,
        menu,
        filters,
        setCategoryList,
        setSubCategoryList
      );
    } else if (catalog.filters.filter === "Custom Reports") {
      generateCategoryLists(
        medCores,
        menu,
        filters,
        setCategoryList,
        setSubCategoryList
      );
    } else {
      setCategoryList(menu);

      let subcategories = menu.find((item) => item.text === category)
        ?.subCategories;
      if (subcategories) {
        setSubCategoryList(subcategories);
      } else {
        setSubCategoryList([]);
      }
    }
  }, [
    category,
    favIds,
    reportSubs,
    subscriptions,
    catalog.filters,
    catalog.categories,
    medCoreFavIds,
    filters,
    menu,
    medCores,
  ]);

  return (
    <Container id="CatalogCategoryFilter" hidden={hidden}>
      <Heading>
        <HeadingLeft>
          <h2 id="heading1">{category !== "" ? category : sectionName}</h2>
          <h2 id="heading2">{category !== "" ? category : sectionName}</h2>
        </HeadingLeft>
      </Heading>
      <Lists id="CatalogFilterLists">
        <CatalogFilterList categoryLevel={"category"} list={categoryList} />
        <CatalogFilterList
          categoryLevel={"subCategory"}
          list={subCategoryList}
        />
      </Lists>
    </Container>
  );
};

const Container = styled.div`
  border: 3px solid ${(props) => props.theme.colors.grey};
  padding: 2rem;
  grid-area: cat;
  max-height: inherit;

  @media screen and (max-width: 700px) {
    /* max-height: inherit; */
  }
`;

const Heading = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 2rem;
`;

const HeadingLeft = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  justify-content: space-between;
  width: 100%;

  overflow: hidden;
  position: relative;

  h2 {
    margin: 0;
    font-size: 2.6rem;
  }

  @keyframes marquee1 {
    0% {
      transform: translateX(0%);
    }
    50% {
      transform: translateX(-150%);
    }
    100% {
      transform: translateX(-150%);
    }
  }

  @keyframes marquee2 {
    0% {
      transform: translateX(50%);
    }
    50% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }

  #heading1 {
    display: inline-block;
    animation: 7.5s linear infinite;
  }

  #heading2 {
    display: inline-block;
    animation: 7.5s linear infinite;
  }
`;

const Lists = styled.div`
  @media screen and (max-width: 1000px) {
    height: 85%;
  }

  display: flex;
  align-content: space-between;
  column-gap: 2rem;
`;

export default CatalogCategoryFilter;
