import React from "react";
import styled from "styled-components";

const RequiresCookies: React.FC = () => {
  if (!navigator.cookieEnabled) {
    return (
      <Container>
        It looks like your cookies has been disabled. Please enable your cookies
        as they are <strong>required</strong> to use iData Online.
      </Container>
    );
  } else {
    return <></>;
  }
};

const Container = styled.div`
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 0;
  left: 0;
  background-color: ${(props) => props.theme.colors.red};
  text-align: center;
  color: #000;
  padding: 1rem;
  width: 100%;
  z-index: 100;
  font-size: 2rem;
`;

export default RequiresCookies;
