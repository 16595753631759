import React, { useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import { setVisibleStatus } from "redux/actions/catalog";
import { deleteReport } from "redux/actions/report";
import { setNotification } from "redux/actions/notifications";
import { NotificationVariant } from "redux/reducers/notifications";
import { ReportMeta } from "types/reports";
import trashIcon from "assets/icons/trash-icon-red.svg";
import trashIconWhite from "assets/icons/trash-icon-white.svg";

type ItemProps = {
  report: ReportMeta;
  setCatalogChanged: () => void;
  setToggleModal: Function;
  setReportToEdit: Function;
};

const ReportListItem: React.FC<ItemProps> = ({
  report,
  setCatalogChanged,
  setToggleModal,
  setReportToEdit,
}) => {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState<boolean>(report.visible === 1);

  const handleClick = async () => {
    setVisible(!visible);
    setCatalogChanged();

    //change visible status on db
    const obj = { status: !visible ? 1 : 0 };
    await setVisibleStatus(report.report_id, obj);

    // show notification
    dispatch(
      setNotification(
        {
          message:
            `${report.report_name} - ${report.report_year} will be ` +
            (!visible ? "shown" : "hidden"),
          variant: !visible
            ? NotificationVariant.primary
            : NotificationVariant.danger,
        },
        false
      )
    );
  };

  const handleDelete = async () => {
    if (report.subscriptions && report.subscriptions > 0) {
      dispatch(
        setNotification({
          message: "ERROR: Cannot delete a report with active subscriptions!",
          variant: NotificationVariant.danger,
        })
      );
      return;
    }

    if (window.confirm("Are you sure you want to delete this report?")) {
      dispatch(deleteReport(report.report_id));
    }
  };

  return (
    <TableRow key={report.report_id}>
      <RowTitle>{report.report_name}</RowTitle>
      <RowData>{report.report_year}</RowData>
      <RowData>{report.region}</RowData>
      <RowData>{report.subscriptions}</RowData>
      <RowShow>
        <input type="checkbox" checked={visible} onChange={handleClick} />
      </RowShow>
      <RowDel onClick={handleDelete}>
        <TrashDark src={trashIcon} alt="trash icon dark" />
        <TrashLight src={trashIconWhite} alt="trash icon light" />
      </RowDel>
      <RowEdit
        onClick={() => {
          setToggleModal(true);
          setReportToEdit({
            id: report.report_id,
            title: report.report_name,
            year: report.report_year,
            region: report.region,
            description: report.description,
            icon: report.icon,
          });
        }}
      >
        Edit Report Details
      </RowEdit>
    </TableRow>
  );
};

const RowTitle = styled.td`
  transition: all 0.2s;
  width: 50rem;
`;

const RowShow = styled.td`
  display: flex;
  justify-content: center;
  transition: all 0.2s;

  input {
    height: 2rem;
    width: 2rem;
  }
`;

const RowData = styled.td`
  height: 100%;
  transition: all 0.2s;
`;

const TrashDark = styled.img`
  display: inline-block;
`;

const TrashLight = styled.img`
  display: none;
`;

const RowDel = styled.td`
  text-align: center;
  transition: all 0.2s;

  &:hover {
    background-color: ${(props) => props.theme.colors.lightRed};
    opacity: 0.6;
  }

  &:hover ${TrashDark} {
    display: none;
  }

  &:hover ${TrashLight} {
    display: inline-block;
  }
`;

const RowEdit = styled.td`
  text-align: center;
  transition: all 0.2s;

  &:hover {
    background-color: ${(props) => props.theme.colors.green};
    opacity: 0.6;
  }
`;

const TableRow = styled.tr`
  cursor: pointer;

  &:hover ${RowData}, &:hover ${RowTitle}, &:hover ${RowShow} {
    background-color: ${(props) => props.theme.colors.grey};
  }

  td,
  th {
    padding: 1rem;
  }
`;

export default ReportListItem;
