import React from "react";
import styled from "styled-components";
import diag from "assets/images/Homepage-Circle-Graphic-1.png";
import StandardButton from "components/Common/buttons/StandardButton";

const Diagram = () => (
  <Container>
    <DiagramTitle>
      Your One-Access Portal
      <br /> To All of Your Data.
    </DiagramTitle>
    <DiagramImg src={diag} alt="idata diagram" />
    <SignInButton
      onClick={() => document.getElementById("LoginForm")?.scrollIntoView(true)}
      type={"button"}
      color={"green"}
    >
      Sign in
    </SignInButton>
  </Container>
);

const Container = styled.div`
  padding: 2.5rem;

  @media screen and (max-width: ${(props) => props.theme.bp.lg}px) {
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    // probably choose a different breakpoint
    /* height: 75vh; // account for header and mobile bottom address bar */
    /* height: 80%; */
    min-height: 83vh;
  }
`;

const SignInButton = styled(StandardButton)`
  display: none;

  @media screen and (max-width: ${(props) => props.theme.bp.lg}px) {
    display: block;
  }
`;

const DiagramTitle = styled.h1`
  font-size: 4.8rem;
  font-weight: 500;
  text-align: center;

  @media screen and (max-width: ${(props) => props.theme.bp.xxs}px) {
    font-size: 3rem;
    margin: 0;
  }

  @media screen and (max-width: ${(props) => props.theme.bp.lg}px) {
    margin: 0;
    font-size: 3.5rem;
  }
`;

const DiagramImg = styled.img`
  display: block;
  width: 100%;
  max-width: 43rem;
  margin: 1.5rem auto;

  @media screen and (max-width: ${(props) => props.theme.bp.lg}px) {
    width: 80%;
  }
`;

export default Diagram;
