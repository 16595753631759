import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { useAuth } from "contexts/AuthContext";

import StandardButton from "components/Common/buttons/StandardButton";
import { generateReportUrl } from "utils/report.utils";

type ItemProps = {
  subs: any;
  remove: Function;
};

const MedSKUSummary: React.FC<ItemProps> = ({ subs, remove }) => {
  return subs.map((sub, index) =>
    <Item key={`MedSKU_SubscriptionsItem_${sub.medsku_id}_${index}`} sub={sub} remove={remove} />
  );
};

const Item = ({ sub, remove }) => {
  const { isSales } = useAuth();

  const [keep, setKeep] = useState<boolean>(false);
  const [date, setDate] = useState("N/A");

  const url = generateReportUrl(sub.medsku_name);

  useEffect(() => {
    if (sub.date_added) {
      setDate(new Date(sub.date_added).toString());
    }
  }, []);

  function handleClick() {
    setKeep(!keep);
    remove(sub.medsku_id);
  }

  if (isSales()) {

    return (
      <SubscriptionsItem $keep={keep}>
        <div>
          <Link
            to={`/medsku/dashboard/${sub.medsku_id}/${url}`}
            target="_blank"
          >
            {sub.medsku_name}
          </Link>
          <Small>Access assigned on: {date}</Small>
          <Small>{sub.medsku_demo_id || sub.demo ? "Sample" : "Full"}</Small>
        </div>

      </SubscriptionsItem>
    );
  } else {
    return (
      <SubscriptionsItem $keep={keep}>
        <div>
          <Link
            to={`/medsku/dashboard/${sub.medsku_id}/${url}`}
            target="_blank"
          >
            {sub.medsku_name}
          </Link>
          <Small>Access assigned on: {date}</Small>
          <Small>{sub.medsku_demo_id || sub.demo ? "Sample" : "Full"}</Small>
        </div>

        <StandardButton onClick={handleClick} size="xs" type={"button"}>
          {keep ? "Keep" : "Remove"}
        </StandardButton>
      </SubscriptionsItem>
    );
  }
}

const SubscriptionsItem = styled.div<{ $keep?: boolean; }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  padding: 1rem;

  div {
    width: 100%;
  }

  opacity: ${props => props.$keep ? 0.5 : 1};
  transition: 0.2s;
`;

const Small = styled.div`
  font-size: 1.5rem;
`;

export default MedSKUSummary;
