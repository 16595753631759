import React, { useState, useEffect } from "react";
import styled from "styled-components";

import expandIcon from "assets/icons/add-black-sm.svg";
import shrinkIcon from "assets/icons/remove-black-sm.svg";
import { SubCategory } from "types/catalog";
import StandardButton from "components/Common/buttons/StandardButton";
import axios from "axios";

type SubMenuProps = { readonly toggleSubMenu: boolean };

type ListSubMenuProps = {
  productType: string;
  s: any;
  add: Function;
};

const ListSubMenu = ({ productType, s, add }: ListSubMenuProps) => {
  const [expanded, setExpanded] = useState<boolean>(false);

  function addDemo(item) {
    item.demo = true;
    add(item);
  }

  return (
    <Container>
      <ReportListSubHeading onClick={() => setExpanded(!expanded)}>
        {s.sub_category_name}
        <img src={expanded ? shrinkIcon : expandIcon} alt="toggle icon" />
      </ReportListSubHeading>

      {s.collections.length > 0 ? (
        <SubMenu toggleSubMenu={expanded}>
          {s.collections.map((r, index) => {
            if (!r.c_report_id) {

              if (r.global_dashboard === 1) {
                return (
                  <ReportListItem key={r[`${productType}_id`] + "_" + index}>
                    <Text>
                      {r[`${productType}_name`]} ({r[`${productType}_year`]})
                    </Text>

                    <AddGlobalReportDashboardSample item={r} add={add} />

                    <div>
                      <StandardButton onClick={() => add(r)} size="xs" type={"button"}>
                        Add Full
                      </StandardButton>
                    </div>

                  </ReportListItem>
                );
              } else if (r.medsku_id !== undefined) {

                return (
                  <ReportListItem key={r[`${productType}_id`] + "_" + index}>
                    <Text>
                      {r[`${productType}_name`]} ({r[`${productType}_year`]})
                    </Text>

                    <StandardButton onClick={() => addDemo(r)} size="xs" type={"button"}>
                      Add Sample
                    </StandardButton>
                    <StandardButton onClick={() => add(r)} size="xs" type={"button"}>
                      Add Full
                    </StandardButton>

                  </ReportListItem>
                );
              } else if (r.pt_id !== undefined) {

                return (
                  <ReportListItem key={r[`${productType}_id`] + "_" + index}>
                    <Text>
                      {r[`${productType}_name`]} ({r[`${productType}_year`]})
                    </Text>

                    <StandardButton onClick={() => addDemo(r)} size="xs" type={"button"}>
                      Add Sample
                    </StandardButton>
                    <StandardButton onClick={() => add(r)} size="xs" type={"button"}>
                      Add Full
                    </StandardButton>

                  </ReportListItem>
                );
              } else {
                return (
                  <ReportListItem key={r[`${productType}_id`] + "_" + index}>
                    <Text>
                      {r[`${productType}_name`]} ({r[`${productType}_year`]})
                    </Text>

                    <StandardButton onClick={() => add(r)} size="xs" type={"button"}>
                      Add Full
                    </StandardButton>

                  </ReportListItem>
                );
              }

            }
          })}
        </SubMenu>
      ) : null}

    </Container>
  );
};

const AddGlobalReportDashboardSample = ({ item, add }) => {
  const [markets, setMarkets] = useState([]);
  const [selectedMarket, setSelectedMarket] = useState("");

  useEffect(() => {
    axios
      .get(`/global-dashboards/markets/${item.report_id}`)
      .then((response) => {
        setMarkets(response.data);
        setSelectedMarket(response.data[0]);
      });
  }, []);

  function addSample() {
    item.sample = true;
    item.market_name = selectedMarket;

    add(item);
  }

  return (
    <SampleInputs>
      <select name="markets" onChange={e => setSelectedMarket(e.target.value)}>
        {markets.map((market) => <option key={market} value={market}>{market}</option>)}
      </select>

      <StandardButton onClick={() => addSample()} size="xs" type={"button"}>
        Add Sample
      </StandardButton>
    </SampleInputs>
  )
}

const Container = styled.div`
  margin: 1rem 0;
`;

const ReportListSubHeading = styled.div`
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }

  display: flex;
  align-items: center;
  margin-left: 1rem;
`;

const ReportListItem = styled.li`
  display: flex;
  align-items: center;

  padding: 1rem;

  &:hover {
    background-color: ${(props) => props.theme.colors.grey};
  }

  button:last-child {
    margin: 0 1rem;
  }
`;

const Text = styled.div`
  width: 100%;
`;

const SubMenu = styled.ul<SubMenuProps>`
  display: ${(props) => (props.toggleSubMenu ? "block" : "none")};
  padding-left: 2rem;
`;

const SampleInputs = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 0.5rem;
  background-color: gainsboro;

  select {
    width: 20rem;
    margin-bottom: 0.5rem;
  }
`;

export default ListSubMenu;
