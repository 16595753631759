import React, { Dispatch, SetStateAction } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { useAuth } from "contexts/AuthContext";
import { MenuItem as MenuItemType } from "types";
import { User as UserType } from "types/firebase";
import { setFilters, resetFilters } from "redux/actions/catalog";
import { toggleRecommending } from "redux/actions/admin";
import { RootState } from "redux/reducers";
import { setErrors } from "redux/actions/errors";

type MobileMenuItemProps = {
  menu: MenuItemType;
  isDropdown: boolean;
  setToggleDropdown: Dispatch<SetStateAction<boolean>>;
};

const MobileMenuItem: React.FC<MobileMenuItemProps> = ({
  menu,
  isDropdown,
  setToggleDropdown,
}) => {
  const { currentUser, logout } = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { recommending } = useSelector((state: RootState) => state.admin);
  const handleClick = async (e: any, key: string) => {
    dispatch(resetFilters());
    setToggleDropdown(false);

    if (key === "sign-out") {
      if (recommending) dispatch(toggleRecommending());

      try {
        await logout();
      } catch {
        return dispatch(setErrors({ message: "Error while logging out" }));
      }

      navigate("/");
    } else if (key === "my-subscriptions") {
      dispatch(setFilters("subscribed", true));
    } else if (key === "my-favorites") {
      dispatch(setFilters("category", e.target.text));
    }
  };

  const renderChildMenuItems = (
    menu: MenuItemType["childMenu"],
    user: UserType
  ) => {
    if (!menu) return null;

    return menu.map((child) => {
      if (child.access.includes(user.role)) {
        return (
          <li key={child.key}>
            <DropdownItem
              to={child.path}
              onClick={(e) => handleClick(e, child.key)}
            >
              {child.name}
            </DropdownItem>
          </li>
        );
      } else {
        return null;
      }
    });
  };

  const renderSectionHeading = (menu) => {
    if (menu.childMenu.length > 0) {
      return <SectionHeading>{menu.name}</SectionHeading>;
    }
  };

  if (menu.childMenu && menu.childMenu.length > 0) {
    return (
      <Container>
        {renderSectionHeading(menu)}
        <Dropdown>{renderChildMenuItems(menu.childMenu, currentUser)}</Dropdown>
      </Container>
    );
  } else {
    return null;
  }
};

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border-bottom: solid 1px white;
  padding: 1rem 0;

  > *:not(:last-child) {
    margin-right: 2rem;
  }
`;

const SectionHeading = styled.h2`
  font-size: 2.25rem;
  margin: 0;
`;

const Dropdown = styled.ul`
  display: flex;
  margin: 0.5rem 0;

  > *:not(:last-child) {
    margin-right: 2rem;
  }
`;

const DropdownItem = styled(Link)`
  color: #fff;
  display: block;
  // padding: 1rem 3rem;

  &:hover {
    color: #fff;
    text-decoration: underline;
  }
`;

export default MobileMenuItem;
