import { AnyAction } from "redux";

import {
  FETCH_SURVEY_CATALOG_DATA,
  SET_FILTERS,
  RESET_FILTERS,
  SET_POSSIBLE_REGIONS,
} from "redux/actions/survey";

const initialCatalogState = {
  categories: [],
  filters: {
    filter: "Subscriptions",
    category: "",
    subCategory: "",
    subscribed: true,
    region: "all",
    year: "All",
  },
  possibleRegions: ["", ""],
};

const surveyReducer = (state = initialCatalogState, action: AnyAction) => {
  switch (action.type) {
    case FETCH_SURVEY_CATALOG_DATA:
      return {
        ...state,
        categories: action.payload,
      };
    case SET_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.type]: action.payload.value,
        },
      };
    case RESET_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          category: "",
          subCategory: "",
        },
      };
    case SET_POSSIBLE_REGIONS:
      return {
        ...state,
        possibleRegions: action.payload,
      };
    default:
      return state;
  }
};

export default surveyReducer;
