import axios from "axios";
import { Dispatch } from "redux";

export const FETCH_CATALOG_DATA = "FETCH_CATALOG_DATA";
export const SET_FILTERS = "SET_FILTERS";
export const RESET_FILTERS = "RESET_FILTERS";
export const SET_POSSIBLE_REGIONS = "SET_POSSIBLE_REGIONS";

export const fetchCatalogData: any = (
  setLoading?: (loading: boolean) => void,
  subscriberCount?: boolean
) => async (dispatch: Dispatch) => {
  let data;

  if (subscriberCount) {
    data = await axios.get("/catalogs", { params: { subscriberCount } });
  } else {
    data = await axios.get("/catalogs");
  }

  dispatch({
    type: FETCH_CATALOG_DATA,
    payload: data.data.categoriesData,
  });

  if (setLoading) {
    setLoading(false);
  }
};

export const setFilters = (type: string, value: string | boolean) => async (
  dispatch: Dispatch
) => {
  dispatch({
    type: SET_FILTERS,
    payload: {
      type,
      value,
    },
  });
};

export const resetFilters = () => async (dispatch: Dispatch) => {
  dispatch({
    type: RESET_FILTERS,
  });
};

export const setVisibleStatus = async (reportId: number, body: object) => {
  return await axios.patch(`/reports/visible-status/${reportId}`, body);
};

export const setPossibleRegions = (possibleRegions: string[]) => async (
  dispatch: Dispatch
) => {
  dispatch({
    type: SET_POSSIBLE_REGIONS,
    payload: possibleRegions,
  });
};
