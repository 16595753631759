import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import styled, { css } from "styled-components";

import { useAuth } from "contexts/AuthContext";

import { accountMenu } from "data/menus/accountMenu";
import MobileButton from "components/Common/buttons/MobileButton";

type ContainerProps = {
  readonly toggleMenu: boolean;
};

type MenuProps = {
  readonly toggleMenu: boolean;
};

type ItemLinkProps = {
  readonly isselected: number;
  // readonly toggleMenu: boolean;
};

const AccountMenu: React.FC = () => {
  const location = useLocation();
  const { currentUser } = useAuth();
  const [toggleMenu, setToggleMenu] = useState<boolean>(false);
  const [selected, setSelected] = useState<string>(location.pathname);

  return (
    <Container toggleMenu={toggleMenu}>
      <CustomMobileButton
        toggleMenu={toggleMenu}
        className=""
        onClick={() => setToggleMenu(!toggleMenu)}
      />
      <MenuDiv toggleMenu={toggleMenu}>
        {accountMenu
          .filter((item) => item.access.includes(currentUser.role))
          .map((item) => (
            <ItemLink
              // toggleMenu={toggleMenu}
              key={item.key}
              to={item.path}
              isselected={selected === item.path ? 1 : 0}
              onClick={() => {
                setSelected(item.path);
                setToggleMenu(false);
              }}
            >
              <li>{item.name}</li>
            </ItemLink>
          ))}
      </MenuDiv>
    </Container>
  );
};

const Container = styled.ul<ContainerProps>`
  margin: 0;

  @media screen and (max-width: ${(props) => props.theme.bp.md}px) {
    background-color: ${(props) =>
      props.toggleMenu ? props.theme.colors.lighterGrey : "transparent"};
    box-shadow: ${(props) =>
      props.toggleMenu ? "0px 0px 2px rgba(0, 0, 0, 0.3)" : "none"};
    height: 100%;
    padding: 2rem;
    position: absolute;
    left: 0;
    top: 0rem;
    z-index: 100;
  }
`;

const CustomMobileButton = styled(MobileButton)`
  display: none;

  @media screen and (max-width: ${(props) => props.theme.bp.md}px) {
    display: block;
  }
`;

const MenuDiv = styled.div<MenuProps>`
  @media screen and (max-width: ${(props) => props.theme.bp.md}px) {
    display: ${(props) => (props.toggleMenu ? "block" : "none")};
  }
`;

const ItemLink = styled(Link)<ItemLinkProps>`
  border-radius: 5rem;
  color: ${(props) => props.theme.colors.idataDarkGrey};
  display: block;
  font-size: 2.5rem;
  padding: 1rem 2rem;

  &:hover {
    ${() => ItemLinkSelectedStyles}
  }

  ${(props) => (props.isselected ? ItemLinkSelectedStyles : "")}
`;

const ItemLinkSelectedStyles = () => {
  return css`
    background-color: ${(props) => props.theme.colors.grey};
    color: ${(props) => props.theme.colors.idataDarkGrey};
  `;
};

export default AccountMenu;
