import React, {
  useState,
  // useEffect
} from "react";
import {
  useSelector,
  // useDispatch
} from "react-redux";
import {
  Route, Routes
} from "react-router-dom";
import styled, { css } from "styled-components";
// import axios, { AxiosRequestConfig } from "axios";

import settingsIcon from "assets/icons/settings-black.svg";
import checkIcon from "assets/icons/check-black.svg";
// import lockIcon from "assets/icons/lock-black.svg";
// import { updateSubscriptionDownloads } from "redux/actions/subscriptions";
import { RootState } from "redux/reducers";
import { Usergroup } from "types/firebase";

import ReportContent from "./ReportContent";
import Unsubscribed from "./Unsubscribed";

type Toggle = { toggle?: boolean };
type ItemLinkType = { disabled?: boolean };
type T = {
  subscribed: boolean;
  usergroup: Usergroup;
};

const ReportMain: React.FC<T> = ({ subscribed, usergroup }) => {
  // const dispatch = useDispatch();
  const {
    report,
    // subscriptions
  } = useSelector((state: RootState) => state);
  const [toggle, setToggle] = useState<boolean>(false);
  const [tablesOnly, setTablesOnly] = useState<boolean>(false);
  // const [remaining, setRemaining] = useState<number>(0);
  // const [loading, setLoading] = useState<boolean>(false);
  // const [downloadable, setDownloadable] = useState<boolean>(false);

  const title = subscribed ? report.reportMeta : report.unsubscribed;
  // const reportId = title.report_id;
  // const sub =
  //   subscribed && subscriptions.active.find((r) => r.report_id === reportId);

  // useEffect(() => {
  //   if (sub) {
  //     setRemaining(sub.download_limit - sub.download_used);
  //   }
  // }, [sub, subscriptions]);

  // useEffect(() => {
  //   // Check whether there is a PDF to download, set update downloadable state
  //   const options: AxiosRequestConfig = {
  //     responseType: "blob",
  //     headers: { "Content-Type": "application/pdf" },
  //   };
  //   axios
  //     .get(`/reports/check-file-exists/${reportId}/${usergroup.id}`, options)
  //     .then(() => {
  //       setDownloadable(true);
  //     })
  //     .catch(() => {
  //       setDownloadable(false);
  //     });
  // }, [reportId, usergroup.id]);

  // const handleDownload = async () => {
  //   if (!usergroup.id) return;

  //   const newValue = remaining - 1;
  //   const used = sub.download_limit - newValue;
  //   const update = {
  //     subId: sub.subscription_id,
  //     used,
  //     limit: sub.download_limit,
  //   };

  //   setLoading(true);

  //   const options: AxiosRequestConfig = {
  //     responseType: "blob",
  //     headers: { "Content-Type": "application/pdf" },
  //   };
  //   try {
  //     const res = await axios.get(
  //       `/reports/download-report/${reportId}/${usergroup.id}`,
  //       options
  //     );
  //     const fileName = res.headers["content-disposition"].replace(
  //       /(attachment;\sfilename=)|(")/g,
  //       ""
  //     );

  //     const url = window.URL.createObjectURL(new Blob([res.data]));
  //     const link = document.createElement("a");

  //     link.href = url;
  //     link.setAttribute("download", fileName);
  //     document.body.appendChild(link);
  //     link.click();
  //     setLoading(false);
  //     dispatch(updateSubscriptionDownloads(usergroup.id, update));
  //     setRemaining(newValue);
  //   } catch (error) {
  //     // console.log("No PDF to download");
  //   }
  // };

  return (
    <Container id="ReportMain">
      {subscribed && (
        <ReportSettings>
          <SettingsButton onClick={() => setToggle(!toggle)}>
            <img src={settingsIcon} alt="gear icon" />
          </SettingsButton>
          <Dropdown toggle={toggle}>
            <DropdownItem>
              <ItemLink onClick={() => setTablesOnly(!tablesOnly)}>
                View Tables Only{" "}
                {tablesOnly && <CheckIcon src={checkIcon} alt="check icon" />}
              </ItemLink>
            </DropdownItem>
            {/* <DropdownItem>
              <ItemLink
                disabled={loading || remaining < 1 || !downloadable}
                onClick={handleDownload}
              >
                {downloadable ? (
                  <>Export Report as PDF</>
                ) : (
                  <>No PDF Available to Export</>
                )}
              </ItemLink>
              {remaining > 0 ? (
                <Downloads>({remaining} remaining)</Downloads>
              ) : (
                <>
                  <LockIcon src={lockIcon} alt="lock icon" />
                  <UpgradeLink to="/report">Upgrade</UpgradeLink>
                </>
              )}
            </DropdownItem> */}
          </Dropdown>
        </ReportSettings>
      )}
      <Routes>
        <Route
          path=":name/:chapter"
          element={ subscribed ?          
            <ReportContent 
              contentData={report.reportContentData} 
              reportType="regular" 
              tablesOnly={tablesOnly} /> :
            <Unsubscribed
              report_id={title.report_id}
              report_name={title.report_name}
              report_year={title.report_year}
            />
          }
        />
      </Routes>
    </Container>
  );
};

const Container = styled.div`
  margin: 1rem 0;
  padding: 3rem 0;
  background-color: #fff;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.3);
  padding: 2rem 2.5rem;
  min-height: 100rem;
`;

const disabledLinkStyles = () => {
  return css`
    opacity: 0.3;
    pointer-events: none;
  `;
};

const ItemLink = styled.button<ItemLinkType>`
  color: ${(props) => props.theme.colors.idataDarkGrey};
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  height: 2.6rem;

  &:hover {
    opacity: 0.9;
  }

  ${(props) => (props.disabled ? disabledLinkStyles : "")};
`;

const ReportSettings = styled.div`
  float: right;
  position: relative;
`;

const SettingsButton = styled.button`
  &:hover {
    opacity: 0.8;
  }
`;

const Dropdown = styled.ul<Toggle>`
  background: #fff;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.3);
  display: ${(props) => (props.toggle ? "block" : "none")};
  font-size: 2rem;
  padding: 2.5rem 3rem;
  position: absolute;
  top: 3rem;
  right: -4rem;
  text-align: left;
  min-width: 35rem;
`;

const DropdownItem = styled.li`
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

const CheckIcon = styled.img`
  vertical-align: bottom;
`;

export default ReportMain;
