import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

import { generateReportUrl } from "utils/report.utils";
import { ChapterMeta } from "types/reports";
import { RootState } from "redux/reducers";

type MenuItemProps = {
  item: ChapterMeta;
  chapter: number;
  searchItems: number[];
};
type ChildLinkProps = {
  readonly selected: boolean;
  readonly highlighted: number;
};

const ReportMenuItem: React.FC<MenuItemProps> = ({
  item,
  chapter,
  searchItems,
}) => {
  const report = useSelector((state: RootState) => state.report);
  const url = generateReportUrl(report.reportMeta.report_name);

  return (
    <ItemContainer key={item.id}>
      <ChildLink
        to={`/reports/${report.reportMeta.report_id}/${url}/${item.chapter_number}`}
        selected={item.chapter_number === chapter}
        highlighted={searchItems.includes(item.chapter_number) ? 1 : 0}
      >
        {item.chapter_title}
      </ChildLink>
    </ItemContainer>
  );
};

const ItemContainer = styled.li`
  &:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

const selectedStyles = () => {
  return css`
    color: ${(props) => props.theme.colors.idataBlue};

    &::before {
      content: "";
      background-color: ${(props) => props.theme.colors.idataBlue};
      border-radius: 50%;
      height: 7px;
      width: 7px;
      position: absolute;
      left: -15px;
      top: 47%;
      transform: translateY(-47%);
    }
  `;
};

const childLinkHandler = (selected: boolean, highlighted: number) => {
  return css`
    color: ${(props) =>
      highlighted === 1
        ? props.theme.colors.yellow
        : props.theme.colors.idataDarkGrey};
    pointer-events: auto;

    &:hover {
      color: ${(props) => props.theme.colors.idataBlue};
    }

    ${selected ? selectedStyles : null}
  `;
};

const ChildLink = styled(Link)<ChildLinkProps>`
  border-radius: 0;
  color: ${(props) => props.theme.colors.darkGrey};
  display: flex;
  align-items: center;
  flex: flex-wrap;
  font-size: 2rem;
  pointer-events: none;
  position: relative;
  width: 100%;
  height: 100%;
  text-align: left;

  ${(props) => childLinkHandler(props.selected, props.highlighted)}
`;

export default ReportMenuItem;
