import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { useAuth } from "contexts/AuthContext";

import { generateReportUrl } from "utils/report.utils";

type ItemProps = { subs: any; usergroup: any };

const CustomReportSummary: React.FC<ItemProps> = ({ subs, usergroup }) => {
  if (subs.length > 0) {
    return subs.map((sub) =>
      <Item key={`Custom_SubscriptionsItem_${sub.c_report_id}`} sub={sub} usergroup={usergroup} />
    );
  } else {
    return null;
  }
};

const Item = ({ sub, usergroup }) => {
  const { isSales } = useAuth();

  const [reportsInfo, setReportsInfo] = useState<any[]>([]);

  // Get name and year of reports
  useEffect(() => {
    const fetchCustomReportInfo = async () => {
      let arr: number[] = [];

      for (let collection of sub.collections) {
        arr.push(collection.report_id);
      }

      // https://stackoverflow.com/a/14438954
      function onlyUnique(value, index, self) {
        return self.indexOf(value) === index;
      }

      var unique = arr.filter(onlyUnique);

      Promise.all(
        unique.map((report_id) =>
          fetch(`/reports/report-info/${report_id}`)
            .then((resp) => resp.json())
        )
      )
        .then((data) => {
          setReportsInfo(data);
        })
        .catch((error) => null);
    };

    fetchCustomReportInfo();
  }, [sub, usergroup]);

  const url = generateReportUrl(sub.c_report_name);

  if (reportsInfo.length === 1) {
    if (isSales()) {
      return (
        <SubscriptionsItem>
          <Link
            to={`/custom-reports/${sub.c_report_id}/${url}/1`}
            target="_blank"
          >
            {sub.c_report_name}
          </Link>

          <Details>
            <div>{sub.collections.length} chapters</div>
            <div>Created on: {new Date(sub.created_on).toString()}</div>
            <div>Includes content from:</div>
            <ul>
              {reportsInfo.map((report) => (
                <li key={`${report.report_name} - ${report.report_year}`}>
                  <Link
                    to={`/reports/${report.report_id}/${generateReportUrl(report.report_name)}/1`}
                    target="_blank"
                  >
                    {report.report_name} ({report.report_year})
                  </Link>
                </li>
              ))}
            </ul>
          </Details>

        </SubscriptionsItem>
      );
    } else {
      return (
        <SubscriptionsItem>
          <Link
            to={`/custom-reports/${sub.c_report_id}/${url}/1`}
            target="_blank"
          >
            {sub.c_report_name}
          </Link>

          <Details>
            <div>{sub.collections.length} chapters</div>
            <div>Created on: {new Date(sub.created_on).toString()}</div>
            <div>Includes content from:</div>
            <ul>
              {reportsInfo.map((report) => (
                <li key={`${report.report_name} - ${report.report_year}`}>
                  <Link
                    to={`/reports/${report.report_id}/${generateReportUrl(report.report_name)}/1`}
                    target="_blank"
                  >
                    {report.report_name} ({report.report_year})
                  </Link>
                </li>
              ))}
            </ul>
          </Details>

        </SubscriptionsItem>
      );
    }
  } else {
    return null;
  }
};

const SubscriptionsItem = styled.div`
  padding: 1rem;

  ul {
    list-style-type: disc;
    padding-left: 3rem;
    margin: 0;
  }
`;

const Details = styled.div`
  font-size: 1.5rem;
`;

export default CustomReportSummary;
