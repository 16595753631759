import { Dispatch } from "redux";

export const SET_ERRORS = "SET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";

// Sets errors in store (forms)
export const setErrors = (error: any) => {
  const errors = error.response ? error.response.data : error;
  return {
    type: SET_ERRORS,
    payload: errors,
  };
};

// Clears errors (forms)
export const clearErrors = () => (dispatch: Dispatch) => {
  dispatch({
    type: CLEAR_ERRORS,
  });
};
