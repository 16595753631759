import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { useAuth } from "contexts/AuthContext";
import { RootState } from "redux/reducers";
import { sendContactUsMessage } from "redux/actions/admin";
import { clearErrors } from "redux/actions/errors";

import closeIcon from "assets/icons/close-black.svg";

import TextAreaInput from "./TextAreaInput";
import StandardButton from "components/Common/buttons/StandardButton";
import { ANALYST, SALES, TECH_SUPPORT } from "data/email";

type AskAnalystProps = {
  setToggleModal: Function;
};

export const AskAnalyst: React.FC<AskAnalystProps> = ({ setToggleModal }) => {
  const dispatch = useDispatch();
  const { currentUser } = useAuth();
  const errors = useSelector((state: RootState) => state.errors);

  const [message, setMessage] = useState<string>("");
  const [recipient, setRecipient] = useState<string>("Analyst");

  let subject: string,
    other: string = "";

  useEffect(() => {
    return () => {
      dispatch(clearErrors());
    };
  }, [dispatch]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    dispatch(clearErrors());

    switch (recipient) {
      case "Analyst":
        subject = ANALYST;
        break;
      case "Sales":
        subject = SALES;
        break;
      case "Technical Support":
        subject = TECH_SUPPORT;
        break;
    }

    other = window.location.href;

    const userMessage = {
      name: currentUser.name,
      email: currentUser.email,
      phone: currentUser.phone ? currentUser.phone : "n/a",
      title: currentUser.title ? currentUser.title : "n/a",
      company: currentUser.company,
      category: "",
      message,
      subject,
      other,
    };

    dispatch(sendContactUsMessage(userMessage, setToggleModal));
  };

  return (
    <Container onSubmit={handleSubmit}>
      <Header>
        <div>Contact Us</div>
        <button
          onClick={() => {
            setToggleModal();
          }}
        >
          <img src={closeIcon} alt="close icon" />
        </button>
      </Header>
      <FormElement>
        <label>
          To:
          <select
            onChange={(e) => setRecipient(e.target.value)}
            defaultValue={"Analyst"}
          >
            <option value="Analyst">Analyst</option>
            <option value="Sales">Sales</option>
            <option value="Technical Support">Technical Support</option>
          </select>
        </label>
      </FormElement>
      <FormElement>
        <TextAreaInput
          name="message"
          placeholder="Your message... *"
          onChange={(e) => setMessage(e.target.value)}
          value={message}
          error={errors.message}
        />
      </FormElement>
      <ButtonContainer>
        <StandardButton type="submit" size="md">
          Submit
        </StandardButton>
      </ButtonContainer>
    </Container>
  );
};

const Container = styled.form`
  padding: 2rem;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 1.5em;
  margin-bottom: 1em;
`;

const FormElement = styled.div`
  width: 45rem;
  margin: 4rem 0;

  select {
    margin-left: 1rem;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export default AskAnalyst;
