import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import styled, { css } from "styled-components";

import adminMenu from "data/menus/adminMenu";

type AdminLinkProps = {
  readonly isselected: string;
};

const AdminMenu = () => {
  const location = useLocation();
  const [selected, setSelected] = useState<string>(location.pathname);

  return (
    <Container>
      {adminMenu.map((item) => (
        <AdminLink
          key={item.key}
          to={item.path}
          isselected={selected === item.path ? "true" : "false"}
          onClick={() => setSelected(item.path)}
        >
          <AdminItem>
            {item.icon}
            {item.name}
          </AdminItem>
        </AdminLink>
      ))}
    </Container>
  );
};

const Container = styled.ul`
  border-bottom: 1px solid ${(props) => props.theme.colors.grey};
  display: flex;
  align-items: stretch;
  padding-left: 3rem;
  flex-wrap: wrap;
  /* height: 5rem; */
`;

const AdminItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 5rem;
`;

const AdminLink = styled(Link)<AdminLinkProps>`
  color: ${(props) => props.theme.colors.darkGrey};
  padding-right: 5px;

  svg {
    fill: ${(props) => props.theme.colors.darkGrey};
    margin-bottom: 5px;
    margin-right: 5px;
  }

  ${(props) => (props.isselected === "true" ? AdminLinkSelectedStyles : "")}

  &:hover {
    ${() => AdminLinkSelectedStyles}
  }

  &:not(:last-child) {
    margin-right: 2rem;
  }
`;

const AdminLinkSelectedStyles = () => {
  return css`
    border-bottom: 1px solid ${(props) => props.theme.colors.idataBlue};
    color: ${(props) => props.theme.colors.idataBlue};

    svg {
      fill: ${(props) => props.theme.colors.idataBlue};
      margin-bottom: 4px;
    }
  `;
};

export default AdminMenu;
