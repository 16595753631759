import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Modal from "react-modal";
import styled from "styled-components";

import loginHead from "assets/images/Homepage-Login-Person-Icon-Blue.png";
import iconPerson from "assets/icons/Person-Icon-Small-Field.png";
import iconKey from "assets/icons/Key-Icon-Small-Field.png";
import closeIcon from "assets/icons/close-black.svg";

import { clearErrors } from "redux/actions/errors";
import { RootState } from "redux/reducers";

import { modalStyles } from "styles/modals";
import { useAuth } from "contexts/AuthContext";

import StandardButton from "../../../Common/buttons/StandardButton";
import EnrollMFA from "./LoginChildComponents/EnrollMFA";
import VerifyCode from "./LoginChildComponents/VerifyCode";

type InputProps = { icon: string };

const LoginForm: React.FC = () => {
  const { login } = useAuth();
  const dispatch = useDispatch();
  const errors = useSelector((state: RootState) => state.errors);
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [toggleModal, setToggleModal] = useState(false);
  const [firebaseUser, setFirebaseUser] = useState({});
  const [toggleGetPhone, setToggleGetPhone] = useState(false);
  const [toggleGetCode, setToggleGetCode] = useState(false);
  const [error, setError] = useState({});

  const StartEnrollMFA = () => {
    setToggleGetPhone(true);
    setToggleGetCode(false);
  };

  const StartVerifyCode = (error) => {
    setToggleGetPhone(false);
    setToggleGetCode(true);
    setError(error);
  };

  useEffect(() => {
    return () => {
      dispatch(clearErrors());
    };
  }, [dispatch]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    dispatch(clearErrors());

    login(email, password, setFirebaseUser, StartEnrollMFA, StartVerifyCode);
  };

  if (toggleGetPhone) {
    return <EnrollMFA key="GetPhone" user={firebaseUser} />;
  } else if (toggleGetCode) {
    return <VerifyCode key="GetCode" error={error} />;
  } else {
    return (
      <Wrapper id="LoginForm">
        <div>
          <Form onSubmit={handleSubmit}>
            <LoginIcon src={loginHead} alt="login icon" />
            <LoginTitle>Sign In</LoginTitle>
            <Input
              type="email"
              placeholder="Email"
              icon={iconPerson}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              autoFocus
            />
            <Input
              type="password"
              placeholder="Password"
              icon={iconKey}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Error>
              {errors.message !== "Missing or insufficient permissions."
                ? errors.message
                : ""}
            </Error>
            <StandardButton id="sign-in-button" type="submit">
              Enter
            </StandardButton>
            <AuthLinks>
              <AuthLink to="/password-recovery">Forgot Password?</AuthLink>
            </AuthLinks>
            <CookiesRequired>
              Ensure that cookies are enabled because they are required to use
              iData Online.
            </CookiesRequired>
          </Form>

          {/* <p>
            Don't have an account?{" "}
            <button
            onClick={() => {
            setToggleModal(true);
            }}
            >
            Sign up now
            </button>
            </p>
	  */}
          <Modal
            onRequestClose={() => setToggleModal(false)}
            isOpen={toggleModal}
            contentLabel="Sign up"
            style={modalStyles}
          >
            <Header>
              <div>Sign Up for iData Online</div>
              <button
                type="button"
                onClick={() => {
                  setToggleModal(false);
                }}
              >
                <img src={closeIcon} alt="close icon" />
              </button>
            </Header>
            <Frame>
              {/*             <ZohoForm /> */}
              {/* <p>Zoho Lead Form is planned to be added here</p> */}
            </Frame>
          </Modal>
        </div>
      </Wrapper>
    );
  }
};

const Wrapper = styled.div`
  scroll-margin-top: 6rem;
  border: 1px solid ${(props) => props.theme.colors.darkGrey};
  text-align: center;
  padding: 7%;

  @media screen and (max-width: ${(props) => props.theme.bp.lg}px) {
    margin: 10rem auto;
  }

  @media screen and (max-width: 560px) {
    margin: 10rem 1rem;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  max-width: 45rem;
  width: 100%;

  @media screen and (max-width: ${(props) => props.theme.bp.xs}px) {
    padding: 2.5rem;
  }

  @media screen and (max-width: 600px) {
    padding: 0;
  }
`;

const LoginIcon = styled.img`
  width: 10rem;
`;

const LoginTitle = styled.h2`
  font-size: 4rem;
  font-weight: 500;
  margin: 2rem 0;
`;

const Input = styled.input<InputProps>`
  background-image: url(${(props) => props.icon});
  background-position: left 2rem top 50%;
  font-size: 2.5rem;
  padding: 1rem 5.5rem;
  height: 5rem;
  width: 100%;

  &:not(:last-child) {
    margin-bottom: 2rem;
  }
`;

const Error = styled.p`
  color: ${(props) => props.theme.colors.red};
  font-size: 1.4rem;
  font-weight: 700rem;
`;

const AuthLinks = styled.div`
  display: flex;
  justify-content: flex-end;
  font-size: 1.8rem;
  padding: 1rem;
  width: 100%;
`;

const AuthLink = styled(Link)`
  color: #fff;

  &:hover {
    color: #fff;
    opacity: 0.9;
  }
`;

const CookiesRequired = styled.small`
  text-align: left;
  width: max-content;
`;

const Frame = styled.div`
  max-height: 80vh;
  max-width: 70rem;
  overflow-y: auto;
  padding: 1rem;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 1.5em;
  margin-bottom: 1em;
`;
export default LoginForm;
