import React from "react";
import { Navigate, Outlet } from "react-router-dom";

import { useAuth } from "contexts/AuthContext";

const PrivateRoutes = () => {
  const { currentUser } = useAuth();

  return (
    currentUser && currentUser.enabled ? <Outlet /> : <Navigate to="/" />
  )
}

export default PrivateRoutes;
