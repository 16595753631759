import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import axios from "axios";

import { useAuth } from "contexts/AuthContext";

import {
  getDevices,
  getUserDoc,
  resetDevices,
  updateUserDoc,
  deleteUser,
} from "../../../../../firebase";

import { Usergroup } from "types/firebase";

import { setNotification } from "redux/actions/notifications";
import { NotificationVariant } from "redux/reducers/notifications";
import { getUsergroups } from "redux/actions/admin";

import StandardButton from "components/Common/buttons/StandardButton";
import DataTables from "datatables.net";

type T = { usergroup: Usergroup | undefined };

const UserList: React.FC<T> = ({ usergroup }) => {
  const { isSales } = useAuth();

  if (usergroup) {
    return (
      <Wrapper>
        <Table id="example">
          <thead>
            <tr>
              <th>Enabled</th>
              <th>Name</th>
              <th>Email</th>
              <th>Email Verified</th>
              <th>Role</th>
              <th>Devices</th>
              <th>Last Active</th>
              <th>Added On</th>
              <th hidden={isSales()}>ID</th>
              <th hidden={isSales()}>Send Email Verification</th>
              <th hidden={isSales() || usergroup.license === "unassigned" ||
                usergroup.license === "corporate" || usergroup.license == "enterprise"}>
                Reset Devices
              </th>
              <th hidden={isSales()}>Delete</th>
            </tr>
          </thead>
          <tbody>
            {usergroup.users?.map(user => <User usergroup={usergroup} user={user} key={user.id} />)}
          </tbody>
        </Table>
      </Wrapper>
    );
  } else {
    return <></>;
  }
};

function User({ usergroup, user }) {
  const dispatch = useDispatch();
  const { isAdmin, isSales } = useAuth();

  const [enabled, setEnabled] = useState<boolean>(false);
  const [name, setName] = useState<string>("Fetching...");
  const [email, setEmail] = useState<string>("Fetching...");
  const [role, setRole] = useState<string>("Fetching...");
  const [deviceCount, setDeviceCount] = useState<any>("Fetching...");
  const [lastActive, setLastActive] = useState<string>("Fetching...");
  const [createdAt, setCreatedAt] = useState<string>("Fetching...");
  const [id, setId] = useState<string>("Fetching...");

  const license = usergroup.license;

  useEffect(() => {
    getUserDoc(user.id).then((result) => {
      if (result) {
        if (result.enabled) setEnabled(result.enabled);
        if (result.name) setName(result.name);
        if (result.email) setEmail(result.email);
        if (result.role) setRole(result.role);
        if (result.lastActive) {
          setLastActive(result.lastActive.toString());
        } else {
          setLastActive("Has not logged in yet");
        }
        if (result.createdAt) setCreatedAt(result.createdAt.seconds);
        if (result.id) setId(result.id);
      }
    });
  }, [user]);

  useEffect(() => {
    // TODO: This does not work at the moment...
    if (license === "unassigned") {
      setDeviceCount("N/A");
    } else if (license === "single" || license === "site") {
      getDeviceCount();
    } else if (license === "corporate" || license === "enterprise") {
      setDeviceCount("N/A");
    }

    function getDeviceCount() {
      getDevices("users", user.id).then((res) => {
        Promise.all(res).then((deviceRefs) => {
          setDeviceCount(deviceRefs.length);
        });
      });
    }
  }, [usergroup]);

  const handleEnabled = async (user) => {
    if (isAdmin()) {
      try {
        const obj = { enabled: !enabled };
        await updateUserDoc(obj, user);
        setEnabled(!enabled);
        dispatch(getUsergroups());
      } catch {
        dispatch(
          setNotification({
            message: "Failed to update user",
            variant: NotificationVariant.danger,
          })
        );
      }
    }
  };

  const sendEmailVerification = () => {
    if (isAdmin()) {
      axios
        .post(`/user/verify/${email}`, {
          name: name,
        })
        .then(() => {
          dispatch(
            setNotification({
              message: `Sent email verification to ${email}`,
              variant: NotificationVariant.success,
            })
          );
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  const handleResetDevices = () => {
    resetDevices("users", user.id);
    setDeviceCount(0);
  };

  const handleDelete = () => {
    if (isAdmin()) {
      if (window.confirm(`Are you sure you want to delete user: ${name}?`)) {
        deleteUser(usergroup.id, user.id);
      }
    }
  };

  const convertToDate = (ms) => {
    const num = Number(ms);

    if (isNaN(num)) {
      return ms;
    } else {
      if (Number.isInteger(num) && num > 0) {
        return (
          new Date(num).toLocaleDateString() +
          " " +
          new Date(num).toLocaleTimeString()
        );
      }
    }
  };

  return (
    <tr>
      <td><input type="checkbox" id="enabled" name="enabled" checked={enabled}
        disabled={isSales()} onChange={() => handleEnabled(user)} /></td>
      <td>{name}</td>
      <td><a href={`mailto:${email}`}>{email}</a></td>
      <td>yes</td>
      <td>{role}</td>
      <td>{deviceCount.toString()}</td>
      <td>{convertToDate(lastActive)}</td>
      <td>{convertToDate(String(+createdAt * 1000))}</td>
      <td hidden={isSales()}>{id}</td>
      <td hidden={isSales()}>
        <StandardButton type="button" size="xs"
          onClick={() => sendEmailVerification()}>
          Send</StandardButton>
      </td>
      <td hidden={isSales() || license === "unassigned" ||
        license === "corporate" || license == "enterprise"}>
        <StandardButton type="button" size="xs"
          onClick={handleResetDevices}>
          Reset Devices</StandardButton>
      </td>
      <td hidden={isSales()}><StandardButton type="button" size="xs" onClick={handleDelete}>Delete</StandardButton></td>
    </tr>
  );
}

const Wrapper = styled.div`
  overflow: auto;
`;

const Table = styled.table`
  table-layout: auto;
  border-collapse: collapse;
  margin-top: 1rem;
  width: 100%;

  tbody td {
    text-align: center;
  }

  button {
    margin: 0 auto;
  }

  th, td {
    padding: 1rem;  
    border: 1px solid black;
  }

  tr:nth-child(odd) td{
    background-color: ${(props) => props.theme.colors.grey};
  }
`;

export default UserList;
