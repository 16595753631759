import React, { useState } from "react";
import Modal from "react-modal";
import { modalStyles } from "styles/modals";

import StandardButton from "components/Common/buttons/StandardButton";
import Inquiry from "components/Common/forms/Inquiry";

Modal.setAppElement("#root");

const RequestConsultationButton = () => {
  const [toggleModal, setToggleModal] = useState(false);

  return (
    <>
      <StandardButton
        type="button"
        size="md"
        color="green"
        onClick={() => setToggleModal(true)}
      >
        Request a Consultation
      </StandardButton>
      <Modal
        onRequestClose={() => setToggleModal(false)}
        isOpen={toggleModal}
        contentLabel="Request a Consultation"
        style={modalStyles}
      >
        <Inquiry
          setToggleModal={setToggleModal}
          modalTitle="Request a Consultation"
        />
      </Modal>
    </>
  );
};

export default RequestConsultationButton;
