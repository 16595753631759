import axios from "axios";
import { Dispatch } from "redux";

import { setErrors } from "./errors";
import { setNotification } from "./notifications";
import { NotificationVariant } from "../reducers/notifications";
import { Usergroup } from "types/firebase";
import { SubDownload } from "types/reports";

export const FETCH_SUBSCRIPTIONS = "FETCH_SUBSCRIPTIONS";
export const FETCH_MEDSKU_SUBSCRIPTIONS = "FETCH_MEDSKU_SUBSCRIPTIONS";
export const FETCH_PT_SUBSCRIPTIONS = "FETCH_PT_SUBSCRIPTIONS";
export const FETCH_SURVEY_SUBSCRIPTIONS = "FETCH_SURVEY_SUBSCRIPTIONS";
export const CLEAR_SUBSCRIPTIONS = "CLEAR_SUBSCRIPTIONS";

export const fetchSubscriptions: any = (
  id: string,
  setLoading?: (loading: boolean) => void
) => async (dispatch: Dispatch) => {
  try {
    const { data } = await axios.get(`/subscriptions/company/${id}`);

    dispatch({
      type: FETCH_SUBSCRIPTIONS,
      payload: data,
    });

    if (setLoading) {
      setLoading(false);
    }
  } catch (error) {
    setErrors(error);
  }
};

export const clearSubscriptions = () => (dispatch: Dispatch) => {
  dispatch({
    type: CLEAR_SUBSCRIPTIONS,
  });
};

export const updateSubscriptions = (
  usergroup: Usergroup,
  update: any[]
) => async (dispatch: Dispatch) => {
  try {
    await axios.post(`/subscriptions/company/${usergroup.id}`, update);

    dispatch(
      setNotification({
        message: `Updated subscriptions for ${usergroup.company}. Reload the page to update the summary.`,
        variant: NotificationVariant.primary,
      })
    );

    dispatch(fetchSubscriptions(usergroup.id));
  } catch (error) {
    setErrors(error);
  }
};

export const updateSubscriptionDownloads = (
  companyId: string,
  update: SubDownload
) => async (dispatch: Dispatch) => {
  try {
    await axios.patch(`/subscriptions/company/${companyId}`, update);

    // refetch subscriptions
    dispatch(fetchSubscriptions(companyId));
  } catch (error) {
    setErrors(error);
  }
};

export const fetchMedSKUSubscriptions: any = (
  id: string,
  setLoading?: (loading: boolean) => void
) => async (dispatch: Dispatch) => {
  try {
    const { data } = await axios.get(`/medsku/subscriptions/company/${id}`);

    // Rename medsku_id to report_id so that it works with isSubscribed function
    data.forEach(function (o) {
      // https://stackoverflow.com/a/50101979
      delete Object.assign(o, { report_id: o["medsku_id"] })["medsku_id"];
    });

    dispatch({
      type: FETCH_MEDSKU_SUBSCRIPTIONS,
      payload: data,
    });

    if (setLoading) {
      setLoading(false);
    }
  } catch (error) {
    setErrors(error);
  }
};

export const fetchPTSubscriptions: any = (
  id: string,
  setLoading?: (loading: boolean) => void
) => async (dispatch: Dispatch) => {
  try {
    const { data } = await axios.get(`/pt/subscriptions/company/${id}`);

    // Rename pt_id to report_id so that it works with isSubscribed function
    data.forEach(function (o) {
      // https://stackoverflow.com/a/50101979
      delete Object.assign(o, { report_id: o["pt_id"] })["pt_id"];
    });

    dispatch({
      type: FETCH_PT_SUBSCRIPTIONS,
      payload: data,
    });

    if (setLoading) {
      setLoading(false);
    }
  } catch (error) {
    setErrors(error);
  }
};

export const fetchSurveySubscriptions: any = (
  id: string,
  setLoading?: (loading: boolean) => void
) => async (dispatch: Dispatch) => {
  try {
    const { data } = await axios.get(`/survey/subscriptions/company/${id}`);

    // Rename pt_id to report_id so that it works with isSubscribed function
    data.forEach(function (o) {
      // https://stackoverflow.com/a/50101979
      delete Object.assign(o, { report_id: o["survey_id"] })["survey_id"];
    });

    dispatch({
      type: FETCH_SURVEY_SUBSCRIPTIONS,
      payload: data,
    });

    if (setLoading) {
      setLoading(false);
    }
  } catch (error) {
    setErrors(error);
  }
};

export const updateMedSKUSubscriptions = (
  usergroup: Usergroup,
  update: any[]
) => async (dispatch: Dispatch) => {
  try {
    await axios.post(`/medsku/subscriptions/company/${usergroup.id}`, update);

    dispatch(
      setNotification({
        message: `Updated MedSKU subscriptions for ${usergroup.company}. Reload the page to update the summary.`,
        variant: NotificationVariant.primary,
      })
    );

    // dispatch(fetchSubscriptions(usergroup.id));
  } catch (error) {
    setErrors(error);
  }
};

export const updatePTSubscriptions = (
  usergroup: Usergroup,
  update: any[]
) => async (dispatch: Dispatch) => {
  try {
    await axios.post(`/pt/subscriptions/company/${usergroup.id}`, update);

    dispatch(
      setNotification({
        message: `Updated PT subscriptions for ${usergroup.company}. Reload the page to update the summary.`,
        variant: NotificationVariant.primary,
      })
    );

    // dispatch(fetchSubscriptions(usergroup.id));
  } catch (error) {
    setErrors(error);
  }
};

export const updateSurveySubscriptions = (
  usergroup: Usergroup,
  update: any[]
) => async (dispatch: Dispatch) => {
  try {
    await axios.post(`/survey/subscriptions/company/${usergroup.id}`, update);

    dispatch(
      setNotification({
        message: `Updated Survey subscriptions for ${usergroup.company}. Reload the page to update the summary.`,
        variant: NotificationVariant.primary,
      })
    );

    // dispatch(fetchSubscriptions(usergroup.id));
  } catch (error) {
    setErrors(error);
  }
};

// Update headquarter company subscriptions with subsidiaryCompanies subscriptions
export const syncSubscriptionsHQ = async (
  headquarterId: string | undefined,
  subsidiaryCompanies: string[] | undefined
) => {
  try {
    if (headquarterId === undefined || subsidiaryCompanies === undefined)
      throw new Error(
        "Either headquarterId or subsidiaryCompanies is undefined"
      );

    await axios.post("/subscriptions/syncHQ", {
      headquarterId,
      subsidiaryCompanies,
    });
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error(error.message);
    }
  }
};
