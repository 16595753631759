import { AnyAction } from "redux";
import {
  FETCH_CUSTOM_REPORT_DATA,
  FETCH_CUSTOM_REPORT_METADATA,
  UPDATE_CUSTOM_REPORT_CONTENTS,
  SET_CUSTOM_REPORT_SEARCH,
  SET_CUSTOM_SCROLL_POSITIONS,
  SET_CUSTOM_CURRENT_CHAPTER,
  RESET_CUSTOM_REPORT_SEARCH,
  RESET_CUSTOM_REPORT_DATA,
  SET_CUSTOM_SEARCH_POSITION,
} from "../actions/custom-report";

const initialCustomReportState = {
  reportData: {},
  chapterData: [],
  contentData: [],
  currentChapter: 1,
  search: {
    query: "",
    chapters: [],
    scrollPositions: [],
    position: 1,
  },
  metadata: [],
};

const customReportReducer = (
  state = initialCustomReportState,
  action: AnyAction
) => {
  switch (action.type) {
    case FETCH_CUSTOM_REPORT_DATA:
      return {
        ...state,
        ...action.payload,
      };
    case FETCH_CUSTOM_REPORT_METADATA:
      return {
        ...state,
        metadata: action.payload,
      };
    case UPDATE_CUSTOM_REPORT_CONTENTS:
      return {
        ...state,
        contentData: action.payload,
      };
    case SET_CUSTOM_REPORT_SEARCH:
      return {
        ...state,
        search: {
          ...state.search,
          ...action.payload,
        },
      };
    case SET_CUSTOM_SCROLL_POSITIONS:
      return {
        ...state,
        search: {
          ...state.search,
          scrollPositions: action.payload,
        },
      };
    case SET_CUSTOM_CURRENT_CHAPTER:
      return {
        ...state,
        currentChapter: action.payload,
      };
    case RESET_CUSTOM_REPORT_SEARCH:
      return {
        ...state,
        search: initialCustomReportState.search,
      };
    case RESET_CUSTOM_REPORT_DATA:
      return initialCustomReportState;
    case SET_CUSTOM_SEARCH_POSITION:
      return {
        ...state,
        search: {
          ...state.search,
          position: action.payload,
        },
      };
    default:
      return state;
  }
};

export default customReportReducer;
