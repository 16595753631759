/** ACCESS KEY 
//  0: not logged in / browse only
//  1: standard user
//  2: team leader
//  3: admin
//  4: publications
//  5: sales
**/

export const navbar = [
  {
    menu: {
      access: ["standard", "team leader", "admin", "publications", "sales"],
      name: "Reports",
      path: "/catalog/all-reports",
      key: "report",
      childAccess: [
        "standard",
        "team leader",
        "admin",
        "publications",
        "sales",
      ],
      childMenu: [
        {
          access: ["standard", "team leader", "admin", "publications", "sales"],
          name: "Subscriptions",
          path: "/catalog/subscriptions",
          key: "subscriptions",
        },
        {
          access: ["standard", "team leader", "admin", "publications", "sales"],
          name: "Favorites",
          path: "/catalog/favorites",
          key: "favorites",
        },
      ],
    },
  },
  {
    menu: {
      access: ["standard", "team leader", "admin", "publications", "sales"],
      name: "MedSKU",
      path: "/medsku/all-medsku",
      key: "medsku",
      childAccess: [
        "standard",
        "team leader",
        "admin",
        "publications",
        "sales",
      ],
      childMenu: [
        {
          access: ["standard", "team leader", "admin", "publications", "sales"],
          name: "Subscriptions",
          path: "/medsku/subscriptions",
          key: "subscriptions",
        },
        {
          access: ["standard", "team leader", "admin", "publications", "sales"],
          name: "Favorites",
          path: "/medsku/favorites",
          key: "favorites",
        },
      ],
    },
  },
  {
    menu: {
      access: ["standard", "team leader", "admin", "publications", "sales"],
      name: "Procedure T",
      path: "/pt/all-procedure-tracker",
      key: "pt",
      childAccess: [
        "standard",
        "team leader",
        "admin",
        "publications",
        "sales",
      ],
      childMenu: [
        {
          access: ["standard", "team leader", "admin", "publications", "sales"],
          name: "Subscriptions",
          path: "/pt/subscriptions",
          key: "subscriptions",
        },
        {
          access: ["standard", "team leader", "admin", "publications", "sales"],
          name: "Favorites",
          path: "/pt/favorites",
          key: "favorites",
        },
      ],
    },
  },
  {
    menu: {
      access: ["standard", "team leader", "admin", "publications", "sales"],
      name: "Surveys",
      path: "/survey/all-surveys",
      key: "survey",
      childAccess: [
        "standard",
        "team leader",
        "admin",
        "publications",
        "sales",
      ],
      childMenu: [
        {
          access: ["standard", "team leader", "admin", "publications", "sales"],
          name: "Subscriptions",
          path: "/survey/subscriptions",
          key: "subscriptions",
        },
        {
          access: ["standard", "team leader", "admin", "publications", "sales"],
          name: "Favorites",
          path: "/survey/favorites",
          key: "favorites",
        },
      ],
    },

  },
  {
    menu: {
      access: [],
      name: "News",
      path: "https://idataresearch.com/blog/",
      key: "news",
      childAccess: [],
      childMenu: [],
      external: true,
    },
  },
  {
    menu: {
      access: [],
      name: "Translate",
      path: "/translate",
      key: "translate-reports",
      childAccess: [],
      childMenu: [],
    },
  },
  {
    menu: {
      access: [],
      name: "Sign In",
      path: "/",
      key: "signin",
      childAccess: [],
      childMenu: [],
    },
  },
  {
    menu: {
      access: ["standard", "team leader", "admin", "publications", "sales"],
      name: "Signed In User",
      path: "/dashboard/account-info",
      key: "signed-in-user",
      childAccess: [
        "standard",
        "team leader",
        "admin",
        "publications",
        "sales",
      ],
      childMenu: [
        {
          access: ["standard", "team leader"],
          name: "Account Information",
          path: "/dashboard/account-info",
          key: "account-info",
        },
        {
          access: ["admin"],
          name: "Admin Panel",
          path: "/admin-panel",
          key: "admin-panel",
        },
        {
          access: ["publications"],
          name: "Report Upload",
          path: "/report-upload",
          key: "report-upload",
        },
        {
          access: ["sales"],
          name: "Client Access",
          path: "/client-access",
          key: "client-access",
        },
        {
          access: ["sales"],
          name: "User Report",
          path: "/user-report",
          key: "user-report",
        },
        {
          access: ["standard", "team leader", "admin", "publications", "sales"],
          name: "Sign Out",
          path: "/",
          key: "sign-out",
        },
      ],
    },
  },
];
