import { AnyAction } from "redux";
import {
  FETCH_REPORT_DATA,
  UPDATE_REPORT_CONTENTS,
  GET_REPORTS_BY_TITLE,
  SET_REPORT_SEARCH,
  SET_SCROLL_POSITIONS,
  SET_CURRENT_CHAPTER,
  RESET_REPORT_SEARCH,
  FETCH_RECOMMENDATIONS,
  FETCH_UNSUBSCRIBED_DATA,
  SET_SEARCH_POSITION,
} from "../actions/report";

const initialReportState = {
  reportMeta: {
    report_id: 0,
    category_id: 0,
    sub_category_id: 0,
    report_name: "",
    report_year: 0,
    global_dashboard: 0,
  },
  chapterMeta: [],
  reportContentData: [],
  currentChapter: 1,
  search: {
    query: "",
    chapters: [],
    scrollPositions: [],
    position: 1,
  },
  recommendations: [],
  reportsWithSameName: [],
  unsubscribed: {},
};

const reportReducer = (state = initialReportState, action: AnyAction) => {
  switch (action.type) {
    case FETCH_REPORT_DATA:
      return {
        ...state,
        ...action.payload,
      };
    case FETCH_UNSUBSCRIBED_DATA:
      return {
        ...state,
        unsubscribed: action.payload,
      };
    case UPDATE_REPORT_CONTENTS:
      return {
        ...state,
        reportContentData: action.payload,
      };
    case GET_REPORTS_BY_TITLE:
      return {
        ...state,
        reportsWithSameName: action.payload,
      };
    case SET_REPORT_SEARCH:
      return {
        ...state,
        search: {
          ...state.search,
          ...action.payload,
        },
      };
    case SET_SCROLL_POSITIONS:
      return {
        ...state,
        search: {
          ...state.search,
          scrollPositions: action.payload,
        },
      };
    case SET_CURRENT_CHAPTER:
      return {
        ...state,
        currentChapter: action.payload,
      };
    case RESET_REPORT_SEARCH:
      return {
        ...state,
        search: initialReportState.search,
      };
    case FETCH_RECOMMENDATIONS:
      return {
        ...state,
        recommendations: action.payload,
      };
    case SET_SEARCH_POSITION:
      return {
        ...state,
        search: {
          ...state.search,
          position: action.payload,
        },
      };
    default:
      return state;
  }
};

export default reportReducer;
