import { AnyAction } from "redux";
import {
  FETCH_SUBSCRIPTIONS,
  FETCH_MEDSKU_SUBSCRIPTIONS,
  FETCH_PT_SUBSCRIPTIONS,
  FETCH_SURVEY_SUBSCRIPTIONS,
  CLEAR_SUBSCRIPTIONS,
} from "../actions/subscriptions";

const initialSubscriptionsState = {
  active: [],
  expired: [],
  medsku: [],
  pt: [],
  survey: []
};

const customReportReducer = (
  state = initialSubscriptionsState,
  action: AnyAction
) => {
  switch (action.type) {
    case FETCH_SUBSCRIPTIONS:
      return {
        medsku: state.medsku,
        pt: state.pt,
        ...action.payload,
      };
    case CLEAR_SUBSCRIPTIONS:
      return initialSubscriptionsState;
    case FETCH_MEDSKU_SUBSCRIPTIONS:
      return {
        ...state,
        medsku: action.payload,
      };
    case FETCH_PT_SUBSCRIPTIONS:
      return {
        ...state,
        pt: action.payload,
      };
    case FETCH_SURVEY_SUBSCRIPTIONS:
      return {
        ...state,
        survey: action.payload,
      };
    default:
      return state;
  }
};

export default customReportReducer;
