import { User } from "types/firebase";
import { SubscriptionData, Subscription, ExpSubscription } from "types/reports";

export const isSubscribed = (
  subscriptions: SubscriptionData,
  reportId: number,
  user: User,
  property: string
) => {
  if (user.role === "admin" || user.role === "sales") return true;
  if (!user.enabled) return false;

  return subscriptions[property]
    .map((sub: Subscription) => sub.report_id)
    .includes(reportId);
};

export const isSubscription = (
  subscriptions: SubscriptionData,
  item: Subscription | ExpSubscription
) => {
  const subs = subscriptions.active.map((s) => s.report_id);
  return subs.includes(item.report_id);
};
