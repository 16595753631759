import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { useAuth } from "contexts/AuthContext";
import {
  fetchCustomReportMetadata,
  removeCustomReport,
} from "redux/actions/custom-report";
import { CustomReport } from "types/custom";
import trashIcon from "assets/icons/trash-icon-dark-lg.svg";
import { generateReportUrl } from "utils/report.utils";
import { RootState } from "redux/reducers";

import Spinner from "components/Common/misc/Spinner";
import { generatePageTitle } from "utils/general.utils";

const CustomReports = () => {
  const dispatch = useDispatch();
  const { currentUser } = useAuth();
  const customReports = useSelector((state: RootState) => state.customReport);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    document.title = generatePageTitle("Custom Reports");

    if (customReports.metadata.length === 0) {
      dispatch(
        fetchCustomReportMetadata([currentUser.usergroupData.id], setLoading)
      );
    } else {
      setLoading(false);
    }
  }, [currentUser.usergroupData.id, customReports.metadata.length, dispatch]);

  const handleDelete = (id: number) => {
    if (
      window.confirm(
        "Are you sure you wish to delete this report? This action cannot be reversed."
      )
    ) {
      dispatch(removeCustomReport(id, currentUser.usergroupData.id));
    }
  };

  return loading ? (
    <Spinner />
  ) : (
    <Container>
      <SectionContainer>
        <SectionTitle>{currentUser.company}'s Custom Reports</SectionTitle>
        <Section>
          <SectionList>
            {customReports.metadata.length > 0
              ? customReports.metadata.map((customReport: CustomReport) => {
                  const url = generateReportUrl(customReport.c_report_name);
                  const created = customReport.created_on
                    ? new Date(customReport.created_on).toLocaleDateString()
                    : "N/A";
                  const modified = customReport.modified_on
                    ? new Date(customReport.modified_on).toLocaleDateString()
                    : "N/A";
                  const chapterCount =
                    customReport.collections && customReport.collections.length;
                  const owned = customReport.user_email === currentUser.email;

                  if (customReport.user_email !== "support@idataresearch.net") {
                    return (
                      <SectionListItem key={customReport.c_report_id}>
                        <div>
                          <Link
                            to={`/custom-reports/${customReport.c_report_id}/${url}/1`}
                            target="_blank"
                          >
                            {customReport.c_report_name}
                          </Link>
                          <SubText>
                            Chapters: {chapterCount}
                            {" | "}
                            Created on {created} by {customReport.user_email}
                            {" | "}
                            Last modified on {modified}
                          </SubText>
                        </div>
                        {owned && (
                          <Control
                            onClick={() =>
                              handleDelete(customReport.c_report_id)
                            }
                          >
                            <img src={trashIcon} alt="trash icon" />
                          </Control>
                        )}
                      </SectionListItem>
                    );
                  } else {
                    return <></>;
                  }
                })
              : "No custom reports to display"}
          </SectionList>
        </Section>
      </SectionContainer>
    </Container>
  );
};

const Container = styled.div`
  margin: 0 auto;
  max-width: 90rem;
  width: 100%;
`;

const SectionContainer = styled.div`
  margin-bottom: 5rem;
`;

const SectionTitle = styled.h2`
  color: ${(props) => props.theme.colors.idataDarkGrey};
  font-size: 3.5rem;
  font-weight: 700;
  margin: 2rem 0;
`;

const Section = styled.div`
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.3);
  padding: 1rem 2rem;
`;

const SectionList = styled.ul`
  font-size: 2.4rem;
`;

const SectionListItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:not(:last-child) {
    margin-bottom: 2rem;
  }
`;

const SubText = styled.p`
  color: ${(props) => props.theme.colors.lightBlue};
  font-size: 1.6rem;
  margin: 0;
`;

const Control = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  transition: all 0.2s;
  width: 3.5rem;

  &:hover {
    opacity: 0.5;
  }
`;

export default CustomReports;
