import React, { useState, useEffect } from "react";
import { Dispatch } from "redux";
import { useDispatch } from "react-redux";
import styled, { css } from "styled-components";
import { FirebaseError } from "@firebase/util";

import { useAuth } from "contexts/AuthContext";
import { updateUserDoc, getUsergroupUsers } from "../../../../../firebase";
import generatePaginationGroupings from "utils/generatePaginationGroupings";
import leftArrow from "assets/icons/chevron_left-black-24dp.svg";
import rightArrow from "assets/icons/chevron_right-black-24dp.svg";
import { setNotification } from "redux/actions/notifications";
import { NotificationVariant } from "redux/reducers/notifications";

import InviteUserForm from "./InviteUserForm";
import TeamMember from "./TeamMember";
import StandardButton from "../../../../Common/buttons/StandardButton";
import Spinner from "components/Common/misc/Spinner";

import { generatePageTitle } from "utils/general.utils";

type ButtonProps = { readonly disabled: boolean };
type TeamMemberType = { id: string; enabled: boolean };

const TeamManagement: React.FC = () => {
  const { currentUser } = useAuth();
  const dispatch: Dispatch = useDispatch();
  const [users, setUsers] = useState<any>([]);
  const [team, setTeam] = useState<TeamMemberType[]>([]);
  const [groupings, setGroupings] = useState<number[][]>([[0, 0]]);
  const [selectedGroup, setSelectedGroup] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const activeUsers = team.filter((m) => m.enabled).length;

  useEffect(() => {
    document.title = generatePageTitle("Team Management");
    getUsergroupUsers(currentUser.usergroupData.users).then((result) => {
      setUsers(result);
      const members = result.map((user: any) => {
        return {
          id: user.id,
          enabled: user.enabled,
        };
      });

      setTeam(members);
      setLoading(false);
    });

    // if (currentUser.usergroupData) {
    setGroupings(
      generatePaginationGroupings(currentUser.usergroupData.users, 10)
    );
    // }
  }, [currentUser]);

  const handleFilter = (user: any, i: number) => {
    if (
      currentUser.usergroupData.users.length > 10 &&
      groupings[selectedGroup]
    ) {
      const firstIndex = groupings[selectedGroup][0];
      const lastIndex = groupings[selectedGroup][1];

      return i > firstIndex && i <= lastIndex;
    }

    return true;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (activeUsers > currentUser.usergroupData.licenses) {
      dispatch(
        setNotification({
          message:
            "License limit reached, please contact support, or disable users to free up additional licenses.",
          variant: NotificationVariant.danger,
        })
      );
      return;
    }

    try {
      // update firebase users enabled status
      for (let member of team) {
        if (member.id !== currentUser.id) {
          const data = { enabled: member.enabled };
          const user = { id: member.id };
          await updateUserDoc(data, user);
        }
      }

      dispatch(
        setNotification({
          message: "Team access updated",
          variant: NotificationVariant.primary,
        })
      );

      window.location.reload();
    } catch (error) {
      if (error instanceof FirebaseError) {
        throw new Error(error.message);
      }
    }
  };

  return loading ? (
    <Spinner />
  ) : (
    <Container>
      <SubHeading>Team Management</SubHeading>
      <InviteUserForm usergroup={currentUser.usergroupData} />
      <form onSubmit={handleSubmit}>
        <Table>
          <thead>
            <TableRow>
              <TableHead>
                <div className="th-data">Name</div>
              </TableHead>
              <TableHead>
                <div className="th-data">Email</div>
              </TableHead>
              <TableHead>
                <div>Enabled</div>
              </TableHead>
            </TableRow>
          </thead>
          <tbody>
            {users.filter(handleFilter).map((user) => {
              return (
                <TeamMember
                  key={user.email}
                  user={user}
                  team={team}
                  setTeam={setTeam}
                />
              );
            })}
          </tbody>
          <tfoot>
            <tr>
              <FooterData>Active Users: {activeUsers}</FooterData>
              <FooterData>
                No. of Licenses: {currentUser.usergroupData.licenses}
              </FooterData>
              <FooterData>
                <PaginationControl
                  onClick={() => setSelectedGroup(selectedGroup - 1)}
                  disabled={groupings[selectedGroup][0] <= 0}
                >
                  <img src={leftArrow} alt="left arrow icon" />
                </PaginationControl>
                <PaginationControl
                  onClick={() => setSelectedGroup(selectedGroup + 1)}
                  disabled={
                    groupings[selectedGroup][1] >=
                    currentUser.usergroupData.users.length
                  }
                >
                  <img src={rightArrow} alt="right arrow icon" />
                </PaginationControl>
              </FooterData>
            </tr>
          </tfoot>
        </Table>
        <p>
          Only <strong>Enabled</strong> users are able to view reports
        </p>
        <p>
          The number of Team Members you can have <strong>Enabled</strong>{" "}
          depends on the <strong>No. of Licenses</strong> your company has
        </p>
        <p>
          Contact your sales representative to change the{" "}
          <strong>No. of Licenses</strong>
        </p>
        <CustomButton type="submit" size="sm">
          Save
        </CustomButton>
      </form>
    </Container>
  );
};

const Container = styled.div`
  margin: auto;
  max-width: 90rem;
  width: 100%;
`;

const SubHeading = styled.h2`
  color: ${(props) => props.theme.colors.idataDarkGrey};
  font-size: 3.5rem;
  font-weight: 700;
  margin: 2rem 0;
  margin-right: 2rem;
`;

const Table = styled.table`
  border-collapse: collapse;
  border-style: hidden;
  border-radius: 4px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.3);
  color: ${(props) => props.theme.colors.idataDarkGrey};
  overflow: hidden;
  text-align: left;
  width: 100%;
`;

const TableRow = styled.tr`
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  &:hover td {
    background-color: ${(props) => props.theme.colors.grey};
  }
`;

const TableHead = styled.th`
  background-color: #fff;
  padding: 1.5rem 2rem;

  .th-data {
    border-right: 1px solid grey;
  }
`;

const FooterData = styled.td`
  background-color: #fff;
  padding: 1.5rem 2rem;
  padding-top: 5rem;
`;

const disabledStyles = () => css`
  opacity: 0.3;
  pointer-events: none;
`;

const PaginationControl = styled.button<ButtonProps>`
  ${(props) => (props.disabled ? disabledStyles() : null)};
`;

const CustomButton = styled(StandardButton)`
  margin-top: 5rem;
`;

export default TeamManagement;
