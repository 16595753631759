import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { useAuth } from "contexts/AuthContext";
import { RootState } from "redux/reducers";
import { sendContactUsMessage } from "redux/actions/admin";
import { categories } from "data/menus/formOptions";
import { clearErrors } from "redux/actions/errors";

import closeIcon from "assets/icons/close-black.svg";

import SelectInput from "./SelectInput";
import TextAreaInput from "./TextAreaInput";
import StandardButton from "components/Common/buttons/StandardButton";
import { ANALYST, CONSULTATION } from "data/email";

type InquiryProps = {
  setToggleModal: Function;
  modalTitle: string;
};

export const Inquiry: React.FC<InquiryProps> = ({
  setToggleModal,
  modalTitle,
}) => {
  const dispatch = useDispatch();
  const { currentUser } = useAuth();
  const errors = useSelector((state: RootState) => state.errors);

  const [category, setCategory] = useState<string>("");
  const [message, setMessage] = useState<string>("");

  let subject: string,
    other: string = "";

  useEffect(() => {
    return () => {
      dispatch(clearErrors());
    };
  }, [dispatch]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    dispatch(clearErrors());

    if (modalTitle === "Request a Consultation") {
      subject = CONSULTATION;
    } else if (modalTitle === "Ask an Analyst") {
      subject = ANALYST;
      other = window.location.href;
    }

    const userMessage = {
      name: currentUser.name,
      email: currentUser.email,
      phone: currentUser.phone ? currentUser.phone : "n/a",
      title: currentUser.title ? currentUser.title : "n/a",
      company: currentUser.company,
      category,
      message,
      subject,
      other,
    };

    dispatch(sendContactUsMessage(userMessage, setToggleModal));
  };

  return (
    <Container onSubmit={handleSubmit}>
      <Header>
        <div>{modalTitle}</div>
        <button
          onClick={() => {
            setToggleModal();
          }}
        >
          <img src={closeIcon} alt="close icon" />
        </button>
      </Header>
      <FormElement>
        <SelectInput
          name="category"
          options={categories}
          value={category}
          onChange={(e) => setCategory(e.target.value)}
        />
      </FormElement>
      <FormElement>
        <TextAreaInput
          name="message"
          placeholder="Your message... *"
          onChange={(e) => setMessage(e.target.value)}
          value={message}
          error={errors.message}
        />
      </FormElement>
      <ButtonContainer>
        <StandardButton type="submit" size="md">
          Submit
        </StandardButton>
      </ButtonContainer>
    </Container>
  );
};

const Container = styled.form`
  padding: 2rem;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 1.5em;
  margin-bottom: 1em;
`;

const FormElement = styled.div`
  margin: 4rem 0;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export default Inquiry;
