import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { RootState } from "redux/reducers";
import { createUserAuthorizationRequest } from "redux/actions/admin";
import { clearErrors } from "redux/actions/errors";
import { Usergroup } from "types/firebase";

import StandardButton from "../../../../Common/buttons/StandardButton";

type FormProps = {
  readonly toggleForm: boolean;
};

type T = { usergroup: Usergroup };

const InviteUserForm: React.FC<T> = ({ usergroup }) => {
  const dispatch = useDispatch();
  const { errors } = useSelector((state: RootState) => state);
  const [toggleForm, setToggleForm] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");

  useEffect(() => {
    return () => {
      dispatch(clearErrors());
    };
  }, [dispatch]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    dispatch(clearErrors());

    const newUser = {
      email,
      company: usergroup.company,
    };

    dispatch(createUserAuthorizationRequest(newUser, usergroup));
  };

  return (
    <Container>
      <ButtonLink onClick={() => setToggleForm(!toggleForm)}>
        Invite Team Member +
      </ButtonLink>
      <Wrapper toggleForm={toggleForm}>
        <div>
          <h3>How Team Members are Invited</h3>
          <ol>
            <li>Invite your Team Member by email</li>
            <li>
              iData Research will decide whether to approve or deny the
              invitation
            </li>
            <li>
              If invitation is approved, your Team Member will receive an email
              with a link to complete the registration process
            </li>
          </ol>
        </div>
        <Form toggleForm={toggleForm} onSubmit={handleSubmit}>
          <Input
            type="email"
            placeholder="Enter an email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <StandardButton type="submit" size="sm" color="green">
            Send Invite
          </StandardButton>
          <Error>{errors.email}</Error>
        </Form>
      </Wrapper>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
`;

const Wrapper = styled.div<FormProps>`
  display: ${(props) => (props.toggleForm ? "flex" : "none")};
  justify-content: space-between;
`;

const ButtonLink = styled.button`
  display: flex;
  justify-content: flex-end;
  color: ${(props) => props.theme.colors.coolBlue};
  font-weight: 700;
  margin: 5px 0;

  &:hover {
    opacity: 0.9;
    text-decoration: underline;
  }
`;

const Input = styled.input`
  background-color: #fff;
  border: 1px solid ${(props) => props.theme.colors.grey};
  font-size: 2rem;
  padding: 1rem 2.5rem;
  height: 5rem;
  margin-bottom: 1rem;

  &:active,
  &:focus {
    outline: none;
  }
`;

const Form = styled.form<FormProps>`
  display: ${(props) => (props.toggleForm ? "flex" : "none")};
  flex-direction: column;
  align-items: flex-end;
  margin-top: 2rem;
`;

const Error = styled.p`
  color: ${(props) => props.theme.colors.red};
  font-size: 1.4rem;
  font-weight: 700rem;
`;

export default InviteUserForm;
