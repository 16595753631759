import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { RootState } from "redux/reducers";

import NewListSubMenu from "./NewListSubMenu";

const NewFullCatalogList = function NewFullCatalogList({ productType, add }) {
  let catalogType = productType;

  if (productType === "report") {
    catalogType = "catalog";
  }

  const catalog = useSelector((state: RootState) => state[catalogType]);

  const handleFilterCat = (cat) => cat.sub_categories.length > 0;
  const handleFilterSub = (sub) => sub.collections.length > 0;

  function hasEntries(sub_category): boolean {
    for (const iterator of sub_category) {
      if (iterator.collections.length > 0) {
        return true;
      }
    }
    return false;
  }

  return (
    <>
      <ul>
        {catalog.categories.filter(handleFilterCat).map((c, index) => (
          // Categories
          <li key={productType + "_" + c.category_id + "_" + index}>
            {c.sub_categories.length > 0 && hasEntries(c.sub_categories) && (
              <>
                <div>{c.category_name}</div>
                <ul>
                  {c.sub_categories.filter(handleFilterSub).map((s, s_index) => (
                    // Subcategories
                    <NewListSubMenu
                      key={productType + "_" + s.sub_category_id + "_" + s_index}
                      productType={productType}
                      s={s}
                      add={add}
                    />
                  ))}
                </ul>
              </>
            )}

          </li>
        ))}
      </ul>
    </>
  );
};

const CategoryName = styled.div`
  font-weight: bold;
`;

export default NewFullCatalogList;
