import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import styled from "styled-components";

import { RootState } from "redux/reducers";
import { UserInvite } from "types/admin";
import { getAuthRequests, approveAuthRequest } from "redux/actions/admin";
import { generatePageTitle } from "utils/general.utils";

const AdminPanelHome: React.FC = () => {
  const dispatch = useDispatch();
  const admin = useSelector((state: RootState) => state.admin);

  useEffect(() => {
    document.title = generatePageTitle("Admin Panel");
    dispatch(getAuthRequests());
  }, [dispatch]);

  const handleAllow = async (req: UserInvite) => {
    dispatch(approveAuthRequest(req));
  };

  const handleDeny = async (req: UserInvite) => {
    if (window.confirm("Are you sure you wish to deny this request?")) {
      await axios.delete(
        `/usergroups/team-management/auth-requests/${req.pending_id}`
      );
      dispatch(getAuthRequests());
    }
  };

  return (
    <div>
      <PageTitle>Pending User Authorization Requests</PageTitle>
      <ul>
        {admin.authRequests.length > 0 ? (
          admin.authRequests.map((req: UserInvite) => (
            <Item key={req.pending_id}>
              <ItemText>
                <TextBlue>{req.company}</TextBlue> is requesting access for{" "}
                <TextBlue>{req.email}</TextBlue>
              </ItemText>
              <ButtonLinkGreen onClick={() => handleAllow(req)}>
                Allow
              </ButtonLinkGreen>{" "}
              |
              <ButtonLinkRed onClick={() => handleDeny(req)}>
                Deny
              </ButtonLinkRed>
            </Item>
          ))
        ) : (
          <li>
            <em>There are no pending authorization requests</em>
          </li>
        )}
      </ul>
    </div>
  );
};

const PageTitle = styled.h2`
  color: ${(props) => props.theme.colors.idataDarkGrey};
  margin-bottom: 3rem;
`;

const Item = styled.li`
  margin-bottom: 2rem;
  max-width: 50rem;
  padding: 1rem 0;

  &:not(:last-child) {
    border-bottom: 1px solid ${(props) => props.theme.colors.grey};
  }
`;

const ItemText = styled.p`
  margin: 0;
`;

const TextBlue = styled.span`
  color: ${(props) => props.theme.colors.coolBlue};
`;

const ButtonLinkGreen = styled.button`
  color: ${(props) => props.theme.colors.green};
  font-weight: 700;

  &:hover {
    opacity: 0.9;
    text-decoration: underline;
  }
`;

const ButtonLinkRed = styled(ButtonLinkGreen)`
  color: ${(props) => props.theme.colors.red};
`;

export default AdminPanelHome;
