import React from "react";
import styled from "styled-components";

import helpIcon from "assets/icons/help.svg";

interface TipProps {
  bottom: number;
  right: number;
  width: number;
}

const Tip: React.FC<{
  text: string;
  bottom: number;
  right: number;
  width: number;
}> = ({ text, bottom, right, width }) => {
  // https://www.w3schools.com/css/tryit.asp?filename=trycss_tooltip

  return (
    <Container>
      <img className="tip" src={helpIcon} alt="help" />
      <TextSpan bottom={bottom} right={right} width={width}>
        {text}
      </TextSpan>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  position: relative;

  &:hover span {
    visibility: visible;
  }
`;

const TextSpan = styled.span<TipProps>`
  font-size: 2rem;
  visibility: hidden;
  background-color: ${(props) => props.theme.colors.idataBlue};
  color: white;
  padding: 10px;
  border-radius: 5px;
  width: ${(props) => props.width}px;

  position: absolute;
  z-index: 1;
  bottom: ${(props) => props.bottom}px;
  right: ${(props) => props.right}px;
`;

export default Tip;
