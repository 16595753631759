import React, { useState } from "react";
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack';
import styled from "styled-components";

import StandardButton from "components/Common/buttons/StandardButton";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const TableOfContents = ({ url }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const handleClick = (key: string) => {
    if (key === "prev") {
      setPageNumber(pageNumber - 1);
    } else {
      setPageNumber(pageNumber + 1);
    }
  };

  if (url) {
    return (
      <Container>

        <Document file={url} onLoadSuccess={onDocumentLoadSuccess}>
          <Page renderAnnotationLayer={false} pageNumber={pageNumber} />
        </Document>

        <p>
          Page {pageNumber} of {numPages}
        </p>

        <ButtonsDiv>
          <StandardButton
            type="button"
            size="sm"
            color="blue"
            disabled={pageNumber === 1}
            onClick={() => handleClick("prev")}
          >
            Previous
          </StandardButton>
          <StandardButton
            type="button"
            size="sm"
            color="blue"
            disabled={pageNumber === numPages}
            onClick={() => handleClick("next")}
          >
            Next
          </StandardButton>
        </ButtonsDiv>

      </Container>
    );
  } else {
    return <></>;
  }
};

const Container = styled.div`
  color: ${(props) => props.theme.colors.idataDarkGrey};
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
  width: 100%;

  canvas {
    box-shadow: 0px 0px 2px rgb(0 0 0 / 20%);
  }
`;

const ButtonsDiv = styled.div`
  display: flex;
  justify-content: space-between;

  button {
    &:not(:last-child) {
      margin-right: 1rem;
    }
  }
`;

export default TableOfContents;
