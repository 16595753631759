import { AnyAction } from "redux";
import {
  GET_SEARCH_RESULTS,
  SET_SEARCH_FILTERS,
  RESET_SEARCH_FILTERS,
  // SET_POSITION
} from "../actions/search";

const initialSearchState = {
  query: "",
  results: [],
  filters: {
    sortBy: "new",
    category: "all",
    region: "all",
  },
};

const searchReducer = (state = initialSearchState, action: AnyAction) => {
  switch (action.type) {
    case GET_SEARCH_RESULTS:
      return {
        query: action.payload.query,
        results: action.payload.results,
        filters: {
          ...state.filters,
        },
      };
    case SET_SEARCH_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.type]: action.payload.value,
        },
      };
    case RESET_SEARCH_FILTERS:
      return {
        ...state,
        filters: initialSearchState.filters,
      };
    // case SET_POSITION:
    //   return {
    //     ...state,
    //     position: action.payload.value,
    //   };
    default:
      return state;
  }
};

export default searchReducer;
