import React from "react";
import styled from "styled-components";

import Placeholder from "assets/images/CategoryThumbnails/placeholder.png";
import Arthroscope from "assets/images/CategoryThumbnails/ReportIcon-Arthroscope-MS.jpg";
import Bandage from "assets/images/CategoryThumbnails/ReportIcon-Bandage-MS.jpg";
import Bed from "assets/images/CategoryThumbnails/ReportIcon-Bed-MS.jpg";
import Brain from "assets/images/CategoryThumbnails/ReportIcon-Brain-MS.jpg";
import Cell from "assets/images/CategoryThumbnails/ReportIcon-Cell-MS.jpg";
import Computer from "assets/images/CategoryThumbnails/ReportIcon-Computer-MS.jpg";
import Cosmo from "assets/images/CategoryThumbnails/ReportIcon-Cosmo-MS.jpg";
import Dental from "assets/images/CategoryThumbnails/ReportIcon-Dental-MS.jpg";
import Diabetes from "assets/images/CategoryThumbnails/ReportIcon-Diabetes-MS.jpg";
import Ear from "assets/images/CategoryThumbnails/ReportIcon-Ear-MS.jpg";
import ENT from "assets/images/CategoryThumbnails/ReportIcon-ENT-MS.jpg";
import Eye from "assets/images/CategoryThumbnails/ReportIcon-Eye-MS.jpg";
import Foot from "assets/images/CategoryThumbnails/ReportIcon-Foot-MS.jpg";
import Heart from "assets/images/CategoryThumbnails/ReportIcon-Heart-MS.jpg";
import iDataPharma from "assets/images/CategoryThumbnails/ReportIcon-iDataPharma-MS.jpg";
import Kidneys from "assets/images/CategoryThumbnails/ReportIcon-Kidneys-MS.jpg";
import Knee from "assets/images/CategoryThumbnails/ReportIcon-Knee-MS.jpg";
import MRI from "assets/images/CategoryThumbnails/ReportIcon-MRI-MS.jpg";
import OxygenTank from "assets/images/CategoryThumbnails/ReportIcon-OxygenTank-MS.jpg";
import PatientMonitoring from "assets/images/CategoryThumbnails/ReportIcon-PatientMonitoring-MS.jpg";
import Pharma from "assets/images/CategoryThumbnails/ReportIcon-Pharma-MS.jpg";
import Screw from "assets/images/CategoryThumbnails/ReportIcon-Screw-MS.jpg";
import Skull from "assets/images/CategoryThumbnails/ReportIcon-Skull-MS.jpg";
import Spine from "assets/images/CategoryThumbnails/ReportIcon-Spine-MS.jpg";
import Stomach from "assets/images/CategoryThumbnails/ReportIcon-Stomach-MS.jpg";
import Vascular from "assets/images/CategoryThumbnails/ReportIcon-Vascular-MS.jpg";
import Video from "assets/images/CategoryThumbnails/ReportIcon-Video-MS.jpg";
import WomensHealth from "assets/images/CategoryThumbnails/ReportIcon-WomensHealth-MS.jpg";

const Thumbnails = {
  Placeholder,
  Arthroscope,
  Bandage,
  Bed,
  Brain,
  Cell,
  Computer,
  Cosmo,
  Dental,
  Diabetes,
  ENT,
  Ear,
  Eye,
  Foot,
  Heart,
  iDataPharma,
  Kidneys,
  Knee,
  MRI,
  OxygenTank,
  PatientMonitoring,
  Pharma,
  Screw,
  Skull,
  Spine,
  Stomach,
  Vascular,
  Video,
  WomensHealth,
};

type ReportThumbnailProps = {
  icon: string;
};

const ReportThumbnail: React.FC<ReportThumbnailProps> = ({ icon }) => (
  <React.Fragment>
    <Thumbnail
      src={icon ? Thumbnails[icon] : Thumbnails["Placeholder"]}
      alt=""
    />
  </React.Fragment>
);

const Thumbnail = styled.img`
  display: block;
  width: 100%;
  margin: auto;

  @media screen and (max-width: 425px) {
    display: none;
  }
`;

export default ReportThumbnail;
