import React from "react";
import styled, { css } from "styled-components";

interface IButtonProps {
  children: React.ReactNode;
  type: "submit" | "reset" | "button";
  outline?: boolean;
  size?: string;
  color?: string;
  mt?: string;
  onClick?: any;
  disabled?: boolean;
  id?: string;
  value?;
}

const StandardButton = ({ children, ...otherProps }: IButtonProps) => (
  <Button {...otherProps}>{children}</Button>
);

const buttonColors = (color: string = "") => {
  switch (color) {
    case "red":
      return css`
        ${(props) => props.theme.colors.red}
      `;
    case "green":
      return css`
        ${(props) => props.theme.colors.green}
      `;
    case "grey":
      return css`
        ${(props) => props.theme.colors.darkGrey}
      `;
    default:
      return css`
        ${(props) => props.theme.colors.idataBlue}
      `;
  }
};

const buttonSizes = (size: string = "") => {
  switch (size) {
    case "xs":
      return css`
        font-size: 1.6rem;
        width: 10rem;
      `;
    case "sm":
      return css`
        width: 15rem;
      `;
    case "md":
      return css`
        width: 25rem;
      `;
    default:
      return css`
        width: 100%;
      `;
  }
};

const outlineStyles = (color: string = "") => {
  return css`
    background-color: transparent;
    border: 1px solid ${buttonColors(color)};
    color: ${buttonColors(color)};

    &:hover {
      background-color: ${buttonColors(color)};
      color: #fff;
    }
  `;
};

const disabledStyles = () => {
  return css`
    pointer-events: none;
    visibility: hidden;
  `;
};

const Button = styled.button<IButtonProps>`
  background-color: ${(props) => buttonColors(props.color)};
  display: flex;
  align-items: center;
  justify-content: center;
  grid-column: 1 / -1;
  font-size: 2rem;
  margin-top: ${(props) => (props.mt ? props.mt : "0")};
  padding: 1rem;
  height: 5rem;
  transition: all 0.2s;
  ${(props) => buttonSizes(props.size)};

  &:hover {
    opacity: 0.9;
  }

  ${(props) => (props.outline ? outlineStyles(props.color) : null)}
  ${(props) => (props.disabled ? disabledStyles() : null)}
`;

export default StandardButton;
