import React from "react";
import styled from "styled-components";

import { ReactComponent as CloseIcon } from "assets/icons/close-black.svg";
import { ReactComponent as HamburgerIcon } from "assets/icons/Hamburger-Menu-Icon.svg";

type IButtonProps = {
  toggleMenu: boolean;
  onClick: Function;
  className?: string;
};

const MobileButton = ({
  toggleMenu,
  onClick,
  className = "",
}: IButtonProps) => {
  if (toggleMenu) {
    return (
      <Button
        className={className}
        toggleMenu={toggleMenu}
        onClick={() => onClick()}
      >
        <CloseIcon fill="black" />
      </Button>
    );
  } else {
    return (
      <Button
        className={className}
        toggleMenu={toggleMenu}
        onClick={() => onClick()}
      >
        <HamburgerIcon fill="black" />
      </Button>
    );
  }
};

const Button = styled.button<{ toggleMenu: boolean }>`
  background-color: ${(props) =>
    props.toggleMenu
      ? props.theme.colors.lighterGrey
      : props.theme.colors.grey};
  border-radius: 50%;
  padding-top: 5px;

  & svg {
    height: 48px;
    width: 48px;
  }
`;

export default MobileButton;
