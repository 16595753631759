import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const FooterMenu: React.FC = () => (
  <Container>
    <FooterMenuItem>
      <a
        href="https://idataresearch.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        idataresearch.com
      </a>
    </FooterMenuItem>
    <FooterMenuItem>
      <Link to="/contact">Contact</Link>
    </FooterMenuItem>
    <FooterMenuItem>
      <Link to="/help">Help</Link>
    </FooterMenuItem>
  </Container>
);

const Container = styled.ul`
  display: flex;
  flex-wrap: wrap;
  // margin: 1rem;
`;

const FooterMenuItem = styled.li`
  padding: 0 1rem;

  &:not(:last-child) {
    border-right: 1px solid #fff;
  }

  a {
    color: #fff;
    &:hover {
      color: ${(props) => props.theme.colors.grey};
    }
  }
`;

export default FooterMenu;
