import React, { useState } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";

import addIcon from "assets/icons/add-black-sm.svg";
import removeIcon from "assets/icons/remove-black-sm.svg";

import { RootState } from "redux/reducers";
import { addRecommendation, removeRecommendation } from "redux/actions/report";

const RecommendButton: React.FC<{ reportId: number }> = ({ reportId }) => {
  const dispatch = useDispatch();
  const { recommending, report_id_rec_for } = useSelector(
    (state: RootState) => state.admin
  );
  const { recommendations } = useSelector((state: RootState) => state.report);

  // Set local recommend state based on recommendations
  const [isRecommendation, setRecommendation] = useState(
    recommendations.map((rec: any) => rec.rec_report_id).includes(reportId)
  );

  let hidden = true;

  if (recommending) hidden = false;

  if (reportId === report_id_rec_for) hidden = true;

  const handleRecommend = () => {
    if (isRecommendation) {
      setRecommendation(false);
      // check if report exists in report's recommendations
      const exists = recommendations.find(
        (rec: any) => rec.rec_report_id === reportId
      );
      if (exists) {
        // delete recommendation
        dispatch(removeRecommendation(exists.rec_id, report_id_rec_for));
      }
    } else {
      setRecommendation(true);

      // add recommendation
      dispatch(addRecommendation(reportId, report_id_rec_for));
    }
  };

  return (
    <Button hidden={hidden} onClick={handleRecommend}>
      <img src={isRecommendation ? removeIcon : addIcon} alt="recommendation" />
    </Button>
  );
};

const Button = styled.button`
  color: black;

  & img {
    width: 24px;
    height: 24px;
  }
`;

export default RecommendButton;
