// generate category lists
// parameters: subscriptions (current), setCategoryList,
// setSubCategoryList,

import { ProductType } from "types/catalog";

export const generateCategoryLists = (
  reports,
  catalogMenu,
  filters,
  setCategoryList,
  setSubCategoryList
) => {
  const categoryIds: number[] = [];
  // Category
  // Get subscription category ids
  reports.forEach((element) => {
    categoryIds.push(element.category_id);
  });

  // Remove duplicates and undefined
  let uniqueCategoryIds = Array.from(new Set(categoryIds)).filter(function (
    element
  ) {
    return element !== undefined;
  });

  // Filter catalogMenu by subscription category ids
  let filtered = catalogMenu.filter((item) =>
    uniqueCategoryIds.includes(item.categoryId)
  );

  setCategoryList(filtered);

  // Subcategory
  let subCategories: [
    { subCategoryId: number; text: string; url: string } | undefined
  ] = [undefined];

  reports.forEach((sub) => {
    // Get category item from catalogMenu with the same category id as subscribed report
    const catalogCategoryItem = catalogMenu.find(
      (element) => sub.category_id === element.categoryId
    );

    // If category is the same as the currently filtered category,
    if (catalogCategoryItem && catalogCategoryItem.text === filters.category) {
      // Add subcategory item that matches current subscription's subcategory id
      subCategories.push(
        catalogCategoryItem.subCategories.find(
          (element) => sub.sub_category_id === element.subCategoryId
        )
      );
    }
  });

  // Remove duplicates and undefined
  if (subCategories.length > 0) {
    let filtered = subCategories.filter(function (element) {
      return element !== undefined;
    });

    setSubCategoryList(Array.from(new Set(filtered)));
  }
};

export const matchFilterHeights = () => {
  // Make Filters same height
  // get height of map
  const mapFilter = document.getElementById("MapFilter");
  const mapHeight = mapFilter?.offsetHeight;

  if (mapFilter && window.getComputedStyle(mapFilter).display !== "none") {
    // set height of category filters to map height
    document
      .getElementById("CatalogCategoryFilter")
      ?.setAttribute("style", `height: ${mapHeight}px`);
    // adjust filters height so that there is some padding
    document
      .getElementById("CatalogFilterLists")
      ?.setAttribute("style", `height: 82%`);
  }
};

export function handleMarqueeHeading() {
  // https://codepen.io/Jaskaranbir/pen/JKNgMb
  const heading1 = document.getElementById("heading1");
  const heading2 = document.getElementById("heading2");

  const container = document.querySelector(
    "#CatalogCategoryFilter"
  );
  if (container !== null) {
    const computedStyle = getComputedStyle(container);
    let containerWidth = container?.clientWidth;
    containerWidth -=
      parseFloat(computedStyle.paddingLeft) +
      parseFloat(computedStyle.paddingRight);

    if (containerWidth < document.getElementById("heading1")!.offsetWidth) {
      heading2!.style.display = "inline-block";
      heading1!.style.animationName = "marquee1";
      heading2!.style.animationName = "marquee2";
    } else {
      heading2!.style.display = "none";
      heading1!.style.animationName = "";
      heading2!.style.animationName = "";
    }
  }
}

export function getPrettyProductType(productType: ProductType) {
  switch (productType) {
    case "medsku":
     return "MedSKU";
    case "pt":
     return "Procedure Tracker";
    default:
      return "";
  }
}