import React from "react";
import styled from "styled-components";

const TeamMember = ({ user, team, setTeam }) => {
  const member = team.find((m) => m.id === user.id);
  const enabled = member?.enabled;

  const handleSelect = (e) => {
    const userId = e.target.name;
    const member = team.find((m) => m.id === userId);
    const enabled = member?.enabled;
    let status = false;

    if (!enabled) status = true;

    setTeam([
      ...team.filter((m) => m.id !== userId),
      { id: userId, enabled: status },
    ]);
  };

  return (
    <TableRow key={user.id}>
      <TableData>{user.name}</TableData>
      <TableData>{user.email}</TableData>
      <TableData className="td-last">
        <Checkbox
          type="checkbox"
          name={user.id}
          onChange={handleSelect}
          checked={enabled ? true : false}
        />
      </TableData>
    </TableRow>
  );
};

const TableRow = styled.tr`
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  &:hover td {
    background-color: ${(props) => props.theme.colors.grey};
  }
`;

const TableData = styled.td`
  background-color: #fff;
  padding: 1.5rem 2rem;

  &.td-last {
    width: 12rem;
  }
`;

const Checkbox = styled.input`
  height: 1.75rem;
  width: 1.75rem;
`;

export default TeamMember;
