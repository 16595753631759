import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { useAuth } from "contexts/AuthContext";
import { RootState } from "redux/reducers";
import { sendContactUsMessage } from "redux/actions/admin";
import { clearErrors } from "redux/actions/errors";
import { categories } from "data/menus/formOptions";

import SelectInput from "components/Common/forms/SelectInput";
import TextAreaInput from "components/Common/forms/TextAreaInput";
import StandardButton from "components/Common/buttons/StandardButton";
import { CUSTOM_RESEARCH } from "data/email";

import { generatePageTitle } from "utils/general.utils";

const CustomResearchRequest: React.FC = () => {
  const dispatch = useDispatch();
  const errors = useSelector((state: RootState) => state.errors);
  const { currentUser } = useAuth();
  const [category, setCategory] = useState<string>("");
  const [message, setMessage] = useState<string>("");

  useEffect(() => {
    document.title = generatePageTitle("Custom Research Request");
    return () => {
      dispatch(clearErrors());
    };
  }, [dispatch]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    dispatch(clearErrors());

    const userMessage = {
      name: currentUser.name,
      email: currentUser.email,
      phone: currentUser.phone,
      title: currentUser.title,
      company: currentUser.company,
      category,
      message,
      subject: CUSTOM_RESEARCH,
      other: "",
    };

    dispatch(sendContactUsMessage(userMessage));
  };

  return (
    <form onSubmit={handleSubmit}>
      <ProfileSectionTitle>Custom Research Request</ProfileSectionTitle>
      <Container>
        <FormElement>
          <SelectInput
            name="category"
            options={categories}
            value={category}
            onChange={(e) => setCategory(e.target.value)}
          />
        </FormElement>
        <FormElement>
          <TextAreaInput
            name="message"
            placeholder="Your message... *"
            onChange={(e) => setMessage(e.target.value)}
            value={message}
            error={errors.message}
          />
        </FormElement>
        <ButtonContainer>
          <StandardButton type="submit" size="md">
            Save
          </StandardButton>
        </ButtonContainer>
      </Container>
    </form>
  );
};

const Container = styled.div`
  background-color: #fff;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.3);
  padding: 2.5rem;
`;

const ProfileSectionTitle = styled.h2`
  color: ${(props) => props.theme.colors.idataDarkGrey};
  font-size: 3.5rem;
  font-weight: 700;
  margin: 2rem 0;
`;

const FormElement = styled.div`
  margin-bottom: 2rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export default CustomResearchRequest;
