import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { RootState } from "redux/reducers";

import SortOptions from "./SortOptions";
import SearchResults from "./SearchResults";

import { generatePageTitle } from "utils/general.utils";

const Search = () => {
  const { search } = useSelector((state: RootState) => state);
  const [categories, setCategories] = useState<string[]>([]);

  useEffect(() => {
    document.title = generatePageTitle("Search");
  });

  useEffect(() => {
    setCategories([]);
  }, [search.query]);

  return (
    <Container>
      <SortOptions results={search.results.length} categories={categories} />
      <Title>Search Results: "{search.query}"</Title>
      <SubText>Your search was found in the following report(s):</SubText>
      <SearchResults categories={categories} setCategories={setCategories} />
    </Container>
  );
};

const Container = styled.div`
  background-color: #fff;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.3);
  padding: 2rem;
  margin: 2rem auto;
  min-height: 100rem;
  max-width: 110rem;
`;

const Title = styled.h2`
  color: ${(props) => props.theme.colors.idataBlue};
  font-size: 3.5rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
`;

const SubText = styled.p`
  font-size: 2.5rem;
  margin-top: 0;
`;

export default Search;
