import React, { useEffect } from "react";
import styled from "styled-components";

import { generatePageTitle } from "utils/general.utils";

const bucket = process.env.REACT_APP_S3_BUCKET_URL;
const imgPath = bucket + "/AdminPanelHelp";

const Help: React.FC = () => {
  useEffect(() => {
    document.title = generatePageTitle("Help");
  });

  return (
    <div>
      <PageTitle>Admin Panel Help</PageTitle>
      <ul>
        <li>
          <a href="#user-requests">User requests</a>
        </li>
        <li>
          <a href="#uploading-reports">Uploading reports</a>
        </li>
        <li>
          <a href="#managing-reports">Managing reports</a>
        </li>
        <li>
          <a href="#adding-users">Adding companies/users</a>
        </li>
        <li>
          <a href="#updating-subscriptions">
            Updating expiry, licenses, and subscriptions
          </a>
        </li>
      </ul>
      <HelpSection id="user-requests">
        <SectionTitle>User requests</SectionTitle>
        <p>
          New user requests will appear in the <em>Home</em> tab of the admin
          panel. You have the option to
          <strong> Allow</strong> or <strong>Deny</strong> the request.
        </p>
        <p>
          User requests will also automatically be emailed to{" "}
          <em>support@idataresearch.net</em>, and can be allowed via email as
          well <em>(example below)</em>.
        </p>
        <Figure>
          <Img src={`${imgPath}/auth-req-1.png`} alt="image" />
          <Caption>
            The requesting company, as well as the email of the new user are
            shown in the request
          </Caption>
        </Figure>
        <Figure>
          <Img src={`${imgPath}/auth-req-2.png`} alt="image" />
          <Caption>
            Accepting user requests via email is as simple as one click
          </Caption>
        </Figure>
      </HelpSection>
      <HelpSection>
        <SectionTitle id="uploading-reports">Uploading reports</SectionTitle>
        <p>
          To upload a new report, go to the <em>Report Upload</em> tab of the
          admin panel. Here you can fill out the form to create a new report,
          and upload the .zip folder at the same time.
        </p>
        <Steps>
          <li>
            <em>Checklist:</em>
          </li>
          <li>
            - Make sure that the title is exactly how you want it displayed in
            the catalog
          </li>
          <li>- Select a category from the available categories</li>
          <li>- Select a sub category from the available sub categories</li>
          <li>- Select the year the report is from</li>
          <li>- Browse for and attach the report .zip folder</li>
          <li>
            - Click <strong>Upload</strong>, and wait a few moments for the
            report to be uploaded
          </li>
          <li>
            - You should see a 'Report uploaded successfully' notification when
            the report is finished uploading
          </li>
        </Steps>
        <Figure>
          <Img src={`${imgPath}/report-upl-1.png`} alt="image" />
          <Caption>
            Double check all fields are correct before uploading!
          </Caption>
        </Figure>
      </HelpSection>
      <HelpSection id="managing-reports">
        <SectionTitle>Managing reports</SectionTitle>
        <p>
          Visit the <em>Manage Reports</em> tab of the admin panel if you want
          to see a full list of reports that are available in the database.
        </p>
        <p>
          In this section, you can decide to Show or Hide reports from new users
          (though if someone is already subscribed to a report, they will still
          be able to see it).
        </p>
        <p>
          You can also delete reports from the database.{" "}
          <em>Beware - this cannot be undone!</em>
        </p>
        <p>
          Also, you won't be able to delete a report that has active
          subscribers. You must first deactivate all attached subscriptions.
        </p>
        <Figure>
          <Img src={`${imgPath}/manage-reports-1.png`} alt="image" />
          <Caption>
            Each report shows No. of subscribers, Show/Hide option, and delete
            option
          </Caption>
        </Figure>
      </HelpSection>
      <HelpSection id="adding-users">
        <SectionTitle>Adding companies/users</SectionTitle>
        <p>
          In order to add a new user, you must first add that user's company if
          it doesn't already exist. In the event there is just a user and no
          'company', this is fine, you still need to create that user as his or
          her own company before adding them. The reason for this is that
          subscriptions in iData Online version 2 are tied to the company and{" "}
          <em>not</em> the user's themselves.
        </p>
        <h4>Step 1: Create company (if it doesn't exist)</h4>
        <Figure>
          <Img src={`${imgPath}/manage-clients-1.png`} alt="image" />
          <Caption>The description field is optional</Caption>
        </Figure>
        <p>
          Once the company is created, you can begin adding users to it. In the
          second form, you can add a new user to the database, by filling out
          the required details, and selecting the company you just created from
          the dropdown menu under 'Select a Company'. This will attach the user
          to that company, and thus all of it's subscriptions!
        </p>
        <h4>Step 2: Add the new user</h4>
        <Figure>
          <Img src={`${imgPath}/manage-clients-2.png`} alt="image" />
          <Caption>
            Only Name, Field, and Company fields are required for new users
          </Caption>
        </Figure>
        <p>
          You have the option of making a new user a <em>Team Leader</em>. These
          users are able to add, enable, and disable other users within their
          company. Keep in mind that if you do not select Team Leader, the user
          will have to be
          <em>enabled</em> by either an iData Admin, or a company Team Leader.
        </p>
        <Figure>
          <Img src={`${imgPath}/manage-clients-3.png`} alt="image" />
          <Caption>
            Select team leader if you want to allow that user to add additional
            users to their company
          </Caption>
        </Figure>
        <p>
          In addition to this, you will see a <em>Sync Subscriptions</em>{" "}
          option, select this option if you are entering a user from the old
          iData Online and want to bring forward their current subscriptions.
          Please note that it is usually best to only do this for team leaders!
        </p>
        <p>
          Finally, create a password for the new user. You can generate a
          strong, random password using the button, or create your own. This
          password will have to be emailed to the user in order for them to log
          in. It's recommended that they change their password after first
          logging in as well.
        </p>
        <Figure>
          <Img src={`${imgPath}/manage-client-4.png`} alt="image" />
          <Caption>
            Passwords are encrypted and saved in Firebase. So make sure not to
            lose this password if possible.
          </Caption>
        </Figure>
      </HelpSection>
      <HelpSection id="updating-subscriptions">
        <SectionTitle>
          Updating expiry, licenses, and subscriptions
        </SectionTitle>
        <p>
          The <em>Client Access</em> tab in the admin panel allows you to modify
          a company's expiry date, numbers of licenses, view all of it's users,
          enable/disable users, view all current subscriptions, and
          subscribe/unsubscribe reports.
        </p>
        <p>
          To get started, select the desired company from the drop down menu.
        </p>
        <Figure>
          <Img src={`${imgPath}/client-access-1.png`} alt="image" />
          <Caption>
            All companies stored in the database are listed in alphabetical
            order.
          </Caption>
        </Figure>
        <h4>Section 1: Update expiry/licenses</h4>
        <p>
          The first section lets you select a new expiry date. Click the
          calendar icon to select any date. You may also set the number of user
          licenses for this company. For example: 10 licenses would allow 10
          different users (including Team Leaders) to be enabled to view the
          subscribed reports.
        </p>
        <Figure>
          <Img src={`${imgPath}/client-access-2.png`} alt="image" />
          <Caption>Make sure to click Update to save changes</Caption>
        </Figure>
        <h4>Section 2: List of company users</h4>
        <p>
          Below Section 1 is a list of all the users attached to this company:
          including their email address, role (team leader or standard) and
          enabled status. You can toggle any user's enabled status on and off
          here.
        </p>
        <Figure>
          <Img src={`${imgPath}/client-access-3.png`} alt="image" />
          <Caption>
            When toggling Enabled status, the database is updated automatically
            when you click it
          </Caption>
        </Figure>
        <h4>Section 3: Summary of Subscribed Reports</h4>
        <p>
          The top right section shows a list of currently subscribed reports for
          this company. Click <em>View Summary </em>
          to access the dropdown list.
        </p>
        <Figure>
          <Img src={`${imgPath}/client-access-4.png`} alt="image" />
          <Caption>
            This is one item in a list of currently subscribed reports
          </Caption>
        </Figure>
        <p>
          For each subscribed report, you will see the title, and two controls.
        </p>
        <Steps>
          <li>
            <strong>Download limit - </strong>allows you to set a limit for how
            many times the user can download this report in PDF format
          </li>
          <li>
            <strong>Downloads used - </strong>lets you see how many times that
            report has already been download, and allows you to reset the
            counter back to 0 if you so choose
          </li>
        </Steps>
        <p>
          <em>
            Make sure to click Save beside each report that you've made changes
            to!
          </em>
        </p>
        <h4>Section 4: List of all reports</h4>
        <p>
          The final section is a list of all reports in the database. Each
          grouped by category and sub category. Reports with a selected checkbox
          beside them are ones the client is already subscribed to. Deselect a
          report to unsubscribe the client, and select reports that you wish to
          subscribe them to.
        </p>
        <Figure>
          <Img src={`${imgPath}/client-access-5.png`} alt="image" />
          <Caption>
            Expand categories/sub categories to view reports related to them
          </Caption>
        </Figure>
        <p>
          Once you are finished making changes to the subscriptions, you must
          Save the changes.
        </p>
        <Figure>
          <Img src={`${imgPath}/client-access-6.png`} alt="image" />
          <Caption>This menu is found below the report list</Caption>
        </Figure>
        <p>Here is what each control does in detail:</p>
        <Steps>
          <li>
            <strong>Save - </strong>saves the current changes and updates the
            client's subscriptions based on what is selected above
          </li>
          <li>
            <strong>Refresh - </strong>returns all selections to their initial
            state (won't work if you already clicked Save)
          </li>
          <li>
            <strong>Unsubscribe All - </strong>removes ALL subscriptions for
            this user. Use with caution!
          </li>
        </Steps>
      </HelpSection>
    </div>
  );
};

const PageTitle = styled.h2`
  color: ${(props) => props.theme.colors.idataDarkGrey};
  margin-bottom: 3rem;
`;

const HelpSection = styled.div`
  margin-top: 5rem;
  border-bottom: 1px solid ${(props) => props.theme.colors.grey};

  p,
  li {
    max-width: 75rem;
  }
`;

const SectionTitle = styled.h3`
  color: ${(props) => props.theme.colors.idataDarkGrey};
`;

const Figure = styled.figure`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5rem 0;
  max-width: 75rem;
`;

const Steps = styled.ul`
  li {
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }
`;

const Img = styled.img`
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.3);
  width: 100%;
`;

const Caption = styled.figcaption`
  font-style: italic;
  margin-top: 1rem;
`;

export default Help;
