import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { setFilters } from "redux/actions/catalog";
import { RootState } from "redux/reducers";

import { matchFilterHeights } from "utils/catalog.utils";

const regions = [
  { name: "North America", id: "north-america-button" },
  { name: "Latin America", id: "latin-america-button" },
  { name: "Europe", id: "europe-button" },
  { name: "Middle East", id: "middle-east-button" },
  { name: "Africa", id: "africa-button" },
  { name: "Asia Pacific", id: "asia-pacific-button" },
  { name: "Global", id: "global-list-button" },
];

interface MapFilterListProps {
  props?: any;
  hidden: boolean;
}

// Asset and process files in \\server2\Company\IT\iData Online\Code-Assets\Map Filter
// There is a path for each of the regions and the entire world for Global
// Everytime the category and subcategory is changed, possibleRegions is updated
// Only the regions in possibleRegions can be interacted with
const MapFilterList = ({ props, hidden }: MapFilterListProps) => {
  const dispatch = useDispatch();
  const { catalog } = useSelector((state: RootState) => state);
  const [region, setRegion] = useState("All");
  const [hoveredRegion, setHoveredRegion] = useState("");
  const [prettyRegion, setPrettyRegion] = useState("None");
  const [disabledRegions, setDisabledRegions] = useState([
    { name: "", id: "" },
  ]);

  const hoverColor = "#376187"; // idataDarkBlue
  const selectedColor = "#1D83B7"; // idataBlue
  const defaultColor = "#ffffff"; // darkGrey
  const regionsWNoReportColor = "#e4e4e4"; // darkGrey

  matchFilterHeights();

  const handleMouseEnter = (e) => {
    if (e.target.id !== region) {
      // disabled
      if (
        isDisabledRegion(e.target.id) &&
        !catalog.possibleRegions.includes("Global")
      ) {
        document
          .getElementById(e.target.id)
          ?.setAttribute(
            "style",
            `background-color: ${
              region === "Global" ? selectedColor : "white"
            }; cursor: not-allowed; color: ${selectedColor};`
          );
      } else {
        // possible
        setHoveredRegion(e.target.id);
        // Style target id
        document
          .getElementById(e.target.id)
          ?.setAttribute(
            "style",
            `background-color: ${hoverColor}; color: white;`
          );
      }
    }
  };

  const handleMouseLeave = () => {
    // disabled
    if (
      isDisabledRegion(hoveredRegion) &&
      !catalog.possibleRegions.includes("Global")
    ) {
      document
        .getElementById(hoveredRegion)
        ?.setAttribute(
          "style",
          `background-color: ${
            region === "Global" ? selectedColor : regionsWNoReportColor
          }; cursor: not-allowed; color: white;`
        );
    } else {
      // possible
      document
        .getElementById(hoveredRegion)
        ?.setAttribute(
          "style",
          `background-color: ${
            region === "Global" ? selectedColor : defaultColor
          }; color: ${region === "Global" ? "white" : selectedColor};`
        );
    }
  };

  // Tests whether at least one disabled region matches the region passed by id
  const isDisabledRegion = (id: string) => {
    return disabledRegions.some((disabledRegion) => disabledRegion.id === id);
  };

  const resetHighlight = useCallback(() => {
    regions.forEach((region) => {
      document
        .getElementById(region.id)
        ?.setAttribute("style", `background-color: ${defaultColor}`);
    });
  }, []);

  const disableRegions = useCallback(() => {
    if (!catalog.possibleRegions.includes("Global")) {
      resetHighlight();

      // filter out possibleRegions and Global
      const regionsCopy = [...regions];
      catalog.possibleRegions.forEach((possibleRegion) => {
        const index = regionsCopy.findIndex((i) => i.name === possibleRegion);
        regionsCopy.splice(index, 1);
      });

      setDisabledRegions(regionsCopy);

      regionsCopy.forEach((region) => {
        document
          .getElementById(region.id)
          ?.setAttribute(
            "style",
            `fill: ${regionsWNoReportColor}; cursor: not-allowed;`
          );
      });

      document
        .getElementById("global-list-button")
        ?.setAttribute(
          "style",
          `color: ${regionsWNoReportColor}; cursor: not-allowed; border: solid 1px transparent;`
        );
    } else {
      document
        .getElementById("global-list-button")
        ?.setAttribute(
          "style",
          `border: solid 1px ${selectedColor}; cursor: pointer;`
        );
    }
  }, [catalog.possibleRegions, resetHighlight]);

  const global = useCallback(() => {
    if (catalog.possibleRegions.includes("Global")) {
      setRegion("Global");
      setPrettyRegion("Global");
      setHoveredRegion("");

      dispatch(setFilters("region", "Global"));

      disableRegions();

      // Highlight global SVG
      regions.forEach((region) => {
        document
          .getElementById(region.id)
          ?.setAttribute(
            "style",
            `background-color: ${selectedColor}; color: white`
          );
      });

      document
        .getElementById("global-list-button")
        ?.setAttribute(
          "style",
          `background-color: ${selectedColor}; color: white; cursor: pointer;`
        );
    }
  }, [catalog.possibleRegions, disableRegions, dispatch]);

  const resetRegions = useCallback(() => {
    setRegion("All");
    setPrettyRegion("None");

    dispatch(setFilters("region", "All"));

    resetHighlight();

    disableRegions();
  }, [disableRegions, dispatch, resetHighlight]);

  const updateRegion = useCallback(
    (regionId) => {
      setRegion(regionId);
      setHoveredRegion("");

      let key: string = "";

      const region = regions.find((region) => region.id === regionId);

      if (region) {
        // If clicked region is a part of possibleRegions, set the region accordingly
        const regionName = catalog.possibleRegions.find(
          (possibleRegion) => region.name === possibleRegion
        );

        if (regionName) {
          key = region?.name;
          dispatch(setFilters("region", key));

          resetHighlight();

          disableRegions();

          // Style the newly selected region
          document
            .getElementById(regionId)
            ?.setAttribute(
              "style",
              `background-color: ${selectedColor}; color: white`
            );

          setPrettyRegion(region?.name);
        }
      }
    },
    [catalog.possibleRegions, disableRegions, dispatch, resetHighlight]
  );

  const handleOnClick = useCallback(
    (e) => {
      updateRegion(e.target.id);
    },
    [updateRegion]
  );

  useEffect(() => {
    resetRegions();
  }, [resetRegions]);

  useEffect(() => {
    // Synchronize local region state with region in Redux store
    // Used when region is changed with MapFilter
    const storedRegion = regions.find(
      (element) => element.name === catalog.filters.region
    );
    if (storedRegion && storedRegion.id && storedRegion.id !== region) {
      setRegion(storedRegion.id);

      if (storedRegion.name === "Global") {
        global();
      } else {
        updateRegion(storedRegion.id);
      }
    } else {
      if (catalog.filters.region === "All") resetRegions();
    }
  }, [
    catalog.filters.region,
    global,
    handleOnClick,
    region,
    resetRegions,
    updateRegion,
  ]);

  return (
    <Wrapper id="MapFilterList" hidden={hidden}>
      <div>Filtering by region: {prettyRegion}</div>
      <RegionButtonsDiv>
        {regions.map((region) => {
          if (region.name !== "Global") {
            return (
              <Button
                id={region.id}
                key={region.id}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onClick={handleOnClick}
              >
                {region.name}
              </Button>
            );
          } else {
            return null;
          }
        })}
      </RegionButtonsDiv>

      <SpecialButtonsDiv>
        <Button id="global-list-button" role="button" onClick={global}>
          Global
        </Button>

        <Button role="button" onClick={resetRegions}>
          Reset
        </Button>
      </SpecialButtonsDiv>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  grid-area: map;
  display: none;

  @media screen and (max-width: 1000px) {
    display: block;
  }
`;

const Button = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  background-color: white;
  color: ${(props) => props.theme.colors.idataBlue};
  padding: 0.5rem 2rem;
  cursor: pointer;
  border-radius: 25px;
  border: solid 1px transparent;

  &:hover {
    background-color: ${(props) => props.theme.colors.idataBlue};
    color: #fff;
  }
`;

const RegionButtonsDiv = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;

  > *:not(:last-child) {
    margin-right: 1rem;
  }

  @media screen and (max-width: 800px) {
    flex-wrap: wrap;
    justify-content: flex-start;

    > *:not(:last-child) {
      margin-bottom: 1rem;
    }
  }
`;

const SpecialButtonsDiv = styled.div`
  display: flex;
  background-color: #e6e7e8;
  align-items: center;
  padding: 1rem;
  width: min-content;
  margin-top: 1rem;

  > *:not(:last-child) {
    margin-right: 1rem;
  }
`;

export default MapFilterList;
