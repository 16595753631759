import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import styled from "styled-components";

import { useAuth } from "contexts/AuthContext";
import { isSubscribed } from "utils/subscriptions.utils";
import { generateReportUrl } from "utils/report.utils";
import { RootState } from "redux/reducers";

import StandardButton from "../../../../Common/buttons/StandardButton";
import Pagination from "../../../../Common/misc/Pagination";

type ResultsProps = {
  categories: string[];
  setCategories: Function;
};

type Item = {
  category_name: string;
  chapters: number;
  report_id: number;
  report_name: string;
  report_year: number;
};

const SearchResults: React.FC<ResultsProps> = ({
  categories,
  setCategories,
}) => {
  const { currentUser } = useAuth();
  const { search, subscriptions } = useSelector((state: RootState) => state);

  const handleSort = (a, b) => {
    const { sortBy } = search.filters;

    switch (sortBy) {
      case "new":
        return b.report_year - a.report_year;
      case "old":
        return a.report_year - b.report_year;
      case "category":
        if (a.category_name.toLowerCase() < b.category_name.toLowerCase()) {
          return -1;
        }
        if (a.category_name.toLowerCase() > b.category_name.toLowerCase()) {
          return 1;
        }
        return 0;
      default:
        // chapters
        return b.chapters.length - a.chapters.length;
    }
  };

  const handleFilter = (item: Item) => {
    const { category } = search.filters;
    let display = {
      categories: true,
    };

    if (category !== "all") {
      display.categories = item.category_name === category;
    }

    if (!Object.values(display).includes(false)) {
      return item;
    }
  };

  const renderTitle = (title: string) => {
    if (title.toLowerCase().includes(search.query)) {
      const regex = new RegExp(search.query, "gi");
      const match = title.match(regex);

      if (match) {
        title = title.replace(match[0], `<strong>${match[0]}</strong>`);
      }
    }

    return title;
  };

  return (
    <ul>
      <Pagination limit={10}>
        {search.results
          .sort(handleSort)
          .filter(handleFilter)
          .map((item) => {
            const { report_id, report_name, report_year, chapters } = item;
            const subscribed = isSubscribed(
              subscriptions,
              report_id,
              currentUser,
              "active"
            );
            const url = generateReportUrl(report_name);

            // collect unique categories and regions from result
            if (categories.indexOf(item.category_name) === -1) {
              setCategories([...categories, item.category_name]);
            }

            return (
              <SearchItem key={report_id}>
                <div>
                  <Link
                    to={`/reports/${report_id}/${url}/1?term=${search.query}`}
                    state={{backUrl: "/catalog/search"}}
                    target="_blank"
                  >
                    <ResultText>
                      {parse(renderTitle(report_name))} - {report_year}
                    </ResultText>
                  </Link>
                  <ResultSubText>
                    Found in: {chapters.length} Chapter
                    {chapters.length > 1 ? "s" : ""}
                  </ResultSubText>
                </div>
                <Link
                  to={`/reports/${report_id}/${url}/1?term=${search.query}`}
                  state={{backUrl: "/catalog/search"}}
                  target="_blank"
                >
                  <StandardButton
                    type="button"
                    color={subscribed ? "blue" : "grey"}
                    outline={!subscribed}
                    size="md"
                  >
                    {subscribed ? "Subscribed" : "Not Subscribed"}
                  </StandardButton>
                </Link>
              </SearchItem>
            );
          })}
      </Pagination>
    </ul>
  );
};

const SearchItem = styled.li`
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: ${(props) => props.theme.bp.sm}px) {
    flex-direction: column;
  }
`;

const ResultText = styled.p`
  font-size: 2.5rem;
  font-weight: 400;
  margin-bottom: 1rem;
  max-width: 75rem;
`;

const ResultSubText = styled.p`
  font-size: 2rem;
  margin-top: 0;
`;

export default SearchResults;
