import axios from "axios";
import { Dispatch } from "redux";

export const FETCH_MEDSKU_CATALOG_DATA = "FETCH_MEDSKU_CATALOG_DATA";
export const SET_FILTERS = "SET_FILTERS";
export const RESET_FILTERS = "RESET_FILTERS";
export const FETCH_SUBSCRIPTIONS = "FETCH_SUBSCRIPTIONS";
export const SET_POSSIBLE_REGIONS = "SET_POSSIBLE_REGIONS";

export const fetchMedSKUCatalogData: any = (
  setLoading?: (loading: boolean) => void
) => async (dispatch: Dispatch) => {
  let data = await axios.get("/medsku/catalogs");

  dispatch({
    type: FETCH_MEDSKU_CATALOG_DATA,
    payload: data.data.categoriesData,
  });

  if (setLoading) {
    setLoading(false);
  }
};

export const resetFilters = () => async (dispatch: Dispatch) => {
  dispatch({
    type: RESET_FILTERS,
  });
};
