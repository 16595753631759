import React, { ReactNode, useState } from "react";
import styled, { css } from "styled-components";
import { RootState } from "redux/reducers";
import { useSelector } from "react-redux";

import generatePaginationGroupings from "../../../utils/generatePaginationGroupings";

import StandardButton from "components/Common/buttons/StandardButton";
import CustomReportsGuide from "components/Common/misc/CustomReportsGuide";

type PaginationProps = {
  children: Array<ReactNode>;
  limit: number;
};

type ButtonLinkProps = {
  readonly isactive: boolean;
};

const Pagination: React.FC<PaginationProps> = ({ children, limit }) => {
  const groupings = generatePaginationGroupings(children, 20);
  const [selectedGroup, setSelectedGroup] = useState<number>(0);
  const { catalog, subscriptions } = useSelector((state: RootState) => state);

  // Before and during fetching subscriptions
  if (children.length === 0) {
    if (catalog.filters.filter === "All Reports") {
      return (
        <Default>
          <p>Select any category to start browsing</p>
          <p>Contact your sales representative to subscribe to a report</p>
        </Default>
      );
    } else if (catalog.filters.filter === "Custom Reports") {
      return <CustomReportsGuide />;
    } else if (subscriptions.active.length === 0) {
      return (
        <Default>
          <p>You are not subscribed to any reports</p>
          <p>Contact your sales representative to subscribe to a report</p>
          <p>You may also browse our Research Catalog here</p>
        </Default>
      );
    }

    return <Default>No reports available</Default>;
  }

  // reset pagination if out of bounds
  if (!groupings[selectedGroup]) setSelectedGroup(0);

  if (children.length > limit && groupings[selectedGroup]) {
    const firstIndex = groupings[selectedGroup][0];
    const lastIndex = groupings[selectedGroup][1];

    return (
      <>
        {children.filter(
          (r: ReactNode, i: number) => i >= firstIndex && i <= lastIndex
        )}
        <PaginationControls>
          <Groupings>
            {groupings.map((group, index) => (
              <ButtonLink
                key={index}
                onClick={() => setSelectedGroup(index)}
                isactive={selectedGroup === index}
              >
                {index + 1}
              </ButtonLink>
            ))}
          </Groupings>
          <StandardButton
            type="button"
            size="sm"
            color="green"
            onClick={() => setSelectedGroup(selectedGroup - 1)}
            disabled={firstIndex <= 0}
          >
            previous
          </StandardButton>
          <StandardButton
            type="button"
            size="sm"
            color="green"
            onClick={() => setSelectedGroup(selectedGroup + 1)}
            disabled={lastIndex >= children.length}
          >
            next
          </StandardButton>
        </PaginationControls>
      </>
    );
  }

  return <React.Fragment>{children}</React.Fragment>;
};

const activeButtonLinkStyles = () => css`
  color: ${(props) => props.theme.colors.textGray};
  pointer-events: none;
  text-decoration: none;
`;

const PaginationControls = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 5rem;

  button {
    &:not(:last-child) {
      margin-right: 2rem;
    }
  }
`;

const Groupings = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 5rem;
  width: 100%;
`;

const ButtonLink = styled.button<ButtonLinkProps>`
  background: transparent;
  border: none;
  color: ${(props) => props.theme.colors.idataBlue};
  text-decoration: underline;

  ${(props) => (props.isactive ? activeButtonLinkStyles : null)}
`;

const Default = styled.div`
  font-size: 2.2rem;
  font-style: italic;
  text-align: center;
`;

export default Pagination;
