import {
  CustomChecklistItem,
  CustomChapterMeta,
  ChecklistMultiple,
} from "types/custom";

export const checklistItemExists = (
  checklist: CustomChecklistItem[],
  item: CustomChecklistItem
) => {
  return checklist.find((c) => {
    return (
      c.report_id === item.report_id && c.chapter_number === item.chapter_number
    );
  });
};

export const combineCustomChapters = (data: CustomChapterMeta[]) => {
  const chapterNumbers: ChecklistMultiple[] = [];
  const temp = {};

  // aggregate chapters by report name
  data.forEach((chapter) => {
    const reportName = `${chapter.report_name} (${chapter.report_year})`;

    if (temp[reportName]) {
      temp[reportName].push(chapter);
    } else {
      temp[reportName] = [chapter];
    }
    chapterNumbers.push({
      collection_id: chapter.collection_id,
      report_id: chapter.report_id,
      chapter_number: chapter.chapter_number,
      custom_order: chapter.custom_order,
    });
  });

  const collections = Object.keys(temp).map((title) => {
    return {
      title,
      chapters: temp[title],
    };
  });

  return { collections, chapterNumbers };
};

export const removeChecklistItem = (
  checklist: ChecklistMultiple[],
  item: ChecklistMultiple
) => {
  // first check if item to be removed is the last for that report
  const reportItems = checklist.filter((i) => i.report_id === item.report_id);

  if (reportItems.length === 1) {
    // this is the last item
    return false;
  }

  return checklist.filter((c) => {
    let isReport = false,
      isChapter = false;

    if (c.report_id === item.report_id) {
      isReport = true;
    }
    if (c.chapter_number === item.chapter_number) {
      isChapter = true;
    }

    return !(isReport && isChapter);
  });
};

export const createChecklist = (collections, report) => {
  return collections
    .filter((col) => col.report_id === report.reportMeta.report_id)
    .map((col) => {
      return {
        chapter_number: col.chapter_number,
        chapter_title: col.chapter_title,
      };
    });
};

export const shouldRefetchCustomReport = (customReport, id) => {
  const length = customReport.chapterData.length;
  if (length === 0) return true;

  const customReportId = customReport.reportData.c_report_id;
  const metadata = customReport.metadata.find(
    (c) => c.c_report_id === customReportId
  );
  if (!metadata) return true;
  const chapters = new Set([
    ...customReport.chapterData.map((c) => c.report_id + c.chapter_number),
    ...metadata.collections.map((c) => c.report_id + c.chapter_number),
  ]);

  return (
    length !== metadata.collections.length ||
    length !== chapters.size ||
    parseInt(id) !== customReportId
  );
};
