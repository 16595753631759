import React from "react";
import axios from "axios";
import styled from "styled-components";
import Logo from "components/Header/ChildComponents/Logo";

type ErrorState = { hasError: boolean; reportsHasError: boolean };

class ErrorBoundary extends React.Component<{}, ErrorState> {
  constructor(props) {
    super(props);
    this.state = { hasError: false, reportsHasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // Send error to server
    // error: general error message
    // errorInfo: captures which component contained the exception, and its ancestors

    const url = window.location.href;

    axios.post("/log", {
      url,
      error,
      errorInfo,
    });
  }

  render() {
    if (this.state.hasError) {
      // fallback UI

      // general
      // back to catalog
      return (
        <>
          <Header>
            <Logo />
          </Header>
          <ErrorDiv>
            <h1>Something went wrong</h1>
            <p>The error has been recorded. Sorry for the inconvenience.</p>
            <a href="/">Back to catalog</a>
          </ErrorDiv>
        </>
      );
    }

    return this.props.children;
  }
}

const Header = styled.div`
  background-color: ${(props) => props.theme.colors.idataDarkBlue};
  box-shadow: 1rem 0rem 2rem 1rem rgba(0, 0, 0, 0.3);
  display: grid;
  justify-content: space-between;
  grid-template-columns: minmax(10rem, 25rem) minmax(15rem, 45rem) max-content;
  grid-template-rows: 100%;
  grid-gap: 2rem;
  padding: 0 2rem;
  position: relative;
  z-index: 999;
  height: 100%;
  min-height: 10vh;
`;

const ErrorDiv = styled.div`
  min-height: 90vh;
  max-width: 75rem;
  margin: 0 auto;
  padding: 2rem;
`;

export default ErrorBoundary;
