import { Dispatch } from "redux";
import axios from "axios";

import { setErrors } from "./errors";
import { combineSearchData } from "utils/search.utils";

export const GET_SEARCH_RESULTS = "GET_SEARCH_RESULTS";
export const SET_SEARCH_FILTERS = "SET_SEARCH_FILTERS";
export const RESET_SEARCH_FILTERS = "RESET_SEARCH_FILTERS";
export const SET_POSITION = "SET_POSITION";

export const searchDocuments = (
  body: { query: string },
  navigate
) => async (dispatch: Dispatch) => {
  try {
    const { data } = await axios.post("/search/query", body);
    const results = combineSearchData(data.matches);
    dispatch({
      type: GET_SEARCH_RESULTS,
      payload: {
        query: body.query,
        results,
      },
    });
    dispatch(resetSearchFilters());
    navigate("/catalog/search");
  } catch (error) {
    dispatch(setErrors(error));
  }
};

export const setSearchFilters = (type: string, value: string) => async (
  dispatch: Dispatch
) => {
  dispatch({
    type: SET_SEARCH_FILTERS,
    payload: {
      type,
      value,
    },
  });
};

export const resetSearchFilters: any = () => async (dispatch: Dispatch) => {
  dispatch({
    type: RESET_SEARCH_FILTERS,
  });
};
