import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import generator from "generate-password";
import { FirebaseError } from "@firebase/util";

import { useAuth } from "contexts/AuthContext";
import { RootState } from "redux/reducers";
import { setErrors, clearErrors } from "redux/actions/errors";
import { setNotification } from "redux/actions/notifications";
import { syncSubscriptions } from "redux/actions/admin";
import { NotificationVariant } from "redux/reducers/notifications";
import { countries, categories } from "data/menus/formOptions";

import SelectInput from "components/Common/forms/SelectInput";
import StandardButton from "components/Common/buttons/StandardButton";

const NewUserForm = () => {
  const dispatch = useDispatch();
  const { adminCreateUser } = useAuth();
  const { admin, errors } = useSelector((state: RootState) => state);
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [company, setCompany] = useState<string>("");
  const [title, setTitle] = useState<string>("");
  const [country, setCountry] = useState<string>("");
  const [category, setCategory] = useState<string>("");
  const [lead, setLead] = useState<boolean>(false);
  const [sync, setSync] = useState<boolean>(false);
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const renderUsergroups = () => {
    const arr = [
      { id: 0, company: "--- Select a company ---" },
      ...admin.usergroups,
    ];

    return arr
      .sort((a, b) => a.company.localeCompare(b.company))
      .map((usergroup) => {
        return {
          id: usergroup.id,
          value: usergroup.company,
        };
      });
  };

  const handleGenerate = () => {
    const strongPassword = generator.generate({
      length: 10,
      strict: true,
      numbers: true,
      symbols: true,
    });

    setPassword(strongPassword);
    setConfirmPassword(strongPassword);
  };

  const handleReset = () => {
    setEmail("");
    setCompany("");
    setName("");
    setPhone("");
    setTitle("");
    setLead(false);
    setPassword("");
    setConfirmPassword("");
    setLoading(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(clearErrors());

    if (company === "") {
      return dispatch(
        setErrors({ selectCompany: "User must be associated with a company" })
      );
    }

    // Ensure that password entered twice match
    if (password !== confirmPassword) {
      return dispatch(setErrors({ confirmPassword: "Passwords do not match" }));
    }

    // Get company info from Redux
    const companyInfo = admin.usergroups.find((u) => u.company === company);

    if (companyInfo.license === "site") {
      if (companyInfo.users.length < 5) {
        createUser(companyInfo);
      } else {
          dispatch(
            setNotification({
              message: `Unable to add anymore users. ${companyInfo.company} is under a Site license which only allows for 5 users`,
              variant: NotificationVariant.danger,
            })
          );
      }
    } else if (companyInfo.license === "corporate" || companyInfo.license === "enterprise") {
      if (companyInfo.users.length < 100) {
        createUser(companyInfo);
      } else {
          dispatch(
            setNotification({
              message: `Unable to add anymore users. ${companyInfo.company} is under a Corporate/Enterprise license which only allows for 100 users`,
              variant: NotificationVariant.danger,
            })
          );
      }
    } else {
      createUser(companyInfo);
    }
  };

  async function createUser(companyInfo) {
    const newUser = {
      email: email.toLowerCase(),
      company,
      name,
      phone,
      title,
      country,
      category,
      lead,
      password,
      confirmPassword,
    };

    try {
      setLoading(true);
      // Create new user in Firestore
      await adminCreateUser(newUser);

      dispatch(
        setNotification({
          message: "User successfully added",
          variant: NotificationVariant.primary,
        })
      );

      // (optional) if successful, check for past subscriptions
      // on previous platform, and update accordingly
      if (sync) await syncSubscriptions({ email, companyId: companyInfo.id });

      handleReset();
    } catch (error) {
      if (error instanceof FirebaseError) {
        dispatch(setErrors({ general: error.message }));
        setLoading(false);
      }
    }
  }

  return (
    <Form onSubmit={handleSubmit}>
      <FormTitle>Add New User</FormTitle>
      <div>
        <Label htmlFor="name">Name</Label>
        <br />
        <Input
          type="text"
          placeholder="Name"
          name="name"
          onChange={(e) => setName(e.target.value)}
          value={name}
          required
        />
      </div>
      <div>
        <Label htmlFor="email">Email</Label>
        <br />
        <Input
          type="email"
          placeholder="Email"
          name="email"
          onChange={(e) => setEmail(e.target.value)}
          value={email}
          required
        />
      </div>
      <div>
        <Label htmlFor="phone">Phone</Label>
        <br />
        <Input
          type="tel"
          placeholder="Phone"
          name="phone"
          onChange={(e) => setPhone(e.target.value)}
          value={phone}
        />
      </div>
      <SelectContainer>
        <SelectInput
          name="company"
          value={company}
          options={renderUsergroups()}
          onChange={(e) => setCompany(e.target.value)}
        />
        <Error>{errors.selectCompany}</Error>
      </SelectContainer>
      <div>
        <Label htmlFor="title">Title</Label>
        <br />
        <Input
          type="text"
          placeholder="Title"
          name="title"
          onChange={(e) => setTitle(e.target.value)}
          value={title}
        />
      </div>
      <SelectContainer>
        <SelectInput
          name="country"
          value={country}
          options={countries}
          onChange={(e) => setCountry(e.target.value)}
        />
      </SelectContainer>
      <SelectContainer>
        <SelectInput
          name="category"
          value={category}
          options={categories}
          onChange={(e) => setCategory(e.target.value)}
        />
      </SelectContainer>
      <CheckboxContainer>
        <input
          name="lead"
          onChange={() => setLead(!lead)}
          type="checkbox"
          checked={lead}
        />
        <label htmlFor="lead">Team Leader?</label>
      </CheckboxContainer>
      <CheckboxContainer>
        <input
          name="sync"
          onChange={() => setSync(!sync)}
          type="checkbox"
          checked={sync}
        />
        <label htmlFor="lead">Sync Subscriptions?</label>
      </CheckboxContainer>
      <hr />
      <div>
        <GenerateButton type="button" onClick={handleGenerate}>
          Generate Secure Password
        </GenerateButton>
      </div>
      <div>
        <Label htmlFor="password">Password</Label>
        <br />
        <Input
          type="text"
          placeholder="Password"
          name="password"
          onChange={(e) => setPassword(e.target.value)}
          value={password}
          required
        />
      </div>
      <div>
        <Label htmlFor="password">Confirm Password</Label>
        <br />
        <Input
          type="text"
          placeholder="Confirm password"
          name="confirmPassword"
          onChange={(e) => setConfirmPassword(e.target.value)}
          value={confirmPassword}
          required
        />
        <Error>{errors.confirmPassword}</Error>
        <span>
          <em>Please copy this password to send to client</em>
        </span>
      </div>
      <Error>{errors.general}</Error>
      <StandardButton type="submit" size="sm" disabled={loading}>
        Add User
      </StandardButton>
    </Form>
  );
};

const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  min-width: 50rem;

  button {
    margin: 5rem auto;
  }
`;

const FormTitle = styled.h3`
  border-bottom: 2px solid ${(props) => props.theme.colors.green};
  max-width: 25rem;
  margin: 0 auto;
  padding-bottom: 1rem;
`;

const Input = styled.input`
  background-color: transparent;
  border-bottom: 2px solid ${(props) => props.theme.colors.idataDarkGrey};
  border-radius: 0;
  font-size: 1.8rem;
  padding: 0.5rem;
  height: 4rem;
  width: 80%;
`;

const SelectContainer = styled.div`
  margin-top: 1rem;

  select {
    border-bottom: 2px solid ${(props) => props.theme.colors.idataDarkGrey};
    border-radius: 0;
    padding: 0.5rem;
    width: 80%;
  }
`;

const CheckboxContainer = styled.div`
  margin-top: 2rem;
`;

const Label = styled.label`
  color: ${(props) => props.theme.colors.idataDarkGrey};
  display: none;
`;

const GenerateButton = styled.button`
  background-color: ${(props) => props.theme.colors.grey};
  border-radius: 0;
  border: 1px solid #000;
  color: #000;
  font-size: 1.4rem;
  padding: 0.5rem;
  margin: 5rem 0 0 0 !important;
`;

const Error = styled.p`
  color: ${(props) => props.theme.colors.red};
  font-size: 1.4rem;
  font-weight: 700rem;
`;

export default NewUserForm;
