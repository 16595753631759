import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { setErrors, clearErrors } from "redux/actions/errors";
import { setNotification } from "redux/actions/notifications";
import { NotificationVariant } from "redux/reducers/notifications";
import { useAuth } from "contexts/AuthContext";
import { RootState } from "redux/reducers";

import FormInput from "../../../Common/forms/FormInput";
import StandardButton from "../../../Common/buttons/StandardButton";

const RecoveryForm: React.FC = () => {
  const { resetPassword } = useAuth();
  const dispatch = useDispatch();
  const errors = useSelector((state: RootState) => state.errors);
  const [email, setEmail] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    return () => {
      dispatch(clearErrors());
    };
  }, [dispatch]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    dispatch(clearErrors());

    try {
      setLoading(true);
      await resetPassword(email);

      dispatch(
        setNotification({
          message:
            "Password recovery instructions have been sent to your email if it has been registered.",
          variant: NotificationVariant.success,
        })
      );
    } catch {
      dispatch(setErrors({ email: "Failed to send recovery email " }));
    }

    setLoading(false);
  };

  return (
    <Container onSubmit={handleSubmit}>
      <Info>Enter your email for password reset</Info>
      <FormInput
        type="email"
        name="email"
        placeholder="Email"
        value={email}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setEmail(e.target.value)
        }
        error={errors.email}
      />
      <br />
      <StandardButton type="submit" disabled={loading}>
        Reset Password
      </StandardButton>
    </Container>
  );
};

const Container = styled.form`
  background-color: #fff;
  border: 1px solid ${(props) => props.theme.colors.darkGrey};
  padding: 5.5rem;
  margin: 10rem auto;
  max-width: 50rem;
  text-align: center;

  @media screen and (max-width: ${(props) => props.theme.bp.sm}px) {
    padding: 2.5rem;
  }
`;

const Info = styled.p`
  grid-column: 1 / -1;
  margin-top: -3rem;
  padding: 2rem 0;
`;

export default RecoveryForm;
