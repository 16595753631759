import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { RootState } from "redux/reducers";

import CatalogFilterListItem from "./CatalogFilterListItem";

type CatalogFilterListProps = {
  list?: any[];
  hidden?: boolean;
  categoryLevel: string;
};

export const CatalogFilterList: React.FC<CatalogFilterListProps> = ({
  hidden,
  list,
  categoryLevel,
}) => {
  const { filters } = useSelector((state: RootState) => state.catalog);

  if (list) {
    return (
      <Container hidden={hidden}>
        {list.map((item) => (
          <CatalogFilterListItem
            categoryLevel={categoryLevel}
            item={item}
            key={item.text}
            highlight={filters[categoryLevel.toString()] !== item.text}
          />
        ))}
      </Container>
    );
  } else {
    return <div></div>;
  }
};

const Container = styled.div`
  background-color: white;
  overflow-y: scroll;
  max-height: 100%;
  min-height: 100%;
  width: 100%;
`;

export default CatalogFilterList;
